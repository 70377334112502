import { RotateAnimation } from "@local";
import { useContext, FC } from "react";

import Modal from 'react-modal';
import { AuthenticationConsumer } from "../Context/AuthenticationConsumer";
import { Icon } from "../Icon";
import { SHeadline, SWrapper } from "./index.styled";

interface AuthenticationModalProps {
  isOpen: boolean;
  headline: string;
  secondText: string;
}

const customModalStyles: Modal.Styles = {
  overlay: {
    background: 'rgba(85, 85, 85, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '320px',
    width: '320px',
    background: 'white',
    padding: '26px 47px',
    fontSize: '12px',
    overflow: "hidden"
  }

}

const AuthenticationModal: FC<AuthenticationModalProps> = ({ isOpen, headline, secondText }) => {

  Modal.setAppElement('#root');

  const { controlCode } = useContext(AuthenticationConsumer);
  return (
    <Modal isOpen={isOpen} style={customModalStyles}>
      <SWrapper>
        <RotateAnimation duration={1500} reverse={false}>
          <Icon icon="VALITORICONBOXSTACKED" />
        </RotateAnimation>
        <SHeadline className="header">{headline}</SHeadline>
        <p>Öryggistala: <b>{controlCode ? controlCode : "Sæki öryggistölu..."}</b></p>
        <p>{secondText}</p>
      </SWrapper>
    </Modal >
  );
};

export { AuthenticationModal };