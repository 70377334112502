import styled, { css } from 'styled-components';

export const SMediaUpload = styled.label`
  ${({ theme: { color, grid } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${color.purple};
    background-color: transparent;
    border: 1px solid ${color.purple};
    border-radius: 4px;
    padding: 16px 0px;
    cursor: pointer;

    .media-upload-text {
      margin-left: ${`${grid.horizontal}px`};
    }
    .medium-margin-bottom {
      margin-bottom: ${grid.horizontal * 40}px;
    }
  `}
`;

export const Sparagraph = styled.p`
  ${({ theme: { grid } }) => css`
    margin-bottom: ${`${grid.vertical * 6}px`};
  `}
`;

export const SMediaUploadInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const SIconContainer = styled.div`
  ${({ theme: { color } }) => css`
    position: relative;

    &.init {
      svg:nth-child(2),
      svg:nth-child(3) {
        opacity: 0;
      }
    }
    svg {
      transition: opacity 0.5s;

      &:first-child {
        position: absolute;
      }

      &:nth-child(3) {
        position: absolute;
        left: 0;
      }
    }

    &.loading {
      svg:first-child,
      svg:nth-child(3) {
        opacity: 0;
      }

      svg:nth-child(2) {
        opacity: 1;
      }
    }

    &.ready {
      svg:nth-child(3) path {
        fill: ${color.teal};
      }

      svg:first-child,
      svg:nth-child(2) {
        opacity: 0;
      }
    }
  `}
`;
