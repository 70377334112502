import styled, { css } from 'styled-components';

export const SNav = styled.nav`
  ${({ theme: { grid } }) => css`
    padding-top: ${grid.horizontal * 8}px;
    margin-bottom: ${grid.horizontal * 5.5}px;
    max-width: 100%;
  `}
`;
export const SWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    display: flex;
    justify-content: space-between;
    padding-left: ${grid.vertical}px;
    padding-right: ${grid.vertical}px;
    max-width: 100vw;
  `}
`;

export const SButton = styled.button`
  ${({ theme: { color, grid } }) => css`
    border: none;
    height: ${grid.horizontal * 3.5}px;
    flex-direction: column;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.333em;
    margin-bottom: 0;
    background-color: white;
    color: ${color.blue};
    display: flex;
    align-items: center;
    opacity: 1;

    &:disabled {
      opacity: 0.2;
      pointer-events: none;
    }
    &[aria-current='true'] {
      font-weight: bold;
    }

    &:hover {
      text-decoration: none;
    }
    /* Fix so the progressbar icons don't jump when they get selected because of font bold */
    &:before {
      display: block;
      visibility: hidden;
      overflow: hidden;
      height: 0;
      font-weight: bold;
      content: attr(data-title);

      @media (max-width: 767px) {
        display: none;
      }
    }
    svg {
      height: ${grid.horizontal * 3}px;
      width: ${grid.vertical * 4}px;
    }
  `}
`;

export const SButtonText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`
