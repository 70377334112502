import { TerminalProps } from '../components/terminal/Terminal';
import { CompanyData } from '../components/Context/CompaniesHouseConsumer';
import { SelectOption } from 'src/components/Select';

interface FormValuesTypes {
  [index: string]: any; // eslint-disable-line
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Email: string;
  Phone: string;
  PhonePrefix: string;
  PhoneNumber: string;
  Street: string;
  StreetContinued: string;
  City: string;
  Postcode: string;
  StoreName: string;
  Mcc: {
    value: string;
    label: string;
  };
  SecondMcc: {
    value: string;
    label: string;
  };
  TradingStreet: string;
  TradingStreetContinued: string;
  TradingStreetCity: string;
  TradingStreetPostcode: string;
  EmailStores: string;
  PhoneStores: string;
  AcceptedCardsList: { value: string };
  OpeningHours: string;
  BankName: string;
  BankAddress: string;
  BankAddressContinued: string;
  BankAddressStreetCity: string;
  BankAddressStreetPostcode: string;
  SwiftCode: string;
  SortCode: string;
  AccountNumber: string;
  Iban: string;
  AverageTransactionAmount: string;
  AvgRevenue: string;
  AvgProcessed: string;
  MaxTrans: string;
  MotoTrans: string;
  DepositDays: string;
  DepositWeeks: string;
  DepositMonths: string;
  PaymentDays: string;
  AcceptedPaymentsGuaranteeWarranties: string;
  UpfrontDays: string;
  UpfrontPayments: string[];
  AverageTimeDelivery: string;
  AnnualTurnOverDeposit: string;
  AnnualTurnoverPayments: string;
  AuthorizationFees: string;
  DiscountRate: string;
  FasterPayments: string;
  Chargeback: string;
  PaymentFee: string;
  Year: SelectOption;
  Month: SelectOption;
  Date: SelectOption;
  AgreementDate: Date;
  AirtimeServiceFee: string;
  PaymentTerminalFee: string;
  SPFirstName: string;
  SPLastName: string;
  EmailSales: string;
  BDAOrSelfGen: string;
  BDAName: string;
  terminalList: { terminals: TerminalProps[] };
  TerminalDeliveryPrice: number;
  userID: File;
  PreviouslyAcceptedCards: string;
  HasTakenRemedialActions: string;
  AuthorizedSignatory: string;
  TypeOfCards: string[];
  AmexPaymentPlan: string;
  Stores: StoreType[];
  FeeList: {
    MerchantServiceChargeFees: { [index: string]: string };
    ProcessingFees: { [index: string]: string };
    OtherFees: { [index: string]: string };
  };
  BusinessStartDate: string;
  BusinessStartDay: string;
  BusinessStartMonth: string;
  BusinessStartYear: string;
  CompaniesHouseData: CompanyData;
  ProofOfBankVerified: string[];
  AdobeEmails: {
    Email: string;
  }[];
  Directors: {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Street: string;
    StreetContinued: string;
    City: string;
    Postcode: string;
    Email: string;
    Phone: string;
    PhoneNumber: string;
    PhonePrefix: string;
    Roles: string[];
    Identification: File;
  }[];
  DirectorsPersons: {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    BirthStartDay: string;
    BirthStartMonth: string;
    BirthStartYear: string;
    Street: string;
    StreetContinued: string;
    City: string;
    Postcode: string;
    Email: string;
    Phone: string;
    PhoneNumber: string;
    PhonePrefix: string;
    Shares: string;
    Identification: File;
  }[];
  Company: {
    Name: string;
    Number: string;
    BusinessStartDay: string;
    BusinessStartMonth: string;
    BusinessStartYear: string;
    Street: string;
    StreetContinued: string;
    City: string;
    Postcode: string;
  };
  ShareCompanies: {
    Name: string;
    Number: string;
    Shares: number;
    Country: string;
  }[];
  SignedContract: string;
  AmexTermsAccepted: string[],
  AgreementAccepted: string[],
  TermsAccepted: string[],
  RafraenSkilriki: string,
  DescriptionOfProductsAndServices: string,
}

interface EcommerceSolution {
  EcommerceType: EcommerceType;
  WebSiteURL: string;
}

interface EcommerceType {
  value: string;
  label: string;
}

interface StoreType {
  StoreName: string;
  Mcc?: {
    value: string;
    label: string;
  };
  TradingStreet: string;
  TradingStreetContinued: string;
  TradingStreetPostcode: string;
  TradingStreetCity: string;
  AvgTrans: string;
  AvgProcessed: string;
  MaxTrans: string;
  MotoTrans: string;
  BusinessExistence: object | object[];
  AvgRevenue?: string;
  ReasonForMoto?: string;
  TakeMoto?: string;
  EcommerceSolutions?: EcommerceSolution;
};

enum TargetMarket {
  Iceland = "Iceland",
  CrossBorder = "CrossBorder"
}

enum EnvironmentUrl {
  DEV = "localhost",
  TEST = "vali9",
  UAT = "sandbox"
}

export type { FormValuesTypes, EcommerceSolution, StoreType }
export { TargetMarket, EnvironmentUrl }