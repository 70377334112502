import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FormValuesTypes } from 'src/types/formValues';
import ObjectPath from 'object-path';

/* eslint-disable no-param-reassign */
const parsePhoneNumber = (componentName: string, values?: FormValuesTypes) => {
  const phonePrefixName = `${componentName}Prefix`;
  const phoneNumbername = `${componentName}Number`;

  if (values) {
    if (ObjectPath.get(values, componentName)) {
      const componentNameValue = ObjectPath.get(values, componentName);

      ObjectPath.set(
        values,
        phonePrefixName,
        `+${
          parsePhoneNumberFromString(componentNameValue as string)
            ?.countryCallingCode
        }`,
      );

      const phonePrefixValue = ObjectPath.get(values, phonePrefixName);

      ObjectPath.set(
        values,
        phoneNumbername,
        componentNameValue.replace(phonePrefixValue, ''),
      );
    }
  }
};

export { parsePhoneNumber };
