import { FC, useState } from "react";
import { animated, useSpring } from "react-spring";

interface RotateAnimationProps {
  duration: number,
  reverse: boolean
}

const RotateAnimation: FC<RotateAnimationProps> = ({
  duration,
  children,
  reverse
}) => {

  const [resetOrbit, setResetOrbit] = useState(false);
  const animationStyle = useSpring({
    config: { duration },
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
    reverse: reverse,
    onRest: () => setResetOrbit(prev => !prev),
    reset: resetOrbit
  });


  return (
    <animated.div style={animationStyle}>
      {children}
    </animated.div>
  );
};
export { RotateAnimation };