import React from 'react';

export const DocumentIcon = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 0.5H0.75V15.5H11.25V5H6.75V0.5ZM3.25 5H5.25C5.38261 5 5.50979 5.05268 5.60355 5.14645C5.69732 5.24021 5.75 5.36739 5.75 5.5C5.75 5.63261 5.69732 5.75979 5.60355 5.85355C5.50979 5.94732 5.38261 6 5.25 6H3.25C3.11739 6 2.99021 5.94732 2.89645 5.85355C2.80268 5.75979 2.75 5.63261 2.75 5.5C2.75 5.36739 2.80268 5.24021 2.89645 5.14645C2.99021 5.05268 3.11739 5 3.25 5ZM7.75 13.5H3.25C3.11739 13.5 2.99021 13.4473 2.89645 13.3536C2.80268 13.2598 2.75 13.1326 2.75 13C2.75 12.8674 2.80268 12.7402 2.89645 12.6464C2.99021 12.5527 3.11739 12.5 3.25 12.5H7.75C7.88261 12.5 8.00979 12.5527 8.10355 12.6464C8.19732 12.7402 8.25 12.8674 8.25 13C8.25 13.1326 8.19732 13.2598 8.10355 13.3536C8.00979 13.4473 7.88261 13.5 7.75 13.5ZM8.75 11H3.25C3.11739 11 2.99021 10.9473 2.89645 10.8536C2.80268 10.7598 2.75 10.6326 2.75 10.5C2.75 10.3674 2.80268 10.2402 2.89645 10.1464C2.99021 10.0527 3.11739 10 3.25 10H8.75C8.88261 10 9.00979 10.0527 9.10355 10.1464C9.19732 10.2402 9.25 10.3674 9.25 10.5C9.25 10.6326 9.19732 10.7598 9.10355 10.8536C9.00979 10.9473 8.88261 11 8.75 11ZM8.75 7.5C8.88261 7.5 9.00979 7.55268 9.10355 7.64645C9.19732 7.74021 9.25 7.86739 9.25 8C9.25 8.13261 9.19732 8.25979 9.10355 8.35355C9.00979 8.44732 8.88261 8.5 8.75 8.5H3.25C3.11739 8.5 2.99021 8.44732 2.89645 8.35355C2.80268 8.25979 2.75 8.13261 2.75 8C2.75 7.86739 2.80268 7.74021 2.89645 7.64645C2.99021 7.55268 3.11739 7.5 3.25 7.5H8.75Z"
      fill="#E95420"
    />
  </svg>
);

export default DocumentIcon;
