import styled, { css } from 'styled-components';

export const SDescriptionTerm = styled.dt`
  ${({ theme: { color } }) => css`
    color: ${color.blue};
    font-size: 16px;
    font-weight: normal;
    padding-bottom: 4px;
  `}
`;

export const SDescriptionDetail = styled.dd`
  ${({ theme: { color, grid } }) => css`
    font-size: 22px;
    color: ${color.darkGray};
    line-height: 1.363em;
    margin-bottom: ${grid.horizontal * 1.5}px;

    &.extra-margin-bottom {
      margin-bottom: ${grid.horizontal * 4}px;
    }
  `}
`;
