import React, { FunctionComponent } from 'react';
import { Title } from '@local';
import { FormValuesTypes } from 'src/types/formValues';
import ObjectPath from 'object-path';

interface DynamicTitleProps {
  tag?: 'h1' | 'h2' | 'h3' | 'h4';
  className?: string;
  fieldName?: string;
  values?: FormValuesTypes;
  text?: string;
}

const DynamicTitle: FunctionComponent<DynamicTitleProps> = ({
  tag,
  fieldName,
  className,
  values,
  text,
}) => {
  const fieldText =
    (values && values[fieldName as string]) ||
    (values && ObjectPath.get(values, fieldName as string)) ||
    'Field name is missing';

  const displayText = text ? `${text} ${fieldText}` : fieldText;
  return <Title tag={tag} text={displayText} className={className} />;
};

export { DynamicTitle };
export type { DynamicTitleProps };
