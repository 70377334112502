import React from 'react';
import { Counter, Terminal } from '@local';

import { TerminalProps } from '../Terminal';
import { SetValueProps } from '../../Counter';
import { SWrapper } from './index.styled';

interface TerminalListProps {
  terminals?: TerminalProps[];
  name: string;
  onChange: (value: SetValueProps) => void;
  counterText?: string;
}

const TerminalList = ({ onChange, name, terminals, counterText }: TerminalListProps) => {
  return terminals ? (
    <>
      {terminals.map(({ image, title, kicker, list, index, defaultValue }) => (
        <SWrapper className="row" key={title + kicker}>
          <Terminal title={title} kicker={kicker} list={list} image={image}>
            <Counter
              onChange={onChange}
              title={title}
              name={`${name}.${index}`}
              text={counterText || 'How many do you need?'}
              index={index}
              defaultValue={defaultValue}
            />
          </Terminal>
        </SWrapper>
      ))}
    </>
  ) : null;
}
export { TerminalList };
