export default {
  id: 'bankaUpplysingar',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Bankaupplýsingar',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'BankName',
      label: 'Nafn á viðskiptabanka',
      type: 'text',
      icon: 'BANK',
      placeholder: 'Sláðu inn nafn á viðskiptabanka þínum',
      validations: ['REQUIRED(Þú þarft að slá inn nafn á viðskiptabanka)'],
      reviewPageTitle: 'Nafn á viðskiptabanka',
    },
    {
      componentType: 'TITLE',
      text: 'Reikningsupplýsingar',
      className: 'size-md-medium medium-margin-top',
    },
    {
      componentType: 'INPUTSIDEBYSIDE',
      reviewPageTitle: 'Reikningsupplýsingar',
      components: [
        {
          componentType: 'INPUT',
          name: 'Bank',
          label: 'Banki',
          type: 'text',
          placeholder: '0123',
          maxLength: 4,
          validations: ['REQUIRED(Þú þarft að slá inn banka)', 'NUMBER(Verður að vera tala)', 'MINVALUE(3 IS)', 'MAXLENGTH(4 IS)'],
          reviewPageTitle: 'Banki',
        },
        {
          componentType: 'INPUT',
          name: 'Ledger',
          label: 'Hb.',
          type: 'text',
          placeholder: '26',
          maxLength: 2,
          validations: ['REQUIRED(Þú þarft að slá inn Hb.)', 'NUMBER(Verður að vera tala)', 'MAXLENGTH(2 IS)', 'MINLENGTH(2 IS)'],
          reviewPageTitle: 'Höfuðbók',
        },
        {
          componentType: 'INPUT',
          name: 'AccountNumber',
          label: 'Reikningsnúmer',
          type: 'text',
          placeholder: '123456',
          maxLength: 6,
          validations: ['REQUIRED(Þú þarft að slá inn reikningsnúmer)', 'NUMBER(Verður að vera tala)', 'MINVALUE(1 IS)', 'MAXLENGTH(6 IS)'],
          reviewPageTitle: 'Reikningsnúmer',
        },
      ],
    },
  ],
};
