import React, { useContext, useEffect } from 'react';

import {
  Accordion,
  AccordionList,
  FeesConsumer,
  FeesLine,
  Icon,
  Info,
  Button,
} from '@local';

import { removeWhiteSpace } from '@utils';

import { FeeLine, Fee } from 'src/components/Context/FeesConsumer';
import { FormValuesTypes } from 'src/types/formValues';
import {
  SFeesListWrapper,
  SLoadingWrapper,
  SWarningWrapper,
  SWrapper,
} from './index.styled';
import { captureException } from '@sentry/nextjs';
import { Context } from 'vm';

interface FeesListProps {
  accordionOpen?: boolean;
  title?: string;
  validations?: string[];
  values: FormValuesTypes;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkAmexFee = (TypeOfCards: string[]) => {
  const amex = TypeOfCards ? TypeOfCards.includes('Amex') : null;
  if (amex) {
    return ({
      title: 'Other Fees',
      fees: [
        ({
          name: 'Amex discount rate',
          min: 0,
          max: 100,
          value: 3.6,
          decimalPrecision: 2,
          editable: true,
          icon: 'percentage',
          ValueType: 'Percentage',
        } as unknown) as Fee,
      ],
    } as unknown) as FeeLine;
  }
  return null;
};

const FeesList = ({ accordionOpen, values }: FeesListProps) => {
  const { feeLine, status, fetchFeesFunction, setFeesLoadedSuccessfully } = useContext(FeesConsumer);
  // Amex fees should only be displayed when amex option is checked in store step
  useEffect(() => {
    const amexFeeLine = checkAmexFee(values.TypeOfCards);
    if (amexFeeLine != null && !feeLine.some(x => x.title === 'Other Fees')) {
      feeLine.push(amexFeeLine);
    } else if (feeLine.filter(x => x.title === 'Other Fees').length > 1) {
      feeLine.pop();
    }
  }, [feeLine, values.TypeOfCards]);

  if (status === 'fetching') {
    setFeesLoadedSuccessfully(false);
    return (
      <SLoadingWrapper>
        <Icon icon="LOADINGLARGE" />
      </SLoadingWrapper>
    );
  }

  if (status === 'error') {
    // Log error to sentry
    captureException("Error getting fees", scope => {
      scope.setContext("values", null);
      scope.setContext("values", values as Context);
      return scope;
    });
    setFeesLoadedSuccessfully(false);
    return (
      <SWarningWrapper>
        <Info icon='INFOERROR'>An error occured when trying to fetch the fees. You can try to fetch them again with the button below.</Info>
        <Button type='button' id='fetch-fees-again' onClick={() => fetchFeesFunction()}>Retry</Button>
      </SWarningWrapper>
    );
  }

  if (status === 'completed') {
    setFeesLoadedSuccessfully(true);
  }

  return feeLine ? (
    <>
      {feeLine.map( (line: FeeLine, index: number) => (
        <SFeesListWrapper key={index} >
          {line.title && (
            <AccordionList>
              <Accordion
                title={line.title}
                id={line.title}
                startOpen={accordionOpen}>
                <SWrapper>
                  {line.fees &&
                    line.fees.map(fee => (
                      <FeesLine
                        validations={[
                          `MINVALUE(${fee.min})`,
                          `MAXVALUE(${fee.max})`,
                          'REQUIRED',
                          'NUMBER',
                        ]}
                        key={fee.name}
                        icon="percentage"
                        text={fee.name}
                        name={`FeeList.${removeWhiteSpace(
                          line.title,
                        )}.${removeWhiteSpace(fee.name)}`}
                        disabled={!fee.editable}
                        initialValue={Number(fee.value).toFixed(
                          fee.decimalPrecision,
                        )}
                        type={fee.ValueType}
                        fixed={fee.decimalPrecision}
                        shouldUpdate={fee.shouldUpdate}
                        values={values}
                      />
                    ))}
                </SWrapper>
              </Accordion>
            </AccordionList>
          )}
        </SFeesListWrapper>
      ))}
    </>
  ) : (
    <SWarningWrapper>
      <Info icon='INFOERROR'>An error occured when trying to fetch the fees. You can try to fetch them again with the button below.</Info>
      <Button type='button' id='fetch-fees-again' onClick={() => fetchFeesFunction()}>Retry</Button>
    </SWarningWrapper>
  );
};
export { FeesList };
export type { FeesListProps }