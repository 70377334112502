export default {
  id: 'kortategundir',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  conditionalPreviousSteps: [
    {
      id: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
      value: 'Shopify',
      skipToStep: 'verslun',
    },
    {
      id: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
      value: 'WooCommerce',
      skipToStep: 'verslun',
    },
    {
      id: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
      value: 'Magento',
      skipToStep: 'verslun',
    },
    {
      id: 'PaymentSolutions',
      value: 'cardPresent',
      skipToStep: 'verslun',
    },
  ],
  fields: [
    {
      componentType: 'TITLE',
      text: 'Hvenær hófst þú rekstur?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'BusinessStartDate',
      label: 'Upphafsdagsetning fyrirtækis (DD / MM / YYYY)',
      type: 'text',
      mask: 'DATE',
      placeholder: 'DD / MM / YYYY',
      validations: ['REQUIRED(Þú þarft að slá inn dagsetningu)', 'MAXLENGTH(14 IS)', 'MINLENGTH(14 IS)', 'DATE(IS)'],
      reviewPageTitle: 'Upphafsdagsetning fyrirtækis',
    },
    {
      componentType: 'TITLE',
      className: 'size-md-medium',
      text: 'Hvaða kortategundum vilt þú taka á móti?',
    },
    {
      componentType: 'CHECKBOX',
      label: 'Card type',
      validations: ['CHECKBOX(Þú þarft að velja kortategund)'],
      name: 'TypeOfCards',
      reviewPageTitle: 'Leyfilegar kortategundir',
      checkboxValues: [
        {
          labelText: 'Visa',
          name: 'TypeOfCards',
          value: 'Visa',
          checked: true,
        },
        {
          labelText: 'Mastercard',
          name: 'TypeOfCards',
          value: 'Mastercard',
          checked: true,
        }
      ],
    },
  ],
};
