export default {
  id: 'greidslulausnir',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  conditionalNextSteps: [
    {
      id: 'PaymentSolutions',
      value: ['ecommerce'],
      skipToStep: 'verslun',
    },
  ],
  fields: [
    {
      componentType: 'TITLE',
      text: 'Veldu greiðslulausnir',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'CHECKBOX',
      validations: ['CHECKBOX(Þú þarft að velja netverslun og/eða greiðsluposar)'],
      reviewPageTitle: 'Valdar greiðslulausnir',
      name: 'PaymentSolutions',
      checkboxValues: [
        {
          labelText: 'Netverslun',
          name: 'PaymentSolutions',
          value: 'ecommerce',
        },
        {
          labelText: 'Greiðsluposar',
          name: 'PaymentSolutions',
          value: 'cardPresent',
        },
      ],
    },
  ],
};
