import React, { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { AbsBeginning, Icon, VersionNumber, ValitorUKBeginning } from '@local';
import { SBeggingScreenWrapper, SContentWrapper, SLogo } from './index.styled';
import { ValitorISBeginning } from './ValitorIS';

// TODO have the text and list of forms come from a JSON so it can be easily changable

const Beginning: FunctionComponent = () => {
  const router = useRouter();
  return (
    <SBeggingScreenWrapper>
      <SContentWrapper>
        <SLogo>
          <Icon icon="VALITORICONBOX" />
        </SLogo>

        <div className="row">
          <div className="col-sm-5 col-md-5 col-lg-7">
            {router.pathname === '/umsokn' ? <ValitorISBeginning /> : router.query.country ? <AbsBeginning /> : <ValitorUKBeginning />}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-5 col-md-6 col-lg-7">
            <VersionNumber />
          </div>
        </div>
      </SContentWrapper>
    </SBeggingScreenWrapper>
  );
};

export { Beginning };
