import React from 'react';

export const LoadingIndicatorLarge = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="#4F9EA6"
    width="250px"
    height="70px">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <circle cx="31" cy="35" r="30" fill="#FFF">
        <animate
          attributeType="XML"
          attributeName="r"
          keyTimes="0;0.167;0.5;0.668;1"
          values="0;30;30;17.5;0"
          dur="2000ms"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="98" cy="35" r="30" fill="#FFF">
        <animate
          attributeType="XML"
          attributeName="r"
          keyTimes="0;0.167;0.334;0.668;0.835;1"
          values="0;17.5;30;30;17.5;0"
          dur="2000ms"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="168" cy="35" r="30" fill="#FFF">
        <animate
          attributeType="XML"
          attributeName="r"
          keyTimes="0;0.334;0.5;0.835;1"
          values="0;17.5;30;30;0"
          dur="2000ms"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </g>
  </svg>
);

export const LoadingIndicatorSmall = () => (
  <svg
    width="70"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#4F9EA6">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <circle cx="9" cy="12" r="8" fill="#FFF">
        <animate
          attributeType="XML"
          attributeName="r"
          keyTimes="0;0.167;0.5;0.668;1"
          values="0;8;8;2.4;0"
          dur="2000ms"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="30" cy="12" r="8" fill="#FFF">
        <animate
          attributeType="XML"
          attributeName="r"
          keyTimes="0;0.167;0.334;0.668;0.835;1"
          values="0;2.4;8;8;2.4;0"
          dur="2000ms"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="50" cy="12" r="8" fill="#FFF">
        <animate
          attributeType="XML"
          attributeName="r"
          keyTimes="0;0.334;0.5;0.835;1"
          values="0;2.4;8;8;0"
          dur="2000ms"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </g>
  </svg>
);
