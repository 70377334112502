import { post } from "./http";

interface SignDocumentWithMobileIDData {
  token: string;
  status: string;
  control_code: string;
}

interface SignDocumentWithMobileIDResponseType {
  status: number;
  data: SignDocumentWithMobileIDData;
}

interface File {
  name: string;
  content: string;
  digest: string;
}

interface SignDocumentWithMobileIDStatusData {
  status: string;
  signature_id: string;
  file: File;
}

interface SignDocumentWithMobileIDStatusResponseType {
  status: number;
  data: SignDocumentWithMobileIDStatusData;
}

const apiLocation = () => {
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : `https://api.${window.location.host}`;
};

const signDocumentWithMobileID = async (
  phonenumber: string,
  firstName: string,
  lastName: string,
  unsignedContract: string,
) => {
  const requestUri = `${apiLocation()}/api/SignDocumentWithMobileID`;

  const lastNameArray = lastName.split(' ');

  // Remove trailing whitespace if user doesn't have a middle name
  if (lastNameArray[0] === '') {
    lastName = lastName.replace(' ', '');
  }

  const body = {
    "phone": phonenumber,
    "firstName": firstName,
    "lastName": lastName, // If there is a middle name, it comes here before the last name
    "unsignedContract": unsignedContract,
  }

  const response = await post(body, requestUri, '65d671b0-e569-4e59-b290-ca6663b20bf6');

  if (response.status !== 200) {
    throw JSON.parse(response.parsedBody as string);
  }

  return {
    data: response.parsedBody,
    status: response.status
  } as SignDocumentWithMobileIDResponseType;
}

const signDocumentWithMobileIDStatus = async (token: string) => {

  const requestUri = `${apiLocation()}/api/SignDocumentWithMobileIDStatus?token=${token}`;

  const response = await fetch(requestUri);

  if (response.status !== 200) {
    throw await response.json();
  }

  const data = await response.json();

  return {
    data,
    status: response.status
  } as SignDocumentWithMobileIDStatusResponseType;
}

export { signDocumentWithMobileID, signDocumentWithMobileIDStatus };