import React from 'react';
import ReactSelect from 'react-select';
import { StylesConfig } from 'react-select/lib/styles';
import { AnyObject } from 'react-final-form';
import ObjectPath from 'object-path';
import makeAnimated from 'react-select/lib/animated';
import { MCCODES } from '../../public/static/content/mcc_codes';
import * as variables from '../../public/static/variables';
import { SelectWrapper, StyledLabel } from './index.styled';
import { useRouter } from 'next/router';
import { ISL_MCCCODES } from '../../public/static/content/mcc_codes_is';

interface SelectProps {
  placeholder?: string;
  options?: SelectOption[] | string;
  id: string | number;
  label?: string;
  defaultValue?: SelectOption;
  disabled?: boolean;
  isSearchable?: boolean;
  error?: boolean;
  message?: string;
  active?: boolean;
  touched?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInputChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  value?: string;
  values?: AnyObject;
  name?: string;
  className?: string;
  multiSelect?: boolean;
}

interface SelectOption {
  value: string;
  label: string;
}

const ROLES = [
  { value: 'director', label: 'Director' },
  { value: 'primaryContact', label: 'Primary contact' },
  { value: 'authorizedSignatory', label: 'Authorized signatory' },
  { value: 'boardOfDirectors', label: 'Board of directors' },
  { value: 'beneficialOwner', label: 'Beneficial owner' },
  { value: 'votingControl', label: 'Voting control' },
  { value: 'shareOwner', label: 'Share owner' },
];

const WOOCOMMERCE = [
  { value: 'greidslusida', label: 'Greiðslusíða' },
  { value: 'valitorPay', label: 'ValitorPay' },
]

const SHOPIFY = [
  { value: 'greidslusida', label: 'Greiðslusíða' },
]

const MAGENTO = [
  { value: 'valitorPay', label: 'ValitorPay' },
]

const OTHERECOMMERCE = [
  { value: 'greidslusida', label: 'Greiðslusíða' },
  { value: 'valitorPay', label: 'ValitorPay' },
  { value: 'checkout', label: 'Checkout' }
]

const animatedComponents = makeAnimated();

// Style object for react-select. See react-select documentation on custom styles for further reference.
const selectStyles: StylesConfig = {
  control: (_styles, { isFocused, hasValue }) => {
    const isTeal = isFocused || hasValue;

    return {
      display: 'flex',
      borderRadius: 4,
      border: isTeal
        ? `1px solid ${variables.theme.color.teal}`
        : `1px solid ${variables.theme.color.grayThree}`,
      transition: 'all .2s',
      fontSize: '20px',
    };
  },
  dropdownIndicator: (_styles, { isFocused }) => {
    return {
      color: isFocused
        ? variables.theme.color.teal
        : variables.theme.color.darkGray,
      transform: `translateY(${isFocused ? '4px' : '0px'}) rotate(${
        isFocused ? '180deg' : '0deg'
      })`,
      transition: 'all .2s',
    };
  },
  indicatorsContainer: () => {
    return {
      display: 'flex',
      padding: `${variables.theme.grid.vertical * 2}px ${
        variables.theme.grid.horizontal
      }px ${variables.theme.grid.horizontal}px 0px`,
      alignItems: 'center',
    };
  },
  indicatorSeparator: () => {
    return { display: 'none' };
  },
  option: (styles, { isSelected, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? variables.theme.color.teal15
        : variables.theme.color.white,
      fontWeight: isSelected ? 600 : 'normal',
      fontSize: '20px',
      color: variables.theme.color.darkGray,
      paddingBottom: `${variables.theme.grid.horizontal}px`,
      paddingTop: `${variables.theme.grid.horizontal}px`,
    };
  },
  valueContainer: styles => {
    return {
      ...styles,
      padding: `${variables.theme.grid.vertical * 2}px ${variables.theme.grid
        .horizontal * 1.5}px`,
    };
  },
  menu: styles => {
    return {
      ...styles,
      border: `1px solid ${variables.theme.color.teal}`,
    };
  },
  container: styles => {
    return {
      ...styles,
      marginTop: `${variables.theme.grid.vertical}px`,
    };
  },
  placeholder: styles => {
    return {
      ...styles,
      color: `${variables.theme.color.grayThree}`,
      fontWeight: 100,
    };
  },
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: `${variables.theme.color.lightGrayTwo}`,
    };
  },
  multiValueLabel: styles => {
    return {
      ...styles,
      color: `${variables.theme.color.grayThree}`,
      fontWeight: 500,
    };
  },
  multiValueRemove: styles => {
    return {
      ...styles,
      color: `${variables.theme.color.grayTwo}`,
      ':hover': {
        backgroundColor: `${variables.theme.color.lightGrayThree}`,
      },
    };
  },
};

const Select = (props: SelectProps) => {
  const {
    disabled,
    placeholder,
    label,
    options,
    error,
    message,
    id,
    isSearchable,
    active,
    touched,
    defaultValue,
    value,
    values,
    name,
    className,
    multiSelect,
    ...rest
  } = props;

  // TODO: Should not be hardcoded for MCC
  let templateOptions;

  const router = useRouter();

  if (typeof options === 'string') {
    if (options === 'ROLES') { templateOptions = ROLES; }
    else if (options === 'MCC' && router.asPath.includes('umsokn')) { templateOptions = ISL_MCCCODES }
    else if (options === 'MCC') { templateOptions = MCCODES; }
    else if (options === "Shopify") { templateOptions = SHOPIFY }
    else if (options === "WooCommerce") { templateOptions = WOOCOMMERCE }
    else if (options === "Magento") { templateOptions = MAGENTO }
    else if (options === "OtherEcommerce") { templateOptions = OTHERECOMMERCE }
  } else {
    templateOptions = options;
  }

  // After we added dynamic routing the component is rendered before the window is defined - no styles rendered
  // So we add this check so we get fully rendered component with styling
  return options && typeof window !== 'undefined' ? (
    <>
      <SelectWrapper
        active={active}
        error={error}
        touched={touched}
        {...{ className }}>
        <StyledLabel htmlFor={id as string}>{label}</StyledLabel>
        <ReactSelect
          {...rest}
          className={error ? 'invalid' : 'valid'}
          id={(name as string) || (id as string)}
          data-testid={id}
          aria-label={id as string}
          blurInputOnSelect
          instanceId="react-select"
          isDisabled={disabled}
          options={templateOptions}
          styles={selectStyles}
          placeholder={placeholder}
          isSearchable={isSearchable}
          defaultValue={defaultValue}
          isMulti={multiSelect}
          components={animatedComponents}
          menuPortalTarget={document.body}
          value={
            values
              ? values[name as string] || ObjectPath.get(values, name as string)
              : value
          }
        />
      </SelectWrapper>
    </>
  ) : null;
};

export { selectStyles, Select };
export type { SelectOption }