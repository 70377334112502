export default {
  // See logic: https://confluence.iteron.org/display/webdev/DA+-+Risk+questions+flow
  title: 'Do you take payments in advance?',
  id: 'paymentInAdvance',
  conditionalPreviousSteps: [
    {
      id: 'Stores.0.MotoTrans',
      skipToStep: 'maxTransaction',
      optionalLessThen: '1',
    },
  ],
  conditionalNextSteps: [
    {
      id: 'PaymentsAdvance',
      value: 'no',
      skipToPage: 'terminals',
      skipToStep: 'terminalList',
    },
  ],
  fields: [
    {
      componentType: 'RADIOBUTTONS',
      validations: ['REQUIRED'],
      radioValues: [
        {
          labelText: 'Yes',
          name: 'PaymentsAdvance',
          value: 'yes',
        },
        {
          labelText: 'No',
          name: 'PaymentsAdvance',
          value: 'no',
          checked: true,
        },
      ],
    },
  ],
};
