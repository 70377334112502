import styled, { css } from 'styled-components';

const SWrapper = styled.dl`
  ${({ theme: { color, grid } }) => css`
    border-bottom: 2px solid ${color.teal};
    padding-top: ${grid.horizontal * 3}px;
    padding-bottom: ${grid.horizontal * 3}px;
  `}
`;

export { SWrapper };
