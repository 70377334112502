import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import { CompaniesHouseConsumer, ErrorLabel } from '@local';
import { FieldTypes } from 'src/types/field';
import { FormValuesTypes } from 'src/types/formValues';
import { ComponentMap } from '../../../helpers/componentMapper/index';

interface SharesInputProps {
  name: string;
  values?: FormValuesTypes;
}

const SharesInput = ({ name, values }: SharesInputProps) => {
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);

  const inputField = {
    componentType: 'INPUT',
    name,
    label: 'Shares',
    type: 'NUMBER',
    icon: 'PIECHART',
    placeholder: '% Shares',
    validations: ['REQUIRED', 'MAXVALUE(100)'],
    reviewPageTitle: 'Shares',
  } as FieldTypes;

  const validateShares = (allValues: FormValuesTypes) => {
    let totalShares = 0;
    // Counting shares of existing directors
    if (companiesHouseValues) {
      companiesHouseValues.persons_of_interest.forEach(person => {
        totalShares += Number(person.share_percentage);
      });
    }
    // Counting shares of new shareholders
    if (allValues.DirectorsPersons) {
      allValues.DirectorsPersons.forEach(person => {
        if (person.Shares) {
          totalShares += Number(person.Shares);
        }
      });
    }

    if (totalShares > 100) {
      return (
        <ErrorLabel>
          Combined shares percentage cannot be more than 100%
        </ErrorLabel>
      );
    }
    return undefined;
  };

  return (
    <Field
      name={name}
      validate={(v, allValues) => validateShares(allValues as FormValuesTypes)}>
      {() => {
        return <ComponentMap field={inputField} values={values} />;
      }}
    </Field>
  );
};

export { SharesInput };
