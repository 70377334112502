import { DefaultTheme } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const theme: DefaultTheme = {
  breakpoints: {
    mobileMax: '649px',
    mobileMin: '320px',
    tabletMax: '999px',
    tabletMin: '650px',
    desktopMax: '2000px',
    desktopMin: '1000px',
    desktopMed: '1250px',
  },
  color: {
    blue: '#1B5074',
    darkGray: '#555555',
    darkYellow: '#EAB931',
    grayOne: '#FBFBFB',
    grayTwo: '#919191',
    grayThree: '#767676',
    grayFour: '#F3F3F3',
    green: '#00A243',
    lightBlue: '#E8EEF1',
    lightGray: '#FAFAFA',
    lightGrayTwo: '#EEEEEE',
    lightGrayThree: '#C4C4C4',
    moss: '#748116',
    orange: '#E95420',
    purple: '#1B5074',
    red: '#D50000',
    teal: '#4F9EA6',
    yellow: '#F9D010',
    white: '#FFFFFF',

    // Opacity colors
    teal15: '#E5F0F2',
    teal50: '#a7ced2',
  },
  grid: {
    horizontal: 16,
    vertical: 8,
    gridColMobile: 'calc(20vw - 3.2px)',
    gridColTablet: '14.28571vw',
    gridCol: '6.66667vw',
  },
  styles: {
    button: {
      primary: 'primary',
      secondary: 'secondary',
    },
  },
  input: {
    icon: {
      pound: 'pound',
      percentage: 'percentage',
    },
  },
};