import { captureException } from '@sentry/nextjs';
import Router from 'next/router';
import React, {
  FunctionComponent,
  useState,
  createContext,
  useEffect,
} from 'react';

interface Fee {
  name: string;
  max: number;
  min: number;
  value: string;
  decimalPrecision: number;
  editable: true;
  ValueType: string;
  shouldUpdate?: string;
}

interface FeeLine {
  title: string;
  fees: Fee[];
}

interface ProgressInterface {
  visaPremiumCredit: number;
  visaPremiumDebit: number;
  masterCardPremiumDebit: number;
  masterCardPremiumCredit: number;
  paymentFee: number;
  feeLine: FeeLine[];
  status: string;
  feesLoadedSuccessfully: boolean;
  fetchFeesFunction: () => Promise<void>;
  setVisaPremiumCredit: (i: number) => void;
  setVisaPremiumDebit: (i: number) => void;
  setMasterCardPremiumDebit: (i: number) => void;
  setMasterCardPremiumCredit: (i: number) => void;
  setPaymentFee: (i: number) => void;
  setFeeLine: (i: FeeLine[]) => void;
  setStatus: (i: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFetchFeesFunction: (i: any) => void;
  setFeesLoadedSuccessfully: (i: boolean) => void;
}

const FeesConsumer = createContext({} as ProgressInterface);

const setValuesToMscFees = (mscFeeNames: string[]) => {
  return (mscFeeNames.map(feeName => ({
    name: feeName,
    min:
      feeName === 'Visa Premium Debit' ||
      feeName === 'Visa Premium Credit' ||
      feeName === 'Mastercard Premium Debit' ||
      feeName === 'Mastercard Premium Credit'
        ? 0.04
        : 0.01,
    editable: !(
      feeName === 'Visa Premium Debit' ||
      feeName === 'Visa Premium Credit' ||
      feeName === 'Mastercard Premium Debit' ||
      feeName === 'Mastercard Premium Credit'
    ),
    decimalPrecision: 2,
    value:
      feeName === 'Visa Premium Debit' ||
      feeName === 'Visa Premium Credit' ||
      feeName === 'Mastercard Premium Debit' ||
      feeName === 'Mastercard Premium Credit'
        ? 0.4
        : 0,
    ValueType: 'Percentage',
  })) as unknown) as Fee[];
};

const FeesProvider: FunctionComponent = ({ children }) => {
  const [visaPremiumCredit, setVisaPremiumCredit] = useState(0);
  const [visaPremiumDebit, setVisaPremiumDebit] = useState(0);
  const [masterCardPremiumDebit, setMasterCardPremiumDebit] = useState(0);
  const [masterCardPremiumCredit, setMasterCardPremiumCredit] = useState(0);
  const [paymentFee, setPaymentFee] = useState(0);
  const [feeLine, setFeeLine] = useState<FeeLine[]>([]);
  const [status, setStatus] = useState('fetching');
  const [feesLoadedSuccessfully, setFeesLoadedSuccessfully] = useState(true);

  const fetchFees = async (apiLocation: string) => {
    setStatus('fetching');

    const url = `${apiLocation}/api/settings/?applicationtype=${Router.query.id}`;
    fetch(url).then(
      data => {
        if (data.status === 200) {
          data
            .json()
            .then(result => {
              // We only get names for MSC Fees from API, loop through
              // list and add values to each fee.
              const updatedMscFees = setValuesToMscFees(
                result.fees.merchantServiceChargeFeeNames,
              );

              const processingFees = (result.fees
                .processingFees as unknown) as Fee[];

              setFeeLine([
                { title: 'Merchant Service Charge Fees', fees: updatedMscFees },
                { title: 'Processing Fees', fees: processingFees },
              ]);
              setStatus('completed');
            })
            .catch(error => {
              // Log error to sentry
              captureException(error, scope => {
                scope.setContext("values", null);
                return scope;
              });
              setStatus('error');
              // eslint-disable-next-line no-console
              console.log('Unable to fetch fees: ', error);
            });
        } else if (data.status === 500) {
          setStatus('public');
        }
      },
      error => {
        setStatus('error');
        // eslint-disable-next-line no-console
        console.log('Unable to fetch fees: ', error);
      },
    );
  };

  const getApiLocation = () => {
    const apiLocation =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000'
        : `https://api.${window.location.host}`;
    return apiLocation;
  }

  const [fetchFeesFunction, setFetchFeesFunction] = useState(() => () => fetchFees(getApiLocation()));

  useEffect(() => {
    const apiLocation = getApiLocation();
    fetchFees(apiLocation);
  }, []);

  return (
    <FeesConsumer.Provider
      value={{
        visaPremiumCredit,
        setVisaPremiumCredit,
        visaPremiumDebit,
        setVisaPremiumDebit,
        masterCardPremiumDebit,
        setMasterCardPremiumDebit,
        masterCardPremiumCredit,
        setMasterCardPremiumCredit,
        paymentFee,
        setPaymentFee,
        feeLine,
        setFeeLine,
        status,
        setStatus,
        fetchFeesFunction,
        setFetchFeesFunction,
        feesLoadedSuccessfully,
        setFeesLoadedSuccessfully
      }}>
      {children}
    </FeesConsumer.Provider>
  );
};

export { FeesProvider, FeesConsumer, setValuesToMscFees };
export type { Fee, FeeLine, ProgressInterface };