/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormRenderProps } from 'react-final-form';

import { FormValuesTypes } from 'src/types/formValues';
import { FieldTypes } from 'src/types/field';

import {
  Accordion,
  AddButton,
  AddressLookUpSearch,
  Anchor,
  Authentication,
  Button,
  CheckBoxes,
  CompaniesHouseDirectors,
  CompaniesHouseSearch,
  DynamicRadioButtons,
  DynamicTitle,
  DateInput,
  FadeAnimation,
  FeesList,
  Field,
  Info,
  InfoWithParameters,
  Input,
  InputSideBySide,
  LineSeparator,
  DynamicAccordion,
  MediaUpload,
  Paragraph,
  Phonenumber,
  RadioButtons,
  Review,
  Select,
  SplitScreen,
  TerminalList,
  TextArea,
  Title,
  UploadProgress,
  UploadedImage,
  UploadedImages,
  BuyTerminal,
  BuyTerminalList,
  SharesInput,
  MultipleStoreButton,
  DocumentPreview,
  PrivacyPolicy,
} from '@local';

export interface ComponentListProps {
  [index: string]: Function;
}
interface ComponentMapProps {
  values: FormValuesTypes;
  field: FieldTypes;
  form: FormRenderProps['form'];
}

// TODO: Should values be a Context instead of parameter?
// TODO: Now values is passed to every component, even if component does not use it.
// Looks like it is conflicting with input value and adjustment were needed to TEXTAREA and SELECT. Rewrite into a new list "ComponentsWithValue"?

const ComponentMapper = ({ values, field, form }: ComponentMapProps) => {
  const ComponentsWithField: ComponentListProps = {
    BUYTERMINAL: BuyTerminal,
    BUYTERMINALLIST: BuyTerminalList,
    INPUT: Input,
    INPUTSIDEBYSIDE: InputSideBySide,
    MEDIAUPLOAD: MediaUpload,
    SELECT: Select,
    TEXTAREA: TextArea,
    TERMINALLIST: TerminalList,
    MULTIPLESTORES: MultipleStoreButton,
    ADDBUTTON: AddButton,
    ACCORDION: Accordion,
  };

  const Components: ComponentListProps = {
    FADEANIMATION: FadeAnimation,
    ADDRESSLOOKUP: AddressLookUpSearch,
    BUTTON: Button,
    CHECKBOX: CheckBoxes,
    COMPANIESHOUSESEARCH: CompaniesHouseSearch,
    COMPANIESHOUSEDIRECTORS: CompaniesHouseDirectors,
    DYNAMICRADIOBUTTONS: DynamicRadioButtons,
    FEESLIST: FeesList,
    DYNAMICACCORDION: DynamicAccordion,
    RADIOBUTTONS: RadioButtons,
    PHONENUMBER: Phonenumber,
    REVIEW: Review,
    SPLITSCREEN: SplitScreen,
    UPLOADPROGRESS: UploadProgress,
    UPLOADEDIMAGE: UploadedImage,
    UPLOADEDIMAGES: UploadedImages,
    ANCHOR: Anchor,
    INFO: Info,
    INFOWITHPARAMETERS: InfoWithParameters,
    PARAGRAPH: Paragraph,
    TITLE: Title,
    LINESEPARATOR: LineSeparator,
    DYNAMICTITLE: DynamicTitle,
    DATEINPUT: DateInput,
    SHARESINPUT: SharesInput,
    DOCUMENTPREVIEW: DocumentPreview,
    AUTHENTICATION: Authentication,
    PRIVACYPOLICY: PrivacyPolicy,
  };

  if (field.hide) {
    return null;
  }

  if (Components[field.componentType]) {
    return Components[field.componentType]({ ...field, values, form });
  }

  if (ComponentsWithField[field.componentType]) {
    return (
      // Error in storybook (useField must be used inside of a <Form> component) when <Field> is not wrapped in a <Form> component
      // but the title in DynamicAccordion doesn't change when <Form> is used here ???????
      // <Form
      //  onSubmit={() => {}}
      //  render={() => (
      <Field field={field} values={values} form={form}>
        {(props: any) =>
          ComponentsWithField[field.componentType as string](props)
        }
      </Field>
      /*         )}>
      </Form> */
    );
  }
  // eslint-disable-next-line no-console
  console.log(`Missing component type for field ${field.name}`);

  return null;
};

const ComponentMap = React.memo(ComponentMapper as any);

export { ComponentMap };
