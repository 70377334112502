import React from 'react';
import { FormValuesTypes } from 'src/types/formValues';
import { Info } from '@local';
import ObjectPath from 'object-path';
import { SUploadedImage, SImage, SPlaceholder } from './index.styled';

interface ImageProps {
  id: string;
  placeholder?: string;
  values: FormValuesTypes;
}

const UploadedImage = ({ id, placeholder, values }: ImageProps) => (
  <SUploadedImage>
    {id && ObjectPath.get(values, id) ? (
      <>
        <SImage
          src={URL.createObjectURL(ObjectPath.get(values, id))}
          alt="Your uploaded image"
        />
        <Info>
          Make sure your document is physically present, all details are clear
          to read with no blur or glare.
        </Info>
      </>
    ) : (
      <SPlaceholder>{placeholder as string}</SPlaceholder>
    )}
  </SUploadedImage>
);

export { UploadedImage };
