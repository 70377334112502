import React from 'react';

export const PosTerminalIcon = () => (
  <svg
    width="33"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#PosTerminalIcon)">
      <path
        opacity=".5"
        d="M19.0174.999924H2.92493c-.83321 0-1.50867.671576-1.50867 1.499996V29.4999c0 .8285.67546 1.5 1.50867 1.5H19.0174c.8332 0 1.5087-.6715 1.5087-1.5V2.49992c0-.82842-.6755-1.499996-1.5087-1.499996z"
        fill="#1B5074"
      />
      <path
        opacity=".15"
        d="M3.42782 27.9999V.999924h-.50289c-.40012 0-.78386.158036-1.06679.439336-.28293.28131-.44188.66284-.44188 1.06066V29.4999c0 .3978.15895.7794.44188 1.0607s.66667.4393 1.06679.4393H19.0174c.4002 0 .7839-.158 1.0668-.4393.283-.2813.4419-.6629.4419-1.0607H4.93649c-.40012 0-.78386-.158-1.06679-.4393-.28293-.2813-.44188-.6629-.44188-1.0607z"
        fill="#1B5074"
      />
      <path d="M5.43921 5.00003H16.5028V13H5.43921V5.00003z" fill="#F9D010" />
      <path opacity=".5" d="M20.5259 9h7.0404v18h-7.0404V9z" fill="#4F9EA6" />
      <path
        opacity=".6"
        d="M30.0809 9h-2.5145v18h2.5145c.4001 0 .7838-.158 1.0668-.4393.2829-.2813.4418-.6629.4418-1.0607v-15c0-.3978-.1589-.77936-.4418-1.06066C30.8647 9.15804 30.481 9 30.0809 9z"
        fill="#FBFBFB"
      />
      <path opacity=".15" d="M20.5259 9h2.5144v18h-2.5144V9z" fill="#555" />
      <path
        opacity=".2"
        d="M8.95947 5.00003L16.5028 12.5V5.00003H8.95947z"
        fill="#fff"
      />
      <path
        d="M27.5663 9v18M20.5259 9h9.5549c.4001 0 .7839.15804 1.0668.43934.2829.2813.4419.66286.4419 1.06066v15c0 .3978-.159.7794-.4419 1.0607-.2829.2813-.6667.4393-1.0668.4393h-9.5549V9z"
        stroke="#1B5074"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0174.999924H2.92493c-.83321 0-1.50867.671576-1.50867 1.499996V29.4999c0 .8285.67546 1.5 1.50867 1.5H19.0174c.8332 0 1.5087-.6715 1.5087-1.5V2.49992c0-.82842-.6755-1.499996-1.5087-1.499996z"
        stroke="#1B5074"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.497 26h1.0058M5.43921 5.00003H16.5028V13H5.43921V5.00003zm0 12.99997h1.00578-1.00578zm5.02889 0h1.0058-1.0058zm5.0289 0h1.0058-1.0058zM5.43921 22h1.00578-1.00578zm5.02889 0h1.0058-1.0058zm5.0289 0h1.0058-1.0058zM5.43921 26h1.00578-1.00578zm5.02889 0h1.0058-1.0058z"
        stroke="#2E4369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="PosTerminalIcon">
        <path fill="#fff" transform="translate(.4104)" d="M0 0h32.185v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PosTerminalIcon;
