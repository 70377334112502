import React from 'react';

export const EditIcon = () => (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.1692 5.435L26.6127 3l4.006 4.02-2.444 2.435-4.0055-4.02zm-1.419 1.389l-7.6 7.55-2.6771 6.0652c-.1846.4183.2427.8445.6605.6588l6.0166-2.674 7.6-7.6-4-4z"
      fill="#4F9EA6"
    />
    <path
      d="M21.9328 18.574c-.053 0-.1039.021-.1414.0586L20.6 19.824l-.2228.2227c-.018.018-.0392.0324-.0626.0424L20 20.224l-7.2 3.2-4.04075 1.7864c-.25073.1109-.50654-.1449-.39569-.3957L10.15 20.774l.8715-1.9173c.0602-.1324-.0366-.2827-.1821-.2827H6.95542c-.06598 0-.12771.0325-.165.0869l-6.575997 9.6c-.090919.1328.004119.3131.165.3131H31.6206c.1609 0 .2559-.1803.165-.3131l-6.576-9.6c-.0373-.0544-.099-.0869-.165-.0869h-3.1118z"
      fill="#4F9EA6"
    />
  </svg>
);

export default EditIcon;
