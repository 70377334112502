export default {
  id: 'salesAgentEmail',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Salesperson information',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'SPFirstName',
      label: 'First name',
      type: 'text',
      icon: 'USER',
      placeholder: "Enter the salesperson's first name",
      validations: ['REQUIRED', 'MAXLENGTH(50)'],
    },
    {
      componentType: 'INPUT',
      name: 'SPLastName',
      label: 'Last name',
      type: 'text',
      icon: 'USER',
      placeholder: "Enter the salesperson's last name",
      validations: ['REQUIRED', 'MAXLENGTH(50)'],
    },
    {
      componentType: 'INPUT',
      name: 'EmailSales',
      label: 'Email',
      type: 'email',
      icon: 'MAIL',
      placeholder: "Enter the salesperson's email",
      validations: ['REQUIRED', 'EMAIL', 'MAXLENGTH(60)'],
    },
    {
      componentType: 'INPUT',
      name: 'BDAName',
      label: "Salesperson's name (only required if not self generated)",
      type: 'text',
      icon: 'USER',
      placeholder: "Enter the salesperson's last name",
      validations: ['MAXLENGTH(100)'],
    },
    {
      name: 'UploadProgress',
      label: 'Upload progress (only visible when uploading images)',
      componentType: 'UPLOADPROGRESS',
    },
  ],
};
