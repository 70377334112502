import React from 'react';

export const StoreIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#StoreIcon)">
      <path
        d="M16.8442 7.15384L15.2362 1.74231L15.1558 1.5H12.7839V7.15384H16.8442ZM5.42714 7.15384L7.11559 1.5H3.6985L0.482422 7.15384H5.42714ZM18.5327 7.15384H23.4774L20.2613 1.5H16.8442L18.5327 7.15384V7.15384ZM7.07539 7.15384H11.1759V1.5H8.80403L8.72363 1.74231L7.07539 7.15384V7.15384Z"
        fill="#555555"
      />
      <path
        d="M18.0905 8.76923C18.2289 9.45392 18.5986 10.0695 19.1371 10.5116C19.6756 10.9538 20.3497 11.1953 21.0452 11.1953C21.7407 11.1953 22.4148 10.9538 22.9533 10.5116C23.4918 10.0695 23.8616 9.45392 24 8.76923H18.0905ZM0 8.76923C0.138401 9.45392 0.508178 10.0695 1.04668 10.5116C1.58518 10.9538 2.25928 11.1953 2.95477 11.1953C3.65026 11.1953 4.32437 10.9538 4.86287 10.5116C5.40137 10.0695 5.77115 9.45392 5.90955 8.76923H0ZM12.0603 8.76923C12.1987 9.45392 12.5685 10.0695 13.107 10.5116C13.6455 10.9538 14.3196 11.1953 15.0151 11.1953C15.7106 11.1953 16.3847 10.9538 16.9232 10.5116C17.4617 10.0695 17.8314 9.45392 17.9698 8.76923H12.0603ZM6.03015 8.76923C6.16855 9.45392 6.53833 10.0695 7.07683 10.5116C7.61533 10.9538 8.28943 11.1953 8.98492 11.1953C9.68041 11.1953 10.3545 10.9538 10.893 10.5116C11.4315 10.0695 11.8013 9.45392 11.9397 8.76923H6.03015Z"
        fill="#555555"
      />
      <path
        d="M21.0251 12.8077C19.9177 12.8057 18.8478 12.4044 18.0101 11.6769C17.1713 12.4018 16.1015 12.8005 14.995 12.8005C13.8885 12.8005 12.8187 12.4018 11.9799 11.6769C11.1411 12.4018 10.0713 12.8005 8.96483 12.8005C7.85834 12.8005 6.78855 12.4018 5.94976 11.6769C5.11198 12.4044 4.04208 12.8057 2.93468 12.8077H2.33167V22.5H13.5879V13.6154H18.4121V22.5H21.6281V12.7673L21.0251 12.8077ZM11.1759 18.4615H5.54775V13.6154H11.1759V18.4615Z"
        fill="#555555"
      />
    </g>
    <defs>
      <clipPath id="StoreIcon">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default StoreIcon;
