import React, { useState, useContext, useEffect, SetStateAction } from 'react';
import { Field } from 'react-final-form';
import { trackPromise } from 'react-promise-tracker';
import { CompaniesHouseConsumer, Select, LoadingIndicator } from '@local';

import { validate } from '@utils';
import { FormValuesTypes } from 'src/types/formValues';
import { CompaniesHouseResults } from '../CompaniesHouseResults';
import { captureException } from '@sentry/nextjs';
import { Context } from 'vm';

export interface CompaniesHouseInputProps {
  placeholder: string;
  label?: string;
  validations?: string[];
  values?: FormValuesTypes;
}
interface SelecDropDownValues {
  items: { label: string; value: string }[];
}

const LoadingIndicatorStatus = true;

const CompaniesHouseInput = ({
  label,
  placeholder,
  validations,
  values,
}: CompaniesHouseInputProps) => {
  const { companiesHouseValues, setCompaniesHouseValues } = useContext(
    CompaniesHouseConsumer,
  );

  const [selectedValue, setSelectedValue] = useState();

  const [
    companiesHouseResponseOptions,
    setCompaniesHouseResponseOptions,
  ] = useState<SelecDropDownValues>({
    items: [],
  });

  function getCompaniesHouseInformation(typedValue: string) {
    const apiLocation =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000'
        : `https://api.${window.location.host}`;

    const company = `${apiLocation}/api/company/`;
    async function fetchInformation() {
      const res = await fetch(company + typedValue);
      res
        .json()
        .then(data => {
          setCompaniesHouseValues(data);
        })
        .catch(err => {
          // Log error to sentry
          captureException(err, scope => {
            scope.setContext("values", null);
            scope.setContext("values", values as Context);
            return scope;
          });
          // eslint-disable-next-line no-console
          console.log(`Companies House - Fetch information: ${err}`);
        });
    }

    trackPromise(fetchInformation());
  }

  const populateDropdown = (data: {
    items: { title: string; company_number: string }[];
  }) => {
    // Have to redestruct the data to be in the format that Select component expects the data to be
    const restructuredData: { label: string; value: string }[] = [];
    for (let i = 0; i < data.items.length; i++) {
      if (data.items[i].company_number !== null) {
        restructuredData[i] = {
          value: data.items[i].company_number,
          label: data.items[i].title,
        };
      }
    }
    setCompaniesHouseResponseOptions({ items: restructuredData });
  };
  // Force a rerender if the value changes
  useEffect(() => {
    setCompaniesHouseResponseOptions(companiesHouseResponseOptions);
  }, [companiesHouseResponseOptions]);

  function getCompaniesHouseOptions(typedValue: string) {
    const apiLocation =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000'
        : `https://api.${window.location.host}`;

    const companiesHouseOptions = `${apiLocation}/api/companySearch?query=`;

    async function fetchOptions() {
      const res = await fetch(
        `${companiesHouseOptions}${encodeURIComponent(
          typedValue,
        )}&maxResults=10`,
      );
      res
        .json()
        .then(data => {
          data && populateDropdown(data);
        })
        .catch(err => {
          // Log error to sentry
          captureException(err, scope => {
            scope.setContext("values", null);
            scope.setContext("values", values as Context);
            return scope;
          });
          // eslint-disable-next-line no-console
          console.log(`Companies House search: ${err}`);
        });
    }

    trackPromise(fetchOptions());
  }
  return (
    <>
      <Field
        name="companiesHouse"
        validate={validate(label, validations)}
        initialValue={selectedValue}>
        {({ input, meta }) => {
          return (
            <div>
              <Select
                {...input}
                {...meta}
                id="selectstory"
                data-testid="companiesHouse"
                label={label}
                placeholder={selectedValue || placeholder}
                isSearchable
                onInputChange={(val: string) => {
                  getCompaniesHouseOptions(val);
                }}
                onChange={(ev: { value: string }) => {
                  getCompaniesHouseInformation(ev.value);
                  setSelectedValue(
                    (ev.value as unknown) as SetStateAction<undefined>,
                  );
                }}
                options={
                  companiesHouseResponseOptions &&
                  companiesHouseResponseOptions.items
                }
              />
              <LoadingIndicator
                visible={LoadingIndicatorStatus}
                height="40"
                width="20"
                align="right"
                marginTop="-60"
              />
            </div>
          );
        }}
      </Field>
      {companiesHouseValues && <CompaniesHouseResults values={values} />}
    </>
  );
};

export { CompaniesHouseInput };
