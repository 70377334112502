import styled, { css } from 'styled-components';

export const SButton = styled.button`
  ${({ theme: { grid, color } }) => css`
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    color: ${color.blue};
    display: block;
    position: relative;
    width: calc(100% + (${grid.vertical * 4}px));
    background-color: ${color.grayFour};
    border: none;
    border-radius: 4px;
    padding: ${grid.vertical * 2}px;
    padding-right: 80px;
    margin-left: -${grid.vertical * 2}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    svg {
      position: absolute;
      transform: rotate(90deg);
      transition: transform 240ms ease;
      right: ${grid.vertical * 2}px;
      top: calc(50% - 12px);
      height: ${grid.horizontal * 1.5}px;
      width: ${grid.vertical * 3}px;
      path {
        fill: ${color.blue};
      }
    }
    &[aria-expanded='true'] {
      svg {
        transform: rotate(-90deg);
        transition: transform 240ms ease;
      }
    }
  `}
`;

export const SContent = styled.div`
  transition: max-height 320ms ease, opacity 320ms ease;

  overflow: hidden;
  max-height: 0;
  opacity: 0;

  &[aria-hidden='false'] {
    max-height: 100%;
    opacity: 1;
  }
`;
