import styled, { css } from 'styled-components';

const STitle = styled.h1`
  ${({ theme: { color, grid } }) => css`
    color: ${color.blue};
    &.gray {
      color: ${color.darkGray};
    }
    &.size-md-large {
      font-size: 52px;
      line-height: 1.365384615em;
      font-weight: 600;
    }
    &.size-md-medium {
      font-size: 32px;
      line-height: 1.375em;
      font-weight: normal;
      margin-bottom: ${`${grid.horizontal * 4}px`};
      margin-top: ${`${grid.horizontal * 9}px`};
    }
    &.size-md-small {
      font-size: 24px;
      line-height: 1.375em;
      font-weight: normal;
      margin-bottom: ${`${grid.horizontal * 4}px`};
    }
    &.no-margin-top {
      margin-top: 0;
    }
    &.no-margin-bottom {
      margin-bottom: 0;
    }
    &.extra-margin-bottom {
      margin-bottom: ${`${grid.horizontal * 4}px`};
    }
    &.extra-margin-top {
      margin-top: ${`${grid.horizontal * 4}px`};
    }
    &.small-margin-bottom {
      margin-bottom: ${`${grid.horizontal * 2}px`};
    }
    &.medium-margin-top {
      margin-top: ${grid.horizontal * 2}px;
    }
    &.bold {
      font-weight: bold;
    }
  `}
`;

export { STitle };
