import styled, { css } from 'styled-components';

export const SContent = styled.div`
  ${({ theme: { grid, breakpoints } }) => css`
    margin-top: ${grid.horizontal}px;
    @media (min-width: ${breakpoints.tabletMin}) {

    padding-left: ${grid.vertical * 4}px;
  `}
`;

export const SImage = styled.img`
  ${({ theme: { grid } }) => css`
    margin-top: ${grid.horizontal}px;
    width: auto;
    height: auto;
  `}
`;
