import React from 'react';

import {
  AddressLookUpInput,
  AddressLookUpInputProps,
} from './AddressLookUpInput';

const AddressLookUpSearch = (field: AddressLookUpInputProps) => {
  return (
    <AddressLookUpInput
      placeholder={field.placeholder}
      name={field.name}
      label={field.label}
      copyTo={field.copyTo}
      form={field.form}
    />
  );
};

export { AddressLookUpSearch };
