import styled, { css } from 'styled-components';
import { styleUtils } from '@utils';

const SWrapper = styled.div`
  ${({ theme: { color, grid } }) => css`
    margin-bottom: ${grid.horizontal * 6}px;
    padding-top: ${grid.horizontal * 4}px;
    padding-bottom: ${grid.horizontal * 4}px;
    position: relative;
    display: flex;
    ${styleUtils.screenWidthBackgroundPainter(color.grayFour, color.white)}
    :last-child {
      margin-bottom: ${grid.horizontal * 4}px;
    }
  `}
`;

export { SWrapper };
