import React from 'react';

export const HouseIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.9977 12.1557L31.9881 16.112C32.0763 16.2003 31.6484 16.3334 31.5259 16.3334H27.9977V31.4778C27.9977 31.6163 27.9446 31.7491 27.8501 31.847C27.7556 31.945 27.6274 32 27.4937 32H20.9414C20.8077 32 20.6795 31.945 20.585 31.847C20.4904 31.7491 20.4373 31.6163 20.4373 31.4778V22.6001C20.4373 22.4616 20.3842 22.3287 20.2897 22.2308C20.1952 22.1329 20.067 22.0778 19.9333 22.0778H11.8689C11.7352 22.0778 11.607 22.1329 11.5125 22.2308C11.418 22.3287 11.3649 22.4616 11.3649 22.6001V31.4778C11.3649 31.6163 11.3118 31.7491 11.2172 31.847C11.1227 31.945 10.9945 32 10.8608 32H4.30848C4.17481 32 4.04661 31.945 3.95208 31.847C3.85756 31.7491 3.80446 31.6163 3.80446 31.4778V16.3334H0.276268C0.221236 16.3334 0.167455 16.3164 0.121822 16.2846C0.0761892 16.2527 0.0407852 16.2074 0.0201509 16.1546C-0.000483486 16.1017 -0.00540752 16.0437 0.0060103 15.9879C0.0174281 15.9321 0.0446672 15.8811 0.0842341 15.8415L15.9011 0L22.9771 7.08703L22.964 4.85141C22.9632 4.71373 22.9887 4.57724 23.039 4.4498C23.0893 4.32236 23.1634 4.20648 23.2571 4.10883C23.3508 4.01118 23.4622 3.93369 23.5849 3.88081C23.7076 3.82793 23.8392 3.80071 23.9721 3.80071H26.9897C27.257 3.80071 27.5134 3.91075 27.7025 4.10662C27.8915 4.30249 27.9977 4.56814 27.9977 4.84515V12.1557Z"
      fill="#555555"
    />
  </svg>
);

export default HouseIcon;
