import React from 'react';

const kennitala = (e: React.BaseSyntheticEvent) => {
  const { value } = e.target;
  // No value, so return early
  if (!value) {
    return value;
  }

  // Filter string to only have numbers
  const onlyNums = value.replace(/[^\d]/g, '');

  // Numbers before '-', '123456'
  if (onlyNums.length <= 6) {
    return onlyNums;
  }

  // Full strength length, '123456-7890'
  return `${onlyNums.slice(0, 6)}-${onlyNums.slice(6, 10)}`;
};

export { kennitala };