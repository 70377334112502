export default [
  {
    id: 'terminal1',
    value: 0,
    type: 'PAX A80',
    name: 'Pax A80 - Ethernet Countertop',
    maxValue: 400,
    minValue: 280,
    buyName: 'Pax A80',
    price: 280,
  },
  {
    id: 'terminal2',
    value: 0,
    type: 'PAXA920',
    name: 'Pax A920 - Bluetooth, Wi-Fi',
    maxValue: 500,
    minValue: 330,
    buyName: 'Pax A920',
    price: 330,
  },
  {
    id: 'terminal3',
    value: 0,
    type: 'PAXA9204G',
    name: 'Pax A920 4G - Bluetooth, GPRS, Wi-Fi',
    maxValue: 600,
    minValue: 410,
    buyName: 'Pax A920 SIM',
    price: 410,
  },
  {
    id: 'terminal4',
    value: 0,
    type: 'MOVE3500WIFI',
    name: 'Ingenico Move3500 - Bluetooth, Wi-Fi',
    maxValue: 600,
    minValue: 410,
    buyName: 'Ingenico M3500 Bluetooth',
    price: 410,
  },
  {
    id: 'terminal5',
    value: 0,
    type: 'MOVE3500NGPRS',
    name: 'Ingenico Move3500N - GPRS',
    maxValue: 600,
    minValue: 400,
    buyName: 'Ingenico M3500 GPRS',
    price: 400,
  },
  {
    id: 'terminal6',
    value: 0,
    type: 'MOVE5000',
    name: 'Ingenico Move5000',
    maxValue: 800,
    minValue: 540,
    buyName: 'Ingenico M5000',
    price: 540,
  },
];
