import React, { useState, FunctionComponent } from 'react';
import ObjectPath from 'object-path';
import { useRouter } from 'next/router';
import { Icon } from '@local';
import { SButton, SContent } from './index.styled';

interface AccordionProps {
  id: string;
  startOpen?: boolean;
  title: string;
  titleFieldName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
}

const Accordion: FunctionComponent<AccordionProps> = ({
  id,
  startOpen,
  title,
  children,
  titleFieldName,
  values,
}) => {
  const newTitle = titleFieldName
    ? ObjectPath.get(values, titleFieldName)
    : title;
  const [isOpen, setIsOpen] = useState(startOpen);
  const uniqueId = id.replace(/\s/g, '');
  const router = useRouter();

  return (
    <>
      <SButton
        id={`${uniqueId}_tab`}
        data-testid={`${uniqueId}_tab`}
        aria-controls={`${uniqueId}-panel`}
        aria-expanded={isOpen}
        role="tab"
        onClick={() => setIsOpen(!isOpen)}
        type="button">
        {newTitle ? newTitle : router.asPath.includes('umsokn') ? 'Vantar nafn' : 'Missing name'}
        <Icon icon="ARROW" />
      </SButton>
      <SContent
        id={`${uniqueId}-panel`}
        aria-labelledby={`${uniqueId}_tab`}
        role="tabpanel"
        aria-hidden={!isOpen}>
        {children}
      </SContent>
    </>
  );
};

export { Accordion };
