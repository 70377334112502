export default {
  id: 'uploadProofOfBank',
  nextButtonText: 'Upload',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Validation of banking',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'MEDIAUPLOAD',
      fileTypesAccepted: 'image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/text',
      id: 'BankStatement',
      label: 'Bank_Media_Upload',
      text:
        'This can be either a copy of your bank/check statements, copy of a cheque, paying slip or a welcome letter.',
      buttonText: "Upload a document",
      validations: ['REQUIRED(Uploading a document for validation of banking is mandatory)'],
    },
  ],
};
