import React, {
  FunctionComponent,
  useState,
  createContext,
  useEffect,
} from 'react';


interface PersonuUpplysingarProps {
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  kennitala: string;
}

interface AuthenticationInterface {
  isAuthenticated: boolean;
  errorMessage: string;
  controlCode: string;
  userInfo: PersonuUpplysingarProps;
  unsignedContract: string;
  setIsAuthenticated: (i: boolean) => void;
  setErrorMessage: (i: string) => void;
  setControlCode: (i: string) => void;
  setUserInfo: (cb: (value: PersonuUpplysingarProps) => PersonuUpplysingarProps) => void;
  setUnsignedContract: (i: string) => void;
}

const AuthenticationConsumer = createContext({} as AuthenticationInterface);

const AuthenticationProvider: FunctionComponent = ({
  children,
}) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [controlCode, setControlCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [unsignedContract, setUnsignedContract] = useState("");

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    kennitala: ""
  })

  // Check if isAuthenticated is set in sessionStorage
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const sessionStorageData = JSON.parse(sessionStorage.getItem('data') as string);

      if (sessionStorageData) {
        const { isAuthenticated: isAuthenticatedFromSessionStorage } = sessionStorageData;
        setIsAuthenticated(isAuthenticatedFromSessionStorage)

      }
    }

  }, [])
  return (
    <AuthenticationConsumer.Provider
      value={{
        userInfo,
        isAuthenticated,
        errorMessage,
        controlCode,
        setUserInfo,
        setIsAuthenticated,
        setErrorMessage,
        setControlCode,
        unsignedContract,
        setUnsignedContract,
      }}>
      {children}
    </AuthenticationConsumer.Provider>
  );
};

export { AuthenticationConsumer, AuthenticationProvider };
export type { PersonuUpplysingarProps }
