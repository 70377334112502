export default {
  title: 'Hefur þú áður tekið við greiðslukortafærslum?',
  id: 'tekidVidKortum',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'RADIOBUTTONS',
      validations: ['REQUIRED'],
      name: 'PreviouslyAcceptedCards',
      reviewPageTitle: 'Hefur þú áður tekið við greiðslukortafærslum?',
      radioValues: [
        {
          labelText: 'Já',
          name: 'PreviouslyAcceptedCards',
          value: 'yes',
        },
        {
          labelText: 'Nei',
          name: 'PreviouslyAcceptedCards',
          value: 'no',
          checked: true,
        },
      ],
    },
  ],
};
