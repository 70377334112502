export default {
  id: 'settlementFrequencyInfo',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Please select settlement frequency',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'RADIOBUTTONS',
      radioValues: [
        {
          labelText: 'Monthly',
          name: 'SettlementFreqMonth',
          value: 'Monthly',
          checked: true,
        },
        {
          labelText: 'Bi-Weekly',
          name: 'SettlementFreqBi-Weekly',
          value: 'Bi-Weekly',
        },
        {
          labelText: 'Weekly',
          name: 'SettlementFreqWeekly',
          value: 'Weekly',
        },
        {
          labelText: 'Daily',
          name: 'SettlementFreqDaily',
          value: 'Daily',
        },
      ],
    },
  ],
};
