import React, { useContext } from 'react';
import { Icon, ApplicationConsumer } from '@local';
import { SEditButton, SWrapper } from './index.styled';

interface EditButtonProps {
  children?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formSetup: any;
  pageNumber: number;
}

const EditButton = ({ children, pageNumber, formSetup }: EditButtonProps) => {
  const {
    setPageNumber,
    setStep,
    setCurrentStepSetup,
    setDirection,
  } = useContext(ApplicationConsumer);

  return (
    <SWrapper>
      <SEditButton
        id={`pagenumber-${pageNumber}`}
        data-testid={`pagenumber-${pageNumber}`}
        onClick={() => {
          setPageNumber(pageNumber);
          setStep(
            formSetup.pages[pageNumber].steps[0].id === "authentication"
              ? 1
              : 0
          )
          // Go to 3rd step (Personuupplysingar) if the first page is the rafræn skilriki page
          setCurrentStepSetup(
            formSetup.pages[pageNumber].steps[0].id === "authentication"
            ? formSetup.pages[pageNumber].steps[2]
            : formSetup.pages[pageNumber].steps[0]
            );
          setDirection('up');
        }}>
        <Icon icon="EDITICON" />
        {children}
      </SEditButton>
    </SWrapper>
  );
};

export { EditButton };
