export default {
  title:
    'Would you like to accept faster payments (next working day) at the cost of £0.40 for each batch?',
  id: 'fasterPayments',
  conditionalNextSteps: [
    {
      id: 'FasterPayments',
      value: undefined,
      skipToStep: 'storeType',
    },
    {
      id: 'FasterPayments',
      value: [],
      skipToStep: 'storeType',
    },
  ],
  fields: [
    {
      componentType: 'CHECKBOX',
      checkboxValues: [
        {
          labelText: 'Yes, I would like to accept faster payments',
          name: 'FasterPayments',
          value: 'yes',
        },
      ],
    },
  ],
};
