export default {
  uploadedImage: {
    id: 'BankStatement',
    placeholder:
      'Please note that you will have to provide a valid bank statement to finish the application at a later time.',
    componentType: 'UPLOADEDIMAGE',
  },
  proofOfBankVerified: {
    componentType: 'CHECKBOX',
    checkboxValues: [
      {
        labelText: 'Proof of bank verified',
        name: 'ProofOfBankVerified',
        value: 'yes',
      },
    ],
  },
  info: {
    componentType: 'INFO',
    text:
      'Only tick if: Document is within 6 months AND account name matches legal name in application AND account number and sort-code matches information in application.',
    smallerText: 'yes',
    className: 'extra-margin-top',
  },
};
