import * as Icons from '../../public/static/icons';

interface IconProps {
  icon: string;
}

interface IconMapProps {
  [index: string]: JSX.Element;
}

const iconMap: IconMapProps = {
  ARROW: Icons.ArrowIcon(),
  ATTACHMENT: Icons.AttachmentIcon(),
  BANK: Icons.BankIcon(),
  CAKE: Icons.CakeIcon(),
  CHECKMARK: Icons.CheckMarkIcon(),
  COIN: Icons.CoinIcon(),
  DECREMENT: Icons.DecrementIcon(),
  DELIVERYICON: Icons.DeliveryIcon(),
  DOCUMENTICON: Icons.DocumentIcon(),
  DOCUMENTSAVEDICON: Icons.DocumentSavedIcon(),
  EDITICON: Icons.EditIcon(),
  HOUSE: Icons.HouseIcon(),
  IDENTITY: Icons.IdentityIcon(),
  IDENTITYBIG: Icons.IdentityIconBig(),
  INCREMENT: Icons.IncrementIcon(),
  INFO: Icons.InfoIcon(),
  INFOERROR: Icons.InfoIconError(),
  MAIL: Icons.MailIcon(),
  PAYMENT: Icons.PaymentIcon(),
  PHONE: Icons.PhoneIcon(),
  POSTBOXICON: Icons.PostboxIcon(),
  POSTERMINALICON: Icons.PosTerminalIcon(),
  POUND: Icons.PoundIcon(),
  SEARCH: Icons.SearchIcon(),
  STORE: Icons.StoreIcon(),
  STORECOLOR: Icons.StoreIconColor(),
  TOWNBUILDING: Icons.TownBuildingIcon(),
  TRIBUNALICON: Icons.TribunalIcon(),
  USER: Icons.UserIcon(),
  VALITORICONBOX: Icons.ValitorIconBox(),
  VALITORICONBOXSTACKED: Icons.ValitorIconBoxStacked(),
  VAULT: Icons.VaultIcon(),
  WARNING: Icons.WarningIcon(),
  LOADING: Icons.LoadingIndicatorSmall(),
  LOADINGLARGE: Icons.LoadingIndicatorLarge(),
  PIECHART: Icons.PieChartIcon(),
  ZOOMINICON: Icons.ZoomInIcon(),
  ZOOMOUTICON: Icons.ZoomOutIcon(),
  RAPYDLOGO: Icons.RapydLogoIcon(),
};

const Icon = ({ icon }: IconProps) => {
  return iconMap[icon] ? iconMap[icon] : null;
};

export { Icon };
export type { IconMapProps };
