import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { PageStructure } from 'src/types/application';
import { EditButton, Title } from '@local';
import { FormValuesTypes, TargetMarket } from '../../../types/formValues';
import { SReviewBlockContainer } from './index.styled';
import { ReviewStores } from '../ReviewStores';
import { ReviewList } from '../ReviewList';

interface ReviewListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formSetup: any;
  pageNumber: number;
  title: string;
  page?: PageStructure;
  values?: FormValuesTypes;
}

const ReviewBlock = ({
  formSetup,
  title,
  page,
  pageNumber,
  values,
}: ReviewListProps) => {
  const [editButtonText, setEditButtonText] = useState("");
  const router = useRouter();

  useEffect(() => {
    const targetMarket: TargetMarket = router.asPath.includes('umsokn') ? TargetMarket.Iceland : TargetMarket.CrossBorder;

    if (targetMarket === TargetMarket.Iceland) {
      setEditButtonText("Breyta")
    }
    else {
      setEditButtonText("Edit")
    }
  }, [router.asPath])

  if (page && page.title === 'Stores') {
    return (
      <SReviewBlockContainer>
        <Title
          tag="h2"
          className="size-md-medium no-margin-top review-list-title no-margin-bottom">
          {title}
        </Title>
        <EditButton pageNumber={pageNumber} formSetup={formSetup}>
          {editButtonText}
        </EditButton>
        {values &&
          values.Stores &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          values.Stores.map((store: any) => {
            return store.StoreName ? (
              <ReviewStores store={store} title={store.StoreName} />
            ) : null;
          })}
      </SReviewBlockContainer>
    );
  }
  return (
    <SReviewBlockContainer>
      <Title
        tag="h2"
        className="size-md-medium no-margin-top review-list-title no-margin-bottom">
        {title}
      </Title>
      <EditButton pageNumber={pageNumber} formSetup={formSetup}>
        {editButtonText}
      </EditButton>
      {page &&
        values &&
        page.steps.map((step, index: number) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <dl key={`${step.title} + ${index}`}>
              <ReviewList step={step} values={values} />
            </dl>
          );
        })}
    </SReviewBlockContainer>
  );
};

export { ReviewBlock };
