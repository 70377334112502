import React from 'react';

export const IdentityIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#IdentityIcon)">
      <path
        d="M27.2487 5v3h-4.0231V5H9.14463v3H5.12151V5H1.09839v25H31.2718V5h-4.0231z"
        fill="#fff"
      />
      <path
        d="M17.1907 23.45c2.0116-.45 2.0116-1.15 2.0116-1.65v-.75c-.4702-.4432-.8426-.9788-1.0937-1.5727-.251-.5939-.3752-1.2332-.3647-1.8773v-2.25c.1027-.8498.5151-1.6328 1.1591-2.2008.6441-.568 1.4751-.8816 2.336-.8816.8609 0 1.6919.3136 2.336.8816.644.568 1.0564 1.351 1.1591 2.2008v2.25c.0041.6478-.128 1.2894-.3878 1.8835-.2598.5941-.6416 1.1277-1.1209 1.5665v.75c0 .45 0 1.2 2.0619 1.65 2.0618.45 1.9612 2.55 1.9612 2.55H15.1792c-.014-.591.179-1.1684.5459-1.6336.367-.4651.885-.7891 1.4656-.9164z"
        fill="#F9D010"
      />
      <path
        d="M5.12134 2h4.02312v6H5.12134V2zM23.2254 2h4.0231v6h-4.0231V2z"
        fill="#4F9EA6"
      />
      <path
        opacity=".15"
        d="M25.2371 8v1.5h-3.5202V5h1.5087v3h2.0115zM7.13307 8v2H4.11573v18.5H31.2718V30H1.09839V5h4.02312v3h2.01156z"
        fill="#1B5074"
      />
      <path
        d="M17.1907 23.45c2.0116-.45 2.0116-1.15 2.0116-1.65v-.75c-.4702-.4432-.8426-.9788-1.0937-1.5727-.251-.5939-.3752-1.2332-.3647-1.8773v-2.25c.1027-.8498.5151-1.6328 1.1591-2.2008.6441-.568 1.4751-.8816 2.336-.8816.8609 0 1.6919.3136 2.336.8816.644.568 1.0564 1.351 1.1591 2.2008v2.25c.0041.6478-.128 1.2894-.3878 1.8835-.2598.5941-.6416 1.1277-1.1209 1.5665v.75c0 .45 0 1.2 2.0619 1.65 2.0618.45 1.9612 2.55 1.9612 2.55H15.1792c-.014-.591.179-1.1684.5459-1.6336.367-.4651.885-.7891 1.4656-.9164z"
        stroke="#2E4369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.14463 5H23.2256M5.12151 5H1.09839v25H31.2718V5H5.12151z"
        stroke="#2E4369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1676 12.5H5.12134m5.02886 4H5.12134m7.04046 4H5.12134m0-18.5h4.02312v6H5.12134V2zM23.2254 2h4.0231v6h-4.0231V2z"
        stroke="#2E4369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="IdentityIcon">
        <path
          fill="#fff"
          transform="translate(.0925293)"
          d="M0 0h32.185v32H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IdentityIcon;
