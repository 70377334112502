import styled, { css } from 'styled-components';

const SErrorLabel = styled.div`
  ${({ theme: { color, grid } }) => css`
    display: block;
    font-size: 14px;
    margin-bottom: ${grid.horizontal * 1.5 + 1}px;
    line-height: 1.14286em;
    color: ${color.red};
  `}
`;

export { SErrorLabel };
