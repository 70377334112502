import styled, { css } from 'styled-components';

export const SUploadedImage = styled.div`
  min-height: 35vh;
  .info-component {
    ${({ theme: { grid } }) => css`
      margin-bottom: ${grid.vertical * 8}px;
    `}
  }
`;

export const SImage = styled.img`
  ${({ theme: { grid, breakpoints } }) => css`
    width: 100%;
    max-height: ${grid.vertical * 24}px;
    margin-bottom: ${grid.vertical * 4}px;
    object-fit: cover;

    @media (min-width: ${breakpoints.tabletMin}) {
      max-height: ${grid.vertical * 64}px;
    }
  `}
`;

export const SPlaceholder = styled.div`
  ${({ theme: { grid, breakpoints, color } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: ${grid.vertical * 24}px;
    margin-bottom: ${grid.vertical * 4}px;

    background-color: ${color.grayFour};

    @media (min-width: ${breakpoints.tabletMin}) {
      padding: 0 ${grid.horizontal * 5}px;
      min-height: ${grid.vertical * 40}px;
    }
  `}
`;
