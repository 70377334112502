export default {
  id: 'additionalInfo',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Additional information for acquirer',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'TEXTAREA',
      name: 'AddInfoTextArea',
      label: 'Add comment (optional)',
      placeholder: 'Start writing...',
      rows: 3,
    },
    {
      componentType: 'TITLE',
      text: 'Add supporting  documents',
      className: 'size-md-medium extra-margin-top',
    },
    {
      componentType: 'MEDIAUPLOAD',
      fileTypesAccepted: 'image/*,application/pdf',
      id: 'AddInfoDocuments',
      label: 'Bank_Media_Upload',
      text:
        'This can be any document of processing history and/or proof of business existence',
      buttonText: 'Upload file/s',
    },
  ],
};
