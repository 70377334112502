import React, { FunctionComponent, SyntheticEvent } from 'react';

import { SWrapper } from './index.styled';

interface CheckBoxProps {
  labelText: string;
  name: string;
  value: string;
  disabled?: boolean;
  disabledStatusDependsOn?: { listenToValues: string[]; context: string };
  checked?: boolean;
  onChange?: (ev: SyntheticEvent) => void;
}

const CheckBox: FunctionComponent<CheckBoxProps> = ({
  labelText,
  name,
  value,
  disabled,
  checked,
  onChange,
}) => (
    <SWrapper htmlFor={value}>
      <input
        type="checkbox"
        id={value}
        data-testid={value}
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <span className="labelText">{labelText}</span>
      <span className="checkmark" />
    </SWrapper>
  );

export { CheckBox };
export type { CheckBoxProps };
