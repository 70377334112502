import { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { useRouter } from 'next/router';
import { cookieConsentStyles, cookieConsentButtonStyles, cookieConsentContentStyles } from '../../public/static/cookieConsent/styles';
import { SParagraph, STitle, SLink } from './index.styled';

const CookieConsentPopUp = () => {
  const router = useRouter();
  const [text, setText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [buttonText, setButtonText] = useState("");

  useEffect(() => {
    if (router.asPath.includes("umsokn")) {
      setText(`Vefsíðan okkar notar vefkökur (e. cookies) til að tryggja sem besta upplifun á vefsíðunni fyrir notendur.
        Vefkökur eru litlar textaskrár með bókstöfum og tölum sem vistast á því tæki sem notað er til að fara inn á vefsíðuna.
        Þær eru sendar frá Rapyd eða vefþjónum samstarfsaðila okkar þegar vefsvæði Rapyd er heimsótt í fyrsta sinn.
        Textaskráin er geymd á vefvafra notanda til að vefur Rapyd þekki skrána aftur.
        Vefkökur hjálpa okkur að bæta vefsíðuna og veita notendum góða þjónustu þegar vafrað er á síðunni.
        Upplýsingarnar sem safnast saman eru nafnlausar og ekki er hægt að nota þær til að auðkenna þig.
        Með áframhaldandi notkun á síðunni samþykkir þú `);
      setTitleText("Köku skilmáli");
      setButtonText("Ég samþykki skilmála");
    } else {
      setText(`Our website uses cookies to ensure the best experience on the website for users.
        Cookies are small text files with letters and numbers that are stored on the device used to access the website.
        They are sent from Rapyd or our partners' web servers when the Rapyd website is visited for the first time.
        The text file is stored on the user's web browser so that the Rapyd website can recognize the file again.
        Cookies help us improve the website and provide users with good service when browsing the site.
        The information collected is anonymous and cannot be used to identify you.
        By continuing to use the site, you agree to `);
      setTitleText("Cookie terms");
      setButtonText("I accept the terms");
    }
  }, [])

  const renderLink = () => {
    return router.asPath.includes("umsokn")
    ? <SLink className="link" href="https://www.valitor.is/adrar-upplysingar/kokur/" target="_blank" rel="noreferrer noopenerr">notkun okkar á vefkökum.</SLink>
    : <SLink className="link" href="https://www.valitor.com/about-us/cookie-policy/" target="_blank" rel="noreferrer noopenerr">our use of cookies.</SLink>
  }

  return (
    <CookieConsent
      buttonId="cookie-consent-button"
      location="bottom"
      buttonText={buttonText}
      cookieName="dynamic-application-cookie"
      style={cookieConsentStyles}
      buttonStyle={cookieConsentButtonStyles}
      contentStyle={cookieConsentContentStyles}
      buttonWrapperClasses="cookie-consent-button-wrapper"
      expires={150} // Number of days before the cookie expires
    >
      <>
        <STitle>{titleText}</STitle>
        <SParagraph>{text}{renderLink()}</SParagraph>
      </>
    </CookieConsent>
  );
};

export { CookieConsentPopUp };