import React, { useState } from 'react';
import Link from 'next/link';

import { Title, Select } from '@local';

import { SButtonWrapper, SButton } from '../index.styled';

// TODO have the text and list of forms come from a JSON so it can be easily changable

const ValitorUKBeginning = () => {
  const forms = [
    {
      value: 'soletrader',
      label: 'Sole Trader',
    },
    {
      value: 'privatelimitedcompany',
      label: 'Private Limited Company',
    },
    {
      value: 'publiclimitedcompany',
      label: 'Public Limited Company',
    },
  ];

  const agents = [
    {
      value: 'physical',
      label: 'Physical Sale',
    },

    {
      value: 'virtual',
      label: 'Virtual Sale',
    },
  ];

  const [applicationName, setApplicationName] = useState(forms[0].value);
  const [agentName, setagentName] = useState(agents[0].value);

  return (
    <>
      <Title tag="h1" className="size-md-medium no-margin-top">
        Select the following type of sale
      </Title>
      <div className="row">
        <div className="col-sm-5 col-md-5 col-lg-7">
          <Select
            id="chooseAgent"
            label=""
            placeholder="Select"
            onChange={(ev: { value: string }) => {
              setagentName(ev.value);
            }}
            defaultValue={agents[0]}
            options={agents}
          />
        </div>
      </div>
      <Title tag="h1" className="size-md-medium extra-margin-top">
        What type of legal form are you applying for?
      </Title>
      <div className="row">
        <div className="col-sm-5 col-md-5 col-lg-7">
          <Select
            id="chooseLegalForm"
            label="Legal forms"
            placeholder="Select"
            onChange={(ev: { value: string }) => {
              setApplicationName(ev.value);
            }}
            defaultValue={forms[0]}
            options={forms}
          />
        </div>
      </div>
      <SButtonWrapper>
        <Link href={`/form/${applicationName}?typeofsale=${agentName}`}>
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <SButton id="approveAndContinue" onClick={() => {}} type="button">
            Continue
          </SButton>
        </Link>
      </SButtonWrapper>
    </>
  );
};

export { ValitorUKBeginning };
