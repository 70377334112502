import React, { FunctionComponent } from 'react';
import { FormValuesTypes } from 'src/types/formValues';
import { STitle } from './index.styled';

// TODO add responsive font and breakpoints for styling
// We missing mobile and desktop fonts to do so
// TODO Remove margin bottom on title, should come from
// the component using the Title component

interface TitleProps {
  tag?: 'h1' | 'h2' | 'h3' | 'h4';
  className?: string;
  text?: string;
  values?: FormValuesTypes;
}

const Title: FunctionComponent<TitleProps> = ({
  tag,
  text,
  className,
  children,
}) => {
  return (
    <STitle as={tag || 'h1'} {...{ className }}>
      {text || children}
    </STitle>
  );
};

export { Title };
export type { TitleProps };
