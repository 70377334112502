import {
  personuupplysingar,
  greidslulausnir,
  adurTekidVidKortum,
  posar,
  greidslusiduupplysingar,
  verslun,
  kortaTegundir,
  faerslusvid,
  bankaupplysingar,
  authentication,
  samningurTilUndirritunar,
} from '../sharedLogic';

/**
 * TODO: Add ability to control field layout in this JSON.
 * I'd suggest something like https://jsonforms.io/
 */

/*
 *TODO: list
 * Phone number validation
 * Unify all components so that the form decides all parameters, e.g. label, placeholder
 */

export default {
  pageTitle: 'Einstaklingur',
  applicationType: 'SoleTraderIS',
  pages: [
    // Page identity
    {
      icon: 'IDENTITY',
      title: 'Persónuupplýsingar',
      id: 'identity',
      showOnReviewPage: true,
      steps: [
        // Step authentication
        {
          ...authentication,
        },
        // Step skilmálar
        {
          id: 'termsAndConditions',
          nextButtonText: 'Áfram',
          fields: [
            {
              componentType: 'TITLE',
              text: 'Áður en haldið er áfram',
              className: 'size-md-medium no-margin-top',
            },
            {
              componentType: 'PARAGRAPH',
              children:
                <>Með því að haka í boxið hér að neðan staðfestir þú að hafa kynnt þér og samþykkir <a className="link" href="https://www.valitor.is/adrar-upplysingar/skilmalar/" target="_blank" rel="noreferrer noopenerr">skilmála Rapyd</a></>,
            },
            {
              componentType: 'CHECKBOX',
              validations: ['CHECKBOX(Þú þarft að haka í þennan reit til að halda áfram)'],
              classname: 'mt',
              checkboxValues: [
                {
                  labelText: 'Ég samþykki skilmála Rapyd',
                  name: 'TermsAccepted',
                  value: 'yes',
                },
              ],
            },
          ],
        },
        // Step personuupplysingar
        {
          ...personuupplysingar,
        },
      ],
    },
    // Page store
    {
      icon: 'STORECOLOR',
      title: 'Greiðslulausnir',
      id: 'store',
      showOnReviewPage: true,
      steps: [
        // Step 00 - paymentCards
        {
          ...greidslulausnir,
        },
        // Step uploadAcquirerStatement
        {
          ...posar,
        },
        {
          ...verslun,
        },
        {
          ...greidslusiduupplysingar,
        },
        {
          ...kortaTegundir,
        },
        // Step fasterPayments
        {
          ...adurTekidVidKortum,
        },
        {
          ...faerslusvid,
        },
      ],
    },
    // Page finance
    {
      icon: 'TRIBUNALICON',
      title: 'Bankaupplýsingar',
      id: 'finance',
      showOnReviewPage: true,
      steps: [
        // Step listOfFees
        {
          ...bankaupplysingar,
        },
      ],
    },
    // Page review
    {
      icon: 'DOCUMENTSAVEDICON',
      title: 'Yfirlit',
      id: 'review',
      nextButtonText: 'Áfram',
      previousButtonText: 'Til baka',
      showOnReviewPage: false,
      steps: [
        // Step applicationReview
        {
          id: 'applicationReview',
          nextButtonText: 'Áfram',
          previousButtonText: 'Til baka',
          fields: [
            {
              componentType: 'TITLE',
              text: 'Umsóknin þín',
              className: 'size-md-medium no-margin-top',
            },
            {
              componentType: 'PARAGRAPH',
              children:
                'Hér getur þú farið yfir umsóknina þína og staðfest að upplýsingar sé réttar. Þú getur farið til baka og breytt upplýsingum ef þörf er á.',
            },
            {
              componentType: 'REVIEW',
            },
          ],
        },
        {
          ...samningurTilUndirritunar
        }
      ],
    },
  ],
};
