import React from 'react';

export const Arrow = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#arrow)">
      <path
        d="M5.7005 28.5676c-.00053-.4509.08807-.8975.2607-1.3141.17263-.4165.42589-.7948.74522-1.1132l9.88298-10.1736L6.70642 5.81824c-.62538-.6475-.97142-1.51472-.9636-2.41489.00782-.90017.36889-1.76125 1.00542-2.39779C7.38478.369026 8.24586.00796706 9.14603.00014504 10.0462-.007677 10.9134.338366 11.5609.963747L26.2995 15.9667 11.5609 30.9948c-.4801.48-1.0918.8069-1.75765.9393-.66587.1324-1.35606.0644-1.9833-.1954s-1.16337-.6997-1.54062-1.2641c-.37725-.5645-.57868-1.2281-.57883-1.907z"
        fill="#555"
      />
    </g>
    <defs>
      <clipPath id="arrow">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Arrow;
