import React, { FunctionComponent, useState, createContext, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires

interface CompaniesHouseAddressProps {
  address_line_1: string;
  address_line_2: string;
  country: string;
  locality: string;
  po_box: string;
  postal_code: string;
  premises: string;
  region: string;
  care_of: string;
}

interface CompaniesHouseNameElementsData {
  forename: string;
  middle_name: string;
  surname: string;
  title: string;
}

interface CompaniesHouseBirthdayData {
  day: string;
  month: string;
  year: string;
}

interface CompanyData {
  company_number: string;
  company_name: string;
  company_status: string;
  company_status_text: string;
  company_status_detail: string;
  company_status_detail_text: string;
  date_of_creation: string;

  persons_of_interest: {
    name: string;
    name_elements: CompaniesHouseNameElementsData;
    date_of_birth: CompaniesHouseBirthdayData;
    nationality: string;
    occupation: string;
    officer_role: string;
    officer_role_text: string;
    natures_of_control: string[];
    natures_of_control_text: string[];
    share_percentage: number;
    address: CompaniesHouseAddressProps;
  }[];

  subtype: string;
  subtype_text: string;
  registered_office_address: CompaniesHouseAddressProps;
  sic_codes: string[];
  sic_codes_text: string[];
  type: string;
  type_text: string;
  search_date: string;
}

interface CompaniesHouseInterface {
  companiesHouseValues: CompanyData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCompaniesHouseValues: (i: any) => void;
}

const CompaniesHouseConsumer = createContext({} as CompaniesHouseInterface);

const CompaniesHouseProvider: FunctionComponent = ({ children }) => {
  const [companiesHouseValues, setCompaniesHouseValues] = useState();


  useEffect(() => {
    if(typeof window !== 'undefined') {

      // Get data from sessionStorage on rerender
      const dataFromSessionStorage = sessionStorage.getItem('data');

      // If data exist, populate the values of the Context
      if(dataFromSessionStorage) {
        setCompaniesHouseValues(JSON.parse(dataFromSessionStorage).CompaniesHouseData)
      }

    }
  }, [])
  return (
    <CompaniesHouseConsumer.Provider
      value={{
        // @ts-ignore
        companiesHouseValues,
        setCompaniesHouseValues,
      }}>
      {children}
    </CompaniesHouseConsumer.Provider>
  );
};

export { CompaniesHouseConsumer, CompaniesHouseProvider };
export type { CompaniesHouseBirthdayData, CompaniesHouseAddressProps, CompanyData, CompaniesHouseInterface, CompaniesHouseNameElementsData}
