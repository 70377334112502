import styled, { css } from 'styled-components';

interface SButtonProps {
  tag?: 'secondary';
}

const SButton = styled.button<SButtonProps>`
  ${({ theme: { color, grid, styles, breakpoints }, tag }) => css`
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    background-color: ${color.teal};
    color: white;
    border-radius: 4px;
    border-color: transparent;
    width: 100%;
    padding: 0;
    height: ${`${grid.horizontal * 4}px`};

    &:disabled:hover {
      box-shadow: none;
      transform: none;
    }

    /* Primary button */
    &[type="submit"]:disabled {
      background-color: ${color.teal50};
      opacity: 1;

      svg {
        stroke: white;
      }
    }

    :disabled {
      opacity: 0.4;
    }

    /* Secondary button */
    ${tag === styles.button.secondary &&
    css`
        background: ${color.white};
        color: ${color.teal};
        border: 2px solid ${color.teal};

        &:disabled {
          color: ${color.grayTwo};
          border-color: ${color.grayTwo};
        }
      `}

    @media (min-width: ${breakpoints.desktopMin}) {
      transform: translate3d(0, 0, 0);
      box-shadow: 0 0 0 rgba(79, 158, 166, 0.3);
      transition: transform 200ms, box-shadow 200ms ease;
      &:hover {
        transform: translate3d(0, -1px, 0);
        box-shadow: 7px 9px 0px rgba(79, 158, 166, 0.3);
        transition: transform 200ms, box-shadow 200ms ease;
      }
    }
  `}
`;

export { SButton };
