import React, { useContext } from 'react';
import { CompaniesHouseAddressProps } from 'src/components/Context/CompaniesHouseConsumer';
import { AnyObject } from 'final-form';
import { CompaniesHouseConsumer } from '@local';
import { ReviewListItem } from '../../ReviewListItem';
import { SWrapper } from './index.styled';

interface BirthdayProps {
  month: string;
  year: string;
}

const ReviewCompanyDirectors = (values: AnyObject) => {
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);
  const { persons_of_interest } = companiesHouseValues;
  let directorsPersonsExists = false;

  Object.keys(values).forEach(value => {
    if (value === 'DirectorsPersons') {
      directorsPersonsExists = true;
    }
  });

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // change structure of birthday information
  const structuredBirthdayPOS = ({ month, year }: BirthdayProps) => {
    const dateToString = `${year}-${month}`;
    const birhtdayInDateFormat = new Date(dateToString);

    const birthday = `${
      months[birhtdayInDateFormat.getMonth()]
    } ${birhtdayInDateFormat.getFullYear()}`;

    return birthday;
  };

  const structuredBirthdayDP = (month: string, year: string) => {
    const monthName = months[Number(month) - 1];

    return `${monthName} ${year}`;
  };

  // change structure of address information
  const structuredAddressPOS = ({
    address_line_1,
    address_line_2,
    country,
    locality,
    postal_code,
    premises,
  }: CompaniesHouseAddressProps) => `${premises ? `${premises}, ` : ''}
    ${address_line_1 ? `${address_line_1}, ` : ''}
    ${address_line_2 ? `${address_line_2}, ` : ''}
    ${locality ? `${locality}, ` : ''}
    ${country ? `${country}, ` : ''}
    ${postal_code ? `${postal_code}` : ''} `;

  const getInfo = (index: number, type: string) => {
    const valueKey = `personofinterest${index}${type}`;
    const info = values[valueKey];
    return info;
  };

  return (
    <>
      {persons_of_interest.map((person, index) => (
        <SWrapper key={person.name}>
          <ReviewListItem
            title={person.officer_role ? 'Director Name' : 'Shareholder Name'}>
            {person.name}
          </ReviewListItem>
          {person.officer_role && (
            <ReviewListItem title="Active Role">
              {person.officer_role_text}
            </ReviewListItem>
          )}
          <ReviewListItem title="Email">
            {getInfo(index, 'email')}
          </ReviewListItem>
          <ReviewListItem title="Phone number">
            {getInfo(index, 'phonenumber')}
          </ReviewListItem>
          {person.natures_of_control &&
            person.natures_of_control.length > 0 && (
              <ReviewListItem title="Ownership of shares">
                {person.natures_of_control_text &&
                  person.natures_of_control_text.map(text => text)}
              </ReviewListItem>
            )}
          <ReviewListItem title="Date of birth">
            {structuredBirthdayPOS(person.date_of_birth)}
          </ReviewListItem>
          {person.officer_role && (
            <ReviewListItem title="Occupation">
              {person.occupation}
            </ReviewListItem>
          )}
          <ReviewListItem title="Nationality">
            {person.nationality}
          </ReviewListItem>
          <ReviewListItem title="Correspondence Address">
            {structuredAddressPOS(getInfo(index, 'address'))}
          </ReviewListItem>
        </SWrapper>
      ))}
      {directorsPersonsExists ? (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values.DirectorsPersons.map((person: any) => (
          <SWrapper key={person.FirstName}>
            <ReviewListItem title="Shareholder Name">
              {`${person.FirstName} ${person.LastName}`}
            </ReviewListItem>
            <ReviewListItem title="Email">{person.Email}</ReviewListItem>
            <ReviewListItem title="Phone number">{person.Phone}</ReviewListItem>
            <ReviewListItem title="Ownership of shares">
              {`${person.Shares}%`}
            </ReviewListItem>
            <ReviewListItem title="Date of birth">
              {structuredBirthdayDP(
                person.BirthStartMonth,
                person.BirthStartYear,
              )}
            </ReviewListItem>
            <ReviewListItem title="Correspondence Address">
              {`${person.Street}, ${person.City}, ${person.Postcode}`}
            </ReviewListItem>
          </SWrapper>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export { ReviewCompanyDirectors };
