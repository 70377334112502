import React, { Component } from 'react';
import styled, { css } from 'styled-components';

// TODO add Hooks

interface TestGridProps {
  horizontal: boolean;
  vertical: boolean;
}

interface TestGridState {
  browser: {
    browserGreaterThanMedium: boolean;
    browserGreaterThanSmall: boolean;
  };
}

/**
 * This is a grid overlay to help debug components
 * @return {node} - JSX snippet
 */
class TestGrid extends Component<TestGridProps, TestGridState> {
  /**
   * Set initial state
   * @param {object} props - Component props
   */
  constructor(props: TestGridProps) {
    super(props);

    this.state = {
      browser: {
        browserGreaterThanSmall: false,
        browserGreaterThanMedium: false,
      },
    };

    this.handleResize = this.handleResize.bind(this);
  }

  /**
   * Add window listeners
   * @return {undefined}
   */
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  /**
   * Remove window listeners
   * @return {undefined}
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Set viewport states to mimic redux plugin used in the app.
   * @return {undefined}
   */
  handleResize() {
    const { browser } = this.state;
    const browserState = {
      browserGreaterThanSmall: window.matchMedia('(min-width: 650px)').matches,
      browserGreaterThanMedium: window.matchMedia('(min-width: 1000px').matches,
    };
    if (browser !== browserState) {
      this.setState({ browser: browserState });
    }
  }

  render() {
    const { horizontal, vertical } = this.props;
    const { browser } = this.state;

    const STestGrid = styled.div`
      ${({ theme: { breakpoints } }) => css`
        &&& {
          position: fixed;
          z-index: 99999;
          top: 0;
          width: 100vw;
          height: 100vh;
          font-size: 8px;
          text-align: center;
          pointer-events: none;
          color: rgb(49, 126, 56);

          &.horizontal {
            background-image: url('/static/icons/grid-line.svg');
            shape-rendering: crispEdges;
          }

          &.vertical div {
            &:before {
              display: block;
              height: 100%;
              content: attr(data-key);
              background: rgba(49, 126, 56, 0.05);
            }
          }
          @media (max-width: ${breakpoints.mobileMax}) {
            margin-left: -16px;
            padding-left: 8px;
          }
        }
      `}
    `;
    return process.env.NODE_ENV === 'development' ? (
      <STestGrid
        className={`test-grid-wrap row ${horizontal &&
          'horizontal'} ${vertical && 'vertical'}`}>
        <div className="col-sm-1 col-md-1 col-lg-1" data-key="1" />
        <div className="col-sm-1 col-md-1 col-lg-1" data-key="2" />
        <div className="col-sm-1 col-md-1 col-lg-1" data-key="3" />
        <div className="col-sm-1 col-md-1 col-lg-1" data-key="4" />
        <div className="col-sm-1 col-md-1 col-lg-1" data-key="5" />
        {browser.browserGreaterThanSmall && (
          <>
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="6" />
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="7" />
          </>
        )}
        {browser.browserGreaterThanMedium && (
          <>
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="8" />
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="9" />
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="10" />
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="11" />
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="12" />
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="13" />
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="14" />
            <div className="col-sm-1 col-md-1 col-lg-1" data-key="15" />
          </>
        )}
      </STestGrid>
    ) : null;
  }
}

export { TestGrid };
