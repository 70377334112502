const maxValue = (value: undefined | string, label: undefined | string, arg = "0") => {
  let useIcelandicMsg = false;

  if (arg.includes(" ")) {
    useIcelandicMsg = arg.split(" ")[1] === "IS" ? true : false;
    arg = arg.split(" ")[0];
  }

  return (
    Number(value) && Number(value) > Number(arg)
      ? useIcelandicMsg
        ? `${label || 'Þessi dálkur'} má ekki vera hærri en ${arg}`
        : `${label || 'This field'} cannot be higher than ${arg}`
      : undefined
  )
}

export { maxValue };
