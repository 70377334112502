import styled, { css } from 'styled-components';

const SLineWrapper = styled.hr`
  ${({ theme: { grid, color } }) => css`
    background-color: ${color.grayTwo};
    height: 0.5px;
    &.extra-margin-bottom {
      margin-bottom: ${`${grid.horizontal * 4}px`};
    }
    &.extra-margin-top {
      margin-top: ${`${grid.horizontal * 4}px`};
    }
  `}
`;

export { SLineWrapper };
