export default {
  id: 'adobeSignIntegration',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Adobe contract signing',
      className: 'size-md-medium no-margin-top',
    },

    {
      componentType: 'INPUT',
        name: 'AdobeEmails.0.Email',
        label: 'Email',
        type: 'email',
        icon: 'MAIL',
        placeholder: 'Enter email',
        validations: ['REQUIRED', 'EMAIL', 'MAXLENGTH(50)'],
    },
    {
      componentType: 'INFO',
      text:
        'Note: This is the email the contract will be sent to.',
      className: 'medium-margin-top',
    }
  ],
};
