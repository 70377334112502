import React from 'react';

const date = (e: React.BaseSyntheticEvent) => {
  const { value } = e.target;
  // No value, so return early
  if (!value) {
    return value;
  }

  // Filter string to only have numbers
  const onlyNums = value.replace(/[^\d]/g, '');

  // Numbers before '/', '01'
  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  // Numbers after first '/', '01 / 06'
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)} / ${onlyNums.slice(2, 5)}`;
  }

  // Full strength length, '01 / 06 / 1999'
  return `${onlyNums.slice(0, 2)} / ${onlyNums.slice(2, 4)} / ${onlyNums.slice(
    4,
    8,
  )}`;
};

export { date };
