import styled, { css } from 'styled-components';
import { FieldInputProps } from 'react-final-form';

export interface InputProps extends FieldInputProps<string> {
  name: string;
  disabled?: boolean;
  icon?: string;
  label?: string;
  mask?: string;
  placeholder?: string;
  type?: string;
  message?: string;
  transition?: string;
}

export const SInput = styled.input<InputProps>`
  ${({ theme: { color, grid }, icon, touched, active, error }) => css`
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    width: 100%;
    color: ${color.darkGray};
    caret-color: ${color.teal};
    height: ${grid.horizontal * 4}px;
    position: relative;
    border: 1px solid ${color.grayThree};
    border-radius: 4px;
    outline: none;
    font-size: 20px;
    background: inherit;
    margin-bottom: ${grid.horizontal * 3}px;
    ::placeholder {
      color: ${color.grayThree};
      font-weight: 100;
      line-height: 34px;
      font-size: 20px;
    }
    &.no-margin-bottom {
      margin-bottom: ${grid.horizontal * 0}px;
    }
    &.medium-margin-top {
      margin-top: ${grid.horizontal * 10}px;
    }
    ${!error &&
      touched &&
      css`
        border-color: ${color.teal};
        ~ div svg path {
          fill: ${color.teal};
        }
      `};
    ${error &&
      touched &&
      css`
        margin-bottom: ${active
          ? grid.horizontal * 3
          : grid.horizontal * 0.5}px;
        border-color: ${color.red};
        ~ div svg path {
          fill: ${color.red};
        }
      `};
    ${active &&
      css`
        border-color: ${color.teal};
        ~ div svg path {
          fill: ${color.teal};
        }
      `};
    &:disabled {
      background: ${color.lightGray};
      border-color: ${color.grayTwo};
      ~ div svg path {
        fill: ${color.grayTwo};
      }
    }
    padding-left: ${icon ? '58px' : '24px'};
  `}
`;

export const SLeftIconWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 20px;
`;

export const SRightIconWrapper = styled.div`
  margin: 20px 24px;
  right: 0px;
  position: absolute;
`;

export const StyleWrapper = styled.div<InputProps>`
  ${({ theme: { grid }, transition }) => css`
    display: flex;
    flex-direction: column;
    position: relative;

    ${transition &&
      css`
        transition: all 1s ease-out;
        }
      `};

    svg {
      height: ${grid.horizontal * 1.5}px;
      width: ${grid.vertical * 3}px;
    }
  `};
`;

export const InputWrapper = styled.div<InputProps>`
  display: flex;
  ${props => props.labelPosition === 'top' && css`
    flex-direction: column;
  `}
  
  ${props => props.labelPosition === 'bottom' && css`
    flex-direction: column-reverse;
  `}

  ${props => props.labelPosition === 'left' && css`
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;

    label {
      margin-top: 20px;
    }
  `}

  ${props => props.labelPosition === 'right' && css`
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 2rem;

    label {
      margin-top: 20px;
    }
  `}
`