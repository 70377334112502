import React, { useContext } from 'react';

import { Icon, ApplicationConsumer } from '@local';
import { SNav, SButton, SWrapper, SButtonText } from './index.styled';

// TODO : can't animate opacity on progress bar because it rerenders everytime we update the pages
const ProgressBar = () => {
  const {
    pageNumber,
    setPageNumber,
    setStep,
    setDirection,
    pagesFilledIn,
    setCurrentStepSetup,
    application,
  } = useContext(ApplicationConsumer);
  const handleClick = (index: number) => {
    // Is the user going back or forward, set state for the animation
    if (pageNumber > index) {
      setDirection('right');
    } else {
      setDirection('left');
    }
    setPageNumber(index);
    setStep(
      application.pages[index].steps[0].id === "authentication"
        ? 2
        : 0
    )
    setCurrentStepSetup(
      application.pages[index].steps[0].id === "authentication"
      ? application.pages[index].steps[2]
      : application.pages[index].steps[0]
    );
  };
  return (
    <SNav>
      <div className="row" id="progressbar">
        <div className="col-sm-5 col-md-5 col-lg-7 offset-md-1 offset-lg-4">
          <SWrapper>
            {application.pages.map((page, index) => {
              const wrapperProps =
                pageNumber === index ? { 'aria-current': true } : {};

              return (
                <SButton
                  data-title={page.title}
                  key={page.title}
                  onClick={() => handleClick(index)}
                  disabled={pageNumber < index && !(pagesFilledIn >= index)}
                  {...wrapperProps}
                  data-testid={page.title}>
                  <Icon icon={page.icon} />
                  <SButtonText>
                    {page.title}
                  </SButtonText>
                </SButton>
              );
            })}
          </SWrapper>
        </div>
      </div>
    </SNav>
  );
};

export { ProgressBar };
