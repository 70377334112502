import styled, { css } from 'styled-components';

import { Title } from '@local';

export const SUl = styled.ul`
  padding: 0;
`;

export const SLi = styled.li`
  ${({ theme: { grid } }) => css`
    :before {
      position: relative;
      left: 0;
      display: inline-block;
      margin-top: ${grid.horizontal * 0.5}px;
      margin-right: ${grid.vertical * 2}px;
      width: ${grid.vertical * 2}px;
      height: 12px;
      content: '';
      background-image: url('../../static/icons/CheckMarkIcon/check.png');
    }
    padding: 0;
    margin-top: ${grid.horizontal * -0.5}px;
    margin-bottom: ${grid.vertical * 3}px;
    display: flex;
    list-style: none;
    svg {
      min-width: ${grid.vertical * 2}px;
    }
  `}
`;

export const STitle = styled(Title)`
  ${({ theme: { grid } }) => css`
    display: flex;
    line-height: 1.5em;
    margin-bottom: ${grid.horizontal * 2}px;
  `}
`;
