import styled, { css } from 'styled-components';

export const SFeesLineWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    position: relative;
    margin-bottom: ${grid.horizontal * 2}px;
    height: ${grid.horizontal * 3}px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
      margin-bottom: 0;
    }
  `}
`;

export const SFeesInputWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    width: ${grid.vertical * 15}px;
    height: ${grid.horizontal * 3}px;
  `}
`;

export const SLabel = styled.label`
  ${({ theme: { color } }) => css`
    font-size: 18px;
    color: ${color.darkGray};
    line-height: 1.3888em;
    span {
      display: block;
      font-size: 14px;
    }
  `}
`;
export const SErrorWrapper = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  div {
    margin-bottom: 0;
  }
`;
