import styled, { css } from 'styled-components';

export interface LabelProps {
  active?: boolean;
  error?: boolean;
  touched?: boolean;
}

export const SelectWrapper = styled.div<LabelProps>`
  ${({ theme: { color, grid }, active, error, touched }) => css`
    font-size: 18px;
    margin-bottom: ${grid.horizontal * 0.5}px;
    &.extra-margin-bottom{
      margin-bottom: ${grid.horizontal * 4}px;
    }
    &.medium-margin-bottom{
      margin-bottom: ${grid.horizontal * 3}px;
    }


    .invalid {
      ${error &&
        touched &&
        !active &&
        css`
          [class*='singleValue'] {
            color: ${color.red};
            ~ div svg path {
              fill: ${color.red};
            }
          }
        `};

      & > :first-child {
        ${error &&
          touched &&
          !active &&
          css`
            border-color: ${color.red};
          `};
        }
      }
    }
  `}
`;

export const StyledError = styled.div`
  ${({ theme: { grid } }) => css`
    margin-top: ${grid.vertical}px;
  `};
`;

export const StyledLabel = styled.label`
  ${({ theme: { color } }) => css`
    width: 100%;
    color: ${color.darkGray};
    margin: 0;
  `}
`;
