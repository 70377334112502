export default {
  title: {
    componentType: 'TITLE',
    text: 'Please state your business start date',
    className: 'size-md-medium no-margin-top',
  },
  dateInput: {
    componentType: 'DATEINPUT',
    name: 'Business',
  },
};
