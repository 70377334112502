import React, { useState, useContext } from 'react';
import { Icon, ApplicationConsumer } from '@local';
import { FieldTypes } from 'src/types/field';
import { FormValuesTypes } from 'src/types/formValues';
import { SAddButton, SRemoveButton, SButtonWrapper } from './index.styled';

import { ComponentMap } from '../../../helpers/componentMapper/index';

interface AddButtonProps {
  id: string;
  addButtonText: string;
  addButtonIcon: string;
  removeButtonText: string;
  removeButtonIcon: string;
  component: FieldTypes;
  values?: FormValuesTypes;
}

const ReplaceWildCard = (text: string, wildCardReplace: number) => {
  return text.replace('*', wildCardReplace.toString());
};

const AddComponent = (
  component: FieldTypes,
  values: FormValuesTypes,
  index: number,
) => {
  const copyComponent = Object.assign({}, component);
  copyComponent.name = ReplaceWildCard(copyComponent.name || '', index);
  return <ComponentMap field={copyComponent} values={values} />;
};

let inputList = [] as JSX.Element[];

const useForceUpdate = () => {
  const [value, setState] = useState(true);
  return () => setState(!value);
};

const initializeInputs = (component: FieldTypes, values?: FormValuesTypes) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialList = [] as JSX.Element[];

  if (values && values.AdobeEmails && values.AdobeEmails.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values.AdobeEmails.forEach((index: any, key: any) => {
      initialList.push(AddComponent(component, values, key));
    });
    return initialList;
  }
  return [];
};

const newEmail = { Email: '' };

const AddButton = ({
  id,
  addButtonIcon,
  addButtonText,
  values,
  component,
  removeButtonIcon,
  removeButtonText,
}: AddButtonProps) => {
  const { adobeEmails, setAdobeEmails } = useContext(ApplicationConsumer);
  const forceUpdate = useForceUpdate();
  const [disabled, setDisabled] = useState(
    // eslint-disable-next-line no-nested-ternary
    values
      ? values.AdobeEmails
        ? values.AdobeEmails.length > 4 ?? true
        : false
      : false,
  );
  if (values && !values.AdobeEmails) {
    // eslint-disable-next-line no-param-reassign
    values.AdobeEmails = [];
    values.AdobeEmails.push(newEmail);
  }

  if (adobeEmails.length === 0) {
    inputList = initializeInputs(component, values);
    setAdobeEmails(inputList);
  }

  const onAddBtnClick = () => {
    if (inputList.length === 4) {
      setDisabled(true);
    }

    if (values) {
      const addEmail = AddComponent(
        component,
        values,
        values.AdobeEmails.length,
      );
      inputList.push(addEmail);
      values.AdobeEmails.push(newEmail);
      setAdobeEmails(inputList);
      forceUpdate();
    }
  };

  const onRmvBtnClick = () => {
    if (adobeEmails.length > 0) {
      const adobeEmailsList = adobeEmails;
      adobeEmailsList.pop();
      setAdobeEmails(adobeEmailsList);
    }
    values &&
      values.AdobeEmails &&
      values.AdobeEmails[adobeEmails.length] &&
      values.AdobeEmails.splice(-1, 1);
    disabled && setDisabled(false);
    forceUpdate();
  };

  return (
    <>
      <div>{adobeEmails}</div>
      <SButtonWrapper>
        <SAddButton
          type="button"
          id={id}
          data-testid="AddEmailButton"
          onClick={onAddBtnClick}
          disabled={disabled}>
          <Icon icon={addButtonIcon} />
          {addButtonText}
        </SAddButton>
        {adobeEmails.length > 1 && (
          <>
            <SRemoveButton
              type="button"
              id="remove store"
              onClick={() => onRmvBtnClick()}>
              <Icon icon={removeButtonIcon} />
              {removeButtonText}
            </SRemoveButton>
          </>
        )}
      </SButtonWrapper>
    </>
  );
};

export { AddButton };
