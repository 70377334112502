import { CompaniesHouseBirthdayData } from "src/components/Context/CompaniesHouseConsumer";
import { FormValuesTypes } from "src/types/formValues";
import { Address, PersonDateOfBirth } from "../types";

export const getDate = (year: string, month: string, day: string) => {
  return `${year}/${month}/${day}`
}

export const changeDateFormat = (date: string) => {
  if (date) {
    const dateList = date.split(" / ");
    if (dateList.length === 3) {
      const day = dateList[0];
      const month = dateList[1];
      const year = dateList[2];

      return `${year}/${month}/${day}`
    }
  }
}

export const getDateOfBirthObject = (year: string, month: string, day: string) => {
  return {
    DateOfBirth: getDate(year, month, day)
  }
}

export const getPersonofInterestInfoBy = (values: FormValuesTypes, index: number, key: string) => {
  const valueKey = `personofinterest${index}${key}`;
  const info = values[valueKey];
  return info;
};

export const isAddressEqual = (firstAddress: Address, secondAddress: Address) => {
  return firstAddress.Street === secondAddress.Street &&
    firstAddress.StreetContinued === secondAddress.StreetContinued &&
    firstAddress.City === secondAddress.City &&
    firstAddress.Postcode === secondAddress.Postcode
}

export const isCompaniesHouseBirthdayEqualToPersonBirthDay = (companiesHouseBirthDay: CompaniesHouseBirthdayData, personBirthDay: PersonDateOfBirth) => {
  return companiesHouseBirthDay.day === personBirthDay.dateofbirthStartDay &&
    companiesHouseBirthDay.month === personBirthDay.dateofbirthStartMonth &&
    companiesHouseBirthDay.year === personBirthDay.dateofbirthStartYear
}

export const getPostCode = (values: FormValuesTypes) => {
  return values.TradingStreetPostcode
    ? values.TradingStreetPostcode
    : values.Postcode;
}

export const getDateOfBirthObjectFromKennitala = (kennitala: string) => {
  const day = kennitala.substring(0, 2);
  const month = kennitala.substring(2, 4);
  const partialYear = kennitala.substring(4, 6);

  let year;
  if (kennitala[kennitala.length - 1] === "0") {
    year = `20${partialYear}`
  } else {
    year = `19${partialYear}`
  }

  return getDateOfBirthObject(year, month, day);

}

export const formatIcelandicPhoneNumber = (phonePrefix: string, phoneNumber: string) => {
  if (!phonePrefix) {
    if (phoneNumber.substring(0, 4) === '+354') {
      phonePrefix = '+354';
      phoneNumber = phoneNumber.substring(4);
    }
  }

  return {
    Prefix: phonePrefix,
    Number: phoneNumber
  }
}