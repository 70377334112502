export default {
  title: {
    componentType: 'TITLE',
    text: 'Average time between purchase and delivery in days',
    className: 'size-md-medium extra-margin-top',
  },
  input: {
    componentType: 'INPUT',
    name: 'AvgDeliveryTime',
    label: 'Days',
    type: 'NUMBER',
    placeholder: 'Insert a number',
    validations: ['REQUIRED'],
  },
};
