import styled, { css } from 'styled-components';

interface LabelProps {
  disabled?: boolean;
}

const SLabel = styled.label<LabelProps>`
  ${({ theme: { color, grid }, disabled }) =>
    css`
      display: block;
      margin-bottom: 11px;
      color: ${disabled ? color.grayTwo : color.darkGray};
      &.medium-margin-top {
        margin-top: ${grid.horizontal * 2}px;
      }
    `}
`;

export { SLabel };
