import styled, { css } from 'styled-components';

const SVersionNumber = styled.p`
  ${({ theme: { color, grid, breakpoints } }) =>
    css`
      color: ${color.lightGrayTwo};
      font-size: 14px;
      line-height: 1.357em;
      text-align: right;
      @media (min-width: ${breakpoints.tabletMin}) {
        padding-right: ${grid.vertical * 4}px;
      }
    `}
`;

export { SVersionNumber };
