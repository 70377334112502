export default {
  id: 'maxTransaction',
  conditionalNextSteps: [
    {
      id: 'Stores.0.MotoTrans',
      skipToStep: 'paymentInAdvance',
      optionalLessThen: '1',
    },
  ],
  fields: [
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.0.StoreName',
      className: 'size-md-large extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected average transaction amount?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Stores.0.AvgTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      validations: [
        'REQUIRED(Expected average transaction amount is required)',
        'INTEGER',
        'MINVALUE(1)',
      ],
    },
    {
      componentType: 'INFO',
      text:
        'This means you are looking at the total avarage amount, the most common one.',
      className: 'extra-margin-bottom',
    },
    {
      componentType: 'TITLE',
      text: 'How many card transactions are you expecting on an average month?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Stores.0.AvgProcessed',
      label: 'Please enter the expected number',
      type: 'NUMBER',
      fractional: 0,
      placeholder: 'Insert a number',
      validations: [
        'REQUIRED(Expected monthly transaction is required)',
        'INTEGER',
        'MINVALUE(1)',
      ],
    },
    {
      componentType: 'INFO',
      text:
        'This means how many transactions you’ve had over a certain period of time and round it up.',
      className: 'extra-margin-bottom',
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected maximum transaction amount?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Stores.0.MaxTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      validations: [
        'REQUIRED(Expected maximum transaction amount is required)',
        'INTEGER',
        'MINVALUE(1)',
      ],
    },
    {
      componentType: 'INFO',
      className: 'extra-margin-bottom',
      text:
        'This means that you are looking at the highest transaction you have charged recently.',
    },
    {
      componentType: 'LINESEPARATOR',
      className: 'extra-margin-bottom extra-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.1.StoreName',
      className: 'size-md-large extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected average transaction amount?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.1.AvgTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.1.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected average transaction amount is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      text:
        'This means you are looking at the total avarage amount, the most common one.',
      className: 'extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'How many card transactions are you expecting on an average month?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.1.AvgProcessed',
      label: 'Please enter the expected number',
      type: 'NUMBER',
      fractional: 0,
      placeholder: 'Insert a number',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.1.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected monthly transaction is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      text:
        'This means how many transactions you’ve had over a certain period of time and round it up.',
      className: 'extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected maximum transaction amount?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.1.MaxTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.1.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected maximum transaction amount is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      className: 'extra-margin-bottom',
      text:
        'This means that you are looking at the highest transaction you have charged recently.',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'LINESEPARATOR',
      className: 'extra-margin-bottom extra-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.2.StoreName',
      className: 'size-md-large extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected average transaction amount?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.2.AvgTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.2.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected average transaction amount is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      text:
        'This means you are looking at the total avarage amount, the most common one.',
      className: 'extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'How many card transactions are you expecting on an average month?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.2.AvgProcessed',
      label: 'Please enter the expected number',
      type: 'NUMBER',
      fractional: 0,
      placeholder: 'Insert a number',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.2.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected monthly transaction is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      text:
        'This means how many transactions you’ve had over a certain period of time and round it up.',
      className: 'extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected maximum transaction amount?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.2.MaxTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.2.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected maximum transaction amount is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      className: 'extra-margin-bottom',
      text:
        'This means that you are looking at the highest transaction you have charged recently.',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'LINESEPARATOR',
      className: 'extra-margin-bottom extra-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.3.StoreName',
      className: 'size-md-large extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected average transaction amount?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.3.AvgTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.3.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected average transaction amount is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      text:
        'This means you are looking at the total avarage amount, the most common one.',
      className: 'extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'How many card transactions are you expecting on an average month?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.3.AvgProcessed',
      label: 'Please enter the expected number',
      type: 'NUMBER',
      fractional: 0,
      placeholder: 'Insert a number',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.3.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected monthly transaction is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      text:
        'This means how many transactions you’ve had over a certain period of time and round it up.',
      className: 'extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected maximum transaction amount?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.3.MaxTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.3.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected maximum transaction amount is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      className: 'extra-margin-bottom',
      text:
        'This means that you are looking at the highest transaction you have charged recently.',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'LINESEPARATOR',
      className: 'extra-margin-bottom extra-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.4.StoreName',
      className: 'size-md-large extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected average transaction amount?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.4.AvgTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.4.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected average transaction amount is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      text:
        'This means you are looking at the total avarage amount, the most common one.',
      className: 'extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'How many card transactions are you expecting on an average month?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.4.AvgProcessed',
      label: 'Please enter the expected number',
      type: 'NUMBER',
      fractional: 0,
      placeholder: 'Insert a number',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.4.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected monthly transaction is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      text:
        'This means how many transactions you’ve had over a certain period of time and round it up.',
      className: 'extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'TITLE',
      text: 'What is your expected maximum transaction amount?',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.4.MaxTrans',
      label: 'Please enter an amount',
      type: 'NUMBER',
      fractional: 0,
      icon: 'POUND',
      placeholder: 'E.g. 150',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.4.StoreName',
        conditionalValidate: [
          'REQUIRED(Expected maximum transaction amount is required)',
          'INTEGER',
          'MINVALUE(1)',
        ],
      },
    },
    {
      componentType: 'INFO',
      className: 'extra-margin-bottom',
      text:
        'This means that you are looking at the highest transaction you have charged recently.',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
  ],
};
