import React from 'react';
import { CSSTransition } from 'react-transition-group';

interface StepAnimationProps {
  children: React.ReactNode;
  classNames: string;
  key: number | string;
  mountOnEnter: boolean;
  timeout: number;
  unmountOnExit: boolean;
}

const StepAnimation = ({ children, timeout, ...props }: StepAnimationProps) => (
  <CSSTransition timeout={timeout} {...props}>
    {children}
  </CSSTransition>
);

export { StepAnimation };
