import React, { useState, useEffect, useRef } from 'react';
import { Icon, Title } from '@local';
import { FieldTypes } from 'src/types/field';
import { FormValuesTypes } from 'src/types/formValues';
import {
  SAddButton,
  SRemoveButton,
  SWrapper,
  SButtonWrapper,
  SFadeDownDiv,
} from './index.styled';
import { ComponentMap } from '../../../helpers/componentMapper/index';

interface MultipleStoreProps {
  id: string;
  addButtonText: string;
  addButtonIcon: string;
  removeButtonText: string;
  removeButtonIcon: string;
  values?: FormValuesTypes;
}

const firstInputField = {
  name: 'Stores.0.StoreName',
  componentType: 'INPUT',
  label: 'Store name',
  icon: 'STORE',
  validations: ['REQUIRED', 'MAXLENGTH(25)'],
} as FieldTypes;

const firstSelectField = {
  name: 'Stores.0.Mcc',
  id: 'Stores.0.Mcc',
  componentType: 'SELECT',
  label: 'What kind of business is this?',
  placeholder: 'E.g. restaurant',
  options: 'MCC',
  validations: ['SELECTREQUIRED'],
};

const newStore = {
  StoreName: '',
  TradingStreet: '',
  TradingStreetContinued: '',
  TradingStreetPostcode: '',
  TradingStreetCity: '',
  AvgTrans: '',
  AvgProcessed: '',
  MaxTrans: '',
  MotoTrans: '',
  BusinessExistence: {},
};

const ordinalInWord = (cardinal: number) => {
  const ordinals = ['First', 'Second', 'Third', 'Fourth', 'Fifth'];
  return ordinals[cardinal];
};

const AddStore = (index: number, values: FormValuesTypes) => {
  const inputField = {
    name: `Stores.${index}.StoreName`,
    componentType: 'INPUT',
    label: 'Store name',
    icon: 'STORE',
    validations: ['REQUIRED', 'MAXLENGTH(25)'],
  } as FieldTypes;

  const selectField = {
    name: `Stores.${index}.Mcc`,
    id: `Stores.${index}.Mcc`,
    componentType: 'SELECT',
    label: 'What kind of business is this?',
    placeholder: 'E.g. restaurant',
    options: 'MCC',
    validations: ['SELECTREQUIRED'],
  };

  return (
    <SFadeDownDiv>
      <Title className="size-md-medium extra-margin-top">
        {ordinalInWord(index)} Store
      </Title>
      <ComponentMap field={inputField} values={values} />
      <SWrapper>
        <ComponentMap field={selectField} values={values} />
      </SWrapper>
    </SFadeDownDiv>
  );
};

let inputList = [] as JSX.Element[];

const useForceUpdate = () => {
  const [value, setState] = useState(true);
  return () => setState(!value);
};

const initializeInputs = (values?: FormValuesTypes) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialList = [] as JSX.Element[];

  if (values && values.Stores && values.Stores.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values.Stores.slice(1).forEach((index: any, key: any) => {
      initialList.push(AddStore(key + 1, values));
    });
    return initialList;
  }
  return [];
};

const MultipleStoreButton = ({
  id,
  addButtonIcon,
  addButtonText,
  removeButtonIcon,
  removeButtonText,
  values,
}: MultipleStoreProps) => {
  const [disabled, setDisabled] = useState(inputList.length === 4 ?? true);
  const [storesCount, setStoresCount] = useState(inputList.length + 1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addButtonRef = useRef(null as any);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removeButtonRef = useRef(null as any);

  if (values && !values.Stores) {
    // eslint-disable-next-line no-param-reassign
    values.Stores = [];
    values.Stores.push(newStore);
  }
  useEffect(() => {
    if (storesCount > 1) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      addButtonRef.current!.style.animation = 'fadeInDown 1s';
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      removeButtonRef.current!.style.animation = 'fadeInDown 1s';
    }
  }, [storesCount, addButtonRef, removeButtonRef]);

  const forceUpdate = useForceUpdate();
  inputList = initializeInputs(values);

  const onAddBtnClick = () => {
    if (inputList.length === 3) {
      setDisabled(true);
    }
    const store = values && AddStore(inputList.length + 1, values);
    store && inputList.push(store);
    values && values.Stores && values.Stores.push(newStore);
    setStoresCount(inputList.length + 1);
    forceUpdate();
  };

  const onRmvBtnClick = () => {
    inputList.length > 0 && inputList.pop();
    values &&
      values.Stores &&
      values.Stores[inputList.length + 1] &&
      values.Stores.splice(-1, 1);
    disabled && setDisabled(false);
    forceUpdate();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAnimationEnd = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    e.nativeEvent.srcElement!.style.animation = 'none';
  };

  // This is now hardcoded component for Adding stores. Future behavior that this component could add any other components. Should be able to set in forms what component to add.
  return (
    <>
      <div>
        <Title className="size-md-medium no-margin-top">
          Tell us about your business
        </Title>
        <ComponentMap field={firstInputField} values={values} />
        <SWrapper>
          <ComponentMap field={firstSelectField} values={values} />
        </SWrapper>
        {inputList}
      </div>
      <SButtonWrapper>
        <SAddButton
          type="button"
          id={id}
          data-testid="AddStore"
          disabled={disabled}
          onClick={onAddBtnClick}
          ref={addButtonRef}
          onAnimationEnd={e => handleAnimationEnd(e)}>
          <Icon icon={addButtonIcon} />
          {addButtonText}
        </SAddButton>
        {inputList.length > 0 && (
          <SRemoveButton
            type="button"
            id="remove store"
            onClick={() => onRmvBtnClick()}
            ref={removeButtonRef}
            onAnimationEnd={e => handleAnimationEnd(e)}>
            <Icon icon={removeButtonIcon} />
            {removeButtonText}
          </SRemoveButton>
        )}
      </SButtonWrapper>
    </>
  );
};

export { MultipleStoreButton };
