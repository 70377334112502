import React from 'react';
import { UploadedImage } from '@local';
import ObjectPath from 'object-path';

interface UploadedImagesProps {
  valuesArray: string;
  propertyName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
  placeholder?: string;
}

const UploadedImages = ({
  valuesArray,
  propertyName,
  values,
  placeholder,
}: UploadedImagesProps) => {
  const array = ObjectPath.get(values, valuesArray);
  const arrayList = [] as JSX.Element[];
  if (array) {
    for (let arrayIndex = 0; arrayIndex <= array.length; arrayIndex++) {
      const value = ObjectPath.get(
        values,
        `${valuesArray}.${arrayIndex}.${propertyName}`,
      );

      // If user uploaded multiple files for one store
      if (value && value.length > 1) {
        for (let fileIndex = 0; fileIndex < value.length; fileIndex++) {
          const file = value[fileIndex];
          file && file.type.match(/image\/(gif|jpe?g|png|webp|bmp)$/g)
            ? arrayList.push(
                <UploadedImage
                  values={values}
                  id={`${valuesArray}.${arrayIndex}.${propertyName}.${fileIndex}`}
                  placeholder={placeholder}
                />,
              )
            : arrayList.push(
                <UploadedImage
                  values={values}
                  id="BusinessExistence"
                  placeholder={`File cannot be previewed.\nFile name is ${file.name}`}
                />,
              );
        }
        // User uploaded one file for store
      } else if (
        value &&
        value !== undefined &&
        typeof value === 'object' &&
        value.type !== undefined
      ) {
        if (Object.keys(value).length === 0) {
          value.type.match(/image\/(gif|jpe?g|png|webp|bmp)$/g)
            ? arrayList.push(
                <UploadedImage
                  values={values}
                  id={`${valuesArray}.${arrayIndex}.${propertyName}`}
                  placeholder={placeholder}
                />,
              )
            : arrayList.push(
                <UploadedImage
                  values={values}
                  id="BusinessExistence"
                  placeholder={`File cannot be previewed.\nFile name is ${value.name}`}
                />,
              );
        } else {
          for (let index = 0; index < Object.keys(value).length; index++) {
            const valueInArray = ObjectPath.get(
              values,
              `${valuesArray}.${arrayIndex}.${propertyName}.${index}`,
            );
            valueInArray.type.match(/image\/(gif|jpe?g|png|webp|bmp)$/g)
              ? arrayList.push(
                  <UploadedImage
                    values={values}
                    id={`${valuesArray}.${arrayIndex}.${propertyName}.${index}`}
                    placeholder={placeholder}
                  />,
                )
              : arrayList.push(
                  <UploadedImage
                    values={values}
                    id="BusinessExistence"
                    placeholder={`File cannot be previewed.\nFile name is ${valueInArray.name}`}
                  />,
                );
          }
        }
      }
    }
  }

  return arrayList.length > 0 ? (
    <>{arrayList}</>
  ) : (
    <UploadedImage
      values={values}
      id="BusinessExistence"
      placeholder={placeholder}
    />
  );
};

export { UploadedImages };
