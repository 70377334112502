import { FormValuesTypes } from 'src/types/formValues';
import React, { useContext } from 'react';
import { ApplicationConsumer, Title } from '@local';
import { ReviewCompanyDetails } from './ReviewCompanyDetails';
import { ReviewCompanyDirectors } from './ReviewCompanyDirectors';
import { SReviewBlockContainer } from '../ReviewBlock/index.styled';
import { ReviewListItem } from '../ReviewListItem';

const ReviewCompany = (values: FormValuesTypes) => {
  const { application } = useContext(ApplicationConsumer);
  return (
    <>
      <SReviewBlockContainer>
        <Title
          tag="h2"
          className="size-md-medium no-margin-top review-list-title">
          Company Details
        </Title>
        <ReviewCompanyDetails {...values} />
      </SReviewBlockContainer>
      <SReviewBlockContainer>
        <Title
          tag="h2"
          className="size-md-medium no-margin-top review-list-title">
          Company Directors and Shareholders
        </Title>
        <ReviewCompanyDirectors {...values} />
        {application.applicationType === 'PublicLimitedCompany' && (
          <ReviewListItem title="Registered to the stock exchange">
            {values.StockExchange &&
            values.StockExchange.length > 0 &&
            values.StockExchange[0] === 'yes'
              ? 'Yes'
              : 'No'}
          </ReviewListItem>
        )}
      </SReviewBlockContainer>
    </>
  );
};

export { ReviewCompany };
