import styled, { css } from 'styled-components';

export const SBeggingScreenWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    h1 {
      margin-bottom: ${grid.horizontal * 3}px;
    }
    h2 {
      margin-bottom: ${grid.horizontal}px !important;
      font-size: 24px;
      line-height: 1.33333em;
    }
    p {
      margin-bottom: ${grid.horizontal * 3}px;
    }
    button {
      margin-top: ${grid.horizontal * 3}px;
      margin-bottom: ${grid.horizontal * 3}px;
    }
  `}
`;

export const SContentWrapper = styled.div`
  min-height: 55vh;
`;

export const SButtonWrapper = styled.div`
  ${({ theme: { grid, breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${grid.horizontal * 4}px;

    @media (min-width: ${breakpoints.tabletMin}) {
      flex-direction: row;
      justify-content: space-between;
    }
  `};
`;

export const SLogo = styled.div`
  ${({ theme: { grid, breakpoints } }) => css`
    svg {
      margin-top: ${grid.horizontal * 8}px;
      margin-bottom: ${grid.horizontal * 2}px;
      height: ${grid.horizontal * 5}px;
      width: ${grid.horizontal * 6}px;
    }
    @media (min-width: ${breakpoints.tabletMin}) {
      svg {
        height: ${grid.vertical * 9}px;
        width: ${grid.horizontal * 6}px;
        margin-top: ${grid.horizontal * 8.5}px;
        margin-bottom: ${grid.horizontal * 3.5}px;
      }
    }
  `}
`;

export const SButton = styled.button`
  ${({ theme: { color, grid, breakpoints } }) => css`
    margin-bottom: ${grid.horizontal * 1}px;
    font-weight: 600;
    font-size: 18px;
    background-color: ${color.teal};
    color: white;
    border-color: transparent;
    border-radius: 4px;
    height: ${grid.horizontal * 4}px;

    &:disabled:hover {
      box-shadow: none;
      transform: none;
      background-color: ${color.teal50};
    }
    &:disabled {
      box-shadow: none;
      transform: none;
      background-color: ${color.teal50};
      svg {
        rect {
          fill: ${color.teal50};
        }
      }
    }
    align-items: center;

    svg {
      rect {
        fill: ${color.teal};
      }
      path {
        fill: ${color.white};
      }
    }

    @media (min-width: ${breakpoints.tabletMin}) {
      width: ${grid.vertical * 31.4}px;
      margin-bottom: 0px;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
      transform: translate3d(0, 0, 0);
      box-shadow: 0 0 0 rgba(79, 158, 166, 0.3);
      transition: transform 200ms, box-shadow 200ms ease;
      &:hover {
        transform: translate3d(0, -1px, 0);
        box-shadow: 7px 9px 0px rgba(79, 158, 166, 0.3);
        transition: transform 200ms, box-shadow 200ms ease;
      }
    }
  `}
`;
