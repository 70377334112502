import styled, { css } from 'styled-components';
import { FieldInputProps } from 'react-final-form';

export interface SDateInputProps
  extends FieldInputProps<string> {
  disabled?: boolean;
  icon?: string;
  label?: string;
  mask?: string;
  placeholder?: string;
  type?: string;
  message?: string;
}

export const SDateInput = styled.input<SDateInputProps>`
  ${({ theme: { color, grid }, touched, active, error }) => css`
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    width: 100%;
    color: ${color.darkGray};
    caret-color: ${color.teal};
    height: ${grid.horizontal * 4}px;
    border: 1px solid ${color.grayThree};
    border-radius: 4px;
    outline: none;
    font-size: 20px;
    background: inherit;
    ::placeholder {
      color: ${color.grayThree};
      font-weight: 100;
      line-height: 34px;
      font-size: 20px;
    }

    ${!error &&
      touched &&
      css`
        border-color: ${color.teal};
        ~ div svg path {
          fill: ${color.teal};
        }
      `};
    ${error &&
      touched &&
      css`
        border-color: ${color.red};
        ~ div svg path {
          fill: ${color.red};
        }
      `};
    ${active &&
      css`
        border-color: ${color.teal};
        ~ div svg path {
          fill: ${color.teal};
        }
      `};

    padding-left: 24px;
  `}
`;

export const SLegend = styled.label`
  ${({ theme: { color, grid } }) =>
    css`
      font-size: 18px;
      color: ${color.darkGray};
      margin-top: ${grid.horizontal * 2}px;
    `}
`;

export const SInputWrapper = styled.div`
  ${({ theme: { breakpoints, grid } }) => css`
    @media (min-width: ${breakpoints.tabletMin}) {
      flex: 1;
      margin-right: ${grid.horizontal * 2}px;
    }

    div[class$='error-label '] {
      margin-top: ${grid.horizontal * 0.5}px;
      margin-bottom: 0px;
      position: absolute;
    }
  `}
`;

export const SWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    @media (min-width: ${breakpoints.tabletMin}) {
      display: flex;
    }
    margin-top:-32px;
    width: 100%;
    height: 100%;
  `}
`;

export const SParagraphWrapper = styled.p`
  ${({ theme: { grid } }) =>
    css`
      font-size: 18px;
      margin-top: ${grid.horizontal * 2}px;
    `}
`;
