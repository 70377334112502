import React from 'react';

export const IncrementIcon = () => (
  <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="4" fill="#E8EEF1" />
    <path
      d="M29.8438 19.0938c0-.2762-.2239-.5-.5-.5h-7.4376c-.2761 0-.5-.2239-.5-.5v-7.4376c0-.2761-.2238-.5-.5-.5h-1.8124c-.2762 0-.5.2239-.5.5v7.4376c0 .2761-.2239.5-.5.5h-7.4376c-.2761 0-.5.2238-.5.5v1.8124c0 .2762.2239.5.5.5h7.4376c.2761 0 .5.2239.5.5v7.4376c0 .2761.2238.5.5.5h1.8124c.2762 0 .5-.2239.5-.5v-7.4376c0-.2761.2239-.5.5-.5h7.4376c.2761 0 .5-.2238.5-.5v-1.8124z"
      fill="#1B5074"
    />
  </svg>
);

export default IncrementIcon;
