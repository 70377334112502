export default {
  id: 'posar',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Hér getur þú valið þér greiðsluposa',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'TERMINALLIST',
      name: 'terminalList',
      validations: ['POSLIST(Þú þarft að velja að minnsta kosti einn posa'],
      counterText: 'Hversu marga posa þarft þú?',
      terminals: [
        {
          kicker: 'Pax A920 4G',
          maxValue: 699,
          minValue: 492,
          title: 'GPRS & Wi-Fi',
          image: {
            name: 'pax-countertop',
            alt: 'Image of POS device',
          },
          index: 2,
          defaultValue: 1,
          list: [
            {
              text: 'Android stýrikerfi',
            },
            {
              text: '4G eða Wifi tenging',
            },
            {
              text: 'Tenging við sölukerfi',
            },
            {
              text: 'Hægt að fá með hleðslustöð',
            },
            {
              text: 'Sýnir allar nótur á skjánum',
            },
          ],
        },
      ],
    },
    {
      componentType: 'INFO',
      text:
        <a href="https://www.valitor.is/adrar-upplysingar/verdskra-skilmalar/" className="link" rel="noopener noreferrer" target="_blank" >Þú getur séð verðskrá Rapyd hér</a>
    },
  ],
};
