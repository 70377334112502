export default {
  id: 'companyInformation',
  showOnTemplates: 'showOnCountries',
  showOnCountries: ['DK'],
  fields: [
    {
      componentType: 'TITLE',
      text: 'Please fill out company information',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Company.Name',
      label: 'Company name',
      type: 'text',
      placeholder: 'Enter company name',
      validations: ['REQUIRED', 'MAXLENGTH(50)'],
      className: 'medium-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Company.Number',
      label: 'VAT (registration number)',
      type: 'NUMBER',
      placeholder: 'Enter company VAT number',
      validations: ['REQUIRED'],
    },
    {
      componentType: 'TITLE',
      text: 'What’s the company address?',
      className: 'size-md-medium',
    },
    {
      componentType: 'INPUT',
      name: 'Company.Street',
      label: 'Address Line 1',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'Enter the company address',
      validations: ['REQUIRED', 'MAXLENGTH(50)'],
    },
    {
      componentType: 'INPUT',
      name: 'Company.StreetContinued',
      label: 'Address Line 2 (optional)',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'Enter the company address',
    },
    {
      componentType: 'INPUT',
      name: 'Company.City',
      label: 'Town/City',
      type: 'text',
      icon: 'TOWNBUILDING',
      placeholder: 'E.g. London',
      validations: ['REQUIRED', 'MAXLENGTH(100)'],
    },
    {
      componentType: 'INPUT',
      name: 'Company.Postcode',
      label: 'Postcode',
      type: 'text',
      icon: 'POSTBOXICON',
      placeholder: 'E.g. SW1Y 4QE',
      validations: ['REQUIRED', 'MAXLENGTH(10)'],
    },
    {
      componentType: 'TITLE',
      text: 'Company start date',
      className: 'size-md-medium',
    },
    {
      componentType: 'DATEINPUT',
      name: 'Company.Business',
    },
    {
      componentType: 'TITLE',
      text: 'How can we contact the business?',
      className: 'size-md-medium',
    },
    {
      componentType: 'INPUT',
      name: 'EmailStores',
      label: 'Email',
      type: 'email',
      icon: 'MAIL',
      placeholder: 'Enter company email',
      validations: ['REQUIRED', 'EMAIL', 'MAXLENGTH(60)'],
    },
    {
      componentType: 'PHONENUMBER',
      name: 'Phone',
      label: 'Phone number',
      placeholder: 'E.g 7911 123456',
      validations: ['REQUIRED', 'PHONENUMBER', 'MAXLENGTH(15)'],
    },
  ],
};
