export default {
  id: 'storeLocation',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Where is your store located?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.0.StoreName',
      className: 'size-md-medium no-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'ADDRESSLOOKUP',
      name: 'Stores.0.AddressLookUp',
      label: 'Address finder',
      icon: 'SEARCH',
      placeholder: 'Search for address by postcode',
      copyTo: [
        ['line_1', 'Stores.0.TradingStreet'],
        ['line_2', 'Stores.0.TradingStreetContinued'],
        ['post_town', 'Stores.0.TradingStreetCity'],
        ['postcode', 'Stores.0.TradingStreetPostcode'],
      ],
    },
    {
      componentType: 'INPUT',
      name: 'Stores.0.TradingStreet',
      label: 'Address Line 1',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      validations: ['REQUIRED', 'MAXLENGTH(50)'],
      reviewPageTitle: 'Address Line 1',
    },
    {
      componentType: 'INPUT',
      name: 'Stores.0.TradingStreetContinued',
      label: 'Address Line 2 (optional)',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      reviewPageTitle: 'Address Line 2',
    },
    {
      componentType: 'INPUTSIDEBYSIDE',
      reviewPageTitle: 'Town/City - Postcode',
      components: [
        {
          componentType: 'INPUT',
          name: 'Stores.0.TradingStreetCity',
          label: 'Town/City',
          type: 'text',
          icon: 'TOWNBUILDING',
          placeholder: 'E.g. London',
          validations: ['REQUIRED', 'MAXLENGTH(100)'],
          className: 'flex-grow',
          reviewPageTitle: 'Town/City',
        },
        {
          componentType: 'INPUT',
          name: 'Stores.0.TradingStreetPostcode',
          label: 'Postcode',
          type: 'text',
          icon: 'POSTBOXICON',
          placeholder: 'E.g. SW1Y 4QE',
          validations: ['REQUIRED', 'MAXLENGTH(10)', 'MINLENGTH(3)'],
          reviewPageTitle: 'Postcode',
        },
      ],
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.1.StoreName',
      className: 'size-md-medium',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'ADDRESSLOOKUP',
      name: 'Stores.1.StoreAddressLookUp',
      label: 'Address finder',
      icon: 'SEARCH',
      placeholder: 'Search for address by postcode',
      copyTo: [
        ['line_1', 'Stores.1.TradingStreet'],
        ['line_2', 'Stores.1.TradingStreetContinued'],
        ['post_town', 'Stores.1.TradingStreetCity'],
        ['postcode', 'Stores.1.TradingStreetPostcode'],
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.1.TradingStreet',
      label: 'Address Line 1',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.1.StoreName',
        conditionalValidate: ['REQUIRED', 'MAXLENGTH(50)'],
      },

    },
    {
      componentType: 'INPUT',
      name: 'Stores.1.TradingStreetContinued',
      label: 'Address Line 2 (optional)',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'INPUTSIDEBYSIDE',
      reviewPageTitle: 'Town/City - Postcode',
      components: [
        {
          componentType: 'INPUT',
          name: 'Stores.1.TradingStreetCity',
          label: 'Town/City',
          type: 'text',
          icon: 'TOWNBUILDING',
          placeholder: 'E.g. London',
          validations: ['REQUIRED', 'MAXLENGTH(100)'],
          className: 'flex-grow',
        },
        {
          componentType: 'INPUT',
          name: 'Stores.1.TradingStreetPostcode',
          label: 'Postcode',
          type: 'text',
          icon: 'POSTBOXICON',
          placeholder: 'E.g. SW1Y 4QE',
          validations: ['REQUIRED', 'MAXLENGTH(10)', 'MINLENGTH(3)'],
        },
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.2.StoreName',
      className: 'size-md-medium',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'ADDRESSLOOKUP',
      name: 'Stores.2.StoreAddressLookUp',
      label: 'Address finder',
      icon: 'SEARCH',
      placeholder: 'Search for address by postcode',
      copyTo: [
        ['line_1', 'Stores.2.TradingStreet'],
        ['line_2', 'Stores.2.TradingStreetContinued'],
        ['post_town', 'Stores.2.TradingStreetCity'],
        ['postcode', 'Stores.2.TradingStreetPostcode'],
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.2.TradingStreet',
      label: 'Address Line 1',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.2.StoreName',
        conditionalValidate: ['REQUIRED', 'MAXLENGTH(50)'],
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.2.TradingStreetContinued',
      label: 'Address Line 2 (optional)',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'INPUTSIDEBYSIDE',
      reviewPageTitle: 'Town/City - Postcode',
      components: [
        {
          componentType: 'INPUT',
          name: 'Stores.2.TradingStreetCity',
          label: 'Town/City',
          type: 'text',
          icon: 'TOWNBUILDING',
          placeholder: 'E.g. London',
          validations: ['REQUIRED', 'MAXLENGTH(100)'],
          className: 'flex-grow',
        },
        {
          componentType: 'INPUT',
          name: 'Stores.2.TradingStreetPostcode',
          label: 'Postcode',
          type: 'text',
          icon: 'POSTBOXICON',
          placeholder: 'E.g. SW1Y 4QE',
          validations: ['REQUIRED', 'MAXLENGTH(10)', 'MINLENGTH(3)'],
        },
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.3.StoreName',
      className: 'size-md-medium',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'ADDRESSLOOKUP',
      name: 'Stores.3.StoreAddressLookUp',
      label: 'Address finder',
      icon: 'SEARCH',
      placeholder: 'Search for address by postcode',
      copyTo: [
        ['line_1', 'Stores.3.TradingStreet'],
        ['line_2', 'Stores.3.TradingStreetContinued'],
        ['post_town', 'Stores.3.TradingStreetCity'],
        ['postcode', 'Stores.3.TradingStreetPostcode'],
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.3.TradingStreet',
      label: 'Address Line 1',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.3.StoreName',
        conditionalValidate: ['REQUIRED', 'MAXLENGTH(50)'],
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.3.TradingStreetContinued',
      label: 'Address Line 2 (optional)',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'INPUTSIDEBYSIDE',
      reviewPageTitle: 'Town/City - Postcode',
      components: [
        {
          componentType: 'INPUT',
          name: 'Stores.3.TradingStreetCity',
          label: 'Town/City',
          type: 'text',
          icon: 'TOWNBUILDING',
          placeholder: 'E.g. London',
          validations: ['REQUIRED', 'MAXLENGTH(100)'],
          className: 'flex-grow',
        },
        {
          componentType: 'INPUT',
          name: 'Stores.3.TradingStreetPostcode',
          label: 'Postcode',
          type: 'text',
          icon: 'POSTBOXICON',
          placeholder: 'E.g. SW1Y 4QE',
          validations: ['REQUIRED', 'MAXLENGTH(10)', 'MINLENGTH(3)'],
        },
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.4.StoreName',
      className: 'size-md-medium',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'ADDRESSLOOKUP',
      name: 'Stores.4.StoreAddressLookUp',
      label: 'Address finder',
      icon: 'SEARCH',
      placeholder: 'Search for address by postcode',
      copyTo: [
        ['line_1', 'Stores.4.TradingStreet'],
        ['line_2', 'Stores.4.TradingStreetContinued'],
        ['post_town', 'Stores.4.TradingStreetCity'],
        ['postcode', 'Stores.4.TradingStreetPostcode'],
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.4.TradingStreet',
      label: 'Address Line 1',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      conditional: {
        type: ['SHOW', 'VALIDATE'],
        conditionName: 'Stores.4.StoreName',
        conditionalValidate: ['REQUIRED', 'MAXLENGTH(50)'],
      },
    },
    {
      componentType: 'INPUT',
      name: 'Stores.4.TradingStreetContinued',
      label: 'Address Line 2 (optional)',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'The address of your store',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'INPUTSIDEBYSIDE',
      reviewPageTitle: 'Town/City - Postcode',
      components: [
        {
          componentType: 'INPUT',
          name: 'Stores.4.TradingStreetCity',
          label: 'Town/City',
          type: 'text',
          icon: 'TOWNBUILDING',
          placeholder: 'E.g. London',
          validations: ['REQUIRED', 'MAXLENGTH(100)'],
          className: 'flex-grow',
        },
        {
          componentType: 'INPUT',
          name: 'Stores.4.TradingStreetPostcode',
          label: 'Postcode',
          type: 'text',
          icon: 'POSTBOXICON',
          placeholder: 'E.g. SW1Y 4QE',
          validations: ['REQUIRED', 'MAXLENGTH(10)', 'MINLENGTH(3)'],
        },
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
  ],
};
