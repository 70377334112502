export default {
  id: 'companySearch',
  showOnTemplates: 'showOnCountries',
  showOnCountries: ['UK'],
  showBackButton: false,
  fields: [
    {
      componentType: 'TITLE',
      text: 'Search for the company name or number',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'COMPANIESHOUSESEARCH',
      name: 'CompaniesHouse',
      label: 'Company search',
      type: 'text',
      icon: 'SEARCH',
      placeholder: 'Name/number of your company',
      validations: ['REQUIRED'],
    },
  ],
};
