const maxLength = (value: undefined | string, label: undefined | string, arg = '0') => {
  let useIcelandicMsg = false;

  if (arg.includes(" ")) {
    useIcelandicMsg = arg.split(" ")[1] === "IS" ? true : false;
    arg = arg.split(" ")[0];
  }

  return (
    value && value.length > Number(arg)
      ? useIcelandicMsg
        ? `${label || 'Þessi dálkur'} má ekki vera lengri en ${arg} stafir`
        : `${label || 'This field'} cannot be longer than ${arg} characters`
      : undefined
  )
}

export { maxLength };
