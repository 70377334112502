import { css } from 'styled-components';

export const screenWidthBackgroundPainter = (
  oddColor: string,
  evenColor: string,
) =>
  css`
    :nth-child(odd)::before {
      background-color: ${oddColor};
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100vw;
      right: 0;
      border-left: 100vw solid;
      border-left: 100vw solid ${oddColor};
      box-shadow: 100vw 0 0 ${oddColor};
    }
    :nth-child(even)::before {
      background-color: ${evenColor};
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100vw;
      right: 0;
      border-left: 100vw solid;
      border-left: 100vw solid ${evenColor};
      box-shadow: 100vw 0 0 ${evenColor};
    }
  `;

export const styleUtils = {
  screenWidthBackgroundPainter,
};
