import { ConditionalInterface } from 'src/types/field';
import ObjectPath from 'object-path';
import {
  ConditionalAugmentProps,
  referenceHasCorrectValue,
} from '../augmentorUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const conditionArrayValue = (values: any, conditional: ConditionalInterface, field: any, hide: any) => {
  const types = ObjectPath.get(values, conditional.conditionName);
  return types && types.includes(conditional.conditionArrayValue)
    ? { ...field, hide: !hide }
    : { ...field, hide };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, consistent-return
const conditionValue = (values: any, conditional: ConditionalInterface, field: any, hide: any) => {
  const valuesArray = ObjectPath.get(values, conditional.conditionName);
  if (
    Array.isArray(conditional.conditionValue) &&
    Array.isArray(conditional.conditionName)
  ) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, value] of conditional.conditionName.entries()) {
      if (
        conditional.conditionValue[index] !== '' &&
        ObjectPath.get(values, value) !== conditional.conditionValue[index]
      ) {
        return { ...field, hide };
      }
    }
  } else if (Array.isArray(conditional.conditionValue && valuesArray)) {
    return valuesArray
      .map((x: { value: string }) => x.value)
      .filter((value: string) =>
        ((conditional.conditionValue as unknown) as string[]).includes(value),
      ).length > 0
      ? { ...field, hide: !hide }
      : { ...field, hide };
  } else {
    return ObjectPath.get(values, conditional.conditionName) ===
      conditional.conditionValue
      ? { ...field, hide: !hide }
      : { ...field, hide };
  }
}

const conditionalShow = (
  conditionalAugment: ConditionalAugmentProps,
  conditional: ConditionalInterface,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
) => {
  const { field } = conditionalAugment;
  // Check if the reference value is the same as desired value
  const correctValue = referenceHasCorrectValue(
    conditionalAugment,
    conditional,
  );
  const hide = field.hide === undefined || field.hide;

  if (conditional && conditional.conditionArrayValue) {
    return conditionArrayValue(values, conditional, field, hide);
  }

  if (conditional && conditional.conditionValue) {
    conditionValue(values, conditional, field, hide)
  }

  if (conditional && conditional.conditionBiggerThan) {
    return ObjectPath.get(values, conditional.conditionName) >
      conditional.conditionBiggerThan
      ? { ...field, hide: !hide }
      : { ...field, hide };
  }

  if (conditional && conditional.conditionSmallerThan) {
    return ObjectPath.get(values, conditional.conditionName) <
      conditional.conditionSmallerThan
      ? { ...field, hide: !hide }
      : { ...field, hide };
  }
  // If the field has not been declared visible, it's expected to be hidden
  if (!correctValue) {
    // Keeps the initial visible/hidden if the value is incorrect
    return { ...field, hide };
  }

  // console.log('after correct value');
  // Swaps visible/hidden if the value is correct
  return { ...field, hide: !hide };
};

export { conditionalShow };
