import React from 'react';
import * as Sentry from '@sentry/nextjs';
import styled, { css } from 'styled-components';
import { Icon, Title, Button } from '@local';
import Router from 'next/router';

const SErrorScreenWrapper = styled.div`
  ${({ theme: { grid, color, breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    height: 100vh;

    p {
      color: ${color.blue};
      max-width: 618px;
      flex-grow: 1;
      margin: ${grid.vertical * 4}px 0;
      font-size: 24px;
      line-height: 1.33333em;
    }

    svg {
      margin-top: ${grid.horizontal * 6}px;
      margin-bottom: ${grid.horizontal}px;
      height: ${grid.horizontal * 5}px;
      width: ${grid.horizontal * 6}px;
    }

    button {
      margin-bottom: ${grid.vertical * 8}px;
    }

    @media (min-width: ${breakpoints.tabletMin}) {
      svg {
        height: ${grid.horizontal * 7.5}px;
        width: 170px;
        margin-top: ${grid.horizontal * 9.5}px;
        margin-bottom: ${grid.horizontal * 4.5}px;
      }
    }
  `}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ErrorBoundary extends React.Component<any, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any) {
    // log the error to Sentry
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error(error)
  }

  getTitleText() {
    if (typeof window !== "undefined") {
      if (window.location.href.includes(".is") || window.location.href.includes("umsokn")) {
        return "Villa kom upp";
      }
    }
    return "An error has occured";
  }

  getParagraphText() {
    if (typeof window !== "undefined") {
      if (window.location.href.includes(".is") || window.location.href.includes("umsokn")) {
        return "Það eru einhverjar villur í umsókninni þinni, vinsamlegast farðu vel yfir umsóknina og reyndu aftur.";
      }
    }
    return "There are some incorrect or missing inputs in your application, please go carefully over the application and try again.";
  }

  getButtonText() {
    if (typeof window !== "undefined") {
      if (window.location.href.includes(".is") || window.location.href.includes("umsokn")) {
        return "Til baka í umsókn";
      }
    }
    return "Return to application";
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="row">
          <div className="col-sm-5 col-md-5 col-lg-7 offset-md-1 offset-lg-4">
            <SErrorScreenWrapper>
              <Icon icon="VALITORICONBOX" />
                <Title className="size-md-large">
                  {this.getTitleText()}
                </Title>
                <p>
                  {this.getParagraphText()}
                </p>
                <div className="row">
                  <div className="col-lsm-5 col-md-3 col-lg-4">
                    <Button id='return-button' onClick={() => Router.back()}>{this.getButtonText()}</Button>
                  </div>
                </div>
            </SErrorScreenWrapper>
          </div>
        </div>
      )
    }
    return children;
  }
}

export { ErrorBoundary };