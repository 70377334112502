/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { BuyTerminal } from '@local';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import ObjectPath from 'object-path';
import styled, { css } from 'styled-components';

interface BuyTerminalListProps
  extends FieldInputProps<string>,
    FieldMetaState<HTMLInputElement> {
  values: any;
  terminalsName: string;
}

const calculatePrice = (list: any, deliveryPrice: number) => {
  let listTotal = list
    .map(({ value, price }: any) => {
      if (typeof price === 'undefined') {
        return 0;
      }
      return value * price;
    })
    .reduce((a: any, b: any) => a + b, 0);

  if (!Number.isNaN(deliveryPrice) && deliveryPrice > 0) {
    listTotal = Number(listTotal) + Number(deliveryPrice);
  }

  return Number.isNaN(listTotal) ? 0 : listTotal;
};

const SWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${grid.vertical * 4}px;
  `}
`;

const STotal = styled.div`
  ${({ theme: { grid } }) => css`
    position: absolute;
    right: ${grid.horizontal * 1}px;
    margin-top: 20px;
    font-size: 22px;
  `}
`;

const BuyTerminalList = ({
  onChange,
  input,
  meta,
  values,
  terminalsName,
}: BuyTerminalListProps) => {
  const terminalList = ObjectPath.get(values, terminalsName);
  const deliveryPrice = ObjectPath.get(values, 'TerminalDeliveryPrice');
  const [total, setTotal] = useState(0);
  const filteredList = terminalList
    ? terminalList.terminals.filter((terminal: any) => {
        return terminal.value > 0;
      })
    : null;

  useEffect(() => {
    if (filteredList) {
      setTotal(calculatePrice(filteredList, deliveryPrice));
    } else if (!Number.isNaN(deliveryPrice) && deliveryPrice > 0) {
      setTotal(deliveryPrice);
    } else {
      setTotal(0);
    }
  }, [deliveryPrice, filteredList, total]);

  return (
    <>
      <SWrapper>
        {filteredList !== null
          ? filteredList.map(({ id, maxValue, minValue, buyName }: any, index: number) => (
              <BuyTerminal
                {...input}
                {...meta}
                onChange={onChange}
                counterName={`${terminalsName}.${id.match(/\d+/)[0] - 1}`}
                name={buyName}
                labelText="Per terminal"
                icon="POSTERMINALICON"
                inputName={`${terminalsName}.terminals.${id.match(/\d+/)[0] -
                  1}.price`}
                validations={[
                  `MAXVALUE(${maxValue})`,
                  `MINVALUE(${minValue})`,
                  `REQUIRED`,
                  `NUMBER`,
                ]}
                key={index}
              />
            ))
          : null}
        <BuyTerminal
          {...input}
          {...meta}
          name="Delivery & Configuration"
          icon="DELIVERYICON"
          labelText="Enter price"
          hideCounter
          className="no-counter"
          inputName="TerminalDeliveryPrice"
          validations={['REQUIRED', 'NUMBER']}
          defaultValue="0"
        />
      </SWrapper>
      <STotal>
        Total: <b>{total}</b>
      </STotal>
    </>
  );
};

export { BuyTerminalList };
