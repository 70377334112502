import React, { FunctionComponent } from 'react';

import { SErrorLabel } from './index.styled';

interface ErrorLabelProps {
  className?: string;
}

const ErrorLabel: FunctionComponent<ErrorLabelProps> = ({
  className,
  children,
}) => (
  <SErrorLabel className={`error-label ${className || ''}`}>
    {children}
  </SErrorLabel>
);

export { ErrorLabel };
