import React from 'react';

export const IdentityIconBig = () => (
  <svg width="144" height="144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M121.5 22.5V36h-18V22.5h-63V36h-18V22.5h-18V135h135V22.5h-18z"
      fill="#fff"
    />
    <path
      d="M76.5 105.525c9-2.025 9-5.175 9-7.425v-3.375A20.926 20.926 0 0 1 78.975 79.2V69.075a15.75 15.75 0 0 1 31.275 0V79.2a20.939 20.939 0 0 1-6.75 15.525V98.1c0 2.025 0 5.4 9.225 7.425C121.95 107.55 121.5 117 121.5 117h-54a11.475 11.475 0 0 1 9-11.475z"
      fill="#F9D010"
    />
    <path d="M22.5 9h18v27h-18V9zm81 0h18v27h-18V9z" fill="#4F9EA6" />
    <path
      opacity=".15"
      d="M112.5 36v6.75H96.75V22.5h6.75V36h9zm-81 0v9H18v83.25h121.5V135H4.5V22.5h18V36h9z"
      fill="#1B5074"
    />
    <path
      d="M76.5 105.525c9-2.025 9-5.175 9-7.425v-3.375A20.926 20.926 0 0 1 78.975 79.2V69.075a15.75 15.75 0 0 1 31.275 0V79.2a20.939 20.939 0 0 1-6.75 15.525V98.1c0 2.025 0 5.4 9.225 7.425C121.95 107.55 121.5 117 121.5 117h-54a11.475 11.475 0 0 1 9-11.475z"
      stroke="#2E4369"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.5 22.5h63m-81 0h-18V135h135V22.5h-117z"
      stroke="#2E4369"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.5 56.25h-36m22.5 18H22.5m31.5 18H22.5M22.5 9h18v27h-18V9zm81 0h18v27h-18V9z"
      stroke="#2E4369"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IdentityIconBig;
