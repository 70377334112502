export default {
  id: 'directorsOverview',
  showOnTemplates: 'showOnCountries',
  showOnCountries: ['UK'],
  fields: [
    {
      componentType: 'COMPANIESHOUSEDIRECTORS',
    },
    {
      componentType: 'DYNAMICACCORDION',
      titleFieldName: 'DirectorsPersons.*.FirstName',
      addButtonText: 'Add person',
      addButtonIcon: 'INCREMENT',
      removeButtonText: 'Remove person',
      removeButtonIcon: 'DECREMENT',
      listName: 'DirectorsPersons',
      initializeList: false,
      maxListLength: 2,
      components: [
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.FirstName',
          label: 'First name',
          type: 'text',
          icon: 'USER',
          placeholder: 'Enter your first name',
          validations: ['REQUIRED', 'MAXLENGTH(50)'],
          reviewPageTitle: 'First Name',
          className: 'medium-margin-top',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.MiddleName',
          label: 'Middle name (optional)',
          type: 'text',
          icon: 'USER',
          placeholder: 'Enter your middle name',
          validations: ['MAXLENGTH(50)'],
          reviewPageTitle: 'Middle Name',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.LastName',
          label: 'Last name',
          type: 'text',
          icon: 'USER',
          placeholder: 'Enter your last name',
          validations: ['REQUIRED', 'MAXLENGTH(50)'],
          reviewPageTitle: 'Last Name',
        },
        {
          componentType: 'TITLE',
          text: 'When is the person’s birthday?',
          className: 'size-md-medium small-margin-bottom',
        },
        {
          componentType: 'DATEINPUT',
          name: 'DirectorsPersons.*.Birth',
          personsBirthday: true,
        },
        {
          componentType: 'TITLE',
          text: 'What’s the person’s home address?',
          className: 'size-md-medium',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.Street',
          label: 'Address Line 1',
          type: 'text',
          icon: 'HOUSE',
          placeholder: 'Enter the your address',
          validations: ['REQUIRED', 'MAXLENGTH(50)'],
          reviewPageTitle: 'Address Line 1',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.StreetContinued',
          label: 'Address Line 2 (optional)',
          type: 'text',
          icon: 'HOUSE',
          placeholder: 'Enter the your address',
          reviewPageTitle: 'Address Line 2',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.City',
          label: 'Town/City',
          type: 'text',
          icon: 'TOWNBUILDING',
          placeholder: 'E.g. London',
          validations: ['REQUIRED', 'MAXLENGTH(100)'],
          reviewPageTitle: 'Town/City',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.Postcode',
          label: 'Postcode',
          type: 'text',
          icon: 'POSTBOXICON',
          placeholder: 'E.g. SW1Y 4QE',
          validations: ['REQUIRED', 'MAXLENGTH(10)'],
          reviewPageTitle: 'Postcode',
        },
        {
          componentType: 'TITLE',
          text: 'How can we contact the person?',
          className: 'size-md-medium',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.Email',
          label: 'Email',
          type: 'email',
          icon: 'MAIL',
          placeholder: 'Enter your email',
          validations: ['REQUIRED', 'EMAIL', 'MAXLENGTH(60)'],
          reviewPageTitle: 'Email',
        },
        {
          componentType: 'PHONENUMBER',
          name: 'DirectorsPersons.*.Phone',
          label: 'Phone number',
          placeholder: 'E.g 7911 123456',
          validations: ['REQUIRED', 'PHONENUMBER', 'MAXLENGTH(15)'],
          reviewPageTitle: 'Phone number',
        },
        {
          componentType: 'TITLE',
          text: 'What’s the person’s shares?',
          className: 'size-md-medium',
        },
        {
          componentType: 'SHARESINPUT',
          name: 'DirectorsPersons.*.Shares',
        },
        {
          componentType: 'FADEANIMATION',
          name: 'DirectorsPersons.*.Identification',
          component: {
            componentType: 'MEDIAUPLOAD',
            label: 'Media_Upload',
            fileTypesAccepted: 'image/*,application/pdf',
            validations: ['REQUIRED(You must upload a photo of person’s ID)'],
          },
          conditional: {
            type: ['SHOW'],
            conditionName: 'DirectorsPersons.*.Shares',
            conditionBiggerThan: 25,
          },
        },
        {
          componentType: 'FADEANIMATION',
          name: 'DirectorsPersons.*.Identification',
          component: {
            componentType: 'INFO',
            className: 'extra-margin-bottom',
            text:
              'When a person has over 25% shares we need a photo of ID.',
          },
          conditional: {
            type: ['SHOW'],
            conditionName: 'DirectorsPersons.*.Shares',
            conditionBiggerThan: 25,
          },
        },
      ],
    },
  ],
};
