import styled, { css } from 'styled-components';

export interface SFeesInputProps {
  icon: string;
  active?: boolean;
  disabled?: boolean;
  error?: boolean;
  touched?: boolean;
}

export const SWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: ${grid.horizontal * 3}px;
  `}
`;

export const SInput = styled.input<SFeesInputProps>`
  ${({ theme: { color, grid, input }, active, icon, error, touched }) => css`
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    width: 100%;
    color: ${color.darkGray};
    caret-color: ${color.teal};
    height: ${grid.horizontal * 3}px;
    position: relative;
    border: 1px solid ${color.grayThree};
    border-radius: 4px;
    outline: none;
    font-size: 20px;
    &:disabled {
      border-color: ${color.grayFour};
      background-color: ${color.grayFour};
      color: ${color.darkGray};
    }

    ${active &&
      css`
        color: ${color.darkGray};
        border-color: ${color.teal};
      `}
    ${touched &&
      css`
        color: ${color.darkGray};
        border-color: ${color.teal};
      `}
    ${error &&
      touched &&
      !active &&
      css`
        color: ${color.red};
        border-color: ${color.red};
      `}
    ${icon === input.icon.pound &&
      css`
        text-align: left;
        padding-left: ${grid.vertical * 6}px;
      `}
    ${icon === input.icon.percentage &&
      css`
        text-align: right;
        padding-right: ${grid.vertical * 6}px;
      `}
  `}
`;

export const SIcon = styled.div<SFeesInputProps>`
  ${({
    theme: { color, input, grid },
    disabled,
    active,
    icon,
    error,
    touched,
  }) => css`
    position: absolute;
    width: ${grid.vertical * 5}px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${color.darkGray};
    &:after {
      content: '%';
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2em;
      color: ${color.white};
    }

    ${active &&
      css`
        background-color: ${color.teal};
      `}
    ${touched &&
      css`
        background-color: ${color.teal};
      `}
    ${error &&
      touched &&
      !active &&
      css`
        background-color: ${color.red};
      `}
    ${disabled &&
      css`
        color: ${color.darkGray};
        background-color: ${color.grayTwo};
      `}

    ${icon === input.icon.pound &&
      css`
        left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        &:after {
          content: '£';
        }
      `}

    ${icon === input.icon.percentage &&
      css`
        right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &:after {
          content: '%';
        }
      `}
  `}
`;
