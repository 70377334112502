export default {
  title: 'Has your business accepted payment cards in the past?',
  id: 'paymentCards',
  conditionalNextSteps: [
    {
      id: 'PreviouslyAcceptedCards',
      value: 'no',
      skipToStep: 'storeType',
    },
  ],
  fields: [
    {
      componentType: 'RADIOBUTTONS',
      validations: ['REQUIRED'],
      radioValues: [
        {
          labelText: 'Yes',
          name: 'PreviouslyAcceptedCards',
          value: 'yes',
        },
        {
          labelText: 'No',
          name: 'PreviouslyAcceptedCards',
          value: 'no',
          checked: true,
        },
      ],
    },
  ],
};
