import React from 'react';
import { SLabel } from './index.styled';

interface LabelProps {
  name: string;
  disabled?: boolean;
  label?: string;
  className?: string;
}

const Label = ({ name, label, disabled, className }: LabelProps) => (
  <SLabel htmlFor={name} {...{ name, disabled }} className={className}>
    {label}
  </SLabel>
);

export { Label };
