export default {
  id: 'cardsAndHours',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Which cards would your business like to accept?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'CHECKBOX',
      label: 'Card type',
      validations: ['CHECKBOX'],
      checkboxValues: [
        {
          labelText: 'Visa',
          name: 'TypeOfCards',
          value: 'VISA',
        },
        {
          labelText: 'Mastercard',
          name: 'TypeOfCards',
          value: 'MasterCard',
        },
        {
          labelText: 'Amex',
          name: 'TypeOfCards',
          value: 'Amex',
        },
      ],
    },
  ],
};
