import { ConditionalInterface } from 'src/types/field';
import {
  ConditionalAugmentProps,
  referenceHasCorrectValue,
} from '../augmentorUtils';

const conditionalValidate = (
  { field, values, contextMap }: ConditionalAugmentProps,
  conditional: ConditionalInterface,
) => {
  // Check if the reference value is the same as desired value
  const correctValue = referenceHasCorrectValue(
    { field, values, contextMap },
    conditional,
  );

  // If you need your field to rerender with a new validation function, you will
  // need to update another prop on the Field, such as key.
  // See the following sandbox for an example:"
  // https://final-form.org/docs/react-final-form/types/FieldProps

  // Set validations if correct conditions are met
  if (correctValue) {
    return { ...field, validations: conditional.conditionalValidate };
  }

  return { ...field, validations: undefined };
};

export { conditionalValidate };
