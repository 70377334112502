import React from 'react';
import { Icon, Counter, Input } from '@local';
import { validate } from '@utils';
import { Field } from 'react-final-form';
// eslint-disable-next-line import/namespace
import { SetValueProps } from '../../Counter';
import {
  SContent,
  SCounter,
  SDiv,
  SImage,
  SInput,
  SParagraph,
} from './index.styled';

interface BuyTerminalProps {
  name: string;
  icon: string;
  counterName: string;
  inputName: string;
  onChange: (value: SetValueProps) => void;
  labelText: string;
  validations: string[];
  hideCounter?: boolean;
  className?: string;
  defaultValue?: string;
}

const BuyTerminal = ({
  onChange,
  name,
  icon,
  counterName,
  inputName,
  labelText,
  validations,
  hideCounter,
  className,
  defaultValue,
}: BuyTerminalProps) => (
  <SDiv>
    <SImage>
      <Icon icon={icon} />
    </SImage>
    <SContent>
      <SParagraph className={className}>{name}</SParagraph>
      {hideCounter ?? (
        <SCounter>
          <Counter
            name={counterName}
            title="TerminalCounter"
            onChange={onChange}
            className="medium-width no-margins"
          />
        </SCounter>
      )}
    </SContent>
    <SInput>
      <Field
        name={inputName}
        validate={validate('Price', validations)}
        initialValue={defaultValue}>
        {({ input, meta }) => (
          <>
            <Input
              {...input}
              {...meta}
              label={labelText}
              name={inputName}
              icon={window.innerWidth > 600 ? 'POUND' : ''}
            />
            {meta.error && meta.touched && !meta.active && meta.error}
          </>
        )}
      </Field>
    </SInput>
  </SDiv>
);

export { BuyTerminal };
export type { BuyTerminalProps };
