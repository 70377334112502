/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';

import { CompaniesHouseConsumer } from '@local';
import { AnyObject } from 'final-form';
import { ReviewListItem } from '../../ReviewListItem';

const ReviewCompanyDetails = (values: AnyObject) => {
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);
  const {
    company_name,
    company_number,
    company_status_text,
    date_of_creation,
    type_text,
    registered_office_address,
    sic_codes,
    sic_codes_text,
  } = companiesHouseValues;
  const {
    address_line_1,
    address_line_2,
    locality,
    country,
    postal_code,
  } = registered_office_address;

  const structuredAddress = `
  ${address_line_1 ? `${address_line_1}, ` : ''}
  ${address_line_2 ? `${address_line_2}, ` : ''}
  ${locality ? `${locality}, ` : ''}
  ${country ? `${country}, ` : ''}
  ${postal_code ? `${postal_code}` : ''} `;

  // match sic codes to sic codes text
  const sicCodes = sic_codes.map(
    (code, index) => `${code} - ${sic_codes_text[index]}`,
  );
  const dateOfCreationToDate = new Date(date_of_creation);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dateOfCreation = `${dateOfCreationToDate.getDate()} ${
    months[dateOfCreationToDate.getMonth()]
  } ${dateOfCreationToDate.getFullYear()}`;

  const getInfo = (valueKey: string) => {
    const info = values[valueKey];
    return info;
  };

  return (
    <dl>
      <ReviewListItem title="Company Name">{company_name}</ReviewListItem>
      <ReviewListItem title="Company Number">{company_number} </ReviewListItem>
      <ReviewListItem title="Company Status">
        {company_status_text}
      </ReviewListItem>
      <ReviewListItem title="Company Type">{type_text}</ReviewListItem>
      <ReviewListItem title="Nature Of Business (SIC)">
        {sicCodes.map(sicCode => (
          <div key={sicCode}>{sicCode}</div>
        ))}
      </ReviewListItem>
      <ReviewListItem title="Incorporated On">{dateOfCreation}</ReviewListItem>
      <ReviewListItem title="Office Address">
        {structuredAddress}
      </ReviewListItem>
      <ReviewListItem title="Email">{getInfo('EmailStores')}</ReviewListItem>
      <ReviewListItem title="Phone number">{getInfo('Phone')}</ReviewListItem>
    </dl>
  );
};

export { ReviewCompanyDetails };
