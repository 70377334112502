import React from 'react';

export const PostboxIcon = () => (
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M1.24675 18.6H2.90909V10.3333H0L1.24675 18.6Z" fill="#555555" />
    <path
      d="M26.8052 7.23333H11.2208V4.13333H16.4156V0H9.14284V7.23333H4.987V20.6667H16.4156V29.9667C16.4156 30.2407 16.525 30.5036 16.7199 30.6973C16.9147 30.8911 17.179 31 17.4545 31C17.7301 31 17.9944 30.8911 18.1892 30.6973C18.384 30.5036 18.4935 30.2407 18.4935 29.9667V20.6667H32V12.4C32 11.0297 31.4527 9.71555 30.4785 8.74661C29.5043 7.77768 28.1829 7.23333 26.8052 7.23333Z"
      fill="#555555"
    />
  </svg>
);

export default PostboxIcon;
