import styled, { css } from 'styled-components';

interface SCompanyHouseResultsProps {
  companyInactive?: boolean;
  error?: boolean;
  invalid?: boolean;
}

export const SWrapper = styled.div<SCompanyHouseResultsProps>`
  ${({ theme: { color, grid }, invalid, error, companyInactive }) => css`
    padding: ${grid.horizontal * 2}px;
    border: 1px solid ${color.lightGrayTwo};
    border-left: 8px solid ${color.green};
    margin-top: ${grid.horizontal * 3}px;
    border-radius: 4px;
    font-size: 16px;
    dt {
      color: ${color.darkGray};
    }
    dd {
      margin-bottom: ${grid.horizontal * 1.5}px;
    }
    ${companyInactive &&
      css`
        border-left: ${grid.vertical} solid ${color.red};
      `}
    ${invalid &&
      css`
        border-left: ${grid.vertical} solid ${color.red};
        font-weight: bold;
        font-size: 20px;
        color: ${color.darkGray};
      `}
    ${error &&
      css`
        border-left: ${grid.vertical} solid ${color.red};
      `}
  `}
`;

export const SSomething = styled.div``;
