const email = (value: string | undefined, label: string | undefined, arg: string | undefined) => {
  // email validation for string containing @ and dot
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(String(value).toLowerCase().trim());
  return valid
    ? undefined
    : arg ? `${arg}` : 'Incorrect email format';
};

export { email };
