import React from 'react';

export const RapydLogoIcon = () => (
  <svg width="235" height="75" viewBox="0 0 234 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M222.101 0.36426C222.043 0.576792 221.517 3.70406 220.932 7.28675C220.347 10.8694 219.792 14.1182 219.704 14.4521C219.558 15.1201 219.441 15.1201 212.658 15.2415C205.817 15.363 205.7 15.363 203.157 16.3042C195.556 19.0975 189.738 25.6253 187.282 34.1569C186.288 37.6789 186.142 43.3869 186.99 46.3016C188.744 52.374 193.129 56.8068 198.508 57.9606C199.502 58.1731 204.443 58.2946 212.103 58.2946H224.148L228.942 29.8152C231.544 14.1789 233.766 1.06258 233.854 0.667878L234 -8.15392e-05H228.124C223.651 -8.15392e-05 222.189 0.0910038 222.101 0.36426ZM217.57 27.0523C217.57 27.2648 216.839 31.6976 215.962 36.8895C215.085 42.1117 214.354 46.4838 214.354 46.5749C214.354 46.9089 205.379 46.7571 203.829 46.4231C199.824 45.5426 197.69 41.6259 198.538 36.677C199.356 31.7887 202.864 27.9631 207.337 27.0523C209.384 26.6272 217.57 26.6576 217.57 27.0523Z" fill="black"/>
    <path d="M9.55997 1.88235C9.47226 2.27705 7.36732 14.7254 4.88231 29.6027C2.3973 44.4799 0.263118 56.989 0.175412 57.4444L0 58.2946H6.28561H12.5712L12.7466 57.4444C12.8343 56.989 14.5008 47.1821 16.4303 35.675C18.3598 24.1375 19.997 14.4218 20.0847 14.0271L20.2601 13.3287L27.3351 13.4198C33.4453 13.5109 34.527 13.5716 35.3748 14.0574C38.6784 15.9399 38.9415 21.7693 35.8133 25.2306C33.4745 27.8113 32.6559 28.0542 26.1364 28.1757C22.0142 28.2667 20.5232 28.3882 20.6109 28.6311C20.8448 29.2383 30.4633 53.7706 31.3988 56.0781L32.2759 58.2946H38.9708C42.6544 58.2946 45.6657 58.2338 45.6657 58.1427C45.6657 58.082 43.9408 53.6188 41.8358 48.2144C39.7309 42.81 37.9183 38.1951 37.8598 37.9218C37.7721 37.5878 38.3276 37.0717 39.7309 36.1912C46.3673 32.0923 50.6357 24.8662 50.6942 17.6098C50.7819 9.77641 46.3966 3.6737 39.3508 1.82163C37.2459 1.24475 36.1057 1.21439 23.4175 1.21439H9.70615L9.55997 1.88235Z" fill="black"/>
    <path d="M69.0541 16.8811C66.4813 17.4276 62.3007 19.644 59.9034 21.7086C55.9274 25.1091 52.5068 30.7261 51.1912 35.9786C50.4603 38.8934 50.3142 44.2371 50.8989 46.7571C51.9806 51.4328 55.1088 55.7442 58.7924 57.657C62.0376 59.3572 63.2654 59.509 76.3921 59.509H88.2325L91.7992 38.1647C93.7872 26.445 95.3659 16.7596 95.3659 16.6078C95.3659 16.4864 89.8696 16.3953 83.1747 16.4257C73.9071 16.4257 70.4866 16.5471 69.0541 16.8811ZM81.6253 28.2971C81.6253 28.5096 80.8944 33.0032 80.0173 38.2558C79.1403 43.5084 78.4094 47.8805 78.4094 47.9412C78.4094 48.0019 76.0705 48.0019 73.2347 47.9412C68.2062 47.8197 67.9723 47.7894 66.3352 46.9393C63.2362 45.3908 61.9206 42.3546 62.5346 38.2558C63.2947 33.2157 66.569 29.3598 71.0128 28.2667C72.8254 27.8113 81.6253 27.872 81.6253 28.2971Z" fill="black"/>
    <path d="M103.405 16.7596C103.347 16.9722 101.184 29.8456 98.5816 45.3908C95.9797 60.936 93.787 73.8702 93.7286 74.1738C93.5824 74.6596 93.9332 74.6899 99.5172 74.6292L105.452 74.5381L106.241 69.832C106.68 67.2513 107.265 63.8508 107.528 62.3023L107.995 59.509H114.047C121.941 59.509 124.718 58.8714 129.191 56.1389C131.676 54.6208 135.886 50.0969 137.552 47.1518C144.803 34.4909 140.856 19.644 129.542 16.8507C127.028 16.2435 103.61 16.1524 103.405 16.7596ZM125.127 28.6615C126.735 29.3901 128.519 31.4851 128.986 33.2461C129.951 36.7681 128.577 41.717 125.917 44.4799C123.344 47.1821 120.654 47.9716 114.135 47.9716H109.925L110.071 47.1214C110.159 46.666 110.919 42.1421 111.767 37.102L113.316 27.872L118.579 27.9935C122.847 28.0846 124.104 28.2364 125.127 28.6615Z" fill="black"/>
    <path d="M146.235 17.9134C146.235 18.0956 148.223 26.688 150.621 37.011C153.047 47.334 155.006 56.0174 155.006 56.2907C155.006 57.0801 153.457 59.2662 151.936 60.6628C150.387 62.0898 148.603 62.7881 146.031 63.0614C144.364 63.2132 144.218 63.3043 144.072 64.033C143.663 66.0065 142.435 73.6576 142.435 74.1434C142.435 74.5989 142.698 74.6899 144.014 74.6899C146.323 74.6899 150.445 73.8702 152.638 72.9593C157.754 70.8644 162.081 66.6744 165.794 60.2681C167.197 57.8695 187.457 18.1259 187.457 17.7919C187.457 17.7009 184.651 17.6098 181.259 17.6098H175.032L169.361 29.9063C166.232 36.677 163.63 42.1421 163.572 42.0814C163.484 41.9903 159.099 18.1866 159.099 17.7919C159.099 17.7009 156.205 17.6098 152.667 17.6098C148.428 17.6098 146.235 17.7312 146.235 17.9134Z" fill="black"/>
</svg>

);

export default RapydLogoIcon;
