export default {
  id: 'confirmId',
  nextButtonText: 'Upload',
  previousButtonText: 'Use another image',
  showOnTemplates: 'showOnCountries',
  showOnCountries: ['UK'],
  fields: [
    {
      id: 'Identification',
      placeholder:
        'Please note that you will have to provide a valid ID to finish the application at a later time.',
      componentType: 'UPLOADEDIMAGE',
    },
  ],
};
