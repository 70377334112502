import React from 'react';

export const DeliveryIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 87 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.875 61.0526H3.625C1.624 61.0526 0 59.2292 0 56.9825V48.8421H54.375V12.2105H71.2929C75.342 12.2105 77.0929 14.5549 78.3109 16.7162C80.4641 20.5422 83.8789 26.6149 85.956 30.4653C86.6375 31.7352 87 33.1923 87 34.6779V52.9123C87 57.4749 84.361 61.0526 79.75 61.0526H76.125C76.125 67.7928 71.253 73.2632 65.25 73.2632C59.247 73.2632 54.375 67.7928 54.375 61.0526H32.625C32.625 67.7928 27.753 73.2632 21.75 73.2632C15.747 73.2632 10.875 67.7928 10.875 61.0526ZM21.75 56.1684C24.1497 56.1684 26.1 58.3582 26.1 61.0526C26.1 63.7471 24.1497 65.9369 21.75 65.9369C19.3503 65.9369 17.4 63.7471 17.4 61.0526C17.4 58.3582 19.3503 56.1684 21.75 56.1684ZM65.25 56.1684C67.6497 56.1684 69.6 58.3582 69.6 61.0526C69.6 63.7471 67.6497 65.9369 65.25 65.9369C62.8503 65.9369 60.9 63.7471 60.9 61.0526C60.9 58.3582 62.8503 56.1684 65.25 56.1684ZM51.6562 45.7895H25.8281H0V4.07018C0 1.82344 1.624 0 3.625 0H47.5781C49.5791 0 51.6562 1.82344 51.6562 4.07018V24.4211V45.7895ZM61.625 20.3509V32.5614H78.7169L73.544 22.4022C72.8988 21.1324 71.6989 20.3509 70.3975 20.3509H61.625Z"
      fill="#1B5074"
    />
    <path
      d="M2.71875 4.05273C2.71875 3.50045 3.16647 3.05273 3.71875 3.05273H47.9375C48.4898 3.05273 48.9375 3.50045 48.9375 4.05273V21.4775V42.7369H25.8281H2.71875V4.05273Z"
      fill="#4F9EA6"
    />
    <ellipse cx="21.75" cy="61.0525" rx="5.4375" ry="6.10526" fill="#F9D010" />
    <ellipse cx="65.25" cy="61.0525" rx="5.4375" ry="6.10526" fill="#F9D010" />
    <path
      d="M59.8125 18.3151L70.6875 18.3151C72.457 18.1369 74.6991 20.3812 76.125 22.8941L81.5625 33.5783H59.8125V18.3151Z"
      fill="#F9D010"
    />
  </svg>
);

export default DeliveryIcon;
