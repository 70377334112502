export default {
  id: 'posar',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Hér getur þú valið þér greiðsluposa',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'TERMINALLIST',
      name: 'terminalList',
      validations: ['POSLIST(Þú þarft að velja að minnsta kosti einn posa'],
      terminals: [
        {
          kicker: 'Pax A920 4G',
          maxValue: 699,
          minValue: 492,
          title: 'GPRS & Wi-Fi',
          image: {
            name: 'pax-countertop',
            alt: 'Image of POS device',
          },
          index: 2,
          defaultValue: 1,
          list: [
            {
              text: 'Android operating system',
            },
            {
              text: 'Charging base (if needed)',
            },
            {
              text: 'Touch screen',
            },
            {
              text: 'Contactless',
            },
            {
              text: 'Global romaing SIM card',
            },
            {
              text: 'Wi-Fi Capability',
            },
          ],
        },
        {
          kicker: 'Pax A80',
          maxValue: 400,
          minValue: 280,
          title: 'Ethernet Countertop',
          image: {
            name: 'pax-terminal',
            alt: 'Image of POS device',
          },
          index: 0,
          list: [
            {
              text: 'Android operating system',
            },
            {
              text: 'Market leading technology',
            },
            {
              text: 'Contactless',
            },
            {
              text: 'Ethernet only',
            },
            {
              text: 'Mains powered',
            },
          ],
        },
        {
          kicker: 'Pax A920',
          maxValue: 500,
          minValue: 330,
          title: 'Wi-Fi',
          image: {
            name: 'pax-countertop',
            alt: 'Image of POS device',
          },
          index: 1,
          list: [
            {
              text: 'Android operating system',
            },
            {
              text: 'Charging base (if needed)',
            },
            {
              text: 'Touch screen',
            },
            {
              text: 'Contactless',
            },
          ],
        },
        {
          kicker: 'Ingenico Move3500',
          maxValue: 600,
          minValue: 410,
          title: 'Bluetooth, Wi-Fi',
          image: {
            name: 'bluetoothWifi',
            alt: 'Image of POS device',
          },
          index: 3,
          list: [
            {
              text: 'Telium Tetra Operating System',
            },
            {
              text: "Supports multiple MID's, separate Amex MID etc",
            },
            {
              text: 'Portable handheld device with a battery',
            },
            {
              text: 'Ethernet connection via base',
            },
            {
              text: 'Contactless',
            },
          ],
        },
        {
          kicker: 'Ingenico Move3500N',
          title: 'GPRS',
          maxValue: 600,
          minValue: 400,
          image: {
            name: 'bluetoothWifi',
            alt: 'Image of POS device',
          },
          index: 4,
          list: [
            {
              text: 'Telium Tetra Operating System',
            },
            {
              text: "Supports multiple MID's, separate Amex MID etc",
            },
            {
              text: 'Portable handheld device with a battery',
            },
            {
              text: 'Contactless',
            },
          ],
        },
        {
          kicker: 'Ingenico Move5000',
          title: 'GPRS/Bluetooth (Ethernet Only) and Wi-Fi',
          maxValue: 800,
          minValue: 540,
          image: {
            name: 'move5000-terminal',
            alt: 'Image of POS device',
          },
          index: 5,
          list: [
            {
              text: 'Telium Tetra Operating System',
            },
            {
              text: "Supports multiple MID's, separate Amex MID etc",
            },
            {
              text: 'Portable handheld device with a battery',
            },
            {
              text: 'Ethernet connection via base',
            },
            {
              text: 'Contactless',
            },
            {
              text: 'Touch screen',
            },
          ],
        },
      ],
    },
  ],
};
