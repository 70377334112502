import React from 'react';

export const StoreIconColor = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#StoreIconColor)">
      <path
        opacity=".1"
        d="M24.3874 11.25c0 .9945-.3974 1.9483-1.1047 2.6516C22.5753 14.6049 21.616 15 20.6157 15c-1.0003 0-1.9597-.3951-2.667-1.0984-.7073-.7033-1.1047-1.6571-1.1047-2.6516 0 .9945-.3974 1.9483-1.1047 2.6516C15.032 14.6049 14.0727 15 13.0723 15c-1.0003 0-1.9596-.3951-2.6669-1.0984-.70736-.7033-1.10473-1.6571-1.10473-2.6516 0 .9945-.39737 1.9483-1.1047 2.6516C7.48864 14.6049 6.5293 15 5.52899 15l-.75433-.1V29H28.9134V14.95h-.7544c-.9916 0-1.9434-.3882-2.6493-1.0807-.7059-.6924-1.1091-1.6335-1.1223-2.6193z"
        fill="#1B5074"
      />
      <path
        d="M27.2036 3H6.48449l-4.72717 8.25c0 .9946.39738 1.9484 1.1047 2.6517C3.56935 14.6049 4.52869 15 5.529 15s1.95965-.3951 2.66698-1.0983c.70732-.7033 1.10469-1.6571 1.10469-2.6517 0 .9946.39738 1.9484 1.10473 2.6517.7073.7032 1.6666 1.0983 2.667 1.0983 1.0003 0 1.9596-.3951 2.6669-1.0983.7074-.7033 1.1047-1.6571 1.1047-2.6517 0 .9946.3974 1.9484 1.1047 2.6517.7073.7032 1.6667 1.0983 2.667 1.0983 1.0003 0 1.9597-.3951 2.667-1.0983.7073-.7033 1.1047-1.6571 1.1047-2.6517 0 .9946.3973 1.9484 1.1047 2.6517.7073.7032 1.6666 1.0983 2.667 1.0983 1.0003 0 1.9596-.3951 2.6669-1.0983.7074-.7033 1.1047-1.6571 1.1047-2.6517L27.2036 3z"
        fill="#4F9EA6"
      />
      <path
        d="M18.8554 18h6.0347v11h-6.0347V18zM8.79761 18h7.04049v6H8.79761v-6z"
        fill="#F9D010"
      />
      <path
        opacity=".15"
        d="M1.90819 11l-.15087.25c0 .9946.39738 1.9484 1.1047 2.6517C3.56935 14.605 4.52869 15 5.529 15s1.95965-.395 2.66698-1.0983c.70732-.7033 1.10469-1.6571 1.10469-2.6517 0 .9946.39738 1.9484 1.10473 2.6517C11.1127 14.605 12.072 15 13.0724 15c1.0003 0 1.9596-.395 2.6669-1.0983.7074-.7033 1.1047-1.6571 1.1047-2.6517 0 .9946.3974 1.9484 1.1047 2.6517S19.6154 15 20.6157 15c1.0003 0 1.9597-.395 2.667-1.0983.7073-.7033 1.1047-1.6571 1.1047-2.6517 0 .9946.3973 1.9484 1.1047 2.6517.7073.7033 1.6666 1.0983 2.667 1.0983 1.0003 0 1.9596-.395 2.6669-1.0983.7074-.7033 1.1047-1.6571 1.1047-2.6517l-.1508-.25H1.90819z"
        fill="#000"
      />
      <path
        opacity=".15"
        d="M28.159 15c-1.0003 0-1.9596-.3951-2.6669-1.0984-.7074-.7033-1.1047-1.6571-1.1047-2.6516 0 .9945-.3974 1.9483-1.1047 2.6516C22.5753 14.6049 21.616 15 20.6157 15c-1.0003 0-1.9597-.3951-2.667-1.0984-.7073-.7033-1.1047-1.6571-1.1047-2.6516 0 .9945-.3974 1.9483-1.1047 2.6516C15.032 14.6049 14.0727 15 13.0723 15c-1.0003 0-1.9596-.3951-2.6669-1.0984-.70736-.7033-1.10473-1.6571-1.10473-2.6516 0 .9945-.39737 1.9483-1.1047 2.6516C7.48864 14.6049 6.5293 15 5.52899 15l-.75433-.1v2.6H28.9134v-2.55l-.7544.05z"
        fill="#000"
      />
      <path
        d="M18.8555 18h6.0346v11h-6.0346V18z"
        stroke="#1B5074"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9134 15v14H4.77466V15"
        stroke="#1B5074"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3874 11.25L21.8729 3M8.79778 18h7.04042v6H8.79778v-6zM27.2036 3H6.48449l-4.72717 8.25c0 .9946.39738 1.9484 1.1047 2.6517C3.56935 14.6049 4.52869 15 5.529 15s1.95965-.3951 2.66698-1.0983c.70732-.7033 1.10469-1.6571 1.10469-2.6517 0 .9946.39738 1.9484 1.10473 2.6517.7073.7032 1.6666 1.0983 2.667 1.0983 1.0003 0 1.9596-.3951 2.6669-1.0983.7074-.7033 1.1047-1.6571 1.1047-2.6517 0 .9946.3974 1.9484 1.1047 2.6517.7073.7032 1.6667 1.0983 2.667 1.0983 1.0003 0 1.9597-.3951 2.667-1.0983.7073-.7033 1.1047-1.6571 1.1047-2.6517 0 .9946.3973 1.9484 1.1047 2.6517.7073.7032 1.6666 1.0983 2.667 1.0983 1.0003 0 1.9596-.3951 2.6669-1.0983.7074-.7033 1.1047-1.6571 1.1047-2.6517L27.2036 3zM1.75732 11H31.9307 1.75732zM16.844 3v8.25V3zm-7.54333 8.25L11.8151 3l-2.51443 8.25z"
        stroke="#2E4369"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="StoreIconColor">
        <path
          fill="#fff"
          transform="translate(.751465)"
          d="M0 0h32.185v32H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default StoreIconColor;
