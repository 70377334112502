import { ConditionalInterface } from 'src/types/field';

import {
  ConditionalAugmentProps,
  referenceHasCorrectValue,
} from '../augmentorUtils';

const conditionalTemplate = (
  conditionalAugment: ConditionalAugmentProps,
  conditional: ConditionalInterface,
) => {
  const { field, agent } = conditionalAugment;

  // Check if the reference value is the same as desired value
  const correctValue = referenceHasCorrectValue(
    conditionalAugment,
    conditional,
  );

  // If the field has not been declared visible, it's expected to be hidden

  const hide = field.hide === undefined || field.hide;

  // If agent in url matches allowed agents in template then we do not hide component.

  if (
    !correctValue &&
    agent &&
    conditional &&
    conditional.conditionName.indexOf(agent) > -1
  ) {
    return { ...field, hide: !hide };
  }

  return { ...field, hide };

  // Swaps visible/hidden if the value is correct
};

export { conditionalTemplate };
