export default {
  id: 'companyManagementInfo',
  showOnTemplates: 'showOnCountries',
  showOnCountries: ['DK'],
  information:
    'You are only required to add company to management information if it contains shares in your legal entity. If no company contains shares in your legal entity continue to next step',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Please fill out company management information',
      className: 'size-md-small no-margin-top',
    },
    {
      componentType: 'DYNAMICACCORDION',
      titleFieldName: 'ShareCompanies.*.Name',
      addButtonText: 'Add company',
      addButtonIcon: 'INCREMENT',
      removeButtonText: 'Remove company',
      removeButtonIcon: 'DECREMENT',
      listName: 'ShareCompanies',
      startOpen: true,
      isCompanyInfo: true,
      initializeList: true,
      components: [
        {
          componentType: 'INPUT',
          name: 'ShareCompanies.*.Name',
          label: 'Company name',
          type: 'text',
          placeholder: 'Enter company name',
          validations: ['MAXLENGTH(50)'],
          className: 'medium-margin-top',
        },
        {
          componentType: 'INPUT',
          name: 'ShareCompanies.*.Number',
          label: 'VAT Number (registration number)',
          type: 'text',
          placeholder: 'Enter company number',
          validations: ['MAXLENGTH(50)'],
        },
        {
          componentType: 'INPUT',
          name: 'ShareCompanies.*.Shares',
          label: 'Shares in %',
          type: 'NUMBER',
          placeholder: 'Insert a number',
          validations: ['MAXLENGTH(50)'],
        },
        {
          componentType: 'INPUT',
          name: 'ShareCompanies.*.Country',
          label: 'Country',
          type: 'text',
          icon: 'search',
          placeholder: 'E.g. Iceland',
          validations: ['MAXLENGTH(50)'],
        },
      ],
    },
  ],
};
