import React from 'react';
import { createGlobalStyle } from 'styled-components';

// 100
// 200
// 300
// 400 normal ->  Regular
// 500
// 600 -> Semi bold
// 700 -> bold
// 800
// 900

const SFontStyles = createGlobalStyle`
    @font-face {
      font-family: 'Open Sans';
      font-weight: 300;
      src: url('/static/fonts/OpenSans-Light.ttf');
    }
    @font-face {
      font-family: 'Open Sans';
      font-weight: 400;
      src: url('/static/fonts/OpenSans-Regular.ttf');
    }

    @font-face {
      font-family: 'Open Sans';
      font-weight: 600;
      src: url('/static/fonts/OpenSans-SemiBold.ttf');
    }

    @font-face {
      font-family: 'Open Sans';
      font-weight: bold;
      src: url('/static/fonts/OpenSans-Bold.ttf');
    }

    body {
      font-family:'Open Sans' !important;
      font: 18px/23px 'Open Sans';
      line-height: 1.4375em;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      font-variant-ligatures: no-common-ligatures;
      -moz-osx-font-smoothing: grayscale;
      -webkit-overflow-scrolling: touch;

    }
`;

const FontStyles = () => <SFontStyles />;

export { FontStyles };
