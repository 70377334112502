import React, { FunctionComponent, SyntheticEvent } from 'react';

import { SButton } from './index.styled';

interface ButtonProps {
  id: string;
  onClick?: (ev: SyntheticEvent) => void;
  disabled?: boolean;
  tag?: 'secondary';
  type?: 'button' | 'submit' | 'reset';
  tabIndex?: number;
}

const Button: FunctionComponent<ButtonProps> = ({
  disabled,
  tag,
  children,
  onClick,
  type,
  tabIndex,
  id,
}) => (
  <SButton {...{ disabled, tag, onClick, type, tabIndex, id }} data-testid={id}>
    {children}
  </SButton>
);

export { Button };
