import React from 'react';

export const MailIcon = () => (
  <svg
    width="32"
    height="22"
    viewBox="0 0 32 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1.44v18.3467l9.17333-9.3334L0 1.44zm22.8267 9.0133L32 19.7867V1.44l-9.1733 9.0133zM30.4533 0H1.60001L16 14.1867 30.4533 0z"
      fill="#555"
    />
    <path
      d="M16 17.1734l-5.3333-5.2267L1.44 21.3334h29.12l-9.2267-9.3867L16 17.1734z"
      fill="#555"
    />
  </svg>
);

export default MailIcon;
