import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { LoadingIndicatorWrapper, SkFadingCircle } from './index.styled';

interface LoadingIndicatorProps {
  visible?: boolean;
  width?: string;
  height?: string;
  align?: string;
  marginTop?: string;
}

const LoadingIndicator = ({
  visible,
  width,
  height,
  align,
  marginTop,
}: LoadingIndicatorProps) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <>
      {promiseInProgress && (
        <LoadingIndicatorWrapper visible={visible}>
          <SkFadingCircle
            align={align}
            width={width}
            height={height}
            marginTop={marginTop}>
            <div className="sk-circle1 sk-circle" />
            <div className="sk-circle2 sk-circle" />
            <div className="sk-circle3 sk-circle" />
            <div className="sk-circle4 sk-circle" />
            <div className="sk-circle5 sk-circle" />
            <div className="sk-circle6 sk-circle" />
            <div className="sk-circle7 sk-circle" />
            <div className="sk-circle8 sk-circle" />
            <div className="sk-circle9 sk-circle" />
            <div className="sk-circle10 sk-circle" />
            <div className="sk-circle11 sk-circle" />
            <div className="sk-circle12 sk-circle" />
          </SkFadingCircle>
        </LoadingIndicatorWrapper>
      )}
    </>
  );
};

export { LoadingIndicator };
