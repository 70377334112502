export interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

function timeout<T>(promise: Promise<T>) {
  return new Promise<T>((resolve, reject) => {
    setTimeout(() => reject(new Error('timeout')), 120000);
    promise.then(resolve, reject);
  });
}

async function http<T>(request: Request): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await timeout<Response>(fetch(request));

  response.parsedBody = await response.json();

  return response;
}

export function postFormData<T>(
  body: FormData,
  api: string,
  correlationId: string,
  args: RequestInit = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-CorrelationId': correlationId,
    },
    body,
  },
): Promise<HttpResponse<T>> {
  return http<T>(new Request(api, args));
}

export function post<T>(
  body: object,
  api: string,
  correlationId: string,
  args: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CorrelationId': correlationId,
    },
    body: JSON.stringify(body),
  },
): Promise<HttpResponse<T>> {
  return http<T>(new Request(api, args));
}
