import styled, { css } from 'styled-components';

interface UploadProgressProps {
  visible?: boolean;
}

export const UploadProgressWrapper = styled.div<UploadProgressProps>`
  ${({ theme: { color, grid }, visible }) => css`
    padding-left: ${grid.vertical * 3}px;
    padding-right: ${grid.vertical * 3}px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    position: relative;
    border: 1px solid ${color.grayThree};
    border-radius: 4px;
    color: ${color.darkGray};
    font-size: 20px;
    background: inherit;
    ${!visible &&
      css`
        opacity: 0;
      `}
  `}
`;

export const SUploadProgressHeader = styled.div`
  font-weight: 600;
  margin-bottom: 2px;
`;

export const SUploadProgress = styled.div`
  font-weight: 100;
`;
