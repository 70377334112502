import styled, { css } from 'styled-components';

const SHeader = styled.header`
  ${({ theme: { color, breakpoints, grid } }) => css`
    position: fixed;
    z-index: 99;
    height: ${grid.horizontal * 5}px;

    background-color: ${color.white};
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    padding-left: ${grid.vertical * 5}px;
    padding-right: ${grid.vertical * 5}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: -${grid.vertical * 2}px;
    width: 100vw !important;

    svg {
      height: 35px;
      width: 116px;
    }
    h1 {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.35714em;
      text-align: right;
      color: ${color.blue};
      margin: 0;
    }
    @media (min-width: ${breakpoints.tabletMin}) {
      height: ${grid.horizontal * 5}px;
      margin-left: -${grid.vertical}px;
    }
    @media (min-width: ${breakpoints.desktopMin}) {
      height: ${grid.horizontal * 4}px;
    }
  `}
`;

export { SHeader };
