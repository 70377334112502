import styled from 'styled-components';



const SStoryWrapper = styled.div`
  height: 100%;
  overflow: visible;
  padding-top: 40px;
`;


export { SStoryWrapper };
