export default {
  id: 'uploadAcquirerStatement',
  nextButtonText: 'Upload',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Upload Acquirer Statement',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'MEDIAUPLOAD',
      id: 'AcquirerStatement',
      fileTypesAccepted: 'image/*,application/pdf',
      label: 'AcquirerStatement',
      text: 'This is a copy of an acquirer statement for one month.',
    },
  ],
};
