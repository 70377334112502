import React from 'react';
import { SLineWrapper }  from './index.styled';

interface LineSeparatorInterface {
  className?: string;
}

const LineSeparator = ({ className }: LineSeparatorInterface) => (
  <SLineWrapper className={className} />
);

export { LineSeparator };
export type { LineSeparatorInterface };
