export default {
  id: 'businessInfo',
  showOnTemplates: 'showOnCountries',
  showOnCountries: ['UK'],
  fields: [
    {
      componentType: 'TITLE',
      text: 'What is the contact information for the business?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'EmailStores',
      label: 'Email',
      type: 'email',
      icon: 'MAIL',
      placeholder: 'Business email',
      validations: ['REQUIRED', 'EMAIL', 'MAXLENGTH(60)'],
      reviewPageTitle: 'Store Email',
    },
    {
      componentType: 'PHONENUMBER',
      name: 'Phone',
      placeholder: 'E.g 7911 123456',
      validations: ['REQUIRED', 'PHONENUMBER'],
      reviewPageTitle: 'Store Phone number',
    },
  ],
};
