import { removeWhiteSpace } from "@utils";
import { CompanyData } from "src/components/Context/CompaniesHouseConsumer";
import { FeeLine } from "src/components/Context/FeesConsumer";
import { FormValuesTypes, StoreType, TargetMarket } from "../../../types/formValues";
import { Fees, PersonAddress, PersonDateOfBirth } from "../types";
import {
  getDate,
  getDateOfBirthObject,
  getDateOfBirthObjectFromKennitala,
  getPersonofInterestInfoBy,
  getPostCode,
  isAddressEqual,
  isCompaniesHouseBirthdayEqualToPersonBirthDay,
  formatIcelandicPhoneNumber,
} from "./utils";
import ObjectPath from 'object-path';

export const getCompaniesHouseCompanyInfo = (companiesHouseValues: CompanyData, values: FormValuesTypes, targetMarket: TargetMarket) => {
  return {
    Name: companiesHouseValues.company_name,
    Number: companiesHouseValues.company_number,
    DateOfCreation: companiesHouseValues.date_of_creation,
    RegisteredAddress: {
      Street: companiesHouseValues.registered_office_address.address_line_1.substring(
        0,
        50,
      ),
      StreetContinued:
        companiesHouseValues.registered_office_address.address_line_2,
      City: companiesHouseValues.registered_office_address.locality,
      Postcode: companiesHouseValues.registered_office_address.postal_code,
      CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
    },
    TradingAddress: {
      Street: values.Stores[0].TradingStreet,
      StreetContinued: values.Stores[0].TradingStreetContinued,
      City: values.Stores[0].TradingStreetCity,
      Postcode: values.Stores[0].TradingStreetPostcode,
      CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
    },
  }
}

export const getCompanyWithDirectorInfo = (values: FormValuesTypes, targetMarket: TargetMarket) => {
  return {
    Name: values.Company.Name,
    Number: values.Company.Number,
    DateOfCreation: `${values.Company.BusinessStartYear}/${values.Company.BusinessStartMonth}/${values.Company.BusinessStartDay}`,
    RegisteredAddress: {
      Street: values.Company.Street,
      StreetContinued: values.Company.StreetContinued,
      City: values.Company.City,
      Postcode: values.Company.Postcode,
      CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
    },
    TradingAddress: {
      Street: values.Stores[0].TradingStreet,
      StreetContinued: values.Stores[0].TradingStreetContinued,
      City: values.Stores[0].TradingStreetCity,
      Postcode: values.Stores[0].TradingStreetPostcode,
      CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
    },
  };
}

export const getCompanyInfo = (values: FormValuesTypes, targetMarket: TargetMarket) => {
  return {
    Name: `${values.FirstName}${(values.MiddleName &&
      ` ${values.MiddleName}`) ||
      ''} ${values.LastName}`,
    TradingAddress: isAddressEqual(
      {
        Street: values.Stores[0].TradingStreet,
        StreetContinued: values.Stores[0].TradingStreetContinued,
        City: values.Stores[0].TradingStreetCity,
        Postcode: values.Stores[0].TradingStreetPostcode,
      },
      {
        Street: values.Street,
        StreetContinued: values.StreetContinued,
        City: values.City,
        Postcode: values.Postcode,
      },
    )
      ? {}
      :
      {
        Street: values.Stores[0].TradingStreet,
        StreetContinued: values.Stores[0].TradingStreetContinued,
        City: values.Stores[0].TradingStreetCity,
        Postcode: values.Stores[0].TradingStreetPostcode,
        CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
      },
    RegisteredAddress: {
      Street: values.Street,
      StreetContinued: values.StreetContinued,
      City: values.City,
      Postcode: values.Postcode,
      CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
    },
  };
}

export const getManagementDirectorsInfo = (values: FormValuesTypes, targetMarket: TargetMarket) => {
  return values.Directors.map((person: any) => { // eslint-disable-line
    return {
      FirstName: person.FirstName,
      MiddleName: person.MiddleName,
      LastName: person.LastName,
      Position: person.Roles[0].label,
      Email: person.Email,
      SharePercentage: 100,
      PhoneNumber: {
        Prefix: person.PhonePrefix,
        Number: person.PhoneNumber,
      },
      Title: '',
      DateOfBirth: `${person.BirthStartYear}/${person.BirthStartMonth}/${person.BirthStartDay}`,
      Address: {
        Street: person.Street,
        StreetContinued: person.StreetContinued,
        City: person.City,
        Postcode: person.Postcode,
        CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
      },
    };
  })
}

export const getManagementDirectorsPersonsInfo = (values: FormValuesTypes, targetMarket: TargetMarket) => {
  return values.DirectorsPersons.map((person: any) => { // eslint-disable-line
    return {
      FirstName: person.FirstName,
      MiddleName: person.MiddleName,
      LastName: person.LastName,
      Position: 'Shareholder',
      Email: person.Email,
      SharePercentage: Number(person.Shares),
      PhoneNumber: {
        Prefix: person.PhonePrefix,
        Number: person.PhoneNumber,
      },
      Title: '',
      DateOfBirth: `${person.BirthStartYear}/${person.BirthStartMonth}/${person.BirthStartDay}`,
      Address: {
        Street: person.Street,
        StreetContinued: person.StreetContinued,
        City: person.City,
        Postcode: person.Postcode,
        CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
      },
    };
  })
}

export const getCompaniesHouseDirectorsInfo = (values: FormValuesTypes, companiesHouseValues: CompanyData, targetMarket: TargetMarket) => {
  return companiesHouseValues.persons_of_interest.map((person, index) => {
    const address = getPersonofInterestInfoBy(values, index, 'address') as PersonAddress
    const street =
      person.address.address_line_1 !== address.street
        ? address.street
        : person.address.address_line_1;
    const streetcontinue =
      person.address.address_line_2 !== address.street2
        ? address.street2
        : person.address.address_line_2;
    const city =
      person.address.locality !== address.locality
        ? address.locality
        : person.address.locality;
    const postalcode =
      person.address.postal_code !== address.postal_code
        ? address.postal_code
        : person.address.postal_code;
    const editedDateOfBirth = (values[
      `personofinterest${index}`
    ] as unknown) as PersonDateOfBirth;
    const dateofbirth = !isCompaniesHouseBirthdayEqualToPersonBirthDay(
      person.date_of_birth,
      editedDateOfBirth,
    )
      ? getDate(editedDateOfBirth.dateofbirthStartYear, editedDateOfBirth.dateofbirthStartMonth, editedDateOfBirth.dateofbirthStartDay || "1")
      : getDateOfBirthObject(person.date_of_birth.year, person.date_of_birth.month, person.date_of_birth.day || "1");

    return {
      FirstName: person.name_elements.forename,
      MiddleName: person.name_elements.middle_name,
      LastName: person.name_elements.surname,
      Title: person.name_elements.title,
      Position: person.officer_role_text,
      SharePercentage: getPersonofInterestInfoBy(values, index, 'sharepercentage'),
      DateOfBirth: dateofbirth,
      // ...personOfInterestBirthday(person.date_of_birth),
      Email: getPersonofInterestInfoBy(values, index, 'email'),
      PhoneNumber: {
        Prefix: getPersonofInterestInfoBy(values, index, 'phonenumberPrefix'),
        Number: getPersonofInterestInfoBy(values, index, 'phonenumberNumber'),
      },
      Address: {
        Street: street,
        StreetContinued: streetcontinue,
        City: city,
        Postcode: postalcode,
        CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
      },
    };
  })
}

export const getPersonsOfInterestFromValues = (values: FormValuesTypes, targetMarket: TargetMarket) => {

  let DateOfBirth;
  let PhoneNumber;

  if (targetMarket === TargetMarket.Iceland && values.Kennitala) {
    DateOfBirth = getDateOfBirthObjectFromKennitala(values.Kennitala);
    PhoneNumber = formatIcelandicPhoneNumber(values.PhonePrefix, values.PhoneNumber);
  } else {
    DateOfBirth = { ...getDateOfBirthObject(values.BirthdayInputStartYear, values.BirthdayInputStartMonth, values.BirthdayInputStartDay) };
    PhoneNumber = {
      Prefix: values.PhonePrefix,
      Number: values.PhoneNumber
    }
  }

  return [
    {
      FirstName: values.FirstName,
      MiddleName: values.MiddleName,
      LastName: values.LastName,
      SSN: values.Kennitala,
      SharePercentage: 100,
      ...DateOfBirth,
      Email: values.Email,
      PhoneNumber: PhoneNumber,
      Address: {
        Street: values.Street,
        StreetContinued: values.StreetContinued,
        City: values.City,
        Postcode: values.Postcode,
        CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
      },
    },
  ];
}

export const getStore = (store: StoreType, values: FormValuesTypes, targetMarket: TargetMarket) => {

  return {
    Name: values.StoreName || store.StoreName,
    MerchantCategoryCode: values.Mcc ? values.Mcc.value : store.Mcc ? store.Mcc.value : '0000',
    MerchantCategoryDescription: values.Mcc
      ? values.Mcc.label
      : store.Mcc ? store.Mcc.label : 'Missing Mcc code',
    Address: {
      Street: store.TradingStreet || values.Street,
      StreetContinued: store.TradingStreetContinued,
      City: store.EcommerceSolutions && values.PaymentSolutions?.includes('ecommerce') ? values.PhoneNumber : store.TradingStreetCity || values.City,
      Postcode: store.TradingStreetPostcode || values.Postcode,
      CountryCode: targetMarket === TargetMarket.Iceland && values.Kennitala ? "IS" : "GB"
    },
    AverageRevenue: values.AvgRevenue,
    AverageTransactionAmount: store.AvgTrans || values.AvgTrans,
    AverageProcessed: store.AvgProcessed || values.AvgProcessed,
    MaximumTransactionAmount: store.MaxTrans || values.MaxTrans,
    MotoTrans:
      store.MotoTrans && store.TakeMoto && store.TakeMoto.includes('Yes')
        ? store.MotoTrans
        : '0',
    ReasonForMoto:
      store.ReasonForMoto &&
        store.TakeMoto &&
        store.TakeMoto?.includes('Yes')
        ? store.ReasonForMoto
        : '',
    EcommerceSolutions: store.EcommerceSolutions &&
      store.EcommerceSolutions.EcommerceType
      && {
      ...store.EcommerceSolutions,
      EcommerceType: store.EcommerceSolutions?.EcommerceType
      },
    IsCardPresent: values.terminalList && !store.EcommerceSolutions ? true : false,
    DescriptionOfProductsAndServices: values.DescriptionOfProductsAndServices
  }
}

export const getAmexObject = (values: FormValuesTypes, companiesHouseValues: CompanyData) => {

  if (!values.TypeOfCards.includes('Amex')) {
    return {}
  }

  return {
    HasAmex: true,
    TransactionFee: ObjectPath.get(
      values,
      `FeeList.ProcessingFees.Authorizationfee`,
      0,
    ),
    ChargebackFee: ObjectPath.get(
      values,
      `FeeList.ProcessingFees.Chargebackfee`,
      0,
    ),
    DiscountRate: ObjectPath.get(
      values,
      `FeeList.OtherFees.Amexdiscountrate`,
      0,
    ),
    PaymentPlan: 'DAILY',
    BankAccount: values.RafraenSkilriki ? `${values.Bank}-${values.Ledger}-${values.AccountNumber}` : `${values.SortCode}-${values.AccountNumber}`,
    Merchant: {
      Name: companiesHouseValues
        ? values.Stores[0].StoreName
        : `${values.FirstName} ${values.LastName}`,
      Address: companiesHouseValues
        ? `${values.Stores[0].TradingStreet} ${values.Stores[0].TradingStreetContinued}`
        : `${values.Street} ${values.StreetContinued ? values.StreetContinued : ''}`,
      PostCode: companiesHouseValues
        ? values.Stores[0].TradingStreetPostcode
        : getPostCode(values),
      Email: values.EmailStores ? values.EmailStores : values.Email,
      MerchantType: values.Stores[0].Mcc && values.Stores[0].Mcc.label,
    },
    Director: {
      Name:
        companiesHouseValues &&
          companiesHouseValues.persons_of_interest[
            Number(values.ChosenCompanyDirector) || 0
          ].name
          ? companiesHouseValues &&
          companiesHouseValues.persons_of_interest[
            Number(values.ChosenCompanyDirector) || 0
          ].name
          : `${values.FirstName} ${values.LastName}`,
      Email:
        companiesHouseValues && getPersonofInterestInfoBy(values, values.ChosenCompanyDirector || 0, 'email')
          ? companiesHouseValues &&
          getPersonofInterestInfoBy(values, values.ChosenCompanyDirector || 0, 'email')
          : values.Email,
      Telephone:
        companiesHouseValues &&
          getPersonofInterestInfoBy(values, values.ChosenCompanyDirector || 0, 'phonenumber')
          ? companiesHouseValues &&
          getPersonofInterestInfoBy(values, values.ChosenCompanyDirector || 0, 'phonenumber')
          : values.PhoneNumber,
    },
  };
}

export const getTerminalList = (values: FormValuesTypes) => {
  return [
    {
      Type: values.terminalList.terminals[0].type,
      Count: values.terminalList.terminals[0].value,
      PricePerUnit: values.terminalList.terminals[0].price,
    },
    {
      Type: values.terminalList.terminals[1].type,
      Count: values.terminalList.terminals[1].value,
      PricePerUnit: values.terminalList.terminals[1].price,
    },
    {
      Type: values.terminalList.terminals[2].type,
      Count: values.terminalList.terminals[2].value,
      PricePerUnit: values.terminalList.terminals[2].price,
    },
    {
      Type: values.terminalList.terminals[3].type,
      Count: values.terminalList.terminals[3].value,
      PricePerUnit: values.terminalList.terminals[3].price,
    },
    {
      Type: values.terminalList.terminals[4].type,
      Count: values.terminalList.terminals[4].value,
      PricePerUnit: values.terminalList.terminals[4].price,
    },
    {
      Type: values.terminalList.terminals[5].type,
      Count: values.terminalList.terminals[5].value,
      PricePerUnit: values.terminalList.terminals[5].price,
    },
  ];
}

export const formatFeesList = (values: FormValuesTypes, feeLine: FeeLine[]) => {
  const fees = ({} as unknown) as Fees;
  if (feeLine) {
    Object.values(feeLine).forEach(data => {
      const titleWitheOutSpaces = removeWhiteSpace(data.title);
      if (data.fees) {
        Object.values(data.fees).forEach(fee => {
          const nameWithoutWhitespaces = removeWhiteSpace(fee.name);
          if (
            ObjectPath.has(
              values,
              `FeeList.${titleWitheOutSpaces}.${nameWithoutWhitespaces}`,
            )
          ) {
            ObjectPath.push(fees, `${titleWitheOutSpaces}`, {
              Name: fee.name,
              Value: ObjectPath.get(
                values,
                `FeeList.${titleWitheOutSpaces}.${nameWithoutWhitespaces}`,
              ),
              ValueType: fee.ValueType,
            });
          }
        });
      }
    });
  }
  return fees;
};

