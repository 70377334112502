import styled, { css } from 'styled-components';

const SWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    margin-bottom: ${grid.horizontal * 2}px;
    display: block;
  `}
`;

export { SWrapper };
