import styled, { css } from 'styled-components';

interface SCanvasContainerProps {
  horizontalScrollbarVisible: boolean;
}

export const SCanvasContainer = styled.div<SCanvasContainerProps>`
  ${({ theme: { grid, color, breakpoints }, horizontalScrollbarVisible }) => css`

    display: flex;
    width: 100%;
    height: 1000px;
    overflow: auto;
    background-color: ${color.lightGray};
    text-align: center;
    justify-content: center;
    display: flex;
    margin-bottom: ${grid.vertical * 8}px;

    .pdf-page {
      display: block;
      margin-top: 15px;
      margin-bottom: 15px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .zoom-buttons {
      display: flex;
      flex-direction: column;
    }

    ${horizontalScrollbarVisible &&
      css`
        justify-content: flex-start;
      `}

    ::-webkit-scrollbar {
      width: 20px;
      background: ${color.teal};
      border-radius: 10px;
    }

    ::-webkit-scrollbar:horizontal {
      height: 20px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${color.white};
      border-radius: 10px;
      border-left: 4px solid ${color.teal}
      border-right: 4px solid ${color.teal}
    }

    ::-webkit-scrollbar-thumb:horizontal {
      background-color: ${color.white};
      border-radius: 10px;
      border-top: 4px solid ${color.teal}
      border-bottom: 4px solid ${color.teal}
    }

    @media (max-width: ${breakpoints.tabletMax}) {
      height: ${grid.horizontal * 30}px;
      margin-bottom: ${grid.horizontal * 4.5}px;
    }
  `}
`;

export const SZoomButtons = styled.div`
  ${({ theme: { grid, breakpoints } }) => css`

    display: flex;
    justify-content: center;
    margin-bottom: ${grid.horizontal * 4.5}px;

    > Button {
      width: 20%;
    }

    > Button:first-child {
      margin-right: 20px;
    }

    > Button:nth-child(2) {
      margin-left: 20px;
    }

    @media (max-width: ${breakpoints.tabletMax}) {
      margin-bottom: ${grid.horizontal * 4.5}px;
    }
  `}
`;

export const SInfoErrorWrapper = styled.div`
  ${({ theme: { grid, breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1000px;

    @media (max-width: ${breakpoints.tabletMax}) {
      height: ${grid.horizontal * 30}px;
    }
  `}
`;