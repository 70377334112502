import React from 'react';
import { FieldInputProps, FieldMetaState, AnyObject } from 'react-final-form';
import { Icon, Label } from '@local';
import { Mask } from '../../helpers/masks';

import {
  StyleWrapper,
  SInput,
  SLeftIconWrapper,
  SRightIconWrapper,
  InputWrapper,
} from './index.styled';

interface InputProps
  extends FieldInputProps<string>,
    FieldMetaState<HTMLInputElement> {
  name: string;
  disabled?: boolean;
  icon?: string;
  label?: string;
  mask?: string;
  placeholder?: string;
  type?: string;
  message?: string;
  values?: AnyObject;
  className?: string;
  labelPosition?: "top" | "left" | "right" | "bottom";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Input = (props: any) => {
  const {
    active,
    disabled,
    icon,
    label,
    mask,
    name,
    error,
    touched,
    onChange,
    value,
    className,
    labelPosition = "top",
    type
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.type === 'NUMBER')
      e.currentTarget.value = e.currentTarget.value.replace(/\D+/g, '');

    return mask ? onChange(Mask(mask)(e)) : onChange(e);
  };

  const handlePaste = (e: React.ChangeEvent) => {
    if (props.type === 'NUMBER') e.preventDefault();
  };


  return (
    <InputWrapper labelPosition={labelPosition}>
      <Label {...{ name, label, disabled }} className={className} />
      <StyleWrapper>
        <SInput
          {...props}
          id={name}
          onChange={handleChange}
          onPaste={handlePaste}
          value={value}
          data-testid={name}
          className="extra-margin-top"
          type={type === "NUMBER" ? "text" : type}
        />
        {icon && (
          <SLeftIconWrapper>
            <Icon icon={icon} />
          </SLeftIconWrapper>
        )}
        {error && touched && !active && (
          <SRightIconWrapper>
            <Icon icon="WARNING" />
          </SRightIconWrapper>
        )}
      </StyleWrapper>
    </InputWrapper>
  );




};

export { Input };
export type { InputProps };
