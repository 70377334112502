import styled, { css } from 'styled-components';

export interface InputSideBySideProps {
  widthLeft?: string;
  widthRight?: string;
}

export const Wrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    @media (max-width: ${breakpoints.mobileMax}) {
      flex-direction: column;
    }
  `};
`;

export const SWrapper = styled.div`
         ${() => css`
           &.space {
             margin-right: 0.5em;
           }
         `};
       `;
