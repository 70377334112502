import React from 'react';

export const DocumentSavedIcon = () => (
  <svg
    width="34"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity=".1"
      d="M20.0775 8.99993V.999928H5.99658V30.9999H28.1237V8.99993h-8.0462z"
      fill="#1B5074"
    />
    <path d="M28.1239 8.99993L20.0776.999928V8.99993h8.0463z" fill="#FBFBFB" />
    <path
      opacity=".15"
      d="M17.6133.999928V12.4999h10.5104V8.99993h-8.0463V.999928h-2.4641z"
      fill="#1B5074"
    />
    <path
      d="M5.99658.999928V30.9999H28.1237V8.99993L20.0775.999928H5.99658z"
      stroke="#1B5074"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4411 14.0499l-7.0405 7-3.5202-3.5M20.0775.999928V8.99993h8.0462L20.0775.999928z"
      stroke="#1B5074"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentSavedIcon;
