import styled, { css } from 'styled-components';

interface FSieldSetButtonsProps {
  showGoBackButton: boolean;
}

const SFieldSetButtons = styled.div<FSieldSetButtonsProps>`
  ${({ theme: { grid, breakpoints }, showGoBackButton }) => css`
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin-bottom: ${grid.horizontal * 2}px;
    position: relative;
    min-height: 20vh;
    margin-top: ${grid.horizontal * 4}px;
    .error-label {
      position: absolute;
      bottom: -1vh;
      right: 0;
      margin-bottom: 0px;
    }

    button {
      width: 100%;
      order: 1;
      margin-bottom: ${grid.horizontal}px;

      &:nth-child(1) {
        float: left;
      }
      &:nth-child(2) {
        float: right;
      }
    }
    ${showGoBackButton &&
      css`
        button {
          width: 100%;
          order: 1;
          &:nth-child(1) {
            float: right;
          }
          &:nth-child(2) {
            float: left;
          }
          margin-bottom: ${grid.horizontal}px;
        }
      `}
    @media (min-width: ${breakpoints.tabletMin}) {
      display: flex;
      justify-content: space-between;
      ${showGoBackButton &&
        css`
          flex-direction: row-reverse;
        `}

      .error-label {
        bottom: 10vh;
      }

      button {
        width: calc(50% - 20px);
        float: left;
        margin-bottom: ${grid.horizontal}px;
      }
    }
  `}
`;

export { SFieldSetButtons };
