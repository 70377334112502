import React, {
  FunctionComponent,
  useState,
  createContext,
  useEffect,
} from 'react';
import { FormStructureTypes } from 'src/types/context';
import { PageStructure } from 'src/types/application';
import { useRouter } from 'next/router';
import FormsRouting from '../../public/static/content/formsRouting';

interface ApplicationProps {
  pages: PageStructure[];
  pageTitle: string;
  applicationType: string;
}

interface ApplicationInterface {
  application: ApplicationProps;
  step: number;
  pageNumber: number;
  pagesFilledIn: number;
  direction: string;
  currentStepSetup: FormStructureTypes;
  guid: string;
  uploadMessage: string;
  agent: string;
  adobeEmails: JSX.Element[];
  managementInfo: JSX.Element[];
  companyInfo: JSX.Element[];
  country: string;
  applicationErrorMessage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setApplication: (i: any) => void;
  setStep: (i: number) => void;
  setDirection: (i: string) => void;
  setPageNumber: (i: number) => void;
  setPagesFilledIn: (i: number) => void;
  // Todo figure out a way to use FormStructureTypes in setCurrentStepSetup
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCurrentStepSetup: (value: any) => void;
  setGuid: (i: string) => void;
  setUploadMessage: (message: string) => void;
  setAgent: (i: string) => void;
  setAdobeEmails: (i: JSX.Element[]) => void;
  setManagementInfo: (i: JSX.Element[]) => void;
  setCompanyInfo: (i: JSX.Element[]) => void;
  setCountry: (i: string) => void;
  setApplicationErrorMessage: (i: string) => void;
}

const ApplicationConsumer = createContext({} as ApplicationInterface);

const allowedAgents = ['physical', 'virtual', 'abs'];
const allowedCountries = ['UK', 'DK', 'IS'];

const getCorrectForm = (path: string, setup: string) => {
  if (path.includes('abs')) {
    const absSetup = path.replace(/\?.*/, '').replace(/\//g, '');
    return FormsRouting[absSetup] || FormsRouting.abssoletrader;
  }
  return FormsRouting[setup] || FormsRouting.abssoletrader;
};

interface ApplicationProviderProps {
  query?: { [key: string]: string };
  setup: string;
}
const ApplicationProvider: FunctionComponent<ApplicationProviderProps> = ({
  children,
  setup,
}) => {
  const router = useRouter();

  const selectedForm = setup
    ? getCorrectForm(router.asPath, setup)
    : FormsRouting.soletrader;
  // See if the Url matches a form that we have, otherwise fall back to soletrader application
  const [application, setApplication] = useState(selectedForm);
  const [pageNumber, setPageNumber] = useState(0);
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState('');
  const [pagesFilledIn, setPagesFilledIn] = useState(0);
  const [currentStepSetup, setCurrentStepSetup] = useState(
    selectedForm.pages[pageNumber].steps[step],
  );
  const [guid, setGuid] = useState('');
  const [uploadMessage, setUploadMessage] = useState('-');
  const [agent, setAgent] = useState('');
  const [country, setCountry] = useState('');
  const [adobeEmails, setAdobeEmails] = useState([] as JSX.Element[]);
  const [managementInfo, setManagementInfo] = useState([] as JSX.Element[]);
  const [companyInfo, setCompanyInfo] = useState([] as JSX.Element[]);
  const [applicationErrorMessage, setApplicationErrorMessage] = useState('');

  // For testing purposes we can add parameters to the url to test specific pages
  const PAGE = 'startPage';
  const STEP = 'startStep';

  const queryPage =
    router.query && router.query[PAGE] ? Number(router.query[PAGE]) : 0;
  const queryStep =
    router.query && router.query[STEP] ? Number(router.query[STEP]) : 0;

  // Set physical as default if no agent is selected or url is changed in any way

  let defaultAgent = ['physical'];
  let defaultCountry = ['UK'];

  // No agent is set the we assume user is coming from ABS site.
  if (router.query.typeofsale === undefined) {
    defaultAgent = ['abs'];
  }

  // Check if agent is in url, set agent from url if so
  if (
    router.query.typeofsale &&
    allowedAgents.filter(item => router.query.typeofsale.includes(item))
      .length > 0
  ) {
    defaultAgent = allowedAgents.filter(item =>
      router.query.typeofsale.includes(item),
    );
  }

  if (
    router.query.country &&
    allowedCountries.filter(item => router.query.country.includes(item.toLowerCase()))
      .length > 0
  ) {
    defaultCountry = allowedCountries.filter(item =>
      router.query.country.includes(item.toLowerCase()),
    );
  }

  const validQuery =
    selectedForm.pages[queryPage] &&
    selectedForm.pages[queryPage].steps[queryStep];

  useEffect(() => {
    if (validQuery) {
      setPageNumber(queryPage);
      setStep(queryStep);
      setPagesFilledIn(queryPage);
      setCurrentStepSetup(selectedForm.pages[queryPage].steps[queryStep]);
    }
  }, [validQuery, queryPage, queryStep, selectedForm.pages]);

  useEffect(() => {
    if (validQuery) {
      // If there are multiple agents select first one. We can extend this in the future.

      setAgent(defaultAgent[0]);
      setCountry(defaultCountry[0]);
    }
  }, [agent, defaultAgent, validQuery, country, defaultCountry]);

  return (
    <ApplicationConsumer.Provider
      value={{
        application,
        setApplication,
        step,
        setStep,
        pageNumber,
        direction,
        setDirection,
        setPageNumber,
        pagesFilledIn,
        setPagesFilledIn,
        guid,
        setGuid,
        currentStepSetup,
        setCurrentStepSetup,
        uploadMessage,
        setUploadMessage,
        agent,
        setAgent,
        adobeEmails,
        setAdobeEmails,
        managementInfo,
        companyInfo,
        setManagementInfo,
        setCompanyInfo,
        country,
        setCountry,
        applicationErrorMessage,
        setApplicationErrorMessage,
      }}>
      {children}
    </ApplicationConsumer.Provider>
  );
};

export { ApplicationConsumer, ApplicationProvider };
export type { ApplicationProps, ApplicationInterface}
