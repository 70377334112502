import styled, { css } from 'styled-components';

const SAnchor = styled.a`
  ${({ theme: { grid, color, breakpoints } }) => css`
    display: inline-flex;
    align-items: center;
    color: ${color.darkGray};
    margin: ${grid.vertical * 5}px 0;
    border-bottom: 1px solid transparent;
    &:hover {
      text-decoration: none;
      color: ${color.darkGray};
      border-bottom: 1px dashed ${color.orange};
    }
    svg {
      width: ${grid.horizontal}px;
      height: ${grid.horizontal}px;
      margin-right: ${grid.horizontal / 2}px;
      path {
        fill: ${color.orange};
      }
    }

    @media (max-width: ${breakpoints.desktopMin}) {
      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  `}
`;

export { SAnchor };
