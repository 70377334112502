import styled, { css } from 'styled-components';

export const SPrivacyWrapper = styled.div`
  ${({ theme: { grid, color } }) => css`
    margin-top: ${grid.horizontal * 12}px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    svg {
      height: 1rem;
      width: 1rem;
    }

    a {
      text-decoration: none;
      color: ${color.teal}
    }
  `}
`