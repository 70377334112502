import { useContext } from 'react';
import { AnyObject, FormRenderProps } from 'react-final-form';
import {FieldTypes, ConditionalInterface } from 'src/types/field';
import { CompaniesHouseConsumer } from '@local';
import { ConditionalAugmentProps } from './augmentors/augmentorUtils';
import * as augmentors from './augmentors';

interface AugmentComponentProps {
  field: FieldTypes;
  form: FormRenderProps['form'];
  values: AnyObject;
  agent?: string;
  render: (field: FieldTypes) => JSX.Element | null;
}

const augmentMap: {
  [index: string]: (
    augmentValues: ConditionalAugmentProps,
    conditional: ConditionalInterface,
    form: FormRenderProps['form'],
    values: AnyObject,
  ) => FieldTypes;
} = {
  COPY: augmentors.conditionalCopy,
  DISABLE: augmentors.conditionalDisable,
  VALIDATE: augmentors.conditionalValidate,
  SHOW: augmentors.conditionalShow,
  TEMPLATE: augmentors.conditionalTemplate,
};

const AugmentComponent = ({
  field,
  values,
  render,
  form,
  agent,
}: AugmentComponentProps) => {
  // TODO: Add Mask and other augmenting functions into here to keep basic components such as input more
  //       clean, as well as share the logic with other components should they need them, e.g. textarea
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);
  const contextMap: { [index: string]: AnyObject } = {
    VALUES: values,
    COMPANIESHOUSE: companiesHouseValues,
  };

  let augmentedField = { ...field };

  if (augmentedField.conditional) {
    const conditionals = Array.isArray(augmentedField.conditional)
      ? augmentedField.conditional
      : [augmentedField.conditional];
    // TODO: (Idea): This could also be implemented as a composed reduce function
    conditionals.forEach(conditional => {
      conditional.type.forEach(type => {
        const augmentValues = {
          field: augmentedField,
          values,
          contextMap,
          agent,
        };
        augmentedField = augmentMap[type](
          augmentValues,
          conditional,
          form,
          values,
        );
      });
    });

    return render(augmentedField);
  }

  return render(augmentedField);
};

export { AugmentComponent };
