import React from 'react';
import { ErrorLabel } from '@local';
import { validatorFactory } from '@factories';

type ValidatorFunction = (value: string, label?: string) => string | undefined;

// Takes in a list of functions and linearly runs them by sending in value.
// If any of the functions return anything else than undefined, it returns the
// string value. Otherwise it returns undefined.
const compose = (...validators: ValidatorFunction[]) => (
  value: string,
  label: string | undefined,
) =>
  validators.reduce(
    (error: string | undefined, validator) => error || validator(value, label),
    undefined,
  );

// eslint-disable-next-line react/display-name
const setError = (label: string, validationMessage: ValidatorFunction) => (
  value: string,
) =>
  validationMessage(value, label) ? (
    <ErrorLabel>{validationMessage(value, label)}</ErrorLabel>
  ) : (
    undefined
  );

// First argument is the field's label, followed by a list of functions
// eslint-disable-next-line import/prefer-default-export
export const validate = (label?: string, validations?: string[]) => {
  const result = validations
    ? setError(label || '', compose(...validatorFactory(validations)))
    : undefined;
  return result;
};
