// Gotten from https://www.skatturinn.is/fyrirtaekjaskra/thjonusta/isat-2008/ on 25/07/2022
// Count = 665

const ISATNUMBERS = [
  '01.11.0',
  '01.12.0',
  '01.13.1',
  '01.13.2',
  '01.13.9',
  '01.14.0',
  '01.15.0',
  '01.16.0',
  '01.19.1',
  '01.19.9',
  '01.21.0',
  '01.22.0',
  '01.23.0',
  '01.24.0',
  '01.25.0',
  '01.26.0',
  '01.27.0',
  '01.28.0',
  '01.29.0',
  '01.30.0',
  '01.41.0',
  '01.42.0',
  '01.43.0',
  '01.44.0',
  '01.45.0',
  '01.46.0',
  '01.47.1',
  '01.47.2',
  '01.49.1',
  '01.49.2',
  '01.49.9',
  '01.50.0',
  '01.61.0',
  '01.62.0',
  '01.63.0',
  '01.64.0',
  '01.70.0',
  '02.10.1',
  '02.10.9',
  '02.20.0',
  '02.30.0',
  '02.40.0',
  '03.11.1',
  '03.11.2',
  '03.11.3',
  '03.12.0',
  '03.21.0',
  '03.22.0',
  '05.10.0',
  '05.20.0',
  '06.10.0',
  '06.20.0',
  '07.10.0',
  '07.21.0',
  '07.29.0',
  '08.11.0',
  '08.12.0',
  '08.91.0',
  '08.92.0',
  '08.93.0',
  '08.99.0',
  '09.10.0',
  '09.90.0',
  '10.11.0',
  '10.12.0',
  '10.13.0',
  '10.20.1',
  '10.20.2',
  '10.20.3',
  '10.20.4',
  '10.20.9',
  '10.31.0',
  '10.32.0',
  '10.39.0',
  '10.41.0',
  '10.42.0',
  '10.51.0',
  '10.52.0',
  '10.61.0',
  '10.62.0',
  '10.71.0',
  '10.72.0',
  '10.73.0',
  '10.81.0',
  '10.82.0',
  '10.83.0',
  '10.84.0',
  '10.85.0',
  '10.86.0',
  '10.89.0',
  '10.91.0',
  '10.92.0',
  '11.01.0',
  '11.02.0',
  '11.03.0',
  '11.04.0',
  '11.05.0',
  '11.06.0',
  '11.07.0',
  '12.00.0',
  '13.10.0',
  '13.20.0',
  '13.30.0',
  '13.91.0',
  '13.92.0',
  '13.93.0',
  '13.94.0',
  '13.95.0',
  '13.96.0',
  '13.99.0',
  '14.11.0',
  '14.12.0',
  '14.13.0',
  '14.14.0',
  '14.19.0',
  '14.20.0',
  '14.31.0',
  '14.39.0',
  '15.11.0',
  '15.12.0',
  '15.20.0',
  '16.10.0',
  '16.21.0',
  '16.22.0',
  '16.23.0',
  '16.24.0',
  '16.29.0',
  '17.11.0',
  '17.12.0',
  '17.21.0',
  '17.22.0',
  '17.23.0',
  '17.24.0',
  '17.29.0',
  '18.11.0',
  '18.12.0',
  '18.13.0',
  '18.14.0',
  '18.20.0',
  '19.10.0',
  '19.20.0',
  '20.11.0',
  '20.12.0',
  '20.13.0',
  '20.14.0',
  '20.15.0',
  '20.16.0',
  '20.17.0',
  '20.20.0',
  '20.30.0',
  '20.41.0',
  '20.42.0',
  '20.51.0',
  '20.52.0',
  '20.53.0',
  '20.59.0',
  '20.60.0',
  '21.10.0',
  '21.20.0',
  '22.11.0',
  '22.19.0',
  '22.21.0',
  '22.22.1',
  '22.22.9',
  '22.23.0',
  '22.29.0',
  '23.11.0',
  '23.12.0',
  '23.13.0',
  '23.14.0',
  '23.19.0',
  '23.20.0',
  '23.31.0',
  '23.32.0',
  '23.41.0',
  '23.42.0',
  '23.43.0',
  '23.44.0',
  '23.49.0',
  '23.51.0',
  '23.52.0',
  '23.61.0',
  '23.62.0',
  '23.63.0',
  '23.64.0',
  '23.65.0',
  '23.69.0',
  '23.70.0',
  '23.91.0',
  '23.99.0',
  '24.10.0',
  '24.20.0',
  '24.31.0',
  '24.32.0',
  '24.33.0',
  '24.34.0',
  '24.41.0',
  '24.42.0',
  '24.43.0',
  '24.44.0',
  '24.45.0',
  '24.46.0',
  '24.51.0',
  '24.52.0',
  '24.53.0',
  '24.54.0',
  '25.11.0',
  '25.12.0',
  '25.21.0',
  '25.29.0',
  '25.30.0',
  '25.40.0',
  '25.50.0',
  '25.61.0',
  '25.62.0',
  '25.71.0',
  '25.72.0',
  '25.73.0',
  '25.91.0',
  '25.92.0',
  '25.93.0',
  '25.94.0',
  '25.99.0',
  '26.11.0',
  '26.12.0',
  '26.20.0',
  '26.30.0',
  '26.40.0',
  '26.51.0',
  '26.52.0',
  '26.60.0',
  '26.70.0',
  '26.80.0',
  '27.11.0',
  '27.12.0',
  '27.20.0',
  '27.31.0',
  '27.32.0',
  '27.33.0',
  '27.40.0',
  '27.51.0',
  '27.52.0',
  '27.90.0',
  '28.11.0',
  '28.12.0',
  '28.13.0',
  '28.14.0',
  '28.15.0',
  '28.21.0',
  '28.22.0',
  '28.23.0',
  '28.24.0',
  '28.25.0',
  '28.29.0',
  '28.30.0',
  '28.41.0',
  '28.49.0',
  '28.91.0',
  '28.92.0',
  '28.93.0',
  '28.94.0',
  '28.95.0',
  '28.96.0',
  '28.99.0',
  '29.10.0',
  '29.20.0',
  '29.31.0',
  '29.32.0',
  '30.11.0',
  '30.12.0',
  '30.20.0',
  '30.30.0',
  '30.40.0',
  '30.91.0',
  '30.92.0',
  '30.99.0',
  '31.01.0',
  '31.02.0',
  '31.03.0',
  '31.09.0',
  '32.11.0',
  '32.12.0',
  '32.13.0',
  '32.20.0',
  '32.30.0',
  '32.40.0',
  '32.50.0',
  '32.91.0',
  '32.99.0',
  '33.11.0',
  '33.12.0',
  '33.13.0',
  '33.14.0',
  '33.15.0',
  '33.16.0',
  '33.17.0',
  '33.19.0',
  '33.20.0',
  '35.11.0',
  '35.12.0',
  '35.13.0',
  '35.14.0',
  '35.21.0',
  '35.22.0',
  '35.23.0',
  '35.30.0',
  '36.00.0',
  '37.00.0',
  '38.11.0',
  '38.12.0',
  '38.21.0',
  '38.22.0',
  '38.31.0',
  '38.32.0',
  '39.00.0',
  '41.10.0',
  '41.20.0',
  '42.11.0',
  '42.12.0',
  '42.13.0',
  '42.21.0',
  '42.22.0',
  '42.91.0',
  '42.99.0',
  '43.11.0',
  '43.12.0',
  '43.13.0',
  '43.21.0',
  '43.22.0',
  '43.29.0',
  '43.31.0',
  '43.32.0',
  '43.33.0',
  '43.34.1',
  '43.34.2',
  '43.39.0',
  '43.91.0',
  '43.99.0',
  '45.11.0',
  '45.19.1',
  '45.19.9',
  '45.20.1',
  '45.20.2',
  '45.20.3',
  '45.20.4',
  '45.31.0',
  '45.32.0',
  '45.40.0',
  '46.11.0',
  '46.12.0',
  '46.13.0',
  '46.14.0',
  '46.15.0',
  '46.16.0',
  '46.17.1',
  '46.17.2',
  '46.17.9',
  '46.18.0',
  '46.19.0',
  '46.21.0',
  '46.22.0',
  '46.23.0',
  '46.24.0',
  '46.31.0',
  '46.32.0',
  '46.33.0',
  '46.34.0',
  '46.35.0',
  '46.36.0',
  '46.37.0',
  '46.38.1',
  '46.38.9',
  '46.39.0',
  '46.41.0',
  '46.42.0',
  '46.43.0',
  '46.44.1',
  '46.44.2',
  '46.45.0',
  '46.46.0',
  '46.47.0',
  '46.48.0',
  '46.49.0',
  '46.51.0',
  '46.52.0',
  '46.61.0',
  '46.62.0',
  '46.63.0',
  '46.64.0',
  '46.65.0',
  '46.66.0',
  '46.69.1',
  '46.69.9',
  '46.71.0',
  '46.72.0',
  '46.73.0',
  '46.74.0',
  '46.75.0',
  '46.76.0',
  '46.77.0',
  '46.90.0',
  '47.11.1',
  '47.11.2',
  '47.19.0',
  '47.21.0',
  '47.22.0',
  '47.23.0',
  '47.24.0',
  '47.25.0',
  '47.26.0',
  '47.29.0',
  '47.30.0',
  '47.41.0',
  '47.42.0',
  '47.43.0',
  '47.51.0',
  '47.52.1',
  '47.52.2',
  '47.53.0',
  '47.54.0',
  '47.59.1',
  '47.59.2',
  '47.59.3',
  '47.59.9',
  '47.61.0',
  '47.62.0',
  '47.63.0',
  '47.64.0',
  '47.65.0',
  '47.71.1',
  '47.71.2',
  '47.72.1',
  '47.72.2',
  '47.73.0',
  '47.74.0',
  '47.75.0',
  '47.76.1',
  '47.76.2',
  '47.77.0',
  '47.78.1',
  '47.78.2',
  '47.78.3',
  '47.78.9',
  '47.79.0',
  '47.81.0',
  '47.82.0',
  '47.89.0',
  '47.91.0',
  '47.99.0',
  '49.10.0',
  '49.20.0',
  '49.31.0',
  '49.32.0',
  '49.39.0',
  '49.41.1',
  '49.41.2',
  '49.41.9',
  '49.42.0',
  '49.50.0',
  '50.10.0',
  '50.20.0',
  '50.30.0',
  '50.40.0',
  '51.10.1',
  '51.10.2',
  '51.21.0',
  '51.22.0',
  '52.10.0',
  '52.21.0',
  '52.22.0',
  '52.23.0',
  '52.24.0',
  '52.29.0',
  '53.10.0',
  '53.20.0',
  '55.10.1',
  '55.10.2',
  '55.20.0',
  '55.30.0',
  '55.90.0',
  '56.10.0',
  '56.21.0',
  '56.29.0',
  '56.30.0',
  '58.11.0',
  '58.12.0',
  '58.13.0',
  '58.14.0',
  '58.19.0',
  '58.21.0',
  '58.29.0',
  '59.11.0',
  '59.12.0',
  '59.13.0',
  '59.14.0',
  '59.20.0',
  '60.10.0',
  '60.20.0',
  '61.10.0',
  '61.20.0',
  '61.30.0',
  '61.90.0',
  '62.01.0',
  '62.02.0',
  '62.03.0',
  '62.09.0',
  '63.11.0',
  '63.12.0',
  '63.91.0',
  '63.99.0',
  '64.11.0',
  '64.19.0',
  '64.20.0',
  '64.30.0',
  '64.91.0',
  '64.92.0',
  '64.99.0',
  '65.11.0',
  '65.12.0',
  '65.20.0',
  '65.30.0',
  '66.11.0',
  '66.12.0',
  '66.19.0',
  '66.21.0',
  '66.22.0',
  '66.29.0',
  '66.30.0',
  '68.10.0',
  '68.20.1',
  '68.20.2',
  '68.20.3',
  '68.31.0',
  '68.32.0',
  '69.10.0',
  '69.20.0',
  '70.10.0',
  '70.21.0',
  '70.22.0',
  '71.11.0',
  '71.12.1',
  '71.12.2',
  '71.20.0',
  '72.11.0',
  '72.19.0',
  '72.20.0',
  '73.11.0',
  '73.12.0',
  '73.20.0',
  '74.10.0',
  '74.20.0',
  '74.30.0',
  '74.90.0',
  '75.00.0',
  '77.11.0',
  '77.12.0',
  '77.21.0',
  '77.22.0',
  '77.29.0',
  '77.31.0',
  '77.32.0',
  '77.33.0',
  '77.34.0',
  '77.35.0',
  '77.39.0',
  '77.40.0',
  '78.10.0',
  '78.20.0',
  '78.30.0',
  '79.11.0',
  '79.12.0',
  '79.90.0',
  '80.10.0',
  '80.20.0',
  '80.30.0',
  '81.10.0',
  '81.21.0',
  '81.22.0',
  '81.29.0',
  '81.30.0',
  '82.11.0',
  '82.19.0',
  '82.20.0',
  '82.30.0',
  '82.91.0',
  '82.92.0',
  '82.99.0',
  '84.11.0',
  '84.12.0',
  '84.13.0',
  '84.21.0',
  '84.22.0',
  '84.23.0',
  '84.24.0',
  '84.25.0',
  '84.30.0',
  '85.10.0',
  '85.20.0',
  '85.31.0',
  '85.32.0',
  '85.41.0',
  '85.42.0',
  '85.51.0',
  '85.52.0',
  '85.53.0',
  '85.59.0',
  '85.60.0',
  '86.10.0',
  '86.21.0',
  '86.22.0',
  '86.23.0',
  '86.90.1',
  '86.90.2',
  '86.90.3',
  '86.90.9',
  '87.10.0',
  '87.20.0',
  '87.30.1',
  '87.30.2',
  '87.90.0',
  '88.10.0',
  '88.91.1',
  '88.91.2',
  '88.99.0',
  '90.01.0',
  '90.02.0',
  '90.03.0',
  '90.04.0',
  '91.01.0',
  '91.02.0',
  '91.03.0',
  '91.04.0',
  '92.00.0',
  '93.11.0',
  '93.12.0',
  '93.13.0',
  '93.19.0',
  '93.21.0',
  '93.29.0',
  '94.11.0',
  '94.12.0',
  '94.20.0',
  '94.91.0',
  '94.92.0',
  '94.99.1',
  '94.99.9',
  '95.11.0',
  '95.12.0',
  '95.21.0',
  '95.22.0',
  '95.23.0',
  '95.24.0',
  '95.25.0',
  '95.29.0',
  '96.01.0',
  '96.02.1',
  '96.02.2',
  '96.03.0',
  '96.04.0',
  '96.09.0',
  '97.00.0',
  '98.10.0',
  '98.20.0',
  '99.00.0',
  '99.99.9',
]

export { ISATNUMBERS };