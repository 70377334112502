import React from 'react';

export const CoinIcon = () => (
  <svg width="144" height="144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45 80.7751C22.5 80.7751 4.5 85.5001 4.5 91.8001C4.5 92.0251 4.5 92.2501 4.725 92.2501H4.5V128.025C4.5 134.325 22.725 139.5 45 139.5C67.275 139.5 85.5 134.325 85.5 128.025V92.0251C85.5 85.7251 67.275 80.7751 45 80.7751Z"
      fill="#F9D010"
    />
    <path
      d="M99 4.5C76.5 4.5 58.5 9 58.5 15.3C58.5 15.525 58.5 15.75 58.725 15.75H58.5V81.225C74.25 82.8 85.5 87.075 85.5 92.025V116.325C89.775 116.775 94.275 117 99 117C121.275 117 139.5 111.825 139.5 105.525V15.975C139.5 9.675 121.275 4.5 99 4.5Z"
      fill="#F9D010"
    />
    <path
      opacity="0.25"
      d="M4.5 92.2499V128.025C4.5 133.425 18 137.925 36 139.05V103.05C18.225 102.15 4.725 97.6499 4.5 92.2499ZM58.5 15.9749V81.2248H58.725C62.0555 81.536 65.3624 82.0621 68.625 82.7999H69.525L71.775 83.2499L74.25 83.9249H74.7L76.95 84.5998H77.85L78.975 85.0499H79.875L80.55 85.4999H81.45L81.9 85.9499L82.35 86.3999L83.025 86.8498H83.475L83.925 87.2999V90.6749H84.15V91.5749V26.5499C69.75 25.1999 58.5 20.9249 58.5 15.9749Z"
      fill="#555555"
    />
    <path
      opacity="0.25"
      d="M99 27.45C121.368 27.45 139.5 22.3125 139.5 15.975C139.5 9.63753 121.368 4.5 99 4.5C76.6325 4.5 58.5 9.63753 58.5 15.975C58.5 22.3125 76.6325 27.45 99 27.45Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M45 103.5C67.3675 103.5 85.5 98.3625 85.5 92.0251C85.5 85.6876 67.3675 80.55 45 80.55C22.6325 80.55 4.5 85.6876 4.5 92.0251C4.5 98.3625 22.6325 103.5 45 103.5Z"
      fill="white"
    />
    <path
      d="M99 27.45C121.368 27.45 139.5 22.3125 139.5 15.975C139.5 9.63753 121.368 4.5 99 4.5C76.6325 4.5 58.5 9.63753 58.5 15.975C58.5 22.3125 76.6325 27.45 99 27.45Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.5 33.525C58.5 39.825 76.725 45 99 45C121.275 45 139.5 39.825 139.5 33.525M58.5 51.525C58.5 57.825 76.725 63 99 63C121.275 63 139.5 57.825 139.5 51.525M58.5 69.525C58.5 75.825 76.725 81 99 81C121.275 81 139.5 75.825 139.5 69.525M85.5 98.325C89.775 98.775 94.275 99 99 99C121.275 99 139.5 93.825 139.5 87.525M58.5 81.225V16.875"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M139.5 15.9749V105.525C139.5 111.825 121.5 117 99 117C94.275 117 89.775 116.775 85.5 116.325"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45 103.5C67.3675 103.5 85.5 98.3625 85.5 92.0251C85.5 85.6876 67.3675 80.55 45 80.55C22.6325 80.55 4.5 85.6876 4.5 92.0251C4.5 98.3625 22.6325 103.5 45 103.5Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 110.025C4.5 116.325 22.725 121.5 45 121.5C67.275 121.5 85.5 116.325 85.5 110.025"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.5 92.0251V128.025C85.5 134.325 67.275 139.5 45 139.5C22.725 139.5 4.5 134.325 4.5 128.025V92.9251"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CoinIcon;
