import React, { useContext } from 'react';
import Head from 'next/head';

import { ApplicationConsumer } from '@local';

interface CustomHeadProps {
  useSelectedFormTitle: boolean;
}

const CustomHead = ({ useSelectedFormTitle }: CustomHeadProps) => {
  const { application, pageNumber } = useContext(ApplicationConsumer);
  return (
    <Head>
      <title>
        {useSelectedFormTitle
          ? `${application.pages[pageNumber].title} - ${application.pageTitle}`
          : 'Welcome'}{' '}
        - Rapyd application
      </title>
    </Head>
  );
};

export { CustomHead };
