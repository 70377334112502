import React from 'react';

import { STitle, SUl, SLi } from './index.styled';

interface BulletListProps {
  title: string;
  kicker: string;
  list: BulletProps[];
}
interface BulletProps {
  text: string;
}

const BulletList = ({ title, kicker, list }: BulletListProps) => (
  <>
    <p>{kicker}</p>
    <STitle>{title}</STitle>
    <SUl>
      {list.map(listItem => (
        <SLi key={listItem.text + kicker}>{listItem.text}</SLi>
      ))}
    </SUl>
  </>
);

export { BulletList };
export type { BulletProps };
