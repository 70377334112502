import React, { FunctionComponent } from 'react';

import { FormValuesTypes } from 'src/types/formValues';
import { Icon } from '@local';

import { SSplitScreen, SIconWrapper, SParagraphWrapper } from './index.styled';

interface SplitScreenProps {
  icon: string;
  paragraph: string;
  source: string;
  values?: FormValuesTypes;
  selected?: boolean;
}

const SplitScreen: FunctionComponent<SplitScreenProps> = ({
  icon,
  paragraph,
  source,
}) => (
  <SSplitScreen>
    <SIconWrapper>
      <Icon icon={icon} />
    </SIconWrapper>
    <SParagraphWrapper>
      <p>
        {paragraph}
        <span>{source}</span>
      </p>
    </SParagraphWrapper>
  </SSplitScreen>
);

export { SplitScreen };
