import React from 'react';
import { sortCode } from './sortCode';
import { kennitala } from './kennitala';
import { date } from './date';
import { isatNumber } from './isatNumber';
import { number } from './number';

interface MaskMapProps {
  [index: string]: (e: React.ChangeEvent, args?: string) => string;
}

const validatorMap: MaskMapProps = {
  SORTCODE: sortCode,
  KENNITALA: kennitala,
  DATE: date,
  ISATNUMBER: isatNumber,
  NUMBER: number,
};

const Mask = (maskName = '') =>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  validatorMap[maskName] ? validatorMap[maskName] : () => {};

export { Mask };
