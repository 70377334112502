import React, { FunctionComponent, useContext } from 'react';
import ObjectPath from 'object-path';
import { Icon, EditButtonWithHover, ApplicationConsumer } from '@local';
import { SInfo, SParagraph, SEditButtonWrapper } from '../index.styled';
import { FormValuesTypes } from 'src/types/formValues';

interface InfoWithParametersProps {
  id: string;
  pageNumberForEdit: number;
  pageStepForEdit: number;
  smallerText?: boolean;
  icon?: string;
  text?: string;
  className?: string;
  textFromValues: string[];
  values?: FormValuesTypes;
  editButtonVisible?: boolean;
  hoverText?: string;
}

// Info Component - text with an info icon next to it.
const InfoWithParameters: FunctionComponent<InfoWithParametersProps> = ({
  id,
  children,
  pageNumberForEdit,
  pageStepForEdit,
  text,
  smallerText,
  icon,
  className,
  textFromValues,
  values,
  editButtonVisible,
  hoverText,
}) => {
  const { application } = useContext(ApplicationConsumer);
  const theTextFromValues = [] as string[];

  if (values && textFromValues) {
    textFromValues.forEach(value => {
      theTextFromValues.push(ObjectPath.get(values, value))
    })
  }

  const renderedIcon = icon || 'INFO';

  return (
    <SInfo data-testid={id} className={`info-component ${className || ''}`}>
      <Icon icon={renderedIcon} />
      <SParagraph smallerText={smallerText}>
        {text || children}
        {
          theTextFromValues.map((item, index) => {
            if (index !== theTextFromValues.length - 1) {
              return <><b key={index}>{item}</b> og </>
            } else {
              return <b key={index}>{item}</b>
            }
          })
        }
      </SParagraph>
      {
        editButtonVisible &&
          <SEditButtonWrapper>
            <EditButtonWithHover pageNumber={pageNumberForEdit} pageStep={pageStepForEdit} formSetup={application} hoverText={hoverText} />
          </SEditButtonWrapper>
      }
    </SInfo>
  );
};

export { InfoWithParameters };
export type { InfoWithParametersProps };
