import { Button } from '@local';
import { FC, SyntheticEvent, useContext, useState } from 'react';
import { captureException, configureScope } from '@sentry/nextjs';
import { FormRenderProps } from 'react-final-form';
import { parseKennitala } from '../../helpers/kennitala/parse';
import { ComponentMap } from '../../helpers/componentMapper';
import { authenticate, checkLoginStatus } from '../../helpers/formFunctions/authenticate';
import { FieldTypes } from '../../types/field';
import { FormValuesTypes, EnvironmentUrl } from '../../types/formValues';
import { AuthenticationModal } from '../AuthenticationModal';
import { AuthenticationConsumer, PersonuUpplysingarProps } from '../Context/AuthenticationConsumer';
import { ErrorLabel } from '../ErrorLabel';
import { Context } from 'vm';
import { getErrorByDokobitErrorCode, TestUser } from '../../helpers/dokobit';
import { DokobitError } from 'src/types/dokobit';

interface AuthenticationProps {
  validations?: string[];
  values?: FormValuesTypes;
  form: FormRenderProps['form'];
}

const Authentication: FC<AuthenticationProps> = ({
  validations,
  values,
  form
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState("");

  const { setIsAuthenticated, setControlCode, setUserInfo } = useContext(AuthenticationConsumer)

  const getSplitName = (fullName: string): string[] => {

    // Split name string by space
    const nameArray = fullName.split(' ');

    // If person only has 2 names we set it to first and last name
    if (nameArray.length === 2) {
      return [nameArray[0], "", nameArray[1]];
    } else if (nameArray.length > 2) {

      // If person has more than 2 names, we take the subarray from 1 to (length - 1) and set it as the middle name.
      return [nameArray[0], nameArray.slice(1, nameArray.length - 1).join(' '), nameArray[nameArray.length - 1]]
    }
    return nameArray;

  }

  const createUser = (firstName: string, middleName: string, lastName: string, kennitala: string, phoneNumber: string) => {
    if (!values) {
      return;
    }

    const { setValue } = form.mutators;

    setValue('FirstName', firstName);
    setValue('MiddleName', middleName);
    setValue('LastName', lastName)
    setValue('Kennitala', kennitala);
    setValue('PhoneNumber', phoneNumber);
    setValue('isAuthenticated', true);

    setIsAuthenticated(true);

    const sessionStorageData = JSON.parse(sessionStorage.getItem('data') as string);

    // Set sessionStorage data from authentication
    sessionStorageData.isAuthenticated = true;
    sessionStorageData.PersonuUpplysingar = values.PersonuUpplysingar;

    sessionStorage.setItem('data', JSON.stringify(sessionStorageData));

    setUserInfo((prevState: PersonuUpplysingarProps) => ({
      ...prevState,
      kennitala,
      firstName,
      middleName,
      lastName,
      phoneNumber
    }))
  }

  const handleAuthClick = async (e: SyntheticEvent) => {
    // Prevent step change
    e.preventDefault();

    try {

      if (!values) {
        return;
      }

      // Only create test user in DEV and TEST
      if (typeof window !== "undefined") {
        if (
          (window.location.href.includes(EnvironmentUrl.DEV) ||
          window.location.href.includes(EnvironmentUrl.TEST) ||
          window.location.href.includes(EnvironmentUrl.UAT) ||
          process.env.NODE_ENV === "development" ||
          process.env.NODE_ENV === "test") &&
          values.RafraenSkilriki === TestUser.phoneNumber
        ) {
          createUser(TestUser.firstName, TestUser.middleName, TestUser.lastName, TestUser.kennitala, TestUser.phoneNumber);
          return;
        }
      }


      setModalIsOpen(true);

      const { data } = await authenticate(values.RafraenSkilriki)

      const { control_code, token } = data;

      setControlCode(control_code);

      const { data: loginStatusData, status: loginStatusStatus } = await checkLoginStatus(token);

      if (loginStatusStatus === 200) {
        setIsAuthenticated(true);
      }

      const { name, code } = loginStatusData;

      const [firstName, middleName, lastName] = getSplitName(name);
      // Set username in sentry
      configureScope(scope => {
        scope.setUser(null);
        scope.setUser({
          username: middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`
        })
      })

      createUser(firstName, middleName, lastName, parseKennitala(code), values.RafraenSkilriki)

    } catch (err) {
      // Log error to sentry
      captureException(err, scope => {
        scope.setContext("values", null);
        scope.setContext("values", values as Context);
        return scope;
      });

      const dokobitError = err as DokobitError
      const { error_code } = dokobitError;

      setError(`Auðkenning tókst ekki ${getErrorByDokobitErrorCode(error_code)}`)

    }
    setModalIsOpen(false);
    setControlCode("");
  }

  const inputField = {
    componentType: 'PHONENUMBER',
    name: 'RafraenSkilriki',
    label: 'Skilríki í síma',
    placeholder: 'Sláðu inn símanúmer',
    defaultCountry: 'IS',
    countries: ['IS'],
    validations,
  } as FieldTypes

  // TODO: Figure out how to disable "Auðkenna" button if phone number is not valid
  return (
    <>
      <ComponentMap field={inputField} />
      {error.length > 0 && <ErrorLabel>{error}</ErrorLabel>}
      <Button id="auth-button" type="submit" onClick={handleAuthClick}>Auðkenna</Button>
      <AuthenticationModal
        isOpen={modalIsOpen}
        headline='Auðkenningarbeiðni hefur verið send í símann þinn'
        secondText='Staðfestu aðeins innskráningu ef sama öryggistala birtist í símanum.'
      />
    </>
  );
      };

export { Authentication };