const authentication =  {
  id: 'authentication',
  nextButtonText: 'Áfram',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Innskráning með rafrænum skilríkjum',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'AUTHENTICATION',
      label: 'Skilríki í síma',
      placeholder: 'Sláðu inn símanúmer',
      validations: ['REQUIRED(Þú þarft að slá inn símanúmer)', 'PHONENUMBER(Ekki gilt símanúmer)', 'MAXLENGTH(15 IS)'],
    }
  ],
};

export { authentication }