import styled, { css } from 'styled-components';

const SWrapper = styled.label`
  ${({ theme: { color, grid } }) => css`
    font-size: 20px;
    color: ${color.darkGray};
    cursor: pointer;

    display: block;
    position: relative;

    border: 1px solid ${color.lightGrayTwo};
    border-radius: 4px;

    padding-left: ${grid.vertical * 5.5}px;
    padding-top: ${grid.horizontal * 1.5}px;;
    padding-bottom: ${grid.horizontal * 1.5}px;;

    margin-bottom: ${grid.horizontal * 1.5}px;

    input {
      opacity: 0;
    }
    .checkmark {
      cursor: pointer;
      position: absolute;
      top: ${grid.horizontal * 1.3}px;
      left: ${grid.vertical * 3}px;
      display: block;
      height: ${grid.horizontal * 2}px;
      width: ${grid.vertical * 4}px;
      background-color: white;
      border-radius: 4px;
      border: 1px solid ${color.grayTwo};
    }
    &:hover .checkmark {
      border: 1px solid ${color.teal};
    }
    &:hover {
      border: 1px solid ${color.teal};
    }
    &:focus-within {
      border: 1px dashed ${color.teal};
    }
    input:checked ~ .checkmark {
      background-color: ${color.teal};
      border: none;
      &:before {
        content: url('../../static/icons/CheckMarkIcon/Check.svg');
        top: 6px;
        left: 4px;
        display: block;
        position: absolute;
      }
    }
    .labelText {
      padding-left: ${grid.vertical * 3}px;
    }
    input:checked ~ .labelText {
      font-weight: 600;
    }
    input:disabled ~ .checkmark {
      background-color: ${color.grayFour};
    }
    &:hover input:disabled ~ .checkmark {
      border: 1px solid ${color.grayTwo};
    }
  `}
`;

export { SWrapper };
