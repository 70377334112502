const minLength = (value: undefined | string, label: undefined | string, arg: string | undefined) => {
  let useIcelandicMsg = false;

  if (typeof arg !== "undefined") {
    if (arg.includes(" ")) {
      useIcelandicMsg = arg.split(" ")[1] === "IS" ? true : false;
      arg = arg.split(" ")[0];
    }
  }

  if (value && value.length < Number(arg)) {
    return useIcelandicMsg
      ? `${label || 'Þessi dálkur'} má ekki vera styttri en ${arg} stafir`
      : `${label || 'This field'} cannot be shorter than ${arg} characters`
  } else {
    return undefined;
  }
}

export { minLength };
