import React, { useState } from 'react';

import { ErrorLabel, Icon } from '@local';

import {
  SMediaUpload,
  SIconContainer,
  SMediaUploadInput,
  Sparagraph,
} from './index.styled';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MediaUploadProps {
  id?: string;
  text?: string;
  buttonText?: string;
  fileTypesAccepted: string;
  multipleFileUpload?: boolean;
  maxFiles?: number;
}

/**
 * Handle image upload.
 *
 * I set the input parameter to any on purpose. I could use React Final Form
 * types but they are not really good either. They define the type for the onChange
 * method on input as: onChange: (event: React.ChangeEvent<T> | any) => void;
 */
const handleChange = (
  evt: React.ChangeEvent<HTMLInputElement>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any,
  setLoading: React.Dispatch<React.SetStateAction<number>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  maxFiles: number,
) => {
  const mediaInput = evt.target as HTMLInputElement;

  if (mediaInput !== null && mediaInput.files !== null) {
    setLoading(1);
    const file = mediaInput.files[0];
    if (mediaInput.files.length > maxFiles) {
      setLoading(0);
      setError(`Cannot upload more than ${maxFiles} files`);
      return;
    }
    setTimeout(() => {
      setLoading(2);
      setError('');
      const multipleFiles = mediaInput.files && mediaInput.files.length > 1;
      multipleFiles ? input.onChange(mediaInput.files) : input.onChange(file);
    }, 1000);
  }
};

// Do not

// Media upload component.
const MediaUpload = (props: MediaUploadProps) => {
  const {
    id,
    text,
    buttonText,
    fileTypesAccepted,
    multipleFileUpload,
    maxFiles,
  } = props;
  const [loading, setLoading] = useState(0);
  const [error, setError] = useState('');
  return (
    <>
      <Sparagraph>{text}</Sparagraph>
      <div className="row">
        <div className="col-md-3 col-lg-4">
          <SMediaUpload htmlFor={id}>
            <SIconContainer
              className={
                // eslint-disable-next-line no-nested-ternary
                loading === 0 ? 'init' : loading === 1 ? 'loading' : 'ready'
              }>
              <Icon icon="ATTACHMENT" />
              <Icon icon="LOADING" />
              <Icon icon="CHECKMARK" />
            </SIconContainer>
            <SMediaUploadInput
              type="file"
              name="media-upload"
              id={id}
              accept={fileTypesAccepted}
              capture
              multiple={multipleFileUpload}
              onChange={e =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                handleChange(e, props, setLoading, setError, maxFiles!)
              }
            />
            <span className="media-upload-text">
              {buttonText || 'Upload a photo'}
            </span>
          </SMediaUpload>
          <ErrorLabel>{error}</ErrorLabel>
        </div>
      </div>
    </>
  );
};

export { MediaUpload };
