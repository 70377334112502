import styled, { css } from 'styled-components';

interface InfoProps {
  smallerText?: boolean;
}

export const SInfo = styled.div`
  ${({ theme: { grid } }) => css`
    display: flex;
    justify-content: start;
    align-items: center;

    svg {
      min-width: ${grid.horizontal * 2}px;
      margin-right: ${grid.horizontal}px;
    }
    &.medium-margin-bottom {
      margin-bottom: ${grid.horizontal * 3}px;
    }
    &.extra-margin-bottom {
      margin-bottom: ${grid.horizontal * 8}px;
    }
    &.medium-margin-top {
      margin-top: ${grid.horizontal * 4}px;
    }
    &.extra-margin-top {
      margin-top: ${grid.horizontal * 8}px;
    }
    &.override-margin-top {
      margin-top: -${grid.horizontal * 2}px;
    }
  `}
`;

export const SParagraph = styled.p<InfoProps>`
  ${({ smallerText }) => css`
    font-size: 20px;
    margin-bottom: 0px;
    ${smallerText &&
      css`
        font-size: 16px;
      `}
  `}
`;

export const SEditButtonWrapper = styled.div`
  ${() => css`
    margin-left: auto;
    margin-right: 0;
  `}
`;
