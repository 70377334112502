import styled, { css } from 'styled-components';

export const SFeesListWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    margin-bottom: ${grid.horizontal}px;
  `}
`;
export const SWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    margin-top: ${grid.horizontal * 4}px;
  `}
`;

export const SLoadingWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: ${breakpoints.desktopMin}) {
          min-height: 400px;
        }
    }
  `}
`;

export const SWarningWrapper = styled.div`
  ${({ theme: { breakpoints, grid } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > :last-child {
      margin-top: ${grid.horizontal * 5}px;
      width: calc(50% - 20px);
    }
    @media (min-width: ${breakpoints.desktopMin}) {
      min-height: 400px;
    }
  `}
`;
