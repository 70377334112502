import privatelimitedcompany from './forms/ValitorUK/privatelimitedcompany';
import publiclimitedcompany from './forms/ValitorUK/publiclimitedcompany';
import soletrader from './forms/ValitorUK/soletrader';
import testing from './forms/testing';
import abssoletrader from './forms/ABS/soletrader';
import absprivatelimitedcompany from './forms/ABS/privatelimitedcompany';
import einstaklingur from './forms/ValitorIS/einstaklingur';
import fyrirtaeki from './forms/ValitorIS/fyrirtaeki';
const FormsRouting = {
  soletrader,
  privatelimitedcompany,
  publiclimitedcompany,
  testing,
  abssoletrader,
  absprivatelimitedcompany,
  einstaklingur,
  fyrirtaeki
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default FormsRouting as any;
