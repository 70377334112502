import React, { FunctionComponent, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';

import { Legend } from './Legend';
import { FieldSetButtons } from './FieldSetButtons';
import { FormValuesTypes } from 'src/types/formValues';

interface FieldSetProps {
  nextButtonText?: string;
  previousButtonText?: string;
  step: number;
  page: number;
  title: string;
  information?: string;
  onGoBack?: (ev: SyntheticEvent) => void;
  error?: boolean;
  loading?: boolean;
  showBackButton?: boolean;
  values?: FormValuesTypes;
}

const SFieldset = styled.fieldset`
  min-height: 55vh;
`;

const SInformation = styled.p`
  ${({ theme: { grid } }) => css`
    margin-bottom: ${grid.horizontal * 6}px;
  `}
`;

const FieldSet: FunctionComponent<FieldSetProps> = ({
  children,
  nextButtonText,
  previousButtonText,
  page,
  step,
  title,
  information,
  onGoBack,
  error,
  loading,
  showBackButton,
  values,
}) => {
  return (
    <div>
      <SFieldset>
        <Legend>{title}</Legend>
        {information && <SInformation>{information}</SInformation>}
        {children}
      </SFieldset>
      <FieldSetButtons
        {...{
          nextButtonText,
          previousButtonText,
          page,
          step,
          onGoBack,
          error,
          loading,
          showBackButton,
          values,
        }}
      />
    </div>
  );
};

export { FieldSet };
