import styled, { css } from 'styled-components';

export const SSplitScreen = styled.div`
  min-height: 60vh;
`;

export const SParagraphWrapper = styled.div`
  ${({ theme: { color } }) => css`
    p {
      font-size: 32px;
      line-height: 1.375em;
      text-align: center;
      color: ${color.blue};
      span {
        font-weight: bold;
      }
    }
  `}
`;

export const SIconWrapper = styled.div`
  ${({ theme: { color, grid } }) => css`
    position: relative;
    width: 100%;
    height: 270px;
    margin-bottom: ${grid.horizontal * 5}px;
    ::before {
      background-color: ${color.grayOne};
      content: '';
      height: 270px;
      width: 270px;
      min-width: 200px;
      position: absolute;
      top: calc(50% - 135px);
      left: calc(50% - 135px);
      border-radius: 50px;
      z-index: -9;
      transform: rotate(45deg);
    }
    svg {
      height: 144px;
      width: 162px;
      position: absolute;
      bottom: calc(50% - 72px);
      left: calc(50% - 72px);
    }
  `}
`;
