import styled, { css } from 'styled-components';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

export interface PhoneNumberProps
  extends FieldInputProps<string>,
    FieldMetaState<HTMLInputElement> {}

export const SWrapper = styled.div<PhoneNumberProps>`
  ${({ theme: { color, grid }, touched, active, error }) => css`
    width: 100%;
    height: ${grid.horizontal * 4}px;
    border-radius: 4px;
    border: 1px solid ${color.grayThree};
    display: flex;
    position: relative;
    margin-bottom: ${grid.horizontal * 3}px;
    svg {
      height: ${grid.horizontal * 2}px;
      width: ${grid.horizontal * 2}px;
    }
    ${!error &&
      touched &&
      css`
        border-color: ${color.teal};
        svg {
          fill: ${color.teal};
        }
      `};
    ${error &&
      touched &&
      css`
        margin-bottom: ${active
          ? grid.horizontal * 3
          : grid.horizontal * 0.5}px;
        border-color: ${color.red};
        svg {
          fill: ${color.red};
        }
      `};
    ${active &&
      css`
        border-color: ${color.teal};
        svg {
          fill: ${color.teal};
        }
      `};
    &:disabled {
      background: ${color.lightGray};
      border-color: ${color.grayTwo};
      svg {
        fill: ${color.grayTwo};
      }
    }
    :root {
      --PhoneInputCountryFlag-aspectRatio: 11.333;
    }
    .PhoneInput {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;

      @media (max-width: 767px) {
        max-width: 100%
      }
    }
    .PhoneInputCountry {
      height: 100%;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: ${grid.horizontal}px;
        height: ${grid.horizontal * 2}px;
        width: 1px;
        background-color: ${color.grayThree};
        display: block;
      }
    }
    .PhoneInputInput {
      flex: 1;
      border: 0;
      border-width: 0;
      font-size: 20px;
      caret-color: ${color.teal};
      outline: none;
      color: ${color.darkGray};
      padding-left: ${grid.vertical * 2}px;
      max-width: 100%

      ::placeholder {
        color: ${color.grayThree};
        font-weight: 100;
        line-height: 34px;
        font-size: 20px;
      }
    }
    .PhoneInputCountryIcon {
      width: ${grid.vertical * 4}px;
      height: ${grid.horizontal * 2}px;
      margin-left: ${grid.vertical * 2}px;
      margin-top: ${grid.horizontal}px;
      display: inline-block;
    }

    .PhoneInputCountry {
      position: relative;
      width: ${grid.vertical * 12}px;
    }
    .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    .PhoneInputCountrySelectArrow {
      display: inline-block;
      content: '';
      width: 10px;
      height: 10px;
      margin-left: ${grid.vertical * 2}px;
      border-style: solid;
      border-color: ${color.darkGray};
      border-top-width: 0;
      border-bottom-width: 3px;
      border-left-width: 0;
      border-right-width: 3px;
      transform: rotate(45deg);
    }
  `}
`;

export const SRightIconWrapper = styled.div`
  margin: 20px 24px;
  right: 0px;
  position: absolute;
`;
