import React from 'react';
import styled, { css } from 'styled-components';
import { FormValuesTypes } from 'src/types/formValues';
import { ReviewListItem } from '../ReviewListItem';

interface ReviewStoresProps {
  title: string;
  store: FormValuesTypes;
}

const SHeader = styled.h2`
  ${({ theme: { grid, color } }) => css`
    color: ${color.blue};
    font-size: 26px;
    font-weight: normal;
    margin-bottom: ${grid.horizontal * 2}px;
  `}
`;

// TODO: Here is a opportunity to rewrite this component. F.x. if phone number should be added to
// Review page than instead of editing the component there should be a ShowOnReviewPage flag on
// the property that dictates if it's shown. There could also be titles instead of hardcoded titles.
const ReviewStores = ({ title, store }: ReviewStoresProps) => {
  return (
    <>
      <SHeader>{title}</SHeader>
      <ReviewListItem title="Trading Name">{store.StoreName}</ReviewListItem>
      <ReviewListItem title="Merchant Category">
        {store.Mcc.label}
      </ReviewListItem>
      <ReviewListItem title="Address Line 1">
        {store.TradingStreet}
      </ReviewListItem>
      {store.TradingStreetContinued && (
        <ReviewListItem title="Address Line 2">
          {store.TradingStreetContinued}
        </ReviewListItem>
      )}
      <ReviewListItem title="Town/City">
        {store.TradingStreetCity}
      </ReviewListItem>
      <ReviewListItem title="Postcode" className="extra-margin-bottom">
        {store.TradingStreetPostcode}
      </ReviewListItem>
    </>
  );
};

export { ReviewStores };
