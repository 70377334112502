import React from 'react';

export const SearchIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#searchIcon)">
      <path
        d="M31.532 29.2707L21.0917 18.8229l-.0197.0197c1.8024-2.3677 2.638-5.3318 2.3376-8.2924-.3004-2.96051-1.7143-5.69637-3.9556-7.65385C17.2127.938878 14.3115-.0940046 11.3375.00674979 8.36341.107504 5.53872 1.33437 3.43505 3.43904 1.33139 5.54372.105875 8.369.00654535 11.3431-.092784 14.3172.941488 17.2179 2.90004 19.4583c1.95855 2.2403 4.69509 3.6529 7.65576 3.9519 2.9607.299 5.9244-.5381 8.2913-2.3416l-.0165.0165 10.4386 10.4461c.3.3001.7068.4687 1.131.4688.4243.0001.8312-.1683 1.1312-.4682.3001-.2999.4687-.7068.4688-1.131.0001-.4243-.1683-.8312-.4682-1.1312v.0011zM11.7401 20.271c-1.6871 0-3.33643-.5003-4.73926-1.4377-1.40283-.9373-2.49621-2.2696-3.14186-3.8283-.64565-1.5588-.81459-3.274-.48544-4.9287.32916-1.6548 1.14161-3.17479 2.33462-4.3678 1.19301-1.19301 2.713-2.00546 4.36774-2.33461 1.6548-.32916 3.37-.16022 4.9287.48543 1.5588.64565 2.891 1.73903 3.8284 3.14186.9373 1.40284 1.4376 3.05212 1.4376 4.73932-.0003 2.2623-.8991 4.4319-2.4988 6.0316-1.5997 1.5998-3.7693 2.4986-6.0317 2.4989z"
        fill="#555"
      />
    </g>
    <defs>
      <clipPath id="searchIcon">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SearchIcon;
