import React, { useState, useEffect, useContext } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { SCanvasContainer, SZoomButtons, SInfoErrorWrapper } from './index.styled';
import { theme } from '../../public/static/variables';
import { Button, ErrorLabel, Info, Icon, CompaniesHouseConsumer, ApplicationConsumer, FeesConsumer, AuthenticationConsumer } from "@local";
import { getPrefilledIcelandicContract } from '../../helpers/formFunctions/sendApplication';
import { FormValuesTypes } from 'src/types/formValues';
import { TargetMarket } from '../../types/formValues';
import { useRouter } from 'next/router';
import { Context } from 'vm';
import { captureException } from '@sentry/nextjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface DocumentPreviewProps {
  id: string;
  values: FormValuesTypes;
  errorMessage?: string;
  loadingMessage?: string;
}

const DocumentPreview = ({ id, values, errorMessage, loadingMessage }: DocumentPreviewProps) => {
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);
  const { application, guid, setUploadMessage } = useContext(ApplicationConsumer);
  const { feeLine } = useContext(FeesConsumer);
  const { setUnsignedContract } = useContext(AuthenticationConsumer);

  const [mediaQuery, setMediaQuery] = useState(window ? window.matchMedia(`(max-width: ${theme.breakpoints.tabletMax})`) : null);
  const [scale, setScale] = useState(2);   // 2 for Web, 1 for tablet or smaller
  const [horizontalScrollbarVisible, setHorizontalScrollbarVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [file, setFile] = useState<any>();
  const [numPages, setNumPages] = useState(null);
  const [gettingPdfError, setGettingPdfError] = useState(null as unknown as Error);

  const router = useRouter();

  const isHorizontalScrollbarVisible = (element: HTMLElement | null) => {
    if (element) {
      return element.scrollWidth > element.clientWidth;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = ({numPages: nextNumPages}: any) => {
    setGettingPdfError(null as unknown as Error);
    setNumPages(nextNumPages);
  }

  const renderPages = () => {
    if (numPages !== null) {
      return (
        Array.from({ length: numPages }, (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scale}
            className="pdf-page"
          />
        ))
      )
    } else {
      return (
        <></>
      )
    }
  }

  const getContract = async () => {

    const targetMarket: TargetMarket = router.asPath.includes('umsokn') ? TargetMarket.Iceland : TargetMarket.CrossBorder;

    const response = await getPrefilledIcelandicContract(
      values,
      application.applicationType,
      guid,
      companiesHouseValues,
      feeLine,
      setUploadMessage,
      targetMarket
    )

    return response;
  }

  const loadPdf = () => {
    return (
      <SCanvasContainer id={id} data-testid={id} horizontalScrollbarVisible={horizontalScrollbarVisible}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => setGettingPdfError(error)}
          onSourceError={(error) => setGettingPdfError(error)}
          error={
            <SInfoErrorWrapper data-testid={`${id}InfoError`}>
              <Info icon='INFOERROR'>{errorMessage}</Info>
            </SInfoErrorWrapper>
          }
          noData={loadingMessage}
          loading={loadingMessage}
          options={{
            // In order to show checkmarks on checkboxes etc.
            standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
          }}
        >
          {renderPages()}
        </Document>
      </SCanvasContainer>
    )
  }

  const checkIfHorizontalScrollbarIsVisible = () => {
    if (document) {
      const element = document.getElementById(id);
      if (isHorizontalScrollbarVisible(element)) {
        setHorizontalScrollbarVisible(true);
      } else {
        setHorizontalScrollbarVisible(false);
      }
    }
    loadPdf();
  }

  const resizeHandler = () => {
    if (mediaQuery?.matches) {
      // Tablet or smaller
      setScale(1);
    } else {
      // Web
      setScale(2);
    }
  };

  const base64ToArrayBuffer = (base64: string) => {
    try {
      const binaryString = window.atob(base64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      return bytes;
    } catch (e) {
      // Log error to sentry
      captureException(e, scope => {
        scope.setContext("values", null);
        scope.setContext("values", values as Context);
        return scope;
      });
      return undefined;
    }
  }

  useEffect(() => {
    getContract().then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        const contractString = response.parsedBody?.contract;
        setUnsignedContract(contractString);
        const contractByte = base64ToArrayBuffer(contractString);
        if (typeof contractByte !== 'undefined') {
          const contract = new Blob([contractByte], {type: "application/pdf"})
          setFile(contract);
        }
      }
    ).catch(e => {
      // Log error to sentry
      captureException(e, scope => {
        scope.setContext("values", null);
        scope.setContext("values", values as Context);
        return scope;
      });
      setGettingPdfError(e);
    })

    // Need to listen for resize to change the scale of the document when screen is smaller
    window.addEventListener('resize', resizeHandler);

    if (mediaQuery === null) {
      setMediaQuery(window.matchMedia(`(max-width: ${theme.breakpoints.tabletMax})`) as MediaQueryList);
    }

    checkIfHorizontalScrollbarIsVisible();

    return () => window.removeEventListener("resize", resizeHandler);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaQuery?.matches])

  const handleZoomIn = () => {
    // Maximum size of document
    if (scale === 3.5) {
      return;
    } else {
      setScale(prevState => prevState + 0.25);
    }
    checkIfHorizontalScrollbarIsVisible();
  }

  const handleZoomOut = () => {
    // Minimum size of document
    if (scale === 0.5) {
      return;
    } else {
      setScale(prevState => prevState - 0.25);
    }
    checkIfHorizontalScrollbarIsVisible();
  }

  return (
    <>
      {loadPdf()}
      {
        !gettingPdfError ?
          <SZoomButtons>
            <Button id='zoom-in-button' type='button' onClick={handleZoomIn}>
              <Icon icon='ZOOMINICON' />
            </Button>
            <Button id='zoom-out-button' type='button' onClick={handleZoomOut}>
              <Icon icon='ZOOMOUTICON' />
            </Button>
          </SZoomButtons>
        :
          <ErrorLabel>{errorMessage}</ErrorLabel>
      }
    </>
  )
};

export { DocumentPreview };
