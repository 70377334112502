/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
/* eslint-disable guard-for-in */

import { FormValuesTypes, TargetMarket } from '../../types/formValues';
import { FeeLine } from 'src/components/Context/FeesConsumer';
import ObjectPath from 'object-path';
import {
  CompanyData,
} from '../../components/Context/CompaniesHouseConsumer';
import { post, postFormData } from './http';
import { formatFeesList, getAmexObject, getCompaniesHouseCompanyInfo, getCompaniesHouseDirectorsInfo, getCompanyInfo, getCompanyWithDirectorInfo, getManagementDirectorsInfo, getManagementDirectorsPersonsInfo, getPersonsOfInterestFromValues, getStore, getTerminalList } from './helpers/formatters';
import { getDate, changeDateFormat, getDateOfBirthObject } from './helpers/utils';
import { Fees, FileUpload, Store, UploadAttachmentProps } from './types';

const apiLocation = () => {
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : `https://api.${window.location.host}`;
};

const uploadProgressUpdater = (setUploadMessage: (message: string) => void) => {
  let uploadCount = 0;
  let completedCount = 0;
  return {
    addUpload: () => {
      setUploadMessage(`${completedCount} of ${++uploadCount} images uploaded`);
    },
    completeUpload: () => {
      setUploadMessage(`${++completedCount} of ${uploadCount} images uploaded`);
    },
  };
};

const uploadAttachment = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  guid: string,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allRemainingFileUploads: any[],
  attachmentUri: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  progressUpdater: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allCompletedFileUploads: any[],
) => {
  progressUpdater.addUpload();
  const uploadAttachmentBody = new FormData();
  uploadAttachmentBody.append('File', value);
  uploadAttachmentBody.append('ApplicationId', guid);
  uploadAttachmentBody.append('AttachmentType', key);
  allRemainingFileUploads.push(async () => {
    const response = await postFormData<UploadAttachmentProps>(
      uploadAttachmentBody,
      attachmentUri,
      guid,
    );
    progressUpdater.completeUpload();
    if (response.ok && response.parsedBody) {
      const { fileName } = response.parsedBody;
      const fileUploadData = {
        File: fileName,
        Type: key,
      };
      // eslint-disable-next-line no-param-reassign
      value.fileUploadData = fileUploadData;
      allCompletedFileUploads.push(fileUploadData);
    }
  });
};


const uploadStores = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  guid: string,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allRemainingFileUploads: any[],
  attachmentUri: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  progressUpdater: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allCompletedFileUploads: any[],
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value.forEach(async (storeProp: any, index: number) => {
    for (const prop in storeProp) {
      if (Object.prototype.hasOwnProperty.call(storeProp, prop)) {
        const propValue = storeProp[prop];
        if (propValue.length > 1 && typeof propValue === 'object') {
          for (let len = 0; len < propValue.length; len++) {
            if (
              propValue &&
              propValue[len] &&
              propValue[len].name &&
              propValue[len].type &&
              propValue[len].size &&
              typeof propValue[len] === 'object'
            ) {
              // eslint-disable-next-line no-await-in-loop
              await uploadAttachment(
                propValue[len],
                guid,
                `${key}-${index + 1}-File${len}`,
                allRemainingFileUploads,
                attachmentUri,
                progressUpdater,
                allCompletedFileUploads,
              );
            }
          }
        } else if (
          propValue &&
          propValue.name &&
          propValue.type &&
          propValue.size &&
          typeof propValue === 'object'
        ) {
          // eslint-disable-next-line no-await-in-loop
          await uploadAttachment(
            propValue,
            guid,
            `${key}-${index + 1}`,
            allRemainingFileUploads,
            attachmentUri,
            progressUpdater,
            allCompletedFileUploads,
          );
        }
      }
    }
  });
};

const uploadAttachments = async (
  values: FormValuesTypes,
  guid: string,
  setUploadMessage: (message: string) => void,
) => {
  const attachmentUri = `${apiLocation()}/api/attachment`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const allRemainingFileUploads: string | any[] = [];
  const allCompletedFileUploads = [] as FileUpload[];
  const progressUpdater = uploadProgressUpdater(setUploadMessage);

  // collect all attachments
  // eslint-disable-next-line no-restricted-syntax
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = values[key] as any;
      if (key === 'Stores') {
        uploadStores(
          value,
          guid,
          key,
          allRemainingFileUploads,
          attachmentUri,
          progressUpdater,
          allCompletedFileUploads,
        );
      }
      // Uploading additional documents
      if (key === 'AdditionalDocuments') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Array.from(value).forEach(async (document: any, index: number) => {
          if (document && document.name && document.type && document.size) {
            // Only files here
            await uploadAttachment(
              document,
              guid,
              `${key}-${index}`,
              allRemainingFileUploads,
              attachmentUri,
              progressUpdater,
              allCompletedFileUploads,
            );
          }
        });
      }
      // Check if the value has attributes of an image
      if (value && value.name && value.type && value.size) {
        // Only files here
        if (value.fileUploadData) {
          progressUpdater.completeUpload();
          allCompletedFileUploads.push(value.fileUploadData);
        } else {
          // eslint-disable-next-line no-await-in-loop
          await uploadAttachment(
            value,
            guid,
            key,
            allRemainingFileUploads,
            attachmentUri,
            progressUpdater,
            allCompletedFileUploads,
          );
        }
      }
    }
  }

  // download remaining attachments
  for (let i = 0; i < allRemainingFileUploads.length; i++) {
    // eslint-disable-next-line no-await-in-loop
    await allRemainingFileUploads[i]();
  }

  return allCompletedFileUploads;
};

const uploadCompaniesHouseData = async (
  values: FormValuesTypes,
  guid: string,
) => {
  const companiesHouseUri = `${apiLocation()}/api/companieshouse`;
  const companiesHouseData = values &&
    values.CompaniesHouseData && {
    ApplicationId: guid,
    Name: values.CompaniesHouseData.company_name,
    Number: values.CompaniesHouseData.company_number,
    Status: values.CompaniesHouseData.company_status,
    StatusText: values.CompaniesHouseData.company_status_text,
    StatusDetail: values.CompaniesHouseData.company_status_detail,
    StatusDetailText: values.CompaniesHouseData.company_status_detail_text,
    DateOfCreation: values.CompaniesHouseData.date_of_creation,
    Type: values.CompaniesHouseData.type,
    TypeText: values.CompaniesHouseData.type_text,
    Subtype: values.CompaniesHouseData.subtype,
    SubtypeText: values.CompaniesHouseData.subtype_text,
    SicCodes: values.CompaniesHouseData.sic_codes,
    SicCodesText: values.CompaniesHouseData.sic_codes_text,
    SearchDate: values.CompaniesHouseData.search_date,
    RegisteredAddress: {
      Street: values.CompaniesHouseData.registered_office_address.address_line_1.substring(
        0,
        50,
      ),
      StreetContinued:
        values.CompaniesHouseData.registered_office_address.address_line_2,
      CareOf: values.CompaniesHouseData.registered_office_address.care_of,
      Country: values.CompaniesHouseData.registered_office_address.country,
      Locality: values.CompaniesHouseData.registered_office_address.locality,
      PoBox: values.CompaniesHouseData.registered_office_address.po_box,
      Postcode:
        values.CompaniesHouseData.registered_office_address.postal_code,
      Premises: values.CompaniesHouseData.registered_office_address.premises,
      Region: values.CompaniesHouseData.registered_office_address.region,
    },
    PersonsOfInterest:
      values.CompaniesHouseData &&
      values.CompaniesHouseData.persons_of_interest.map(person => {
        return {
          FirstName: person.name_elements.forename,
          MiddleName: person.name_elements.middle_name,
          LastName: person.name_elements.surname,
          Title: person.name_elements.title,
          ...getDateOfBirthObject(person.date_of_birth.year, person.date_of_birth.month, person.date_of_birth.day || "1"),
          Address: {
            Street: person.address.address_line_1,
            StreetContinued: person.address.address_line_2,
            CareOf: person.address.care_of,
            Country: person.address.country,
            Locality: person.address.locality,
            PoBox: person.address.po_box,
            Postcode: person.address.postal_code,
            Premises: person.address.premises,
            Region: person.address.region,
          },
          Occupation: person.occupation,
          OfficerRole: person.officer_role,
          OfficerRoleText: person.officer_role_text,
          SharePercentage: person.share_percentage,
          NaturesOfControl: person.natures_of_control,
          NaturesOfControlText: person.natures_of_control_text,
        };
      }),
  };

  const response = await post<UploadAttachmentProps>(
    companiesHouseData,
    companiesHouseUri,
    guid,
  );

  if (response.ok && response.parsedBody) {
    const { fileName } = response.parsedBody;
    const fileUploadData = {
      File: fileName,
      Type: 'CompaniesHouseData',
    };
    return fileUploadData as FileUpload;
  }

  return null;
};

/////////////

const uploadApplication = async (
  values: FormValuesTypes,
  applicationType: string,
  guid: string,
  companiesHouseValues: CompanyData,
  allFileUploads: FileUpload[],
  fees: Fees,
  targetMarket: TargetMarket,
  endpoint: string,
) => {

  if (!values.Stores) {
    values.Stores = []
  }

  if (values.Ecommerce && values.PaymentSolutions && values.PaymentSolutions.includes('ecommerce') && !values.PaymentSolutions.includes('cardPresent')) {
    if (values.Stores.length > 0 && targetMarket === TargetMarket.Iceland) {
      values.Stores = [];
    }
    values.Stores.push(values.Ecommerce);
  }

  if (values.CardPresent && values.PaymentSolutions && values.PaymentSolutions.includes('cardPresent') && !values.PaymentSolutions.includes('ecommerce')) {
    if (values.Stores.length > 0 && targetMarket === TargetMarket.Iceland) {
      values.Stores = [];
    }
    values.Stores.push(values.CardPresent);
  }

  if (values.CardPresent && values.Ecommerce && values.PaymentSolutions && values.PaymentSolutions.includes('ecommerce') && values.PaymentSolutions.includes('cardPresent')) {
    values.Stores = [];
    values.Stores.push(values.Ecommerce);
    values.Stores.push(values.CardPresent);
  }

  let businessStartDate;

  if (values.BusinessStartDate) {
    businessStartDate = {
      BusinessStartDate: changeDateFormat(values.BusinessStartDate)
    };
  } else if (values.BusinessStartDateStartYear && values.BusinessStartDateStartMonth && values.BusinessStartDateStartDay) {
    businessStartDate = {
      BusinessStartDate: getDate(values.BusinessStartDateStartYear, values.BusinessStartDateStartMonth, values.BusinessStartDateStartDay)
    }
  }

  // VALITOR IS Specific Amounts
  if (targetMarket === TargetMarket.Iceland) {
    if (values.AverageTransactionAmountMoreThanFifteenThousand) {
      values.AverageTransactionAmount = values.AverageTransactionAmountMoreThanFifteenThousand;
    }

    if (values.AvgTrans.includes('-')) {
      values.AvgTrans = values.AvgTrans.split('-')[1];
    }

    if (values.MaxTrans.includes('-')) {
      values.MaxTrans = values.MaxTrans.split('-')[1];
    }

    if (values.MaxransactionAmountMoreThanFiftyThousand) {
      values.MaxTrans = values.MaxTransactionAmountMoreThanFiftyThousand;
    }

    values.AvgProcessed = String(Math.floor(parseInt(values.AvgRevenue) / parseInt(values.AvgTrans)));
  }
  // End of VALITOR IS

  let company;
  if (values && values.Directors) {
    company = getCompanyWithDirectorInfo(values, targetMarket);
  } else if (companiesHouseValues) {
    company = getCompaniesHouseCompanyInfo(companiesHouseValues, values, targetMarket);
  } else {
    company = getCompanyInfo(values, targetMarket);
  }

  const managementDirectorsInfo = {
    Persons: values && values.Directors && getManagementDirectorsInfo(values, targetMarket)
  }


  const managementDirectorsPersonsInfo = {
    Persons:
      values &&
      values.DirectorsPersons &&
      getManagementDirectorsPersonsInfo(values, targetMarket)
  };

  const companiesHouseDirectorsInfo = {
    Persons: companiesHouseValues && getCompaniesHouseDirectorsInfo(values, companiesHouseValues, targetMarket)
  }

  const personsOfInterest = {
    PrimaryDirector: values.ChosenCompanyDirector || '0',
    Persons: {} as any, // eslint-disable-line
  }

  if (values.Directors) {
    personsOfInterest.Persons = managementDirectorsInfo.Persons
  } else if (companiesHouseValues) {
    personsOfInterest.Persons = companiesHouseDirectorsInfo.Persons
    if (values.DirectorsPersons) {
      for (const person of managementDirectorsPersonsInfo.Persons) {
        ObjectPath.push(personsOfInterest, 'Persons', person);
      }
    }
  } else {
    personsOfInterest.Persons = getPersonsOfInterestFromValues(values, targetMarket);
  }
  const stores: Store[] = values.Stores.map((store) => getStore(store, values, targetMarket))

  let eSign = null
  if (targetMarket === TargetMarket.CrossBorder) {
    eSign = {
      AgreementName: `${values.Stores[0].StoreName}-${guid}`,
      FileName: `${values.Stores[0].StoreName}-${guid}`,
      Emails: values.AdobeEmails
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values.AdobeEmails.map((item: any) => {
          return item.Email;
        })
        : null,
    }
  }

  const amex = getAmexObject(values, companiesHouseValues)

  const terminalList = values.terminalList && getTerminalList(values);

  const purchaseTerminal = {
    TerminalList: terminalList,
    AirtimeQuantity: 1,
    AirtimePricePerUnit: values.AirtimeServiceFee,
    ConfigurationDeliveryQuantity: 1,
    ConfigurationDeliveryPricePerUnit: values.TerminalDeliveryPrice,
  };

  const applicationBody = {
    Id: guid,
    AuthorizedSignatory: values.AuthorizedSignatory,
    Type: applicationType,
    Amex: amex,
    Company: company,
    Esign: eSign,
    TradedOnStockExchange:
      values.StockExchange &&
      values.StockExchange.length > 0 &&
      values.StockExchange[0] === 'yes',
    PersonsOfInterest: personsOfInterest,
    Stores: stores,
    EmailStores: values.EmailStores,
    PhoneNumberStores: {
      Prefix: values.PhoneStoresPrefix ?? values.PhonePrefix,
      Number: values.PhoneStoresNumber ?? values.PhoneNumber, // company??? || sole trader
    },
    OpeningHours: values.OpeningHours,
    AcceptedCardsList: values.TypeOfCards,
    BankAccount: {
      BankName: values.BankName,
      SwiftCode: values.SwiftCode,
      SortCode: values.SortCode,
      Bank: values.Bank,
      Ledger: values.Ledger,
      AccountNumber: values.AccountNumber,
      Iban: values.Iban,
    },
    ...businessStartDate,
    ProofOfBankVerified: values.ProofOfBankVerified
      ? values.ProofOfBankVerified[0] === 'yes'
      : false,
    PaymentsAdvance: values.PaymentsAdvance === 'yes',
    PreviouslyAcceptedCards: values.PreviouslyAcceptedCards === 'yes',
    AnnualTurnoverDeposits: values.AnnualTurnoverDeposits,
    DepositDays: values.DepositDays,
    AnnualTurnoverPayments: values.AnnualTurnoverPayments,
    PaymentDays: values.PaymentDays,
    GuaranteeOrWarrantiesPayment:
      values.AcceptedPaymentsGuaranteeWarranties === 'yes',
    UpfrontDays: values.UpfrontDays,
    UpfrontPayments: values.UpfrontPayments,
    AverageDeliveryTime: values.AverageTimeDelivery,
    Fees: fees,
    Attachments: allFileUploads,
    TerminalList: values.Directors ? [] : terminalList,
    TerminalServices: values.TerminalServices,
    PurchaseTerminal:
      values.PurchaseOrRentTerminal === 'Purchase' ? purchaseTerminal : null,
    IsPurchasingTerminal: values.PurchaseOrRentTerminal === 'Purchase',
    TerminalContractLength: values.TerminalContractLength,
    PaymentTerminalFee: values.PaymentTerminalFee,
    AirtimeServiceFee: values.AirtimeServiceFee,
    FasterPayments: values.FasterPayments && values.FasterPayments[0] === 'yes',
    SPFirstName: values.SPFirstName,
    SPLastName: values.SPLastName,
    EmailSales: values.EmailSales,
    BDAOrSelfGen:
      values.BDAName && values.BDAName !== ''
        ? values.BDAName
        : 'Self generated',
    AgreementDate: new Date(),
    TargetMarket: targetMarket,
  };

  if (targetMarket === TargetMarket.Iceland && values.SignedContract) {
    Object.assign(applicationBody, {SignedContract: values.SignedContract})
  }

  console.log("applicationBody: ", applicationBody);

  const requestUri = `${apiLocation()}${endpoint}`;

  let response;

  // Send application to backend
  if (endpoint === '/api/core') {
    response = await post<{ applicationId: string; message: string }>(
      applicationBody,
      requestUri,
      guid,
    );
  // Generate contract for icelandic application
  } else {
    response = await post<{ contract: string; message: string }>(
      applicationBody,
      requestUri,
      guid,
    );
  }

  return response;
}

const sendApplication = async (
  values: FormValuesTypes,
  applicationType: string,
  guid: string,
  companiesHouseValues: CompanyData,
  feeLine: FeeLine[],
  setUploadMessage: (message: string) => void,
  targetMarket: TargetMarket
) => {
  let companiesHouseFileUpload = null;
  if (values.CompaniesHouseData) {
    companiesHouseFileUpload = await uploadCompaniesHouseData(values, guid);
  }
  // Uplaod all attachments
  const allFileUploads = await uploadAttachments(
    values,
    guid,
    setUploadMessage,
  );

  if (companiesHouseFileUpload) {
    allFileUploads.push(companiesHouseFileUpload);
  }

  const fees = formatFeesList(values, feeLine);
  const endpoint = '/api/core';

  return uploadApplication(
    values,
    applicationType,
    guid,
    companiesHouseValues,
    allFileUploads,
    fees,
    targetMarket,
    endpoint,
  );
};

const getPrefilledIcelandicContract = async (
  values: FormValuesTypes,
  applicationType: string,
  guid: string,
  companiesHouseValues: CompanyData,
  feeLine: FeeLine[],
  setUploadMessage: (message: string) => void,
  targetMarket: TargetMarket
) => {
  let companiesHouseFileUpload = null;
  if (values.CompaniesHouseData) {
    companiesHouseFileUpload = await uploadCompaniesHouseData(values, guid);
  }
  // Uplaod all attachments
  const allFileUploads = await uploadAttachments(
    values,
    guid,
    setUploadMessage,
  );

  if (companiesHouseFileUpload) {
    allFileUploads.push(companiesHouseFileUpload);
  }

  const fees = formatFeesList(values, feeLine);
  const endpoint = '/api/generatecontract';

  return uploadApplication(
    values,
    applicationType,
    guid,
    companiesHouseValues,
    allFileUploads,
    fees,
    targetMarket,
    endpoint,
  );
};

export { sendApplication, getPrefilledIcelandicContract };
