export default {
  id: 'greidslusiduupplysingar',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Upplýsingar söluaðila vegna greiðslusíðu',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Ecommerce.EcommerceSolutions.EcommercePageInformation.SellerEmail',
      label: 'Netfang söluaðila',
      type: 'text',
      icon: 'MAIL',
      placeholder: 'Sláðu inn netfang söluaðila',
      reviewPageTitle: 'Netfang söluaðila',
      validations: ['REQUIRED(Þú þarft að slá inn netfang söluaðila)', 'EMAIL(Ekki gilt netfang)', 'MAXLENGTH(50 IS)'],
    },
    {
      componentType: 'PHONENUMBER',
      name: 'Ecommerce.EcommerceSolutions.EcommercePageInformation.SellerPhone',
      label: 'Símanúmer söluaðila',
      defaultCountry: 'IS',
      placeholder: 'Sláðu inn símanúmer söluaðila',
      validations: ['REQUIRED(Þú þarft að slá inn símanúmer söluaðila)', 'PHONENUMBER(Ekki gilt símanúmer)', 'MAXLENGTH(11 IS)'],
      reviewPageTitle: 'Símanúmer söluaðila',
    },
  ],
};
