export default {
  nextButtonText: 'Undirrita samning og senda umsókn',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Samningur til undirritunar',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'DOCUMENTPREVIEW',
      id: 'AgreementToSign',
      errorMessage: 'Ekki tókst að sækja samninginn',
      loadingMessage: 'Sæki samninginn...'
    },
    {
      componentType: 'CHECKBOX',
      validations: ['CHECKBOX(Þú þarft að haka í þennan reit til að halda áfram)'],
      checkboxValues: [
        {
          labelText: 'Ég hef lesið skjalið og samþykki þess efni',
          name: 'AgreementAccepted',
          value: 'agreementAccepted',
        },
      ],
    },
    {
      componentType: 'PARAGRAPH',
      children:
        <>Með því að haka í boxið hér að neðan staðfestir þú að hafa kynnt þér og samþykkir <a className="link" href="https://www.valitor.is/adrar-upplysingar/skilmalar/" target="_blank" rel="noreferrer noopenerr">skilmála Amex</a></>,
      conditional: {
        type: ['SHOW'],
        conditionName: 'TypeOfCards',
        conditionArrayValue: 'Amex',
      },
    },
    {
      componentType: 'CHECKBOX',
      validations: ['CHECKBOX(Þú þarft að haka í þennan reit til að halda áfram)'],
      checkboxValues: [
        {
          labelText: 'Ég samþykki skilmála Amex',
          name: 'AmexTermsAccepted',
          value: 'amexTermsAccepted',
        },
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'TypeOfCards',
        conditionArrayValue: 'Amex',
      },
    },
    {
      componentType: 'INFOWITHPARAMETERS',
      id: 'InfoWithParameters',
      text: 'Samningur verður sendur til undirritunar á ',
      textFromValues: ['PhoneNumber'],
      className: 'extra-margin-top',
      editButtonVisible: true,
      pageNumberForEdit: 0,
      pageStepForEdit: 2,
      hoverText: 'Breyta símanúmeri'
    },
    {
      componentType: 'PRIVACYPOLICY',
    }
  ]
};
