import React, { useContext } from 'react';
import { Popup } from 'semantic-ui-react';
import { Icon, ApplicationConsumer } from '@local';
import { SEditButton, SWrapper } from '../index.styled';

interface EditButtonProps {
  children?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formSetup: any;
  pageNumber: number;
  pageStep: number;
  hoverText?: string;
}

const EditButtonWithHover = ({ children, pageNumber, pageStep, formSetup, hoverText }: EditButtonProps) => {
  const {
    setPageNumber,
    setStep,
    setCurrentStepSetup,
    setDirection,
  } = useContext(ApplicationConsumer);

  return (
    <SWrapper>
      <Popup
        content={hoverText}
        trigger={
          <SEditButton
            id={`edit-button-with-hover-${pageNumber}`}
            data-testid={`edit-button-with-hover-${pageNumber}`}
            onClick={() => {
              setPageNumber(pageNumber);
              setStep(pageStep);
              setCurrentStepSetup(formSetup.pages[pageNumber].steps[pageStep]);
              setDirection('up');
            }}>
            <Icon icon="EDITICON" />
            {children}
          </SEditButton>
        }
      />
    </SWrapper>
  );
};

export { EditButtonWithHover };
