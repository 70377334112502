export default {
  id: 'name',
  fields: [
    {
      componentType: 'TITLE',
      text: "What's your name?",
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'FirstName',
      label: 'First name',
      type: 'text',
      icon: 'USER',
      placeholder: 'Enter your first name',
      validations: ['REQUIRED', 'MAXLENGTH(50)'],
      reviewPageTitle: 'First Name',
    },
    {
      componentType: 'INPUT',
      name: 'MiddleName',
      label: 'Middle name (optional)',
      type: 'text',
      icon: 'USER',
      placeholder: 'Enter your middle name',
      validations: ['MAXLENGTH(50)'],
      reviewPageTitle: 'Middle Name',
    },
    {
      componentType: 'INPUT',
      name: 'LastName',
      label: 'Last name',
      type: 'text',
      icon: 'USER',
      placeholder: 'Enter your last name',
      validations: ['REQUIRED', 'MAXLENGTH(50)'],
      reviewPageTitle: 'Last Name',
    },
    {
      componentType: 'TITLE',
      text: 'When is your birthday?',
      className: 'size-md-medium',
    },
    {
      componentType: 'DATEINPUT',
      name: 'BirthdayInput',
      reviewPageTitle: 'Date of birth',
      personsBirthday: true,
    },
    {
      componentType: 'TITLE',
      text: 'What’s your home address?',
      className: 'size-md-medium',
    },
    {
      componentType: 'ADDRESSLOOKUP',
      name: 'AddressLookUp',
      label: 'Address finder',
      icon: 'SEARCH',
      placeholder: 'Search for address by postcode',
      copyTo: [
        ['line_1', 'Street'],
        ['line_2', 'StreetContinued'],
        ['post_town', 'City'],
        ['postcode', 'Postcode'],
      ],
    },
    {
      componentType: 'INPUT',
      name: 'Street',
      label: 'Address Line 1',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'Enter your address',
      validations: ['REQUIRED', 'MAXLENGTH(50)'],
      reviewPageTitle: 'Address Line 1',
    },
    {
      componentType: 'INPUT',
      name: 'StreetContinued',
      label: 'Address Line 2 (optional)',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'Enter your address',
      reviewPageTitle: 'Address Line 2',
    },
    {
      componentType: 'INPUT',
      name: 'City',
      label: 'Town/City',
      type: 'text',
      icon: 'TOWNBUILDING',
      placeholder: 'E.g. London',
      validations: ['REQUIRED', 'MAXLENGTH(100)'],
      reviewPageTitle: 'Town/City',
    },
    {
      componentType: 'INPUT',
      name: 'Postcode',
      label: 'Postcode',
      type: 'text',
      icon: 'POSTBOXICON',
      placeholder: 'E.g. SW1Y 4QE',
      validations: ['REQUIRED', 'MAXLENGTH(10)', 'MINLENGTH(3)'],
      reviewPageTitle: 'Postcode',
    },
  ],
};
