export default {
  id: 'uploadId',
  nextButtonText: 'Upload',
  showOnTemplates: 'showOnCountries',
  showOnCountries: ['UK'],
  fields: [
    {
      componentType: 'TITLE',
      text: 'Upload an image of your ID',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'MEDIAUPLOAD',
      id: 'Identification',
      fileTypesAccepted: 'image/*,application/pdf',
      label: 'Media_Upload',
      text:
        'This can be either your passport or drivers licence. Just make sure you upload the side with your profile image.',
      validations: ['REQUIRED(Upload an image of your ID is mandatory)'],
    },
  ],
};
