export default {
  id: 'fyrirtaekjaupplysingar',
  nextButtonText: 'Áfram',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Fyrirtækjaupplýsingar',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Kennitala',
      label: 'Kennitala fyrirtækis',
      type: 'text',
      mask: 'KENNITALA',
      placeholder: 'Sláðu inn kennitölu',
      validations: ['REQUIRED(Þú þarft að slá inn kennitölu)', 'MAXLENGTH(11 IS)', 'MINLENGTH(11 IS)'],
      reviewPageTitle: 'Kennitala fyrirtækis',
    },
    {
      componentType: 'INPUT',
      name: 'ISAT',
      label: 'ÍSAT Atvinnugreinarnúmer',
      type: 'text',
      mask: 'ISATNUMBER',
      placeholder: 'Sláðu inn ÍSAT númer',
      validations: ['REQUIRED(Þú þarft að slá inn ÍSAT númer)', 'MAXLENGTH(7 IS)', 'MINLENGTH(7 IS)', 'ISAT'],
      reviewPageTitle: 'ÍSAT númer',
    },
    {
      componentType: 'INPUT',
      name: 'CompanyVSK',
      label: 'VSK númer',
      type: 'text',
      mask: 'NUMBER',
      maxLength: 6,
      placeholder: 'Sláðu inn VSK númer',
      validations: ['REQUIRED(Þú þarft að slá inn VSK númer)', 'MAXLENGTH(6 IS)'],
      reviewPageTitle: 'VSK númer',
    },
    {
      componentType: 'INPUT',
      name: 'CompanyName',
      label: 'Nafn fyrirtækis',
      type: 'text',
      placeholder: 'Sláðu inn nafn fyrirtækis',
      validations: ['REQUIRED(Þú þarft að slá inn nafn fyrirtækis)', 'MAXLENGTH(50 IS)'],
      reviewPageTitle: 'Nafn fyrirtækis',
    },
    {
      componentType: 'INPUT',
      name: 'Street',
      label: 'Heimilisfang',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'Sláðu inn heimilisfang',
      validations: ['REQUIRED(Þú þarft að slá inn heimilisfang)', 'MAXLENGTH(50 IS)'],
      reviewPageTitle: 'Heimilisfang',
    },
    {
      componentType: 'INPUT',
      name: 'City',
      label: 'Staður',
      type: 'text',
      icon: 'TOWNBUILDING',
      placeholder: 'Sláðu inn stað',
      validations: ['REQUIRED(Þú þarft að slá inn stað)', 'MAXLENGTH(100 IS)'],
      reviewPageTitle: 'Staður',
    },
    {
      componentType: 'INPUT',
      name: 'Postcode',
      label: 'Póstnúmer',
      type: 'text',
      icon: 'POSTBOXICON',
      placeholder: 'Sláðu inn póstnúmer',
      validations: ['REQUIRED(Þú þarft að slá inn póstnúmer)', 'MAXLENGTH(10 IS)', 'MINLENGTH(3 IS)'],
      reviewPageTitle: 'Póstnúmer',
    },
  ],
};
