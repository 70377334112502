export default {
  title: 'What kind of upfront payments do you take?',
  id: 'depositOrFullPayments',
  conditionalNextSteps: [
    {
      id: 'UpfrontPayments',
      value: ['fullPayment'],
      skipToStep: 'fullPayment',
    },
  ],
  fields: [
    {
      componentType: 'CHECKBOX',
      validations: ['CHECKBOX'],
      checkboxValues: [
        {
          labelText: 'Deposit',
          name: 'UpfrontPayments',
          value: 'deposit',
        },
        {
          labelText: 'Full payment',
          name: 'UpfrontPayments',
          value: 'fullPayment',
        },
      ],
    },
  ],
};
