import { ISATNUMBERS } from "../../../public/static/content/isat_numbers";

const isat = (value: string | undefined, label: string | undefined, arg: string | undefined) => {
  if (value) {
    const valid = ISATNUMBERS.includes(value);

    return valid
      ? undefined
      : arg ? `${arg}` : 'ÍSAT númer er ekki til';
  }
}

export { isat }