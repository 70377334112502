import React from 'react';

const sortCode = (e: React.BaseSyntheticEvent) => {
  const { value } = e.target;
  // No value, so return early
  if (!value) {
    return value;
  }

  // Filter string to only have numbers
  const onlyNums = value.replace(/[^\d]/g, '');

  // Numbers before '-', '12'
  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  // Numbers after first '-', '12-34'
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}`;
  }

  // Full strength length, '12-34-56'
  return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 4)}-${onlyNums.slice(
    4,
    6,
  )}`;
};

export { sortCode };
