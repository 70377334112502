import styled, { css, keyframes } from 'styled-components';

export interface LoadingIndicatorProps {
  visible?: boolean;
  align?: string;
  width?: string;
  height?: string;
  marginTop?: string;
  centerDiv?: boolean;
}

export const SkCircleFadeDelay = keyframes`
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
`;

export const LoadingIndicatorWrapper = styled.div<LoadingIndicatorProps>`
  ${({ visible }) => css`
    ${!visible
      ? css`
          display: none;
        `
      : css`
          display: block;
        `};
  `}
`;

export const SkFadingCircle = styled.div<LoadingIndicatorProps>`
  ${({ align, width, height, marginTop, centerDiv }) => css`
    margin: 0px;
    margin-top: ${marginTop}px;

    ${align === 'right'
      ? css`
          margin-right: 50px;
        `
      : css`
          margin-left: 25px;
        `};
    width: ${width}px;
    height: ${height}px;
    position: relative;
    z-index: 9999;
    float: ${align};

    ${centerDiv &&
      css`
        top: 0;
        bottom: 0px;
        left: 0;
        right: 0;
        position: absolute;
        margin: auto;
      `}
  `}

  & > .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  & > .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #4f9ea6;
    border-radius: 100%;
    -webkit-animation: ${SkCircleFadeDelay} 1.2s infinite ease-in-out both;
    animation: ${SkCircleFadeDelay} 1.2s infinite ease-in-out both;
  }

  & > .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  & > .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  & > .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  & > .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }

  & > .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }

  & > .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  & > .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }

  & > .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }

  & > .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  & > .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }

  & > .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }

  & > .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  & > .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  & > .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  & > .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  & > .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  & > .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  & > .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  & > .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  & > .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  & > .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }

  & > .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
`;
