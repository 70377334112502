import React from 'react';

export const ValitorIconBox = () => (
  <svg
    width="170"
    height="120"
    viewBox="0 0 170 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity=".15"
      d="M102.239 41.2389L79.2845 18.4646c-10.2418-10.16147-27.079-10.06262-37.5401.3163L18.9295 41.4168c-9.86326 9.7858-10.64037 25.028-1.9926 35.5849l3.4472 3.4201 22.7551 22.5762.1993.198c10.5407 8.679 26.0031 7.809 35.8663-1.977L102.02 78.5832c10.361-10.2801 10.56-27.084.219-37.3443zM67.4686 89.6145c-3.6863 3.6573-9.2456 4.0725-12.892 1.0478L29.4503 65.7331c-3.0487-3.6178-2.5306-9.2323 1.056-12.7907l22.8349-22.6557c3.8855-3.8551 10.2219-3.8946 14.0676-.0791l23.0541 22.8731c3.8456 3.8155 3.8058 10.1022-.0797 13.9572l-22.815 22.6359-.0797-.0791-.0199.0198z"
      fill="#919191"
    />
    <path
      d="M151.29 41.2389l-22.955-22.7743c-10.242-10.16147-27.079-10.06262-37.5398.3163L67.9803 41.4168c-9.8633 9.7858-10.6404 25.028-1.9926 35.5849l3.4471 3.4201L92.19 102.998l.1993.198c10.5407 8.679 26.0027 7.809 35.8667-1.977l22.815-22.6358c10.361-10.2801 10.56-27.084.219-37.3443zm-34.771 48.3756c-3.686 3.6573-9.245 4.0725-12.892 1.0478L78.501 65.7331c-3.0486-3.6178-2.5305-9.2323 1.0561-12.7907l22.8349-22.6557c3.886-3.8551 10.222-3.8946 14.068-.0791l23.054 22.8731c3.845 3.8155 3.805 10.1022-.08 13.9572l-22.815 22.6359-.08-.0791-.02.0198z"
      fill="#4F9EA6"
    />
  </svg>
);

export default ValitorIconBox;
