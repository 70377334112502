export default {
  id: 'personuupplysingar',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Persónuupplýsingar',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'FirstName',
      label: 'Fornafn',
      type: 'text',
      icon: 'USER',
      placeholder: 'Sláðu inn fornafn',
      validations: ['REQUIRED(Þú þarft að slá inn fornafn)', 'MAXLENGTH(50 IS)'],
      reviewPageTitle: 'Fornafn',
    },
    {
      componentType: 'INPUT',
      name: 'MiddleName',
      label: 'Millinafn (valkvætt)',
      type: 'text',
      icon: 'USER',
      placeholder: 'Sláðu inn millinafn',
      validations: ['MAXLENGTH(50 IS)'],
      reviewPageTitle: 'Millinafn',
    },
    {
      componentType: 'INPUT',
      name: 'LastName',
      label: 'Eftirnafn',
      type: 'text',
      icon: 'USER',
      placeholder: 'Sláðu inn eftirnafn',
      validations: ['REQUIRED(Þú þarft að slá inn eftirnafn)', 'MAXLENGTH(50 IS)'],
      reviewPageTitle: 'Eftirnafn',
    },
    {
      componentType: 'INPUT',
      name: 'Kennitala',
      label: 'Kennitala',
      type: 'text',
      mask: 'KENNITALA',
      placeholder: 'Sláðu inn kennitölu',
      validations: ['REQUIRED(Þú þarft að slá inn kennitölu)', 'MAXLENGTH(11 IS)', 'MINLENGTH(11 IS)'],
      reviewPageTitle: 'Kennitala',
    },
    {
      componentType: 'TITLE',
      text: 'Hvert er lögheimili þitt?',
      className: 'size-md-medium',
    },
    {
      componentType: 'INPUT',
      name: 'Street',
      label: 'Heimilisfang',
      type: 'text',
      icon: 'HOUSE',
      placeholder: 'Sláðu inn heimilisfang',
      validations: ['REQUIRED(Þú þarft að slá inn heimilisfang)', 'MAXLENGTH(50 IS)'],
      reviewPageTitle: 'Heimilisfang',
    },
    {
      componentType: 'INPUT',
      name: 'City',
      label: 'Staður',
      type: 'text',
      icon: 'TOWNBUILDING',
      placeholder: 'Sláðu inn stað',
      validations: ['REQUIRED(Þú þarft að slá inn stað)', 'MAXLENGTH(100 IS)'],
      reviewPageTitle: 'Staður',
    },
    {
      componentType: 'INPUT',
      name: 'Postcode',
      label: 'Póstnúmer',
      type: 'text',
      icon: 'POSTBOXICON',
      placeholder: 'Sláðu inn póstnúmer',
      validations: ['REQUIRED(Þú þarft að slá inn póstnúmer)', 'MAXLENGTH(10 IS)', 'MINLENGTH(3 IS)'],
      reviewPageTitle: 'Póstnúmer',
    },
    {
      componentType: 'TITLE',
      text: 'Hvernig höfum við samband?',
      className: 'size-md-medium',
    },
    {
      componentType: 'INPUT',
      name: 'Email',
      label: 'Netfang',
      type: 'email',
      icon: 'MAIL',
      placeholder: 'Sláðu inn netfang',
      validations: ['REQUIRED(Þú þarft að slá inn netfang)', 'EMAIL(Ekki gilt netfang)', 'MAXLENGTH(60 IS)'],
      reviewPageTitle: 'Netfang',
    },
    {
      componentType: 'PHONENUMBER',
      name: 'PhoneNumber',
      label: 'Símanúmer',
      placeholder: 'Sláðu inn símanúmer',
      defaultCountry: 'IS',
      validations: ['REQUIRED(Þú þarft að slá inn símanúmer)', 'PHONENUMBER(Ekki gilt símanúmer)', 'MAXLENGTH(11 IS)'],
      reviewPageTitle: 'Símanúmer',
    },
  ],
};
