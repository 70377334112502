// eslint-disable-next-line import/prefer-default-export
export const replaceWildCard = (
  text: string,
  wildCardChar: string,
  charToReplaceWith: string,
) => {
  return text.replace(wildCardChar, charToReplaceWith);
};

export const removeWhiteSpace = (text: string) => {
  return text.replace(/\s/g, '');
};

const isNumber = (char: string) => {
  return /^\d$/.test(char);
}

export const changeNumberToWildCardChar = (text: string, wildCardChar: string) => {
  if (!text.includes(wildCardChar)) {
    const number =  text[text.indexOf('.') + 1]
    if (isNumber(number)) {
      return text.replace(number, wildCardChar);
    }
  }
  return text;
}
