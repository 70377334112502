import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || "https://dec260e9230741edb5f8de4315f1415e@o1147156.ingest.sentry.io/6217050",
  enabled: process.env.NODE_ENV === 'production',
  tracesSampleRate: 0.2,
  integrations: [new BrowserTracing()],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});