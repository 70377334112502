export default {
  id: 'additionalDocuments',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Additional documents',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'MEDIAUPLOAD',
      fileTypesAccepted:
        'image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/text',
      multipleFileUpload: true,
      maxFiles: 15,
      id: 'AdditionalDocuments',
      label: 'Additional_documents_upload',
      text:
        'This is optional. You can add supporting documents of types images, text, word, pdf and spreadsheet files.',
      buttonText: 'Upload file/s',
    },
  ],
};
