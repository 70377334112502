import {
  adobeSignIntegration,
  additionalDocuments,
  bankInfo,
  businessStartDate,
  cardTypes,
  confirmAcquirerStatement,
  confirmBusinessExistence,
  confirmId,
  confirmProofOfBank,
  depositOrFullPayments,
  deposits,
  email,
  fasterPayments,
  feeslist,
  fullPayment,
  maxTransaction,
  name,
  storeLocation,
  storeType,
  paymentCards,
  paymentInAdvance,
  salesAgentEmail,
  sharedTerminals,
  uploadAcquirerStatement,
  uploadId,
  uploadProofOfBank,
  uploadBusinessExistence,
} from '../sharedLogic';

/**
 * TODO: Add ability to control field layout in this JSON.
 * I'd suggest something like https://jsonforms.io/
 */

/*
 *TODO:list
 * Phone number validation
 * Unify all components so that the form decides all parameters, e.g. label, placeholder
 */

export default {
  pageTitle: 'Sole Trader',
  applicationType: 'SoleTrader',
  pages: [
    // Page identity
    {
      icon: 'IDENTITY',
      title: 'Identity',
      id: 'identity',
      showOnReviewPage: true,
      steps: [
        // Step name
        {
          ...name,
        },
        // Step email
        {
          ...email,
        },
        // Step uploadId
        {
          ...uploadId,
        },
        // Step confirmId
        {
          ...confirmId,
        },
      ],
    },
    // Page store
    {
      icon: 'STORECOLOR',
      title: 'Stores',
      id: 'store',
      showOnReviewPage: true,
      steps: [
        // Step 00 - paymentCards
        {
          ...paymentCards,
        },
        // Step fasterPayments
        {
          ...fasterPayments,
        },
        // Step uploadAcquirerStatement
        {
          ...uploadAcquirerStatement,
        },
        // Step confirmAcquirerStatement
        {
          ...confirmAcquirerStatement,
        },
        // Step storeType
        {
          ...storeType,
        },
        {
          ...uploadBusinessExistence,
        },
        {
          ...confirmBusinessExistence,
        },
        // Step storeLocation
        {
          ...storeLocation,
        },
        // Step cardsAndHours
        {
          id: 'cardsAndHours',
          fields: [
            { ...businessStartDate.title },
            { ...businessStartDate.dateInput },
            { ...cardTypes.title },
            { ...cardTypes.checkbox },
          ],
        },
        // Step maxTransaction
        {
          ...maxTransaction,
        },
        // Step paymentInAdvance
        {
          ...paymentInAdvance,
        },
        // Step depositOrFullPayments
        {
          ...depositOrFullPayments,
        },
        // Step deposits
        {
          ...deposits,
        },
        // Step fullPayment
        {
          ...fullPayment,
        },
      ],
    },
    // Page terminals
    {
      ...sharedTerminals,
    },
    // Page finance
    {
      icon: 'TRIBUNALICON',
      title: 'Finance',
      id: 'finance',
      showOnReviewPage: true,
      steps: [
        // Step listOfFees
        {
          ...feeslist,
        },
        // Step bankInfo
        {
          id: 'bankInfo',
          fields: [
            { ...bankInfo.bankNameTitle },
            { ...bankInfo.bankNameInput },
            { ...bankInfo.swiftCodeInput },
            { ...bankInfo.accountDetailsTitle },
            { ...bankInfo.accountNumberInput },
          ],
        },
        // Step uploadProofOfBank
        {
          ...uploadProofOfBank,
        },
        // Step confirmProofOfBank
        {
          id: 'confirmProofOfBank',
          nextButtonText: 'Upload',
          previousButtonText: 'Use another image',
          fields: [
            { ...confirmProofOfBank.uploadedImage },
            { ...confirmProofOfBank.proofOfBankVerified },
            { ...confirmProofOfBank.info },
          ],
        },
      ],
    },
    // Page review
    {
      icon: 'DOCUMENTSAVEDICON',
      title: 'Review',
      id: 'review',
      showOnReviewPage: false,
      steps: [
        {
          ...additionalDocuments,
        },
        // Step applicationReview
        {
          id: 'applicationReview',
          fields: [
            {
              componentType: 'TITLE',
              text: 'Review your application',
              className: 'size-md-medium no-margin-top',
            },
            {
              componentType: 'PARAGRAPH',
              text:
                'Here is an overview of the whole application than needs to be signed off by the merchant',
            },
            {
              componentType: 'REVIEW',
            },
          ],
        },
        {
          ...adobeSignIntegration,
        },
        // TODO: put this to a different page
        // Step salesAgentEmail
        {
          ...salesAgentEmail,
        },
      ],
    },
  ],
};
