import React, { useContext } from 'react';
import { ApplicationConsumer } from '@local';

import {
  SUploadProgress,
  UploadProgressWrapper,
  SUploadProgressHeader,
} from './index.styled';

interface UploadProgressProps {
  name: string;
}

const UploadProgress = (props: UploadProgressProps) => {
  const { name } = props;
  const { uploadMessage } = useContext(ApplicationConsumer);

  return (
    <>
      <UploadProgressWrapper id={name} visible={uploadMessage !== '-'}>
        <SUploadProgressHeader>
          Adding files to application
        </SUploadProgressHeader>
        <SUploadProgress>{uploadMessage}</SUploadProgress>
      </UploadProgressWrapper>
    </>
  );
};

export { UploadProgress };
