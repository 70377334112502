export default {
  id: 'listOfFees',
  fields: [
    {
      componentType: 'TITLE',
      text:
        'Here is an overview of all the fees that apply to your business model',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'FEESLIST',
      accordionOpen: true,
      fallBackFeeslist: [
        {
          title: 'Domestic Card Present',
          fees: [
            {
              name: 'Visa Credit (Personal)',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Visa Debit (Personal)',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Mastercard Credit (Personal)',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Mastercard Debit (Personal)',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Visa Premium Credit',
              icon: 'percentage',
              value: '0.40',
              valueType: 'Percentage',
              disabled: false,
              decimalPrecision: 2,
            },
            {
              name: 'Visa Premium Debit',
              icon: 'percentage',
              value: '0.40',
              valueType: 'Percentage',
              disabled: false,
              decimalPrecision: 2,
            },
            {
              name: 'Mastercard Premium Credit',
              icon: 'percentage',
              value: '0.40',
              valueType: 'Percentage',
              disabled: false,
              decimalPrecision: 2,
            },
            {
              name: 'Mastercard Premium Debit',
              icon: 'percentage',
              value: '0.40',
              valueType: 'Percentage',
              disabled: false,
              decimalPrecision: 2,
            },
            {
              name: 'Visa Commercial Debit',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Visa Commercial Credit',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Mastercard Commercial Debit',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Mastercard Commercial Credit',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
          ],
        },
        {
          title: 'Non-domestic Card Present',
          accordionOpen: false,
          fees: [
            {
              name: 'European Standard Debit',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'European Standard Credit',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'European Non-Standard',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Inter-Regional Standard',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Inter-Regional Non-Standard',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
          ],
        },
        {
          title: 'Other transaction fees',
          accordionOpen: false,
          fees: [
            {
              name: 'Customer Debit MOTO',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Customer Credit MOTO',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Non-Standard MOTO',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Uncategorized',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
            {
              name: 'Amex discount rate',
              icon: 'percentage',
              value: '0.00',
              valueType: 'Percentage',
              editable: true,
              decimalPrecision: 2,
            },
          ],
        },
        {
          title: 'Other fees',
          accordionOpen: false,
          fees: [
            {
              name: 'PCI Monthly fee',
              icon: 'pound',
              value: '4.99',
              valueType: 'Fixed',
              editable: false,
              decimalPrecision: 2,
            },
            {
              name: 'Chargeback fee',
              icon: 'pound',
              value: '25.00',
              valueType: 'Fixed',
              editable: false,
              decimalPrecision: 2,
            },
            {
              name: 'Payment fee',
              icon: 'pound',
              value: '0.00',
              valueType: 'Fixed',
              editable: false,
              decimalPrecision: 2,
            },
            {
              name: 'Authorization fee',
              icon: 'pound',
              value: '0.03',
              valueType: 'Fixed',
              editable: true,
              decimalPrecision: 2,
            },
          ],
        },
      ],
    },
  ],
};
