import React, { useState, useContext } from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import { CheckBox, ErrorLabel, FeesConsumer, TerminalConsumer } from '@local';
import { validate } from '@utils';
import { CheckBoxProps } from './CheckBox';

interface CheckBoxesProps {
  checkboxValues?: CheckBoxProps[];
  validations?: string[];
  label?: string;
  stateManagementRequired?: boolean;
  classname?: string
}

const CheckBoxes = ({
  checkboxValues,
  validations,
  label,
  stateManagementRequired,
  classname
}: CheckBoxesProps) => {
  const [error, setError] = useState(undefined);
  const { setPaymentFee } = useContext(FeesConsumer);
  const { terminals } = useContext(TerminalConsumer);
  const populateFasterPayments = (
    input: FieldInputProps<string, HTMLElement>,
  ) => {
    if (input.name === 'FasterPayments') {
      if (input.value === 'yes' && input.checked) {
        setPaymentFee(0.4);
      } else {
        setPaymentFee(0.0);
      }
    }
  };

  // Only exmaple we have is terminal services, so it's a bit hardcoded for that usecase
  // TODO needs rework once we have more example or conditional disabled status for checkboxes
  const conditionalCheckboxes = (
    disabled?: boolean,
    disabledStatusDependsOn?: { listenToValues: string[]; context: string },
  ) => {
    let returnedDisabled = disabled;
    if (
      disabledStatusDependsOn &&
      disabledStatusDependsOn.listenToValues.length > 0 &&
      disabledStatusDependsOn.context === 'terminal'
    ) {
      for (let i = 0; i < terminals.terminals.length; i++) {
        for (
          let x = 0;
          x < disabledStatusDependsOn.listenToValues.length;
          x++
        ) {
          if (
            terminals.terminals[i].type ===
            disabledStatusDependsOn.listenToValues[x]
          ) {
            if (terminals.terminals[i].value > 0) {
              returnedDisabled = false;
            }
          }
        }
      }
    }
    return returnedDisabled;
  };

  return (
    <div className={classname}>
      {checkboxValues
        ? checkboxValues.map(
          ({ value, name, labelText, disabled, disabledStatusDependsOn }) => {
            return (
              <Field
                name={name}
                key={value}
                type="checkbox"
                value={value}
                validate={validate(label, validations)}>
                {({ input, meta }) => {
                  populateFasterPayments(input);
                  setError(
                    meta.error && meta.touched && !meta.active && meta.error,
                  );

                  // Set the disabled state
                  let isDisabled = disabled;
                  if (stateManagementRequired && disabledStatusDependsOn) {
                    isDisabled = conditionalCheckboxes(
                      disabled,
                      disabledStatusDependsOn,
                    );
                  }

                  // Set the checked state
                  let isChecked = input.checked;
                  // uncheck the checkbox if it's disabled
                  if (isDisabled && isChecked) {
                    isChecked = false;
                  }
                  return (
                    <CheckBox
                      value={value}
                      name={name}
                      labelText={labelText}
                      onChange={input.onChange}
                      checked={isChecked}
                      disabled={isDisabled}
                    />
                  );
                }}
              </Field>
            );
          },
        )
        : null}
      <ErrorLabel>{error}</ErrorLabel>
    </div>
  );
};

export { CheckBoxes };
export type { CheckBoxesProps }
