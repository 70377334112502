import React, { useState, useEffect } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Select, Info } from '@local';
import { AnyObject } from 'final-form';
import { SWrapper } from './index.styled';

export interface AddressLookUpInputProps {
  name: string;
  placeholder: string;
  label?: string;
  copyTo: [string, string][];
  form?: FormRenderProps['form'];
}

interface Address {
  postcode: string;
  post_town: string;
  line_1: string;
  line_2: string;
  line_3: string;
  country: string;
}

interface SelectDropDownValues {
  items: { label: string; value: string }[];
}

const AddressLookUpInput = ({
  label,
  placeholder,
  name,
  copyTo,
  form,
}: AddressLookUpInputProps) => {
  const [
    addressLookUpResponseOptions,
    setAddressLookUpResponseOptions,
  ] = useState<SelectDropDownValues>({
    items: [],
  });
  // state for collecting all addresses and all their data populated in the dropdown
  const [addressLookUpData, setAddressLookUpData] = useState<Address[]>();
  const [selectedValue, setSelectedValue] = useState<string>();
  const [postcodeInfoValue, setPostcodeInfoValue] = useState('');

  const populateDropdown = (data: { result: Address[] }) => {
    setAddressLookUpData(data.result);
    // Have to redestruct the data to be in the format that Select component expects the data to be
    const restructuredData: { label: string; value: string }[] = [];
    for (let i = 0; i < data.result.length; i++) {
      if (data.result[i].postcode !== null) {
        restructuredData[i] = {
          value: data.result[i].postcode,
          label: `${data.result[i].line_1}, ${data.result[i].post_town}`,
        };
      }
    }
    setAddressLookUpResponseOptions({ items: restructuredData });
  };

  async function getAddressLookUpOptions(typedValue: string) {
    const apiLocation =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000'
        : `https://api.${window.location.host}`;

    const addressLookUpUrl = `${apiLocation}/api/postcodesearch/`;
    async function fetchOptions() {
      const res = await fetch(addressLookUpUrl + typedValue);
      if (res.ok) {
        res
          .json()
          .then(data => {
            setPostcodeInfoValue('');
            data && populateDropdown(data);
          })
          .catch(err => {
            setPostcodeInfoValue(
              `Address lookup not available at the moment, please fill in the address manually.`,
            );
            // eslint-disable-next-line no-console
            console.log(err);
          });
      } else {
        if (res.status === 404) {
          setPostcodeInfoValue(`No matching postcodes found for ${typedValue}`);
        } else {
          setPostcodeInfoValue(
            `Address lookup not available at the moment, please fill in the address manually.`,
          );
        }
        // eslint-disable-next-line no-console
        console.log(`Unable to process Postcode, responce: ${res}`);
      }
    }
    fetchOptions();
  }

  const setAddressContext = (labelSelected: string) => {
    addressLookUpData &&
      addressLookUpData.forEach(address => {
        // find the value selected by the user and populate the context
        if (labelSelected === `${address.line_1}, ${address.post_town}`) {
          copyTo.forEach(([from, to]) =>
            form?.change(to, (address as AnyObject)[from]),
          );
        }
      });
  };

  // Force a rerender if the value changes
  useEffect(() => {
    setAddressLookUpResponseOptions(addressLookUpResponseOptions);
  }, [addressLookUpResponseOptions, addressLookUpResponseOptions.items]);

  return (
    <SWrapper>
      <Field name={name} initialValue={selectedValue}>
        {({ input, meta }) => {
          return (
            <Select
              {...input}
              {...meta}
              id="addresslookup"
              label={label}
              placeholder={selectedValue || placeholder}
              isSearchable
              onInputChange={(val: string) => {
                val.length >= 6 &&
                  val.length <= 9 &&
                  getAddressLookUpOptions(val);
              }}
              onChange={(ev: { label: string }) => {
                setAddressContext(ev.label);
                setSelectedValue(ev.label);
              }}
              options={
                addressLookUpResponseOptions &&
                addressLookUpResponseOptions.items
              }
            />
          );
        }}
      </Field>
      {postcodeInfoValue !== '' && (
        <Info smallerText icon="INFOERROR">
          {postcodeInfoValue}
        </Info>
      )}
    </SWrapper>
  );
};

export { AddressLookUpInput };
