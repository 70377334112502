import React from 'react';
import { Field } from 'react-final-form';
import PhoneInput, { Country } from 'react-phone-number-input';
import { validate } from '@utils';
import { FormValuesTypes } from 'src/types/formValues';
import { Icon, Label } from '@local';
import flags from 'react-phone-number-input/flags';
import { parsePhoneNumber } from '../../helpers/PhoneNumber/Parse';
import { SWrapper, SRightIconWrapper } from './index.styled';
import { ErrorLabel } from '../ErrorLabel';
import { CountryCode } from 'libphonenumber-js';

interface PhonenumberProps {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  validations?: string[];
  values?: FormValuesTypes;
  defaultCountry?: Country;
  countries?: CountryCode[];
}

const Phonenumber = ({
  name,
  label,
  disabled,
  placeholder,
  validations,
  values,
  defaultCountry,
  countries,
}: PhonenumberProps) => {
  return (
    <Field name={name} validate={validate('Phone number', validations)}>
      {({ input, meta }) => {
        return (
          <>
            <Label {...{ name, label, disabled }} />
            <SWrapper
              error={meta.error}
              touched={meta.touched}
              active={meta.active}>
              <PhoneInput
                flags={flags}
                id="P1"
                placeholder={placeholder}
                defaultCountry={defaultCountry ?? 'GB'}
                countries={countries ?? undefined}
                onKeyUp={() => parsePhoneNumber(name, values)}
                {...input}
                value={input.value === null ? "undefined" : input.value}
                data-testid={name}
              />
              {meta.error && meta.touched && !meta.active && (
                <SRightIconWrapper>
                  <Icon icon="WARNING" />
                </SRightIconWrapper>
              )}
            </SWrapper>
            <ErrorLabel>
              {meta.error && meta.touched && !meta.active && meta.error}
            </ErrorLabel>
          </>
        );
      }}
    </Field>
  );
};

export { Phonenumber };
