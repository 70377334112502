/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Form as FinalForm } from 'react-final-form';
import { TransitionGroup } from 'react-transition-group';
import { AnyObject } from 'final-form';
import ObjectPath from 'object-path';
import { captureException } from '@sentry/nextjs';
import type { FormValuesTypes } from 'src/types/formValues';
import { TargetMarket, EnvironmentUrl } from '../../types/formValues';
import type { FieldTypes } from 'src/types/field';
import type { ConditionalStepProps } from 'src/types/context';

import {
  FieldSet,
  ApplicationConsumer,
  CompaniesHouseConsumer,
  TerminalConsumer,
  FeesConsumer,
  StepAnimation,
  AuthenticationModal,
} from '@local';

import { ComponentMap } from '../../helpers/componentMapper';
import { AugmentComponent } from '../../helpers/augmentComponent';
import { sendApplication } from '../../helpers/formFunctions/sendApplication';
import listOfTerminals from '../../public/static/content/terminals';
import { AuthenticationConsumer } from '../Context/AuthenticationConsumer';
import { signDocumentWithMobileID, signDocumentWithMobileIDStatus } from '../../helpers/formFunctions/signDocumentWithMobileID';
import { Context } from 'vm';
import { DokobitError } from 'src/types/dokobit';
import { getErrorByDokobitErrorCode, TestUser } from '../../helpers/dokobit';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const applicationID = require('uuid/v1');

interface Location {
  pageIndex: number;
  stepIndex: number;
}

const Application = ({ allinitialData }: AnyObject) => {
  const {
    application,
    step,
    setStep,
    pageNumber,
    setPageNumber,
    pagesFilledIn,
    setPagesFilledIn,
    direction,
    setDirection,
    currentStepSetup,
    setCurrentStepSetup,
    guid,
    setGuid,
    setUploadMessage,
    agent,
    country,
    setApplicationErrorMessage,
  } = useContext(ApplicationConsumer);

  const { setTerminals } = useContext(TerminalConsumer);
  const formElement = useRef<HTMLFormElement>(null);
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);
  const { feeLine, setFeesLoadedSuccessfully } = useContext(FeesConsumer);
  const { isAuthenticated, setControlCode, unsignedContract } = useContext(AuthenticationConsumer);

  const [applicationError, setApplicationError] = useState(false);
  const [applicationLoading, setApplicationLoading] = useState(false);
  const [sessionStorageData, setsessionStorageData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const router = useRouter();

  const { conditionalNextSteps, conditionalPreviousSteps } = currentStepSetup;

  // TODO this is a fix for now, needs to be refactored
  if (
    application.pageTitle !== 'Sole Trader' &&
    step === 0 &&
    pageNumber === 0
  ) {
    setCurrentStepSetup(application.pages[0].steps[0]);
  }

  const getConditionalIndex = (
    originalIndex: number,
    list: AnyObject,
    skipToId?: string,
  ) => {
    const newIndex = list.findIndex((item: AnyObject) => item.id === skipToId);
    return newIndex > -1 ? newIndex : originalIndex;
  };

  const getTemplateNextStep = (
    location: Location,
    agents: string,
    templateProperty: string,
  ) => {
    let newLocation = location;
    let templates = ObjectPath.get(
      application.pages[newLocation.pageIndex].steps[newLocation.stepIndex],
      templateProperty,
    );
    while (templates && !templates.includes(agents)) {
      if (
        application.pages[newLocation.pageIndex].steps.length <=
        newLocation.stepIndex + 1
      ) {
        newLocation = {
          pageIndex: newLocation.pageIndex + 1,
          stepIndex: 0,
        };
      } else {
        newLocation = {
          pageIndex: newLocation.pageIndex,
          stepIndex: newLocation.stepIndex + 1,
        };
      }
      // eslint-disable-next-line no-param-reassign
      templates = ObjectPath.get(
        application.pages[newLocation.pageIndex].steps[newLocation.stepIndex],
        templateProperty,
      );
    }
    return newLocation;
  };

  const getTemplateBackStep = (location: Location, agents: string) => {
    let templateProp =
      application.pages[location.pageIndex].steps[location.stepIndex]
        .showOnTemplates;

    let templates = ObjectPath.get(
      application.pages[location.pageIndex].steps[location.stepIndex],
      (templateProp as unknown) as string,
    );

    let newLocation = location;

    while (templateProp && !templates.includes(agents)) {
      if (newLocation.pageIndex === 0 && newLocation.stepIndex === 0) break;
      if (newLocation.stepIndex <= 0) {
        newLocation = {
          pageIndex: newLocation.pageIndex - 1,
          stepIndex: application.pages[newLocation.pageIndex].steps.length - 1,
        };
      } else {
        newLocation = {
          pageIndex: newLocation.pageIndex,
          stepIndex: newLocation.stepIndex - 1,
        };
      }

      templateProp =
        application.pages[newLocation.pageIndex].steps[newLocation.stepIndex]
          .showOnTemplates;

      templates = ObjectPath.get(
        application.pages[newLocation.pageIndex].steps[newLocation.stepIndex],
        (templateProp as unknown) as string,
      );
    }
    return newLocation;
  };

  const getConditionalTemplate = (
    location: Location,

    templates: string[],

    agents: string,

    nextStep: boolean,

    templateProperty: string,
  ) => {
    // Template and agent matches, okay to show step.

    if (templates.indexOf(agents) > -1) {
      // Returns the original value

      return { ...location };
    }

    // User going to next step
    if (nextStep) {
      return getTemplateNextStep(location, agents, templateProperty);
    }

    // User is going back, go to previous page if it's the first step
    return getTemplateBackStep(location, agents);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isObject = (obj: any) => {
    return obj != null && obj.constructor.name === "Object";
  }

  const getConditionalLocation = (
    location: Location,
    values: AnyObject,
    conditionalSteps: ConditionalStepProps[] = [],
  ) => {
    const { pageIndex, stepIndex } = location;
    // Finds the relevant conditional[Next/Previous]Step defined in the template. Compares the required value for each conditional
    // step, and returns a destructured object if found. Default value is an empty object, thus destructured values undefined
    const { id, skipToPage, skipToStep } =
      // TODO: Casting to string allows both values[id] as well as value to be undefined. This is currently intended but should be changed.
      // Example: Faster payments checkbox not checked is an undefined value. Empty values should instead be empty arrays or objects by default.
      conditionalSteps.find(
        ({
          id: conditionalStepId,
          value,
          optionalGreaterThen,
          optionalLessThen,
        }: ConditionalStepProps) => {
          if (optionalGreaterThen) {
            return ObjectPath.get(values, conditionalStepId) > optionalGreaterThen;
          }
          if (optionalLessThen) {
            return ObjectPath.get(values, conditionalStepId) < optionalLessThen;
          }
          // Checks if value is an object (e.g. when using a value from Select component, then the value is: {value: "value", label: "Value"})
          if (isObject(values[conditionalStepId])) {
            return value.includes(values[conditionalStepId].value);
          }
          if (Array.isArray(value) && value.length > 0) {
            return value.includes(String(values[conditionalStepId])) || value === values[conditionalStepId];
          }
          // Case for nested object id's i.e. Ecommerce.EcommerceSolutions.EcommerceCMS
          if (conditionalStepId.includes('.')) {
            return ObjectPath.get(values, conditionalStepId) === String(value);
          }

          return String(values[conditionalStepId]) === String(value);
        },
      ) || {};

    // Check if a step or page should be skipped and if the value is valid
    if (id) {
      const newPage = getConditionalIndex(
        pageIndex,
        application.pages,
        skipToPage,
      );
      const newStep = getConditionalIndex(
        stepIndex,
        application.pages[newPage].steps,
        skipToStep,
      );
      // Returns a new object with the conditional values
      return { pageIndex: newPage, stepIndex: newStep };
    }
    // Returns original values since the conditional is invalid
    return { ...location };
  };

  const next = (values: AnyObject) => {
    if (values.PaymentSolutions && !values.PaymentSolutions.includes('ecommerce')) {
      delete values.Ecommerce;
    }
    if (values.PaymentSolutions && !values.PaymentSolutions.includes('cardPresent')) {
      delete values.CardPresent;
    }

    const isOnLastStep =
      application.pages[pageNumber].steps.length - 1 === step;
    const nextPage = isOnLastStep ? pageNumber + 1 : pageNumber;

    const newLocation = {
      pageIndex: nextPage,
      stepIndex: isOnLastStep ? 0 : step + 1,
    };

    const conditionalLocation = conditionalNextSteps
      ? getConditionalLocation(newLocation, values, conditionalNextSteps)
      : newLocation;

    const templateProps = (application.pages[conditionalLocation.pageIndex]
      .steps[conditionalLocation.stepIndex]
      .showOnTemplates as unknown) as string;

    // NEed to find a way to get both show on templates and show on countries dynamically to getConditionalTEmplate
    const routerProps = templateProps === 'showOnAgents' ? agent : country;
    const { pageIndex, stepIndex } = templateProps
      ? getConditionalTemplate(
          conditionalLocation,
          ObjectPath.get(
            application.pages[conditionalLocation.pageIndex].steps[
              conditionalLocation.stepIndex
            ],
            templateProps,
          ),
          routerProps,
          true,
          templateProps,
        )
      : conditionalLocation;

    setCurrentStepSetup(application.pages[pageIndex].steps[stepIndex]);
    setStep(stepIndex);

    if (pageNumber !== pageIndex) {
      setPageNumber(pageIndex);
      // To keep track how far the user has gone for the progress bar
      if (pageIndex > pagesFilledIn) {
        setPagesFilledIn(pageIndex);
      }
      setDirection('left');
    } else {
      setDirection('up');
    }

    // Pull focus to the fieldset again
    if (formElement && formElement.current) {
      formElement.current.focus();
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const goBack = (ev: React.SyntheticEvent, values: AnyObject) => {
    // We prevent default because otherwise React-Final-Form will validate the values
    ev.preventDefault();
    setApplicationError(false);
    setFeesLoadedSuccessfully(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const newLocation = {
      pageIndex: step === 0 ? pageNumber - 1 : pageNumber,
      stepIndex:
        step === 0
          ? application.pages[pageNumber - 1].steps.length - 1
          : step - 1,
    };

    const conditionalLocation = conditionalPreviousSteps
      ? getConditionalLocation(newLocation, values, conditionalPreviousSteps)
      : newLocation;
    const templateProps = (application.pages[conditionalLocation.pageIndex]
      .steps[conditionalLocation.stepIndex]
      .showOnTemplates as unknown) as string;

    const routerProps = templateProps === 'showOnAgents' ? agent : country;

    const { pageIndex, stepIndex } = templateProps
      ? getConditionalTemplate(
          conditionalLocation,
          ObjectPath.get(
            application.pages[conditionalLocation.pageIndex].steps[
              conditionalLocation.stepIndex
            ],
            templateProps,
          ),
          routerProps,
          false,
          templateProps,
        )
      : conditionalLocation;

    setStep(stepIndex);
    setCurrentStepSetup(application.pages[pageIndex].steps[stepIndex]);

    if (step === 0) {
      setPageNumber(pageIndex);
      setDirection('right');
    } else {
      setDirection('down');
    }

    // Pull focus to the fieldset again
    if (formElement && formElement.current) {
      formElement.current.focus();
    }
  };

  const resetApplication = (targetMarket: TargetMarket) => {
    if (targetMarket === TargetMarket.CrossBorder) {
      router.push({ pathname: '/thankyou' });
    } else {
      router.push({ pathname: '/takkfyrir' });
    }
    setApplicationLoading(false);

    /*
  If the internet connection is slow, the form gets reset and then you move on to the last screen.
  Here we are waiting a couple of seconds before we reset the form.
  */
    setTimeout(() => {
      setTerminals({
        value: 0,
        // TODO: make this more dynamic, rather than hard coded values
        terminals: listOfTerminals,
      });

      setStep(0);
      setCurrentStepSetup(application.pages[0].steps[0]);
      setPageNumber(0);
      setPagesFilledIn(0);
      setGuid(applicationID);
    }, 2000);
  };

  const submitHandler = async (values: FormValuesTypes) => {

    setApplicationError(false);
    setApplicationLoading(true);
    setApplicationErrorMessage('');

    const targetMarket: TargetMarket = router.asPath.includes('umsokn') ? TargetMarket.Iceland : TargetMarket.CrossBorder;

    try {
      const response = await sendApplication(
        values,
        application.applicationType,
        guid,
        companiesHouseValues,
        feeLine,
        setUploadMessage,
        targetMarket
      );

      if (response.ok) {
        resetApplication(targetMarket);
      } else {
        setUploadMessage(
          `${response.statusText}${
            response.parsedBody ? ` - ${response.parsedBody.message}` : ''
          }`,
        );
        // Log error to sentry
        captureException(response, scope => {
          scope.setContext("values", null);
          scope.setContext("values", values as Context);
          return scope;
        });
        setApplicationError(true);
        setApplicationLoading(false);
        setApplicationErrorMessage(
          targetMarket === TargetMarket.Iceland
            ? 'Villa kom upp'
            : response.statusText
        );
        // Navigate to the 500 error page after 1 second so there's time to display the upload message before navigating
        setTimeout(() => {
          router.push({
            pathname: '/500',
          });
        }, 1000);
      }
    } catch (error) {
      // Log error to sentry
      captureException(error, scope => {
        scope.setContext("values", null);
        scope.setContext("values", values as Context);
        return scope;
      });
      // eslint-disable-next-line no-console
      process.env.NODE_ENV === 'development' ?? console.log('error: ', error);
      setApplicationError(true);
      setApplicationLoading(false);
      setApplicationErrorMessage(
        targetMarket === TargetMarket.Iceland
          ? 'Villur fundust í umsókn þinni, vinsamlegast farðu vel yfir umsóknina og reyndu aftur.'
          : 'There are some incorrect or missing inputs in your application, please go carefully over the application and try again.'
      );
    }
  };

  const signContract = async (values: AnyObject) => {
    setApplicationError(false);
    setApplicationErrorMessage("");
    try {
      if (!values) {
        return;
      }

      setModalIsOpen(true);

      const { data } = await signDocumentWithMobileID(
        values.PhoneNumber,
        values.FirstName,
        `${values.MiddleName} ${values.LastName}`,
        unsignedContract,
      );

      setControlCode(data.control_code);

      const { data: loginStatusData, status: loginStatusStatus } = await signDocumentWithMobileIDStatus(data.token);

      if (loginStatusStatus === 200) {
        // Add the signed contract to values
        Object.assign(values, {SignedContract: loginStatusData.file.content})
        await submitHandler(({
          ...values,
        } as any) as FormValuesTypes);
      }

    } catch (err) {
      // Log error to sentry
      captureException(err, scope => {
        scope.setContext("values", null);
        scope.setContext("values", values as Context);
        return scope;
      });

      const dokobitError = err as DokobitError;
      const { error_code } = dokobitError;

      setApplicationError(true);
      setApplicationErrorMessage(`Undirritun tókst ekki ${getErrorByDokobitErrorCode(error_code)}`);
    }
    setModalIsOpen(false);
    setControlCode("");
  }

  // We have AnyObject because react final form expect values to be array of strings
  // So we allow it here but only use our FormValuesTypes
  // eslint-disable-next-line consistent-return
  const handleFormSubmit = async (values: AnyObject) => {
    const lastPage = application.pages.length - 1;
    const lastStep = application.pages[lastPage].steps.length - 1;

    const targetMarket: TargetMarket = router.asPath.includes('umsokn') ? TargetMarket.Iceland : TargetMarket.CrossBorder;

    // Save user data into session storage
    const duplicateValues = Object.assign({}, values);

    // We have to remove image value (File) from session storage since it's a file
    // eslint-disable-next-line no-restricted-syntax
    for (const key in duplicateValues) {
      if ((duplicateValues[key] as any) instanceof File) {
        delete duplicateValues[key];
      }
    }

    // For each step we SAVE values into the sessionstorage
    sessionStorage.setItem('data', JSON.stringify(duplicateValues));
    // Check if we have a stored guid, if not create new one
    const restoreGuid = sessionStorage.getItem('guid');
    if (restoreGuid === null) {
      const applicationGuid = applicationID();
      sessionStorage.setItem('guid', JSON.stringify(applicationGuid));
      setGuid(applicationGuid);
    }

    if (currentStepSetup === application.pages[lastPage].steps[lastStep]) {
      if (targetMarket == TargetMarket.Iceland) {
        if (typeof window !== "undefined") {
          if (
            (window.location.href.includes(EnvironmentUrl.DEV) ||
            window.location.href.includes(EnvironmentUrl.TEST) ||
            window.location.href.includes(EnvironmentUrl.UAT) ||
            process.env.NODE_ENV === "development" ||
            process.env.NODE_ENV === "test") &&
            values.RafraenSkilriki === TestUser.phoneNumber
          ) {
            await submitHandler(({
              ...values,
            } as any) as FormValuesTypes);
            return;
          }
        }
        signContract(values);
        return;
      }
      await submitHandler(({
        ...values,
      } as any) as FormValuesTypes);
    } else {
      return next(values);
    }
  };

  // If show on templates is on very first page of application
  if (
    step === 0 &&
    pageNumber === 0 &&
    application.pages[pageNumber].steps[step].showOnTemplates
  ) {
    const templatesProp = (application.pages[pageNumber].steps[step]
      .showOnTemplates as unknown) as string;

    const templates = ObjectPath.get(
      application.pages[pageNumber].steps[step],
      templatesProp,
    );
    const routingProps = templatesProp === 'showOnAgents' ? agent : country;
    const newLocation = getConditionalTemplate(
      { stepIndex: step, pageIndex: pageNumber },
      templates as string[],
      routingProps,
      true,
      templatesProp,
    );
    setCurrentStepSetup(
      application.pages[newLocation.pageIndex].steps[newLocation.stepIndex],
    );
  }

  useEffect(() => {
    if (step === 0 && pageNumber === 0 && isAuthenticated) {
      setStep(1);
      setCurrentStepSetup(
        application.pages[0].steps[1]
      )
    }
  }, [step, pageNumber, isAuthenticated])
  // GET DATA FROM SESSION STORAGE IF IT EXISTS
  // If browser is refreshed, get all data for the application from session storage
  if (typeof window !== 'undefined') {
    if (sessionStorageData === null) {
      const restoreData = sessionStorage.getItem('data');
      if (restoreData !== null) {
        setsessionStorageData(JSON.parse(restoreData));
      }
    }
    const restoreGuid = sessionStorage.getItem('guid');
    if (restoreGuid !== null) {
      setGuid(restoreGuid.replace(/"/g, ''));
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-5 col-md-5 col-lg-7 offset-md-1 offset-lg-4">
          <FinalForm
            onSubmit={handleFormSubmit}
            initialValues={sessionStorageData || allinitialData}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              }
            }}
            render={({ form, handleSubmit, values, errors }) => (
              <form
                tabIndex={-1}
                ref={formElement}
                name="application-form"
                aria-live="polite"
                // handleSubmit needs to be called to allow final form to touch every field
                autoComplete="off"
                onSubmit={(event) => {
                  // Prevent default form submit
                  event.preventDefault()

                  // Check if any errors are present
                  if(errors && Object.keys(errors).length > 0) {

                    const firstError = Object.keys(errors)[0];

                    let targetElement;

                    // setTimeOut is needed here for now. DO NOT DELETE IT. For more information see https://jira.iteron.org/browse/DYNAPPL-1074
                    setTimeout(() => {
                      if (firstError === "terminalList") {
                        // terminalList doesn't have a name attribute we can hook on to, so we need to select it in a different way.
                        targetElement = document.getElementsByClassName('error-label')[0];

                      } else {
                        // Get first error element
                        targetElement = document.querySelector(`[name*=${firstError}]`);
                      }

                      // Null check
                      if (targetElement) {

                        // Get position of element on page
                        const elementPosition = targetElement.getBoundingClientRect();

                        window.scrollBy({ top: elementPosition.top - 200, behavior: 'smooth' });
                      } else {
                        console.error(`Cannot find element with name ${firstError}`)
                      }
                    }, 0)

                  }

                  handleSubmit(event)
                }}>
                <TransitionGroup
                  className={`transition-group-wrapper-${direction}`}>
                  <StepAnimation
                    key={step}
                    timeout={300}
                    classNames="step-animation"
                    mountOnEnter
                    unmountOnExit>
                    <FieldSet
                      step={step}
                      page={pageNumber}
                      onGoBack={(ev: React.SyntheticEvent) =>
                        goBack(ev, values)
                      }
                      showBackButton={currentStepSetup.showBackButton}
                      information={currentStepSetup.information}
                      title={currentStepSetup.title as string}
                      error={applicationError}
                      nextButtonText={currentStepSetup.nextButtonText}
                      previousButtonText={currentStepSetup.previousButtonText}
                      loading={applicationLoading}
                      values={values as FormValuesTypes}>
                      {// TODO: Set a default value for string values such as name, as '' rather than undefined
                      currentStepSetup &&
                        currentStepSetup.fields &&
                        currentStepSetup.fields.map(
                          (templateField: FieldTypes, index: number) => (
                            <AugmentComponent
                              values={values}
                              field={templateField}
                              form={form}
                              agent={agent}
                              key={index}
                              render={(field: FieldTypes) => (
                                <ComponentMap
                                  values={values}
                                  field={field}
                                  form={form}
                                />
                              )}
                            />
                          ),
                        )}
                    </FieldSet>
                  </StepAnimation>
                </TransitionGroup>
              </form>
            )}
          />
        </div>
      </div>
      <AuthenticationModal
        isOpen={modalIsOpen}
        headline='Undirritunarbeiðni hefur verið send í símann þinn'
        secondText='Undirritaðu aðeins ef sama öryggistala birtist í símanum.'
      />
    </>
  );
};

export { Application };
