import {
  greidslulausnir,
  adurTekidVidKortum,
  posarFyrirtaeki,
  greidslusiduupplysingar,
  verslun,
  kortaTegundir,
  faerslusvid,
  bankaupplysingar,
  samningurTilUndirritunar,
  fyrirtaekjaupplysingar,
  hafaSamband,
  einstaklingarFyrirtaekis
} from '../sharedLogic';

/**
 * TODO: Add ability to control field layout in this JSON.
 * I'd suggest something like https://jsonforms.io/
 */

/*
 *TODO: list
 * Phone number validation
 * Unify all components so that the form decides all parameters, e.g. label, placeholder
 */

export default {
  pageTitle: 'Fyrirtæki og önnur félög',
  applicationType: 'SoleTraderIS',  // Change to company
  pages: [
    // Page identity
    {
      icon: 'IDENTITY',
      title: 'Fyrirtækjaupplýsingar',
      id: 'identity',
      showOnReviewPage: true,
      steps: [
        // Step personuupplysingar
        {
          ...fyrirtaekjaupplysingar,
        },
        {
          ...hafaSamband,
        },
        {
          ...einstaklingarFyrirtaekis,
        },
      ],
    },
    // Page store
    {
      icon: 'STORECOLOR',
      title: 'Greiðslulausnir',
      id: 'store',
      showOnReviewPage: true,
      steps: [
        // Step 00 - paymentCards
        {
          ...greidslulausnir,
        },
        // Step uploadAcquirerStatement
        {
          ...posarFyrirtaeki,
        },
        {
          ...verslun,
        },
        {
          ...greidslusiduupplysingar,
        },
        {
          ...kortaTegundir,
        },
        // Step fasterPayments
        {
          ...adurTekidVidKortum,
        },
        {
          ...faerslusvid,
        },
      ],
    },
    // Page finance
    {
      icon: 'TRIBUNALICON',
      title: 'Bankaupplýsingar',
      id: 'finance',
      showOnReviewPage: true,
      steps: [
        // Step listOfFees
        {
          ...bankaupplysingar,
        },
      ],
    },
    // Page review
    {
      icon: 'DOCUMENTSAVEDICON',
      title: 'Review',
      id: 'review',
      nextButtonText: 'Áfram',
      previousButtonText: 'Til baka',
      showOnReviewPage: false,
      steps: [
        // Step applicationReview
        {
          id: 'applicationReview',
          nextButtonText: 'Áfram',
          previousButtonText: 'Til baka',
          fields: [
            {
              componentType: 'TITLE',
              text: 'Umsóknin þín',
              className: 'size-md-medium no-margin-top',
            },
            {
              componentType: 'PARAGRAPH',
              children:
                'Hér getur þú farið yfir umsóknina þína og staðfest að upplýsingar sé réttar. Þú getur farið til baka og breytt upplýsingum ef þörf er á.',
            },
            {
              componentType: 'REVIEW',
            },
          ],
        },
        {
          ...samningurTilUndirritunar
        }
      ],
    },
  ],
};
