import React from 'react';

export const CheckMarkIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 -6 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3158 0L5.47368 8.42105L1.68421 4.63158L0 6.31579L5.47368 11.7895L16 1.68421L14.3158 0Z"
      fill="#E95420"
    />
  </svg>
);

export default CheckMarkIcon;
