/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent, useContext, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ApplicationConsumer, Icon } from '@local';
import { SHeader } from './index.styled';
import FormsRouting from '../../public/static/content/formsRouting';

interface HeaderProps {
  displayHeaderTitle: boolean;
}

const getCorrectForm = (path: string, setup: string) => {
  if (path.includes('abs')) {
    const absSetup = path.replace(/\?.*/, '').replace(/\//g, '');
    return FormsRouting[absSetup] || FormsRouting.abssoletrader;
  }
  return FormsRouting[setup] || FormsRouting.abssoletrader;
};

const Header: FunctionComponent<HeaderProps> = ({ displayHeaderTitle }) => {
  const router = useRouter();

  const { application, setApplication, agent } = useContext(
    ApplicationConsumer,
  );

  useEffect(() => {
    const setup = router.query.id as string;

    const selectedForm = setup
      ? getCorrectForm(router.asPath, setup)
      : FormsRouting.soletrader;

    setApplication(selectedForm);
  });

  let title = "";

  if(router.query.id === "einstaklingur" || router.query.id === "fyrirtaeki") {
    title = application.pageTitle.toUpperCase();
  } else if (router.asPath === "/umsokn/") {
    title = ""
  } else {
    title = `${agent.toUpperCase()} / ${application.pageTitle.toUpperCase()}`;
  }

  return (
    <SHeader className="full-bleed">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <Link href={router.asPath.includes('umsokn') ? '/umsokn' : '/'} >
        <a data-testid="rapydLogo">
          <Icon icon="RAPYDLOGO" />
          <span className="sr-only">Go to home page</span>
        </a>
      </Link>
      {/* eslint-disable */}
      {displayHeaderTitle && <h1 data-testid="header">{title}</h1>}
    </SHeader>
  );
};

export { Header };
