const ISL_MCCCODES = [
  { 'label': 'Dýralæknar', 'value': 742 },
  { 'label': 'Samvinnufélög í landbúnaði', 'value': 763 },
  { 'label': 'Landslagshönnun', 'value': 780 },
  { 'label': 'Byggingaverktakar', 'value': 1520 },
  { 'label': 'Pípulagningamaður', 'value': 1711 },
  { 'label': 'Rafverktakar', 'value': 1731 },
  { 'label': 'Múrverktakar og steinsmiðir', 'value': 1740 },
  { 'label': 'Trésmíðaverktakar', 'value': 1750 },
  { 'label': 'Þak - og húsklæðningar', 'value': 1761 },
  { 'label': 'Steypuverktakar', 'value': 1771 },
  { 'label': 'Iðnverktakar', 'value': 1799 },
  { 'label': 'Útgáfu- og prentþjónusta', 'value': 2741 },
  { 'label': 'Setjarar, myndmótagerð', 'value': 2791 },
  { 'label': 'Hreingerningarvörur', 'value': 2842 },
  { 'label': 'Járnbrautir', 'value': 4011 },
  { 'label': 'Farþegaflutningar og farþegaferjur', 'value': 4111 },
  { 'label': 'Farþegalestarfélög', 'value': 4112 },
  { 'label': 'Leigubifreiðir og leiga glæsibifreiða', 'value': 4121 },
  { 'label': 'Strætisvagnar', 'value': 4131 },
  { 'label': 'Vöruflutningar og geymsla', 'value': 4214 },
  { 'label': 'Hraðsendingarþjónusta', 'value': 4215 },
  { 'label': 'Opinberar vörugeymslur', 'value': 4225 },
  { 'label': 'Skipafélög', 'value': 4411 },
  { 'label': 'Bátaleigur', 'value': 4457 },
  { 'label': 'Smábátahafnir, bátaþjónusta', 'value': 4468 },
  { 'label': 'Farþegaflug og fraktflug', 'value': 4511 },
  { 'label': 'Flugvellir og flughafnir', 'value': 4582 },
  { 'label': 'Ferðaskrifstofur og ferðaþjónustur', 'value': 4722 },
  { 'label': 'Vegatollar og brúargjöld', 'value': 4784 },
  { 'label': 'Flutningafyrirtæki', 'value': 4789 },
  { 'label': 'Fjarskiptabúnaður og símtæki', 'value': 4812 },
  { 'label': 'Fjarskiptaþjónusta', 'value': 4814 },
  { 'label': 'Tölvu netkerfi/upplýsingaþjónusta', 'value': 4816 },
  { 'label': 'Ritsímaþjónusta', 'value': 4821 },
  { 'label': 'Símsending peninga', 'value': 4829 },
  { 'label': 'Kapalsjónvarp', 'value': 4899 },
  { 'label': 'Veitufyrirtæki - rafmagns, gas, vatns', 'value': 4900 },
  { 'label': 'Bifreiðavörur og nýir varahlutir', 'value': 5013 },
  { 'label': 'Skrifstofuhúsgögn', 'value': 5021 },
  { 'label': 'Byggingavörur', 'value': 5039 },
  { 'label': 'Ljósmynda-, myndrita- og örfilmubúnaður', 'value': 5044 },
  { 'label': 'Tölvur og aukabúnaður', 'value': 5045 },
  { 'label': 'Rekstrarbúnaður', 'value': 5046 },
  { 'label': 'Tækjabúnaður fyrir lækna-, rannsóknastofu', 'value': 5047 },
  { 'label': 'Málmsmíðaverkstæði', 'value': 5051 },
  { 'label': 'Rafknúnir hlutir og raftæki', 'value': 5065 },
  { 'label': 'Málmtæki og birgðavörur', 'value': 5072 },
  { 'label': 'Pípulagningavöruverslun', 'value': 5074 },
  { 'label': 'Iðnaðarvörur', 'value': 5085 },
  { 'label': 'Eðalsteinar og góðmálmar', 'value': 5094 },
  { 'label': 'Varanlegar vörur', 'value': 5099 },
  { 'label': 'Ritfangaverslun', 'value': 5111 },
  { 'label': 'Lyfsöluvörur', 'value': 5122 },
  { 'label': 'Smávöruverslun', 'value': 5131 },
  { 'label': 'Einkennisfatnaður', 'value': 5137 },
  { 'label': 'Skóverksmiðja', 'value': 5139 },
  { 'label': 'Eiturefni og skyldar afurðir', 'value': 5169 },
  { 'label': 'Jarðolía og efni unnin úr jarðolíu', 'value': 5172 },
  { 'label': 'Bækur, tímarit og fréttablöð', 'value': 5192 },
  { 'label': 'Garðyrkjustöð', 'value': 5193 },
  { 'label': 'Málningavörur og lökk', 'value': 5198 },
  { 'label': 'Neytendavörur', 'value': 5199 },
  { 'label': 'Vöruhúsaverslanir fyrir heimilisvörur', 'value': 5200 },
  { 'label': 'Timbur- og byggingavöruverslanir', 'value': 5211 },
  { 'label': 'Gler-, málningar- og veggfóðurverslanir', 'value': 5231 },
  { 'label': 'Járnvöruverslanir', 'value': 5251 },
  { 'label': 'Plöntusala', 'value': 5261 },
  { 'label': 'Hjólhýsi', 'value': 5271 },
  { 'label': 'Innkaupafélög', 'value': 5300 },
  { 'label': 'Tollfrjálsar verslanir', 'value': 5309 },
  { 'label': 'Afsláttarverslanir', 'value': 5310 },
  { 'label': 'Stórverslanir', 'value': 5311 },
  { 'label': 'Vörumarkaðir', 'value': 5331 },
  { 'label': 'Ýmsar almennar vörur', 'value': 5399 },
  { 'label': 'Matvöruverslanir og kjörbúðir', 'value': 5411 },
  { 'label': 'Frystigeymsla', 'value': 5422 },
  { 'label': 'Sælgætis-, hnetu- og sætindaverslanir', 'value': 5441 },
  { 'label': 'Verslanir með mjólkurafurðir', 'value': 5451 },
  { 'label': 'Bakarí', 'value': 5462 },
  { 'label': 'Matvöruverslanir - sérvörumarkaðir', 'value': 5499 },
  { 'label': 'Bifreiðaumboð og varahlutir', 'value': 5511 },
  { 'label': 'Notaðir bílar og varahlutir', 'value': 5521 },
  { 'label': 'Hjólbarðaverslanir', 'value': 5532 },
  { 'label': 'Bifreiðavarahlutaverslun', 'value': 5533 },
  { 'label': 'Bensínstöðvar', 'value': 5541 },
  { 'label': 'Eldsneytissjálfsalar', 'value': 5542 },
  { 'label': 'Bátasalar', 'value': 5551 },
  { 'label': 'Fellihýsa- og tjaldvagnasala', 'value': 5561 },
  { 'label': 'Mótorhjólaverslun', 'value': 5571 },
  { 'label': 'Húsbílasalar', 'value': 5592 },
  { 'label': 'Snjóbíla- og vélsleðasalar', 'value': 5598 },
  { 'label': 'Véltækja- og flugvélasalar', 'value': 5599 },
  { 'label': 'Herra- og fataverslanir', 'value': 5611 },
  { 'label': 'Kvenfataverslanir', 'value': 5621 },
  { 'label': 'Sérverslanir fyrir kvenfólk', 'value': 5631 },
  { 'label': 'Barnafataverslanir', 'value': 5641 },
  { 'label': 'Fataverslun', 'value': 5651 },
  { 'label': 'Hestavörur', 'value': 5655 },
  { 'label': 'Skóbúðir', 'value': 5661 },
  { 'label': 'Feldskerar og loðfeldaverslanir', 'value': 5681 },
  { 'label': 'Fataverslanir fyrir dömur og herra', 'value': 5691 },
  { 'label': 'Klæðskerar, saumakonur', 'value': 5697 },
  { 'label': 'Verslanir með hárkollur og hártoppa', 'value': 5698 },
  { 'label': 'Verslanir með klæðnað og fylgihluti', 'value': 5699 },
  { 'label': 'Húsgögn og innréttingar', 'value': 5712 },
  { 'label': 'Gólfefnaverslanir', 'value': 5713 },
  { 'label': 'Gluggatjöld og áklæði', 'value': 5714 },
  { 'label': 'Verslanir með arna og fylgibúnað', 'value': 5718 },
  { 'label': 'Sérverslanir með heimilisinnréttingar', 'value': 5719 },
  { 'label': 'Heimilistækjaverslanir', 'value': 5722 },
  { 'label': 'Rafeindatækjaverslanir', 'value': 5732 },
  { 'label': 'Hljóðfæraverslun', 'value': 5733 },
  { 'label': 'Tölvu- og hugbúnaðarverslanir', 'value': 5734 },
  { 'label': 'Hljómplötuverslanir', 'value': 5735 },
  { 'label': 'Veitingaþjónusta', 'value': 5811 },
  { 'label': 'Matsölustaðir og veitingahús', 'value': 5812 },
  { 'label': 'Barir, krár, næturkl., kokkteilbarir og d', 'value': 5813 },
  { 'label': 'Skyndibitastaðir', 'value': 5814 },
  { 'label': 'Bækur, myndir, tónlist á stafrænu formi', 'value': 5815 },
  { 'label': 'Tölvuleikir á stafrænu formi', 'value': 5816 },
  { 'label': 'Hugbúnaður á stafrænu formi', 'value': 5817 },
  { 'label': 'Stafrænar vörur - multiflokkur', 'value': 5818 },
  { 'label': 'Lyfjaverslanir', 'value': 5912 },
  { 'label': 'Áfengisverslanir - bjór, vín', 'value': 5921 },
  { 'label': 'Notaður varningur', 'value': 5931 },
  { 'label': 'Fornmunaverslanir - sala, viðgerðir', 'value': 5932 },
  { 'label': 'Veðlánarar', 'value': 5933 },
  { 'label': 'Verslun með tjónabíla', 'value': 5935 },
  { 'label': 'Eftirmyndagerð fornmuna', 'value': 5937 },
  { 'label': 'Reiðhjólaverslanir', 'value': 5940 },
  { 'label': 'Íþróttavöruverslanir', 'value': 5941 },
  { 'label': 'Bókaverslanir', 'value': 5942 },
  { 'label': 'Skólavöruverslun', 'value': 5943 },
  { 'label': 'Skartgripa-, og  úraverslun', 'value': 5944 },
  { 'label': 'Leikfangaverslun', 'value': 5945 },
  { 'label': 'Ljósmyndavöruverslun', 'value': 5946 },
  { 'label': 'Gjafa-  og minjagripaverslanir', 'value': 5947 },
  { 'label': 'Ferðatösku- og leðurvöruverslanir', 'value': 5948 },
  { 'label': 'Vefnaðarvöruverslun', 'value': 5949 },
  { 'label': 'Glervöru- og kristallsvöruverslanir', 'value': 5950 },
  { 'label': 'Vátryggingarfélög', 'value': 5960 },
  { 'label': 'Markaðssetningarfyrirtæki -  ferðaskipule', 'value': 5962 },
  { 'label': 'Farandsalar', 'value': 5963 },
  { 'label': 'Markaðssetningarfyrirtæki - sala gegnum v', 'value': 5964 },
  { 'label': 'Markaðssetningarfyrirtæki, smásöluvörur', 'value': 5965 },
  { 'label': 'Markaðssetningarfyrirtæki - símsala', 'value': 5966 },
  { 'label': 'Markaðssetningarfyrirtæki - símainnkaup', 'value': 5967 },
  { 'label': 'Markaðssetningarfyrirtæki - framhaldssala', 'value': 5968 },
  { 'label': 'Markaðssetningarfyrirtæki, ekki flokkuð a', 'value': 5969 },
  { 'label': 'Vörur til listsköpunar og listiðnaðar', 'value': 5970 },
  { 'label': 'Listaverkasalar og sýningarsalir', 'value': 5971 },
  { 'label': 'Frímerkja- og myntsafnaraverslanir', 'value': 5972 },
  { 'label': 'Verslanir með trúrækilega muni', 'value': 5973 },
  { 'label': 'Hjálpartæki', 'value': 5975 },
  { 'label': 'Vörur til bæklunarskurðlækninga og gervil', 'value': 5976 },
  { 'label': 'Snyrtivöruverslanir', 'value': 5977 },
  { 'label': 'Ritvélaverslanir', 'value': 5978 },
  { 'label': 'Eldsneytissalar - eldsneytisolía', 'value': 5983 },
  { 'label': 'Blómaverslun', 'value': 5992 },
  { 'label': 'Vindlasalar og sölubásar', 'value': 5993 },
  { 'label': 'Blaðasalar og blaðsöluturnar', 'value': 5994 },
  { 'label': 'Gæludýraverslanir', 'value': 5995 },
  { 'label': 'Sundlaugar', 'value': 5996 },
  { 'label': 'Rafmagnsrakvélaverslanir', 'value': 5997 },
  { 'label': 'Verslanir með tjöld og sólskýli', 'value': 5998 },
  { 'label': 'Sérvöruverslanir', 'value': 5999 },
  { 'label': 'Fjármálastofnanir - reiðufé', 'value': 6010 },
  { 'label': 'Fjármálastofnanir - hraðbankar', 'value': 6011 },
  { 'label': 'Fjármálastofnanir - varningur og þjónusta', 'value': 6012 },
  { 'label': 'Gjaldeyrisviðskipti - ekki bankastofnun', 'value': 6051 },
  { 'label': 'Verðbréfasalar og -miðlarar', 'value': 6211 },
  { 'label': 'Vátryggingasala', 'value': 6300 },
  { 'label': 'Real Estate Agents & Managers - Rentals', 'value': 6513 },
  { 'label': 'Gistiþjónusta - hótel og mótel', 'value': 7011 },
  { 'label': 'Sumarleyfis-skiptiíbúðir', 'value': 7012 },
  { 'label': 'Íþrótta- og afþreyinganámskeið', 'value': 7032 },
  { 'label': 'Hjólhýsasvæði og tjaldsvæði', 'value': 7033 },
  { 'label': 'Þvottahús', 'value': 7210 },
  { 'label': 'Þvottahúsaþjónusta', 'value': 7211 },
  { 'label': 'Efnalaugar', 'value': 7216 },
  { 'label': 'Teppa- og húsgagnaáklæðahreinsun', 'value': 7217 },
  { 'label': 'Ljósmyndastofur', 'value': 7221 },
  { 'label': 'Snyrtistofur og rakarastofur', 'value': 7230 },
  { 'label': 'Skósmiðir', 'value': 7251 },
  { 'label': 'Útfararstofur og líkbrennslur', 'value': 7261 },
  { 'label': 'Stefnumóta- og fylgdarþjónusta', 'value': 7273 },
  { 'label': 'Framtalsaðstoð', 'value': 7276 },
  { 'label': 'Ráðgjafaþjónusta', 'value': 7277 },
  { 'label': 'Brúðkaupsþjónusta', 'value': 7278 },
  { 'label': 'Fata - búninga-, einkennisfataleigur', 'value': 7296 },
  { 'label': 'Nuddstofur', 'value': 7297 },
  { 'label': 'Heilsu- og fegrunarstofur', 'value': 7298 },
  { 'label': 'Ýmiss konar þjónusta', 'value': 7299 },
  { 'label': 'Auglýsingastofur', 'value': 7311 },
  { 'label': 'Upplýsingaþjónusta um lánstraust', 'value': 7321 },
  { 'label': 'Auglýsingaljósmyndun og hönnun', 'value': 7333 },
  { 'label': 'Ljósritunarþjónusta', 'value': 7338 },
  { 'label': 'Hraðritunar- og ritaraþjónusta', 'value': 7339 },
  { 'label': 'Meindýraeyðing og sótthreinsun', 'value': 7342 },
  { 'label': 'Hreingerningar', 'value': 7349 },
  { 'label': 'Vinnumiðlunarskrifstofur', 'value': 7361 },
  { 'label': 'Tölvuforritun', 'value': 7372 },
  { 'label': 'Þjónusta fyrir upplýsingar', 'value': 7375 },
  { 'label': 'Tölvuviðgerða- og viðhaldsþjónusta', 'value': 7379 },
  { 'label': 'Stjórnunarráðgjöf og almannatengslaþjónus', 'value': 7392 },
  { 'label': 'Verndar- og öryggisþjónusta', 'value': 7393 },
  { 'label': 'Tækjaleiga', 'value': 7394 },
  { 'label': 'Framköllunarstofur', 'value': 7395 },
  { 'label': 'Viðskiptaþjónusta', 'value': 7399 },
  { 'label': 'Bifreiðaleigur', 'value': 7512 },
  { 'label': 'Vörubifreiða- og tengivagnaleiga', 'value': 7513 },
  { 'label': 'Húsbíla- og tjaldvagnaleigur', 'value': 7519 },
  { 'label': 'Bílastæði og bifreiðageymslur', 'value': 7523 },
  { 'label': 'Bílasmiður', 'value': 7531 },
  { 'label': 'Hjólbarðaverkstæði', 'value': 7534 },
  { 'label': 'Bílamálunarverkstæði', 'value': 7535 },
  { 'label': 'Viðgerðarverkstæði fyrir vélknúin ökutæki', 'value': 7538 },
  { 'label': 'Bílaþvottastöðvar', 'value': 7542 },
  { 'label': 'Dráttarþjónusta', 'value': 7549 },
  { 'label': 'Rafeindatækjaverkstæði', 'value': 7622 },
  { 'label': 'Verkstæði fyrir loftræstinga- og frystitækja', 'value': 7623 },
  { 'label': 'Viðgerðarverkstæði v/áhalda og rafmviðg', 'value': 7629 },
  { 'label': 'Úra- og skartgripaviðgerðir', 'value': 7631 },
  { 'label': 'Húsgagnabólstrun', 'value': 7641 },
  { 'label': 'Logsuða, rafsuða', 'value': 7692 },
  { 'label': 'Ýmiss konar viðgerðarverkstæði', 'value': 7699 },
  { 'label': 'Myndbandaframleiðsla og dreifing', 'value': 7829 },
  { 'label': 'Kvikmyndahús', 'value': 7832 },
  { 'label': 'Myndbandaleigur', 'value': 7841 },
  { 'label': 'Danshús, æfingasalir og skólar', 'value': 7911 },
  { 'label': 'Leikfélög og aðgöngumiðaumboð', 'value': 7922 },
  { 'label': 'Hljómsveitir og skemmtikraftar', 'value': 7929 },
  { 'label': 'Leiktækjasalir', 'value': 7932 },
  { 'label': 'Keilubrautasalir', 'value': 7933 },
  { 'label': 'Íþróttafélög', 'value': 7941 },
  { 'label': 'Ferðamannastaðir og sýningar', 'value': 7991 },
  { 'label': 'Golfvellir', 'value': 7992 },
  { 'label': 'Myndbandaleikjasalar', 'value': 7993 },
  { 'label': 'Myndbandaleiktækjasalir', 'value': 7994 },
  { 'label': 'Veðmál - happdrætti', 'value': 7995 },
  { 'label': 'Skemmtisvæði - Tívolí', 'value': 7996 },
  { 'label': 'Lokuð félög og klúbbar', 'value': 7997 },
  { 'label': 'Sædýrasöfn', 'value': 7998 },
  { 'label': 'Frístundastarfsemi', 'value': 7999 },
  { 'label': 'Læknar', 'value': 8011 },
  { 'label': 'Tannlæknaþjónusta', 'value': 8021 },
  { 'label': 'Bein- og liðskekkjulæknar', 'value': 8031 },
  { 'label': 'Hnykklækningar', 'value': 8041 },
  { 'label': 'Sjónglerjafræðingar og augnlæknar', 'value': 8042 },
  { 'label': 'Sjóntækjafræðingar, sjóntæki og gleraugu', 'value': 8043 },
  { 'label': 'Fótsnyrtar og fótaaðgerðatæknar', 'value': 8049 },
  { 'label': 'Hjúkrunarfræðingar og heimaþjónusta', 'value': 8050 },
  { 'label': 'Lækna- og tannlæknarannsóknarstofur', 'value': 8071 },
  { 'label': 'Heilsugæsluþjónusta', 'value': 8099 },
  { 'label': 'Lögfræðiþjónusta og lögmenn', 'value': 8111 },
  { 'label': 'Sérdeildir háskólans, sérmenntastofnanir', 'value': 8220 },
  { 'label': 'Bréfaskólar', 'value': 8241 },
  { 'label': 'Viðskipta- og ritaraskólar', 'value': 8244 },
  { 'label': 'Iðnskólar og verknámsskólar', 'value': 8249 },
  { 'label': 'Skólar og menntabrautir', 'value': 8299 },
  { 'label': 'Barnagæsla', 'value': 8351 },
  { 'label': 'Góðgerðarfélög og samtök', 'value': 8398 },
  { 'label': 'Borgaraleg samtök og bræðrareglur', 'value': 8641 },
  { 'label': 'Stjórnmálasamtök', 'value': 8651 },
  { 'label': 'Trúfélög', 'value': 8661 },
  { 'label': 'Samtök bifreiðaeigenda', 'value': 8675 },
  { 'label': 'Félagasamtök', 'value': 8699 },
  { 'label': 'Rannsóknarstofur', 'value': 8734 },
  { 'label': 'Arkitekta - og verkfræðistofur', 'value': 8911 },
  { 'label': 'Bókhaldsþjónusta og endurskoðun', 'value': 8931 },
  { 'label': 'Sérfræðiþjónusta', 'value': 8999 },
  { 'label': 'Réttargjöld, málskostnaður, lífeyrir og b', 'value': 9211 },
  { 'label': 'Greiðsla réttartrygginga og réttarábyrgða', 'value': 9223 },
  { 'label': 'Innkaup innan fyrirtækja', 'value': 9950 }
]

export { ISL_MCCCODES }