import React from 'react';

export const VaultIcon = () => (
  <svg width="430" height="430" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.15"
      d="M132.75 4.5H11.25C7.52208 4.5 4.5 7.52208 4.5 11.25V132.75C4.5 136.478 7.52208 139.5 11.25 139.5H132.75C136.478 139.5 139.5 136.478 139.5 132.75V11.25C139.5 7.52208 136.478 4.5 132.75 4.5Z"
      fill="#4F9EA6"
    />
    <path
      d="M76.5 90C86.4411 90 94.5 81.9411 94.5 72C94.5 62.0589 86.4411 54 76.5 54C66.5589 54 58.5 62.0589 58.5 72C58.5 81.9411 66.5589 90 76.5 90Z"
      fill="#F9D010"
    />
    <path
      opacity="0.15"
      d="M58.5001 72.0002C58.4914 69.4414 59.0283 66.9101 60.0751 64.5752C57.4317 65.7821 55.1198 67.6111 53.3369 69.9058C51.554 72.2005 50.3533 74.8928 49.8372 77.7525C49.3211 80.6122 49.505 83.5543 50.3733 86.3275C51.2416 89.1007 52.7683 91.6224 54.8231 93.6772C56.8779 95.732 59.3996 97.2588 62.1728 98.127C64.946 98.9953 67.8881 99.1792 70.7478 98.6631C73.6075 98.147 76.2998 96.9463 78.5945 95.1634C80.8892 93.3805 82.7182 91.0686 83.9251 88.4252C81.1813 89.6676 78.1696 90.2017 75.166 89.9785C72.1623 89.7552 69.2626 88.7818 66.7327 87.1474C64.2027 85.513 62.1233 83.2699 60.685 80.6235C59.2467 77.9771 58.4955 75.0122 58.5001 72.0002Z"
      fill="#555555"
    />
    <path d="M18 36H27V54H18V36ZM18 90H27V108H18V90Z" fill="#EAB931" />
    <path
      opacity="0.15"
      d="M22.5 27H11.25V130.5H114.75V121.5H22.5V108H18V90H22.5V54H18V36H22.5V27Z"
      fill="#555555"
    />
    <path
      d="M132.75 4.5H11.25C7.52208 4.5 4.5 7.52208 4.5 11.25V132.75C4.5 136.478 7.52208 139.5 11.25 139.5H132.75C136.478 139.5 139.5 136.478 139.5 132.75V11.25C139.5 7.52208 136.478 4.5 132.75 4.5Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5 90V54M22.5 36V22.5H121.5V121.5H22.5V108V36Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.5 90C86.4411 90 94.5 81.9411 94.5 72C94.5 62.0589 86.4411 54 76.5 54C66.5589 54 58.5 62.0589 58.5 72C58.5 81.9411 66.5589 90 76.5 90Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.5 45V54V45ZM76.5 90V99V90ZM49.5 72H58.5H49.5ZM94.5 72H103.5H94.5ZM57.375 52.875L63.675 59.175L57.375 52.875ZM89.325 84.825L95.625 91.125L89.325 84.825ZM57.375 91.125L63.675 84.825L57.375 91.125ZM89.325 59.175L95.625 52.875L89.325 59.175ZM18 36H27V54H18V36ZM18 90H27V108H18V90Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.5 74.25C77.7426 74.25 78.75 73.2426 78.75 72C78.75 70.7574 77.7426 69.75 76.5 69.75C75.2574 69.75 74.25 70.7574 74.25 72C74.25 73.2426 75.2574 74.25 76.5 74.25Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VaultIcon;
