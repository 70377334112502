import React, { FunctionComponent } from 'react';

import { Icon } from '@local';

import { SAnchor } from './index.styled';

interface AnchorProps {
  href?: string;
  icon?: string;
}

const Anchor: FunctionComponent<AnchorProps> = ({ href, icon, children }) => {
  const invalidComponent = (!icon && !children) || !href;
  return !invalidComponent ? (
    <SAnchor href={href} rel="noopener" target="_blank">
      {icon && <Icon icon={icon} />}
      {children}
    </SAnchor>
  ) : null;
};

export { Anchor };
