import styled, { css } from 'styled-components';

const SLegend = styled.legend`
  ${({ theme: { color, grid, breakpoints } }) => css`
    color: ${color.blue};
    font-weight: normal;
    font-size: 32px;
    line-height: 1.375em;

    @media (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: ${`${grid.horizontal * 2}px`};
    }
  `}
`;

export { SLegend };
