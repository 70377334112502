import styled, { css } from 'styled-components';

export const SDiv = styled.div`
  ${({ theme: { grid, color, breakpoints } }) => css`
    display: flex;
    background: ${color.lightGray};
    height: ${grid.horizontal * 7}px;
    @media (min-width: ${breakpoints.mobileMin}) {
    }
  `}
`;

export const SImage = styled.div`
  ${({ theme: { grid, breakpoints } }) => css`
    svg {
      height: ${grid.vertical * 12}px;
      width: ${grid.vertical * 12}px;
    }
    align-self: center;
    margin-left: -2px;
    @media (min-width: ${breakpoints.tabletMin}) {
      margin-right: ${grid.vertical * 4}px;
    }
    @media (min-width: ${breakpoints.desktopMin}) {
      margin-right: ${grid.vertical * 4}px;
    }
    @media (min-width: ${breakpoints.desktopMed}) {
      margin-right: ${grid.vertical * 12}px;
    }
    @media (min-width: ${breakpoints.desktopMax}) {
      margin-right: ${grid.vertical * 16}px;
    }
    @media (max-width: ${breakpoints.mobileMax}) {
      svg {
        position: absolute;
        visibility: hidden;
      }
    }
  `}
`;

export const SContent = styled.div`
  ${({ theme: { breakpoints } }) => css`
    margin-right: auto;
    @media (min-width: ${breakpoints.mobileMin}) {
      margin-left: 2px;
    }
  `}
`;

export const SCounter = styled.div`
  ${({ theme: { grid, breakpoints } }) => css`
    @media (min-width: ${breakpoints.desktopMin}) {
      margin-top: ${grid.vertical * 8}px;
    }
  `}
`;

export const SInput = styled.div`
  ${({ theme: { grid, breakpoints } }) => css`
    @media (min-width: ${breakpoints.mobileMin}) {
      width: ${grid.vertical * 16}px;
    }
    @media (min-width: ${breakpoints.desktopMin}) {
      width: ${grid.vertical * 18}px;
    }
    @media (min-width: ${breakpoints.desktopMax}) {
      width: ${grid.vertical * 32}px;
    }
  `}
`;

export const SParagraph = styled.p`
  ${({ theme: { grid, breakpoints } }) => css`
    &.no-counter {
      align-self: center;
      margin-top: ${grid.vertical * 4}px;
    }
    @media (min-width: ${breakpoints.mobileMin}) {
      max-width: 150px;
      font-size: 20px;
    }
    @media (min-width: ${breakpoints.tabletMin}) {
      font-size: 20px;
      max-width: 300px;
    }
    @media (min-width: ${breakpoints.desktopMin}) {
      font-size: 18px;
      max-width: 150px;
      position: absolute;
    }
    @media (min-width: ${breakpoints.desktopMed}) {
      font-size: 20px;
      max-width: 250px;
    }
    @media (min-width: ${breakpoints.desktopMax}) {
      margin-bottom: ${grid.vertical * 3}px;
      font-size: 24px;
      max-width: none;
    }
  `}
`;
