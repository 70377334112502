export default {
  id: 'uploadBusinessExistence',
  nextButtonText: 'Upload',
  showOnTemplates: 'showOnAgents',
  showOnAgents: ['virtual'],
  fields: [
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.0.StoreName',
      text: 'Upload image/s of business for ',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'MEDIAUPLOAD',
      id: 'Stores.0.BusinessExistence',
      fileTypesAccepted: 'image/*, application/pdf',
      multipleFileUpload: true,
      label: 'Media_Upload',
      buttonText: 'Upload files',
      text:
        'This should be a photo or photos to proof customer business existence.',
    },
    {
      componentType: 'INFO',
      text:
        'Note: You can upload more than one photo at a time for each store.',
      className: 'medium-margin-top',
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.1.StoreName',
      text: 'Upload image/s of business for ',
      className: 'size-md-medium extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'MEDIAUPLOAD',
      id: 'Stores.1.BusinessExistence',
      fileTypesAccepted: 'image/*, application/pdf',
      multipleFileUpload: true,
      label: 'Media_Upload',
      buttonText: 'Upload files',
      text:
        'This should be a photo or photos to proof customer business existence.',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'INFO',
      text:
        'Note: You can upload more than one photo at a time for each store.',
      className: 'medium-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.1.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.2.StoreName',
      text: 'Upload image/s of business for ',
      className: 'size-md-medium extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'MEDIAUPLOAD',
      id: 'Stores.2.BusinessExistence',
      fileTypesAccepted: 'image/*, application/pdf',
      multipleFileUpload: true,
      label: 'Media_Upload',
      text: 'This should be a picture to proof customer business existence.',
      buttonText: 'Upload files',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'INFO',
      text:
        'Note: You can upload more than one photo at a time for each store.',
      className: 'medium-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.2.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.3.StoreName',
      text: 'Upload image/s of business for ',
      className: 'size-md-medium extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'MEDIAUPLOAD',
      fileTypesAccepted: 'image/*, application/pdf',
      multipleFileUpload: true,
      id: 'Stores.3.BusinessExistence',
      label: 'Media_Upload',
      text: 'This should be a picture to proof customer business existence.',
      buttonText: 'Upload files',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'INFO',
      text:
        'Note: You can upload more than one photo at a time for each store.',
      className: 'medium-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.3.StoreName',
      },
    },
    {
      componentType: 'DYNAMICTITLE',
      fieldName: 'Stores.4.StoreName',
      text: 'Upload image/s of business for ',
      className: 'size-md-medium extra-margin-bottom',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'MEDIAUPLOAD',
      id: 'Stores.4.BusinessExistence',
      fileTypesAccepted: 'image/*, application/pdf',
      multipleFileUpload: true,
      label: 'Media_Upload',
      text: 'This should be a picture to proof customer business existence.',
      buttonText: 'Upload files',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
    {
      componentType: 'INFO',
      text:
        'Note: You can upload more than one photo at a time for each store.',
      className: 'medium-margin-top',
      conditional: {
        type: ['SHOW'],
        conditionName: 'Stores.4.StoreName',
      },
    },
  ],
};
