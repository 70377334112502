
const date = (value: string | undefined, label: string | undefined, arg: string | undefined) => {
  const monthsWith30Days = [4, 6, 9, 11];
  const currentDate = new Date();
  let birthday = false;
  let IS = false;

  // arg for date validation can be DATE(IS), DATE(IS Birthday) or DATE(Birthday)
  if (arg) {
    if (arg.includes(" ")) {
      IS = arg.split(" ")[0] === "IS";
      birthday = arg.split(" ")[1] === "Birthday";
    } else {
      IS = arg === "IS";
      birthday = arg === "Birthday";
    }
  }

  if (value) {
    const dateList = value.split(' / ');
    // If the whole date has been written then validate
    if (dateList.length > 2 && value.length > 13) {
      const day = Number(dateList[0]);
      const month = Number(dateList[1]);
      const year = Number(dateList[2]);

      const inputDate = new Date(`${year}-${month}-${day}`);

      // Check day, month and year
      if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
        return IS ? 'Ógild dagsetning' : 'Invalid date';
      }

      // Check if date is in future
      if (inputDate > currentDate) {
        return IS ? 'Ekki er hægt að velja framtíðardagsetningu' : 'Cannot select future date';
      }

      // Check if day is valid
      if (monthsWith30Days.indexOf(month) > -1 && day > 30) {
        return IS ? 'Ógild dagsetning' : 'Invalid date';
      }

      // Validate February
      if (month === 2) {
        if (day > 29) {
          return IS ? 'Ógild dagsetning' : 'Invalid date';
        }

        // Check for leap year
        if (day === 29 && year % 4 !== 0) {
          return IS ? 'Ógild dagsetning, árið er ekki hlaupár' : 'Invalid date, the year is not a leap year';
        }
      }

      // Validate if date is a person's birthday
      if (birthday) {
        if (year < currentDate.getFullYear() - 18) {
          return undefined;
        }

        const selectedDateAsTimestamp = new Date(year, month, day).getTime();
        const legalAgeTimestamp = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate(),).getTime();

        // Check if user is younger than 18 years old
        if (selectedDateAsTimestamp >= legalAgeTimestamp) {
          return IS ? 'Þú ert of ung/ur til að sækja um' : 'You are too young to apply';
        }
      }
    }
  }
}

export { date }