import { post } from "./http";

const apiLocation = () => {
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : `https://api.${window.location.host}`;
};

interface AuthenticationData {
  token: string;
  status: string;
  control_code: string;
}

interface AuthenticationResponseType {
  status: number;
  data: AuthenticationData;
}

const authenticate = async (phonenumber: string) => {
  const requestUri = `${apiLocation()}/api/AuthenticateUser`;

  const body = {
    "phone": phonenumber
  }

  // TODO: Change GUID to something dynamic
  const response = await post(body, requestUri, '65d671b0-e569-4e59-b290-ca6663b20bf6')

  if (response.status !== 200) {
    throw JSON.parse(response.parsedBody as string);
  }


  return { data: response.parsedBody, status: response.status } as AuthenticationResponseType
}

const checkLoginStatus = async (token: string) => {

  const requestUri = `${apiLocation()}/api/AuthenticationStatus?token=${token}`;

  const response = await fetch(requestUri);

  if(response.status !== 200) {
    throw await response.json();
  }

  const data = await response.json();

  return { data, status: response.status }

}
export { authenticate, checkLoginStatus }