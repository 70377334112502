import React, { FunctionComponent } from 'react';
import ObjectPath from 'object-path';
import { Icon } from '@local';
import { SInfo, SParagraph } from './index.styled';
import { FormValuesTypes } from 'src/types/formValues';

interface InfoProps {
  smallerText?: boolean;
  icon?: string;
  text?: React.ReactNode;
  className?: string;
  textFromValues?: string;
  values?: FormValuesTypes;
}

// Info Component - text with an info icon next to it.
const Info: FunctionComponent<InfoProps> = ({
  children,
  text,
  smallerText,
  icon,
  className,
  textFromValues,
  values,
}) => {
  const theTextFromValues = values && textFromValues
    ? ObjectPath.get(values, textFromValues)
    : "";

  const renderedIcon = icon || 'INFO';

  return (
    <SInfo className={`info-component ${className || ''}`}>
      <Icon icon={renderedIcon} />
      <SParagraph smallerText={smallerText}>
        {text || children}
        {
          theTextFromValues !== "" ? <b>{theTextFromValues}</b> : <></>
        }
      </SParagraph>
    </SInfo>
  );
};

export { Info };
export type { InfoProps };
