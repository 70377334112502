import { FormRenderProps } from 'react-final-form';
import { ConditionalInterface } from 'src/types/field';
import {
  ConditionalAugmentProps,
  referenceHasCorrectValue,
  getReferenceValue,
} from '../augmentorUtils';

const conditionalCopy = (
  { field, values, contextMap }: ConditionalAugmentProps,
  conditional: ConditionalInterface,
  form: FormRenderProps['form'],
) => {
  // Check if the reference value is the same as desired value
  const correctValue = referenceHasCorrectValue(
    { field, values, contextMap },
    conditional,
  );

  // Change field if correct conditions are met
  if (correctValue && conditional.copyFrom) {
    // Get the value from copyFrom field
    const copyFromValue = getReferenceValue(
      { field, values, contextMap },
      conditional.copyFrom,
    );

    // Get value of field
    const fieldState = form.getFieldState(field.name as string);
    const currentValue = (fieldState || {}).value;

    // If the field is empty, untouched, or disabled, add the copied value
    if (!currentValue || !(fieldState || {}).touched || field.disabled) {
      // Final form's way to change field values (field.name, desiredValue)
      form.change(field.name as string, copyFromValue);

      return { ...field, value: copyFromValue };
    }
  }
  return field;
};

export { conditionalCopy };
