import React from 'react';

import { Field } from 'react-final-form';
import { ErrorLabel } from '@local';
import { FieldTypes } from 'src/types/field';
import { FormValuesTypes } from 'src/types/formValues';
import ObjectPath from 'object-path';
import {
  SWrapper,
  SDateInput,
  SLegend,
  SInputWrapper,
  SParagraphWrapper,
} from './index.styled';

const date = new Date();

interface DateInputInterface {
  name: string;
  preselectDay: string;
  preselectMonth: string;
  preselectYear: string;
  personsBirthday: boolean;
  dayLabel?: string;
  monthLabel?: string;
  yearLabel?: string;
  exampleLabel?: string;
  icelandicMarket?: boolean;
}

const isFebruaryValid = (componentName: string, allValues: FormValuesTypes, icelandicMarket: boolean) => {
  const Day = Number(ObjectPath.get(allValues, `${componentName}StartDay`));
  const Year = Number(ObjectPath.get(allValues, `${componentName}StartYear`));

  // Checking if the user is selecting 30 or 31
  // Remember, this is zero based
  if (Day) {
    if (Day > 29) {
      return <ErrorLabel>{icelandicMarket ? 'Ógild dagsetning' : 'Invalid date'}</ErrorLabel>;
    }
  }
  // Leap year check if user selects 29th
  if (Day && Year) {
    if (Day === 29 && Year % 4 !== 0) {
      return <ErrorLabel>Not a leap year</ErrorLabel>;
    }
  }
  return null;
};

const dateValidation = (componentName: string, allValues: FormValuesTypes, icelandicMarket: boolean) => {
  const Day = ObjectPath.get(allValues, `${componentName}StartDay`);
  const Month = ObjectPath.get(allValues, `${componentName}StartMonth`);
  const Year = ObjectPath.get(allValues, `${componentName}StartYear`);

  const monthsWith30Days = [4, 6, 9, 11];
  const currentYear = new Date().getFullYear();
  const inputDate = new Date(`${Year}-${Month}-${Day}`);

  if (Day && Month && Year && inputDate > new Date()) {
    return <ErrorLabel>{icelandicMarket ? 'Ekki er hægt að velja framtíðardagsetningu' : 'Cannot select future date'}</ErrorLabel>;
  }
  if (
    parseInt(Day, 10) > 31 ||
    parseInt(Month, 10) > 12 ||
    parseInt(Year, 10) > currentYear
  ) {
    return <ErrorLabel>{icelandicMarket ? 'Ógild dagsetning' : 'Invalid date'}</ErrorLabel>;
  }
  if (Day && Month) {
    if (
      monthsWith30Days.indexOf(parseInt(Month, 10)) > -1 &&
      parseInt(Day, 10) > 29
    ) {
      return <ErrorLabel>{icelandicMarket ? 'Ógild dagsetning' : 'Invalid date'}</ErrorLabel>;
    }

    if (parseInt(Month, 10) === 2) {
      return isFebruaryValid(componentName, allValues, icelandicMarket);
    }
    return null;
  }
  return null;
};

// Unable to use required through validate function and validations from form
// Refactored version should use validations from form.
const inputRequired = (label: string, value: FieldTypes[], icelandicMarket: boolean) => {
  switch (label) {
    case 'Dagur':
      label = 'dag';
      break;
    case 'Mánuður':
      label = 'mánuð';
      break;
    case 'Ár':
      label = 'ár';
      break;
  }
  return value ? undefined : <ErrorLabel>{icelandicMarket ? `Þú þarft að slá inn ${label}` : `${label} is required`}</ErrorLabel>;
};

const maxLengthMask = (e: React.BaseSyntheticEvent) => {
  const { maxLength } = e.target;
  e.currentTarget.value = e.currentTarget.value.replace(/\D+/g, '');

  if (e.currentTarget.value.length > 0) {
    e.currentTarget.value = Math.max(0, parseInt(e.currentTarget.value, 10))
      .toString()
      .slice(0, maxLength);
  }
};

const minValue = (value: undefined | string, icelandicMarket: boolean, arg = '0') => {
  return Number(value) <= Number(arg) ? (
    <ErrorLabel>{icelandicMarket ? `Gildið þarf að vera hærra en ${arg}` : `Value must be higher than ${arg}`}</ErrorLabel>
  ) : (
    undefined
  );
};

const DateInput = ({
  name,
  preselectDay = '',
  preselectMonth = '',
  preselectYear = '',
  personsBirthday,
  dayLabel = 'Day',
  monthLabel = 'Month',
  yearLabel = 'Year',
  exampleLabel = 'Example',
  icelandicMarket = false,
}: DateInputInterface) => {
  const isOver18 = (allValues: FormValuesTypes, componentName: string) => {
    // If the DateInput is not for a person's birthday then isOver18 validation is irrelevant
    if (!personsBirthday) {
      return undefined;
    }
    const day = ObjectPath.get(allValues, `${componentName}StartDay`);
    const month = ObjectPath.get(allValues, `${componentName}StartMonth`);
    const year = ObjectPath.get(allValues, `${componentName}StartYear`);

    if (year < date.getFullYear() - 18) {
      return undefined;
    }

    const selectedDateAsTimestamp = new Date(year, month, day).getTime();

    const legalAgeTimestamp = new Date(
      date.getFullYear() - 18,
      date.getMonth(),
      date.getDate(),
    ).getTime();

    if (selectedDateAsTimestamp >= legalAgeTimestamp) {
      // If applicant is under 18 years old, return an error message under the whole component
      return <ErrorLabel>{icelandicMarket ? "Þú ert of ung/ur til að sækja um!" : "You are too young to apply!"}</ErrorLabel>;
    }
    return undefined;
  };

  return (
    <>
      <SWrapper>
        <Field
          name={`${name}StartDay`}
          defaultValue={preselectDay != null ? preselectDay : ''}
          validate={(v, allValues) =>
            inputRequired(dayLabel, (v as unknown) as FieldTypes[], icelandicMarket) ||
            minValue(v, icelandicMarket, '0') ||
            dateValidation(name, allValues as FormValuesTypes, icelandicMarket) ||
            isOver18(allValues as FormValuesTypes, name)
          }>
          {({ input, meta }) => (
            <>
              <SInputWrapper>
                <SLegend>{dayLabel}</SLegend>
                <SDateInput
                  {...input}
                  {...meta}
                  onInput={(e: React.BaseSyntheticEvent) => maxLengthMask(e)}
                  placeholder="DD"
                  type="text"
                  data-testid={`${name}StartDay`}
                  maxLength="2"
                />
                {meta.error && meta.touched && !meta.active && meta.error}
              </SInputWrapper>
            </>
          )}
        </Field>
        <Field
          name={`${name}StartMonth`}
          defaultValue={preselectMonth != null ? preselectMonth : ''}
          validate={(v, allValues) =>
            inputRequired(monthLabel, (v as unknown) as FieldTypes[], icelandicMarket) ||
            minValue(v, icelandicMarket, '0') ||
            dateValidation(name, allValues as FormValuesTypes, icelandicMarket) ||
            isOver18(allValues as FormValuesTypes, name)
          }>
          {({ input, meta }) => (
            <>
              <SInputWrapper>
                <SLegend>{monthLabel}</SLegend>
                <SDateInput
                  {...input}
                  {...meta}
                  onInput={(e: React.BaseSyntheticEvent) => maxLengthMask(e)}
                  placeholder="MM"
                  type="text"
                  data-testid={`${name}StartMonth`}
                  maxLength="2"
                />
                {meta.error && meta.touched && !meta.active && meta.error}
              </SInputWrapper>
            </>
          )}
        </Field>
        <Field
          name={`${name}StartYear`}
          defaultValue={preselectYear != null ? preselectYear : ''}
          validate={(v, allValues) =>
            inputRequired(yearLabel, (v as unknown) as FieldTypes[], icelandicMarket) ||
            minValue(v, icelandicMarket, '1900') ||
            dateValidation(name, allValues as FormValuesTypes, icelandicMarket) ||
            isOver18(allValues as FormValuesTypes, name)
          }>
          {({ input, meta }) => (
            <>
              <SInputWrapper>
                <SLegend>{yearLabel}</SLegend>
                <SDateInput
                  {...input}
                  {...meta}
                  onInput={(e: React.BaseSyntheticEvent) => maxLengthMask(e)}
                  placeholder="YYYY"
                  type="text"
                  data-testid={`${name}StartYear`}
                  maxLength="4"
                />
                {meta.error && meta.touched && !meta.active && meta.error}
              </SInputWrapper>
            </>
          )}
        </Field>
      </SWrapper>
      <SParagraphWrapper>{exampleLabel}: 12 2 1987</SParagraphWrapper>
    </>
  );
};

export { DateInput };
