import React from 'react';

export const DecrementIcon = () => (
  <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4c0-2.20914 1.79086-4 4-4h32c2.2091 0 4 1.79086 4 4v32c0 2.2091-1.7909 4-4 4H4c-2.20914 0-4-1.7909-4-4V4z"
      fill="#EEE"
    />
    <path
      d="M28.8281 21.5625H12.4219c-.3108 0-.6089-.1482-.8287-.4119-.2197-.2637-.3432-.6214-.3432-.9944 0-.3729.1235-.7306.3432-.9943.2198-.2637.5179-.4119.8287-.4119h16.4062c.3108 0 .6089.1482.8287.4119.2197.2637.3432.6214.3432.9943 0 .373-.1235.7307-.3432.9944-.2198.2637-.5179.4119-.8287.4119z"
      fill="#555"
    />
  </svg>
);

export default DecrementIcon;
