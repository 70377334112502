/* eslint-disable no-restricted-syntax */
import React, { FunctionComponent } from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import "semantic-ui-css/semantic.min.css";
import { Header, TestGrid, CustomHead, CookieConsentPopUp } from '@local';
import { ApplicationProvider } from '../components/Context/ApplicationConsumer';
import { FontStyles } from '../public/static/font';
import '../public/static/style.scss';
import { theme } from '../public/static/variables';
import { ErrorBoundary } from '../components/ErrorBoundary/index';
import { AuthenticationProvider } from 'src/components/Context/AuthenticationConsumer';

interface LayoutProps {
  displayHeaderTitle: boolean;
}
const Layout: FunctionComponent<LayoutProps> = ({
  children,
  displayHeaderTitle,
}) => (
  <ThemeProvider theme={theme}>
    <main className="container">
      <TestGrid vertical horizontal />
      <FontStyles />
      <Header displayHeaderTitle={displayHeaderTitle} />
      <div className="layout">{children}</div>
    </main>
  </ThemeProvider>
);

function MyApp({ Component, router, pageProps}: AppProps) {
    const queryID = router.query.id;
    // Only display name of application when interacting with the application, not the frontpage
    const displayHeaderTitle = router.route !== '/';
    return (
      <ApplicationProvider query={router.query as {[key:string]: string}} setup={queryID as string}>
        <AuthenticationProvider>

        <CustomHead useSelectedFormTitle={displayHeaderTitle} />

        <Layout displayHeaderTitle={displayHeaderTitle}>
          <ErrorBoundary>
            <div id="root">
              <Component {...pageProps} />
            </div>
            <CookieConsentPopUp />
          </ErrorBoundary>
        </Layout>
        </AuthenticationProvider>
      </ApplicationProvider>
    );
}

export default MyApp;