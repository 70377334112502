import {
  bankInfo,
  businessContactInformation,
  companiesHouseStoreLocation,
  companySearch,
  confirmId,
  confirmProofOfBank,
  depositOrFullPayments,
  deposits,
  directorsOverview,
  feeslist,
  fullPayment,
  maxTransaction,
  storeType,
  paymentCards,
  uploadId,
  uploadProofOfBank,
  cardTypes,
  averageTimeDelivery,
  settlementFrequency,
  additionalInfo,
  managementInfo,
  companyInformation,
  companyManagementInfo,
} from '../sharedLogic';

export default {
  pageTitle: 'Private limited company',
  applicationType: 'privatelimitedsimple',
  pages: [
    // Page identity
    {
      icon: 'IDENTITY',
      title: 'Identity',
      id: 'identity',
      steps: [
        {
          ...companyInformation,
        },
        // Step companySearch
        {
          ...managementInfo,
        },
        {
          ...companyManagementInfo,
        },
        {
          ...companySearch,
        },
        {
          ...businessContactInformation,
        },
        // Step directorOverview
        {
          ...directorsOverview,
        },
        // Step uploadId
        {
          ...uploadId,
        },
        // Step confirmId
        {
          ...confirmId,
        },
      ],
    },
    // Page store
    {
      icon: 'STORECOLOR',
      title: 'Stores',
      id: 'store',
      showOnReviewPage: true,
      steps: [
        // Step paymentCards
        {
          ...paymentCards,
        },
        // Step storeType
        {
          ...storeType,
        },
        // Step storeLocation
        {
          ...companiesHouseStoreLocation,
        },
        // Step cardsAndHours
        {
          id: 'cardsAndHours',
          fields: [
            { ...cardTypes.titleNoMarginTop },
            { ...cardTypes.checkbox },
            { ...averageTimeDelivery.title },
            { ...averageTimeDelivery.input },
          ],
        },
        // Step maxTransaction
        {
          ...maxTransaction,
        },
        // Step paymentInAdvance
        {
          title: 'Do you take payments in advance?',
          id: 'paymentInAdvance',
          conditionalPreviousSteps: [
            {
              id: 'Stores.0.MotoTrans',
              skipToStep: 'maxTransation',
              optionalLessThen: '1',
            },
          ],
          conditionalNextSteps: [
            {
              id: 'PaymentsAdvance',
              value: 'no',
              skipToStep: 'listOfFees',
              skipToPage: 'finance',
            },
          ],
          fields: [
            {
              componentType: 'RADIOBUTTONS',
              validations: ['REQUIRED'],
              radioValues: [
                {
                  labelText: 'Yes',
                  name: 'PaymentsAdvance',
                  value: 'yes',
                },
                {
                  labelText: 'No',
                  name: 'PaymentsAdvance',
                  value: 'no',
                  checked: true,
                },
              ],
            },
          ],
        },
        // Step depositOrFullPayments
        {
          ...depositOrFullPayments,
        },
        // Step deposits
        {
          ...deposits,
        },
        // Step fullPayment
        {
          ...fullPayment,
        },
      ],
    },
    // Page finance
    {
      icon: 'TRIBUNALICON',
      title: 'Finance',
      id: 'finance',
      showOnReviewPage: true,
      steps: [
        // Step listOfFees
        {
          ...feeslist,
        },
        // Step bankInfo
        {
          id: 'bankInfo',
          fields: [
            { ...bankInfo.bankNameTitle },
            { ...bankInfo.bankNameInput },
            { ...bankInfo.swiftCodeInput },
            { ...bankInfo.accountDetailsTitle },
            { ...bankInfo.ibanInput },
            { ...bankInfo.accountOwnerInput },
          ],
        },
        // Select settlement frequency step
        {
          ...settlementFrequency,
        },
        // Step uploadProofOfBank
        {
          ...uploadProofOfBank,
        },
        // Step confirmProofOfBank
        {
          id: 'confirmProofOfBank',
          nextButtonText: 'Upload',
          previousButtonText: 'Use another image',
          fields: [{ ...confirmProofOfBank.uploadedImage }],
        },
      ],
    },
    // Page review
    {
      icon: 'DOCUMENTSAVEDICON',
      title: 'Review',
      id: 'review',
      showOnReviewPage: false,
      steps: [
        {
          ...additionalInfo,
        },
        // Step companyReview
        {
          id: 'companyReview',
          showOnTemplates: 'showOnCountries',
          showOnCountries: ['UK'],
          fields: [
            {
              componentType: 'TITLE',
              text: 'Review your application',
              className: 'size-md-medium no-margin-top',
            },
            {
              componentType: 'PARAGRAPH',
              text:
                'Here is an overview of the whole application than needs to be signed off by the merchant',
            },
            {
              componentType: 'REVIEW',
              extraComponents: [
                {
                  componentType: 'COMPANYREVIEW',
                },
              ],
            },
          ],
        },
        {
          id: 'applicationReview',
          showOnTemplates: 'showOnCountries',
          showOnCountries: ['DK'],
          fields: [
            {
              componentType: 'TITLE',
              text: 'Review your application',
              className: 'size-md-medium no-margin-top',
            },
            {
              componentType: 'PARAGRAPH',
              text:
                'Here is an overview of the whole application than needs to be signed off by the merchant',
            },
            {
              componentType: 'REVIEW',
            },
          ],
        },
      ],
    },
  ],
};
