import { Icon } from '@local';
import { SPrivacyWrapper } from './index.styled';

const PrivacyPolicy = () => {

  return (
    <SPrivacyWrapper>
      <Icon icon="INFO"/>
      <a href="https://www.valitor.is/um-valitor/personuverndarstefna/" target="_blank" rel="noreferrer">
          Persónuverndarstefna Rapyd
      </a>
    </SPrivacyWrapper>
  );
};

export { PrivacyPolicy };
