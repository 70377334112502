import React from 'react';

export const MailIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6217 15.2727C18.2041 15.0082 17.7006 14.9147 17.2158 15.0116C16.7311 15.1085 16.3023 15.3884 16.0186 15.7931C15.0575 16.9541 13.8962 18.8757 9.53108 14.5121C5.16601 10.1484 7.04819 8.94742 8.20954 7.98661C8.61439 7.70301 8.89438 7.27438 8.99133 6.78979C9.08828 6.30519 8.99474 5.80189 8.73015 5.38444L6.00698 1.22096C5.64657 0.700521 5.16601 -0.140181 4.0447 0.0199525C2.9234 0.180086 0 1.82146 0 5.42447C0 9.02748 2.84331 13.4312 6.72782 17.3144C10.6123 21.1977 15.0175 24 18.5816 24C22.1457 24 23.8678 20.7973 23.9879 19.9967C24.108 19.196 23.3071 18.3953 22.7865 18.035L18.6217 15.2727Z"
      fill="#555555"
    />
  </svg>
);

export default MailIcon;
