import React, { useContext } from 'react';
import { CompaniesHouseConsumer, Title } from '@local';
import { FormValuesTypes } from 'src/types/formValues';
import { CompaniesHouseDirector } from './CompaniesHouseDirectorsItem';
import { SCompanyName } from './index.styled';

interface CompaniesHouseDirectorProps {
  values?: FormValuesTypes;
}
const CompaniesHouseDirectors = ({ values }: CompaniesHouseDirectorProps) => {
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);
  //  Only show radiobuttons if there are two or more directors

  if (companiesHouseValues && companiesHouseValues.persons_of_interest) {
    const showRadioButtons =
      companiesHouseValues.persons_of_interest.length > 1;
    return (
      <>
        <Title className="size-md-medium no-margin-top">
          Fill out information for the directors of{' '}
          <SCompanyName>
            &quot;{companiesHouseValues.company_name}&quot;
          </SCompanyName>
        </Title>
        {companiesHouseValues.persons_of_interest.map((person, index) => (
          <CompaniesHouseDirector
            key={person.name}
            {...person}
            index={index}
            showRadioButtons={showRadioButtons}
            values={values}
          />
        ))}
      </>
    );
  }
  return (
    <>
      <Title className="size-md-medium no-margin-top">
        Fill out information for the directors of
      </Title>
      <p>Companies house results are empty</p>
    </>
  );
};

export { CompaniesHouseDirectors };
