import React, { FC, useContext } from 'react';
import { TerminalConsumer } from '@local';
import { STable } from './index.styled';


interface ReviewTerminalTableProps {
  isCrossBorder: boolean;
}
const ReviewTerminalTable: FC<ReviewTerminalTableProps> = ({ isCrossBorder }) => {
  const { terminals } = useContext(TerminalConsumer);

  return (
    <div>
      {
        terminals.value > 0 ?
          <STable>
            <tbody>
              <tr>
                <th>{isCrossBorder ? 'Type of POS' : 'Tegund posa'}</th>
                <th>{isCrossBorder ? 'Amount' : 'Magn'}</th>
              </tr>
              {terminals.terminals.map(singleTerminal => {
                if (singleTerminal.value > 0) {
                  return (
                    <tr data-testid={singleTerminal.name} key={singleTerminal.name}>
                      <td>{singleTerminal.name}</td>
                      <td>{singleTerminal.value}</td>
                    </tr>
                  )
                }
              }
            )}
            </tbody>
          </STable>
        : <></>
      }
    </div>
  );
};

export { ReviewTerminalTable };
