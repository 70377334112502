import React from 'react';

export const MailIcon = () => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8561 0C12.9242 0 14.8833 0.453747 16.7337 1.36124L15.7378 3.51654C14.0616 2.68467 12.4452 2.26874 10.8888 2.26874C9.55 2.26874 8.53776 2.60365 7.85204 3.27346C7.17721 3.94328 6.8398 5.03444 6.8398 6.54693V11.3275H13.7296V13.3856H6.8398V16.9669C6.8398 18.0473 6.6602 18.9548 6.30102 19.6894C5.95272 20.424 5.37585 21.029 4.57041 21.5044H17.55V24H0.75V21.7151C2.98129 21.2073 4.09694 19.6354 4.09694 16.9993V13.3856H0.864286V11.3275H4.09694V6.20662C4.09694 4.28359 4.70646 2.7711 5.92551 1.66914C7.14456 0.556381 8.7881 0 10.8561 0Z"
      fill="#555555"
    />
  </svg>
);

export default MailIcon;
