export default {
  id: 'verslun',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  conditionalPreviousSteps: [
    {
      id: 'PaymentSolutions',
      value: 'ecommerce',
      skipToStep: 'greidslulausnir',
    },
  ],
  conditionalNextSteps: [
    {
      id: 'PaymentSolutions',
      value: 'cardPresent',
      skipToStep: 'kortategundir',
    },
    {
      id: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
      value: 'Shopify',
      skipToStep: 'kortategundir',
    },
    {
      id: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
      value: 'WooCommerce',
      skipToStep: 'kortategundir',
    },
    {
      id: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
      value: 'Magento',
      skipToStep: 'kortategundir',
    },
  ],
  fields: [
    {
      componentType: 'TITLE',
      text: 'Upplýsingar um þína verslun',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'StoreName',
      label: 'Nafn verslunar',
      type: 'text',
      icon: 'STORE',
      placeholder: 'Sláðu inn nafn verslunar',
      validations: ['REQUIRED(Þú þarft að slá inn nafn verslunar)', 'MAXLENGTH(50 IS)'],
      reviewPageTitle: 'Nafn verslunar',
    },
    {
      componentType: 'INFO',
      className: 'medium-margin-bottom override-margin-top',
      text: 'Þetta nafn mun birtast á færsluyfirliti viðskiptavinar',
    },
    {
      componentType: 'SELECT',
      className: 'medium-margin-bottom',
      name: 'Mcc',
      label: 'Tegund verslunar',
      placeholder: 'Veldu tegund verslunar',
      options: 'MCC',
      validations: ['SELECTREQUIRED(Þú þarft að velja tegund verslunar)'],
    },
    {
      componentType: 'TEXTAREA',
      name: 'DescriptionOfProductsAndServices',
      label: 'Lýsing á vörum og/eða þjónustu (valkvætt)',
      type: 'text',
      placeholder: 'Sláðu inn lýsingu á vörum og/eða þjónustu',
      validations: ['MAXLENGTH(1000 IS)'],
      reviewPageTitle: 'Lýsing á vörum og/eða þjónustu',
    },
    {
      componentType: 'FADEANIMATION',
      name: 'CardPresent.TradingStreet',
      component: {
        componentType: 'INPUT',
        name: 'CardPresent.TradingStreet',
        label: 'Heimilisfang',
        type: 'text',
        icon: 'HOUSE',
        placeholder: 'Sláðu inn heimilisfang',
        validations: ['REQUIRED(Þú þarft að slá inn heimilisfang)', 'MAXLENGTH(50)'],
        reviewPageTitle: 'Heimilisfang verslunar',
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'PaymentSolutions',
        conditionArrayValue: 'cardPresent',
      },
    },
    {
      componentType: 'FADEANIMATION',
      name: 'CardPresent.TradingStreetCity',
      component: {
        componentType: 'INPUT',
        name: 'CardPresent.TradingStreetCity',
        label: 'Staður',
        type: 'text',
        icon: 'TOWNBUILDING',
        placeholder: 'Sláðu inn stað',
        validations: ['REQUIRED(Þú þarft að slá inn stað)', 'MAXLENGTH(100 IS)'],
        reviewPageTitle: 'Staður',
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'PaymentSolutions',
        conditionArrayValue: 'cardPresent',
      },
    },
    {
      componentType: 'FADEANIMATION',
      name: 'CardPresent.TradingStreetPostcode',
      component: {
        componentType: 'INPUT',
        name: 'CardPresent.TradingStreetPostcode',
        label: 'Póstnúmer',
        type: 'text',
        icon: 'POSTBOXICON',
        placeholder: 'Sláðu inn póstnúmer',
        validations: ['REQUIRED(Þú þarft að slá inn póstnúmer)', 'MAXLENGTH(10 IS)', 'MINLENGTH(3 IS)'],
        reviewPageTitle: 'Póstnúmer',
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'PaymentSolutions',
        conditionArrayValue: 'cardPresent',
      },
    },
    {
      componentType: 'FADEANIMATION',
      name: 'Ecommerce.EcommerceSolutions.WebsiteURL',
      component: {
        componentType: 'INPUT',
        name: 'Ecommerce.EcommerceSolutions.WebsiteURL',
        label: 'Slóð vefsíðu',
        type: 'text',
        icon: 'STORE',
        placeholder: 'Sláðu inn slóð vefsíðu',
        validations: ['REQUIRED(Þú þarft að slá inn slóð vefsíðu)', 'WEBSITE(Slóð vefsíðu er ekki gild) ', 'MAXLENGTH(50 IS)'],
        reviewPageTitle: 'Slóð vefsíðu',
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'PaymentSolutions',
        conditionArrayValue: 'ecommerce',
      },
    },

    {
      componentType: 'RADIOBUTTONS',
      validations: ['REQUIRED(Þú þarft að velja vefkerfi)'],
      name: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
      label: 'Veldu vefkerfi',
      reviewPageTitle: 'Vefkerfi',
      radioValues: [
        {
          labelText: 'Shopify',
          name: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
          value: 'Shopify',
          checked: true,
        },
        {
          labelText: 'WooCommerce',
          name: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
          value: 'WooCommerce',
        },
        {
          labelText: 'Magento',
          name: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
          value: 'Magento',
        },
        {
          labelText: 'Annað / Er ekki viss',
          name: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
          value: 'OtherEcommerce',
        },
      ],
      conditional: {
        type: ['SHOW'],
        conditionName: 'PaymentSolutions',
        conditionArrayValue: 'ecommerce',
      },
    },
    {
      componentType: 'FADEANIMATION',
      name: 'Ecommerce.EcommerceSolutions.EcommerceType',
      component: {
        componentType: 'RADIOBUTTONS',
        validations: ['REQUIRED(Þú þarft að velja vefkerfi)'],
        name: 'Ecommerce.EcommerceSolutions.EcommerceType',
        label: 'Veldu veflausn',
        reviewPageTitle: 'Veflausn',
        radioValues: [
          {
            labelText: 'Greiðslusíða',
            name: 'Ecommerce.EcommerceSolutions.EcommerceType',
            value: 'WebPaymentPage',
            checked: true,
          },
        ],
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
        conditionValue: 'Shopify',
      },
    },
    {
      componentType: 'FADEANIMATION',
      name: 'Ecommerce.EcommerceSolutions.EcommerceType',
      component: {
        componentType: 'RADIOBUTTONS',
        validations: ['REQUIRED(Þú þarft að velja vefkerfi)'],
        name: 'Ecommerce.EcommerceSolutions.EcommerceType',
        label: 'Veldu veflausn',
        reviewPageTitle: 'Veflausn',
        radioValues: [
          {
            labelText: 'Greiðslusíða',
            name: 'Ecommerce.EcommerceSolutions.EcommerceType',
            value: 'WebPaymentPage',
            checked: true,
          },
          {
            labelText: 'ValitorPay',
            name: 'Ecommerce.EcommerceSolutions.EcommerceType',
            value: 'valitorPay',
          },
        ],
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
        conditionValue: 'WooCommerce',
      },
    },
    {
      componentType: 'FADEANIMATION',
      name: 'Ecommerce.EcommerceSolutions.EcommerceType',
      component: {
        componentType: 'RADIOBUTTONS',
        validations: ['REQUIRED(Þú þarft að velja vefkerfi)'],
        name: 'Ecommerce.EcommerceSolutions.EcommerceType',
        label: 'Veldu veflausn',
        reviewPageTitle: 'Veflausn',
        radioValues: [
          {
            labelText: 'Greiðslusíða',
            name: 'Ecommerce.EcommerceSolutions.EcommerceType',
            value: 'WebPaymentPage',
            checked: true,
          },
        ],
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
        conditionValue: 'Magento',
      },
    },
    {
      componentType: 'FADEANIMATION',
      name: 'Ecommerce.EcommerceSolutions.EcommerceType',
      component: {
        componentType: 'RADIOBUTTONS',
        validations: ['REQUIRED(Þú þarft að velja vefkerfi)'],
        name: 'Ecommerce.EcommerceSolutions.EcommerceType',
        label: 'Veldu veflausn',
        reviewPageTitle: 'Veflausn',
        radioValues: [
          {
            labelText: 'Greiðslusíða',
            name: 'Ecommerce.EcommerceSolutions.EcommerceType',
            value: 'WebPaymentPage',
            checked: true,
          },
          {
            labelText: 'ValitorPay',
            name: 'Ecommerce.EcommerceSolutions.EcommerceType',
            value: 'valitorPay',
          },
        ],
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'Ecommerce.EcommerceSolutions.EcommerceCMS',
        conditionValue: 'OtherEcommerce',
      },
    },
  ],
};
