import React from 'react';

export const PaymentIconBig = () => (
  <svg width="144" height="144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      d="M117 83.2432H139.5V132.748H117V83.2432Z"
      fill="#4F9EA6"
    />
    <path
      d="M76.6238 42.7524C86.5649 42.7524 94.6238 34.6936 94.6238 24.7524C94.6238 14.8113 86.5649 6.75244 76.6238 6.75244C66.6827 6.75244 58.6238 14.8113 58.6238 24.7524C58.6238 34.6936 66.6827 42.7524 76.6238 42.7524Z"
      fill="#F9D010"
    />
    <path
      d="M45.0181 83.2524C54.9592 83.2524 63.0181 75.1936 63.0181 65.2524C63.0181 55.3113 54.9592 47.2524 45.0181 47.2524C35.0769 47.2524 27.0181 55.3113 27.0181 65.2524C27.0181 75.1936 35.0769 83.2524 45.0181 83.2524Z"
      fill="#F9D010"
    />
    <path
      opacity="0.2"
      d="M81.1147 119.25H58.6147C56.2278 119.25 53.9386 118.302 52.2508 116.614C50.563 114.926 49.6147 112.637 49.6147 110.25C49.6147 107.863 50.563 105.574 52.2508 103.886C53.9386 102.198 56.2278 101.25 58.6147 101.25H67.6147C94.5 101.25 85.5 87.75 117 87.75V124.171C112.5 124.171 83.3918 137.25 67.5 137.25C57.96 137.25 49.6147 132.368 31.5 123.75C22.905 119.655 4.5 108.871 4.5 101.25C4.5 92.25 29.0093 103.642 49.6147 110.25"
      fill="#E95420"
    />
    <path
      opacity="0.15"
      d="M117 115.171C112.5 115.171 83.3917 128.25 67.5 128.25C57.96 128.25 49.6147 123.368 31.5 114.75C24.9502 111.632 12.726 104.625 7.26972 98.1001C6.48816 98.1595 5.76086 98.5224 5.24329 99.111C4.72571 99.6996 4.45893 100.467 4.49997 101.25C4.49997 108.873 22.905 119.657 31.5 123.75C49.6147 132.368 57.96 137.25 67.5 137.25C83.3917 137.25 112.5 124.173 117 124.173V132.75H139.5V123.75H117V115.171Z"
      fill="#1B5074"
    />
    <path
      d="M117 124.171C112.5 124.171 83.3918 137.25 67.5 137.25C57.96 137.25 49.6147 132.367 31.5 123.75C22.905 119.655 4.5 108.871 4.5 101.25C4.5 92.2499 29.0093 103.642 49.6148 110.25M117 83.2432H139.5V132.748H117V83.2432Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.6238 42.7524C86.5649 42.7524 94.6238 34.6936 94.6238 24.7524C94.6238 14.8113 86.5649 6.75244 76.6238 6.75244C66.6827 6.75244 58.6238 14.8113 58.6238 24.7524C58.6238 34.6936 66.6827 42.7524 76.6238 42.7524Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.0181 83.2524C54.9592 83.2524 63.0181 75.1936 63.0181 65.2524C63.0181 55.3113 54.9592 47.2524 45.0181 47.2524C35.0769 47.2524 27.0181 55.3113 27.0181 65.2524C27.0181 75.1936 35.0769 83.2524 45.0181 83.2524Z"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.1147 119.25H58.6147C56.2278 119.25 53.9386 118.302 52.2508 116.614C50.563 114.926 49.6147 112.637 49.6147 110.25C49.6147 107.863 50.563 105.574 52.2508 103.886C53.9386 102.198 56.2278 101.25 58.6147 101.25H67.6147C94.5 101.25 85.4212 87.75 116.921 87.75"
      stroke="#1B5074"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PaymentIconBig;
