export default {
  id: 'fullPayment',
  conditionalPreviousSteps: [
    {
      id: 'UpfrontPayments',
      value: ['fullPayment'],
      skipToStep: 'depositOrFullPayments',
      skipToPage: 'store',
    },
  ],
  fields: [
    {
      componentType: 'TITLE',
      text: 'What % of your annual turnover relates to full payments?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'AnnualTurnoverPayments',
      label: 'Annual turnover in %',
      type: 'NUMBER',
      fractional: 0,
      placeholder: 'E.g. 22%',
      validations: ['REQUIRED', 'INTEGER', 'MINVALUE(1)', 'MAXVALUE(100)'],
    },
    {
      componentType: 'TITLE',
      text: 'How long before delivery are full payments taken?',
      className: 'size-md-medium',
    },
    {
      componentType: 'INPUT',
      name: 'PaymentDays',
      label: 'Days',
      type: 'NUMBER',
      fractional: 0,
      placeholder: '0',
      validations: ['REQUIRED', 'INTEGER', 'MINVALUE(1)', 'MAXLENGTH(3)'],
    },
  ],
};
