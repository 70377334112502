import React, { FunctionComponent, SyntheticEvent, useContext } from 'react';

import { Button, ErrorLabel, Icon, ApplicationConsumer, FeesConsumer } from '@local';

import { SFieldSetButtons } from './index.styled';
import { AuthenticationConsumer } from '../../Context/AuthenticationConsumer';
import { useRouter } from 'next/router';
import { FormValuesTypes } from 'src/types/formValues';

interface FieldSetButtonsProps {
  nextButtonText?: string;
  previousButtonText?: string;
  page?: number;
  step?: number;
  onGoBack?: (ev: SyntheticEvent) => void;
  error?: boolean;
  loading?: boolean;
  showBackButton?: boolean;
  values?: FormValuesTypes;
}

const FieldSetButtons: FunctionComponent<FieldSetButtonsProps> = ({
  nextButtonText,
  previousButtonText,
  page,
  step,
  onGoBack,
  error,
  loading,
  showBackButton,
  values
}) => {

  const router = useRouter();
  const { applicationErrorMessage } = useContext(ApplicationConsumer);
  const { feesLoadedSuccessfully } = useContext(FeesConsumer);
  const { isAuthenticated, unsignedContract } = useContext(AuthenticationConsumer);

  let showGoBackButton = showBackButton;
  showGoBackButton = showGoBackButton ?? (step !== 0 || page !== 0);
  const nextText = nextButtonText || 'Save';
  const previousText = previousButtonText || 'Go back';

  const showNextButton = () => {
    // If Icelandic application and user is on authentication page
    // Don't show next button
    if (router.asPath.includes("/user/testing/") || router.asPath.includes("/umsokn/einstaklingur")) {
      if (step === 0 && page === 0) {
        return false;
      }
    }

    return true;
  }

  const isButtonDisabled = () => {
    // If Icelandic login is required
    // Disable button
    if (router.asPath === "/user/testing/" || router.asPath.includes("/umsokn/einstaklingur")) {
      if (!isAuthenticated) {
        return true;
      }
    }

    // If directors persons list is empty for Icelandic company application
    // Disable button
    if (router.asPath.includes("/umsokn/fyrirtaeki/") && page === 0 && step === 2) {
      if (values) {
        if (values.DirectorsPersons.length < 1) {
          return true;
        }
      }
    }

    // If contract doesn't load for Icelandic user
    // Disable button
    if (nextButtonText === "Undirrita samning og senda umsókn") {
      if (!unsignedContract) {
        return true;
      }
    }

    // If user hasn't accepted Rapyd's terms and conditions
    // Disable button
    if (router.asPath.includes("/umsokn/einstaklingur") && page === 0 && step === 1) {
      if (!values?.TermsAccepted) {
        return true;
      }
      if (values.TermsAccepted) {
        if (!values.TermsAccepted.includes("yes")) {
          return true;
        }
      }
    }

    // If fees have not loaded or loading === true
    // Disable button
    if (!feesLoadedSuccessfully || loading) {
      return true
    }

    // Otherwise enable button
    return false;
  }

  if (isAuthenticated && page === 0 && step === 1) {
    showGoBackButton = false;
  }

  if (router.asPath.includes("/umsokn/einstaklingur") && page === 0 && step === 1) {
    showGoBackButton = false;
  }

  return (
    <SFieldSetButtons {...{ showGoBackButton }}>
      {showNextButton() && (
        <Button type="submit" disabled={isButtonDisabled()} id={nextText}>
          {loading ? <Icon icon="LOADING" /> : nextText}
        </Button>
      )}
      {showGoBackButton && (
        <Button
          tag="secondary"
          disabled={loading}
          id={previousText}
          onClick={onGoBack}>
          {previousText}
        </Button>
      )}
      {error && (
        <ErrorLabel>
          {applicationErrorMessage !== ''
            ? applicationErrorMessage
            : 'Something went wrong, please try again.'}
        </ErrorLabel>
      )}
    </SFieldSetButtons>
  );
};

export { FieldSetButtons };
