/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AnyObject } from 'final-form';
import { ReviewListItem } from '../ReviewListItem';
import { Title } from '@local';
import { ReviewTerminalTable } from '../ReviewTerminalTable';
import objectPath from 'object-path';

interface ReviewListProps {
  step: any;
  values: AnyObject;
}

interface Component {
  label: string;
  name: string;
  reviewPageTitle: string;
  componentType: string;
  radioValues?: MultipleChoice[];
}

interface MultipleChoice {
  name: string,
  labelText: string;
  value: string;
  checked?: boolean
}

interface FieldItem {
  title: string;
  reviewPageTitle: string;
  name: string;
  components: Component[];
  component: Component;
  componentType: string;
  checkboxValues: MultipleChoice[];
  radioValues: MultipleChoice[]
  label?: string;
  id?: string;
  conditional?: any;
}

const getMonth = (month: number) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[month - 1];
};

const getNumberWithSeparator = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

const ReviewList = ({ step, values }: ReviewListProps) =>
  step &&
  step.fields.map(
    (item: FieldItem) => {
      const value = objectPath.get(values, item.name);

      if (!value && item.name !== "BusinessStartDate" && item.name !== "BirthdayInput") {
        return null;
      }

      if (item.name === 'AverageTransactionAmountMoreThanFifteenThousand' || item.name === 'MaxTransactionAmountMoreThanFiftyThousand') {
        return null;
      }

      // Some values are a differently structured than the basic values
      let customValue;

      if (item.componentType && item.reviewPageTitle) {
        if (item.componentType === "FADEANIMATION") {
          if (item.component.componentType === "CHECKBOX" && item.component.name === 'OnStockMarket') {
            if (values[item.component.name]) {
              customValue = values[item.component.name][0] === "yes" ? "Já" : "Nei";
            } else {
              return null;
            }
          }
        }

        if (
          item.componentType === 'DATEINPUT' && item.name === "BusinessStartDate"
        ) {
          // Show numbers for month in IS, but month name for UK.
          if (values.Kennitala) {
            customValue = `${values.BusinessStartDateStartDay} / ${values.BusinessStartDateStartMonth} / ${values.BusinessStartDateStartYear}`
          } else {
            customValue = `${values.BusinessStartDateStartDay} ${getMonth(values.BusinessStartDateStartMonth)} ${values.BusinessStartDateStartYear}`
          }
        } else if (item.componentType === 'DATEINPUT' && item.name === "BirthdayInput") {

          // No IS check needed because we show kennitala instead of birth date for IS applications.
          customValue = `${values.BirthdayInputStartDay} ${getMonth(values.BirthdayInputStartMonth)} ${values.BirthdayInputStartYear}`

        } else if (item.componentType === 'CHECKBOX') {
          if (item.name) {
            // Filter out items that are not stored in state (not checked) and return their labelText
            customValue = item.checkboxValues.filter(checkboxValue => values[item.name].includes(checkboxValue.value)).map((filtered) => filtered.labelText).join(', ');
          }
        } else if (
          item.componentType === 'RADIOBUTTONS'
        ) {
          customValue = item.radioValues.find(radioValue => objectPath.get(values, item.name) === radioValue.value)?.labelText;
        } else if (item.name === "AverageTimeDelivery") {
          if (values.Kennitala) {
            customValue = `${values[item.name]} ${parseInt(values[item.name]) > 1 ? 'dagar' : 'dagur'}`
          } else {
            customValue = `${values[item.name]} ${parseInt(values[item.name]) > 1 ? 'days' : 'day'}`
          }
        } else if (item.name === "AvgRevenue") {
          customValue = `${getNumberWithSeparator(values[item.name])}kr`
        }
      }
      if (
        item.componentType === 'INPUTSIDEBYSIDE' &&
        item.components !== undefined
      ) {
        return (
          <>
            {
              item.components.map((component, idx) => {
                if (!component.reviewPageTitle) { return null }
                else {
                  return (
                    <ReviewListItem
                      key={idx}
                      title={component.reviewPageTitle || component.label}
                    >
                      {values[component.name]}
                    </ReviewListItem>
                  )
                }
              })
            }
          </>
        )
      }

      // TODO: find a way to render one person at a time
      if (item.componentType === "DYNAMICACCORDION") {
        return (
          <>
            <Title
              tag="h2"
              className="size-md-small small-margin-top small-margin-bottom">
              {item.reviewPageTitle}
            </Title>
            {
              item.components.map((component) => {
                if (!component.reviewPageTitle || !component.name?.includes("DirectorsPersons")) { return null }
                const key = component.name?.split(".")[2];
                const directorsPersonsList = objectPath.get(values, "DirectorsPersons");
                return (
                  <>
                    {
                      directorsPersonsList.map((directorPerson: any, idx: any) => {
                        if (directorPerson[key]) {
                          customValue = directorPerson[key]
                          if (customValue) {
                            return (
                              <>
                                <ReviewListItem
                                  key={idx}
                                  title={component.reviewPageTitle}>
                                  {customValue}
                                </ReviewListItem>
                              </>
                            );
                          }
                        }
                      })
                    }
                  </>
                )
              })
            }
          </>
        )
      }

      if (item.name === "AvgTrans" && objectPath.get(values, 'AvgTrans') === "15001") {
        customValue = `${getNumberWithSeparator(objectPath.get(values, 'AverageTransactionAmountMoreThanFifteenThousand'))}kr`
      }
      if (item.name === "MaxTrans" && objectPath.get(values, 'MaxTrans') === "50001") {
        customValue = `${getNumberWithSeparator(objectPath.get(values, 'MaxTransactionAmountMoreThanFiftyThousand'))}kr`
      }

      // Show only the chosen EcommerceType
      if (item.name === "Ecommerce.EcommerceSolutions.EcommerceType") {
        if (item.conditional.conditionValue !== objectPath.get(values, 'Ecommerce.EcommerceSolutions.EcommerceCMS')) {
          return null;
        } else {
          // @ts-ignore
          customValue = item.component.radioValues.find((radioValue) => radioValue.value === objectPath.get(values, "Ecommerce.EcommerceSolutions.EcommerceType"))?.labelText;
        }
      }

      if (
        item.componentType === 'FADEANIMATION' &&
        item.component !== undefined &&
        item.component.reviewPageTitle
      ) {
        return <ReviewListItem
          title={item.component.reviewPageTitle || item.component.label}
        >
          {customValue || value}
        </ReviewListItem>
      }

      if (item.componentType === 'TERMINALLIST') {
        return <ReviewTerminalTable isCrossBorder={values.Kennitala ? false : true} />
      }

      if (item.reviewPageTitle && (customValue || typeof value === "string")) {
        return (
          <>
            <ReviewListItem
              title={item.reviewPageTitle}>
              {customValue || value}
            </ReviewListItem>
          </>
        );
      }


      if (customValue || value) {
        if (item.name === "Mcc") {
          return (
            <>
              <ReviewListItem
                title={item.label || ""}>
                {customValue || values[item.name].label}
              </ReviewListItem>
            </>
          );
        }
      }

      return null;
    },
  );

export { ReviewList };
