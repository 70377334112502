import styled, { css } from 'styled-components';

export const SReviewBlockContainer = styled.div`
  ${({ theme: { color, grid } }) => css`
    border-bottom: 2px solid ${color.grayFour};
    padding-top: ${grid.horizontal * 4}px;
    padding-bottom: ${grid.horizontal * 4}px;
    color: ${color.blue};
    font-weight: normal;
    position: relative;
    .review-list-title {
      font-weight: normal;
      margin-bottom: ${grid.horizontal * 2}px;
    }
  `}
`;

export const SButtonWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    button {
      right: ${grid.vertical * 4}px;
    }
  `}
`;
