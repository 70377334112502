export default {
  id: 'confirmAcquirerStatement',
  nextButtonText: 'Upload',
  previousButtonText: 'Use another image',
  fields: [
    {
      id: 'AcquirerStatement',
      placeholder:
        'Please note that you will have to provide a copy of an acquirer statement to finish the application at a later time.',
      componentType: 'UPLOADEDIMAGE',
    },
  ],
};
