import React from 'react';
import { Field } from 'react-final-form';
import { SFeesInputProps } from 'src/components/Input/FeesInput/index.styled';
import { validate } from '@utils';
import { FeesInput } from '@local';
import ObjectPath from 'object-path';
import {
  SFeesLineWrapper,
  SFeesInputWrapper,
  SErrorWrapper,
  SLabel,
} from './index.styled';

// The setting and reading values for the feesinput is hardcoded for now. We need to see if this kind of things will be needed in other forms
interface FeesLineProps extends SFeesInputProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
  initialValue?: string;
  subText?: string;
  text?: string;
  shouldUpdate?: string;
  validations?: string[];
  type?: string;
  fixed?: number;
}

const FeesLine = ({
  disabled,
  name,
  subText,
  text,
  validations,
  initialValue,
  type,
  fixed,
  values,
}: FeesLineProps) => {
  const fixedValue = (value: string) => {
    // Need this to update disabled fields immediately
    if (name === 'FeeList.MerchantServiceChargeFees.VisaPremiumCredit') {
      return ObjectPath.get(
        values,
        'FeeList.MerchantServiceChargeFees.VisaPremiumCredit',
      );
    }
    if (name === 'FeeList.MerchantServiceChargeFees.VisaPremiumDebit') {
      return ObjectPath.get(
        values,
        'FeeList.MerchantServiceChargeFees.VisaPremiumDebit',
      );
    }
    if (name === 'FeeList.MerchantServiceChargeFees.MastercardPremiumCredit') {
      return ObjectPath.get(
        values,
        'FeeList.MerchantServiceChargeFees.MastercardPremiumCredit',
      );
    }
    if (name === 'FeeList.MerchantServiceChargeFees.MastercardPremiumDebit') {
      return ObjectPath.get(
        values,
        'FeeList.MerchantServiceChargeFees.MastercardPremiumDebit',
      );
    }
    return value;
  };

  return (
    <SFeesLineWrapper>
      <SLabel htmlFor={name}>
        {text}
        <span>{subText}</span>
      </SLabel>
      <SFeesInputWrapper>
        <Field
          name={name}
          key={name}
          format={(value: string) =>
            value === undefined ? '' : Number(value).toFixed(fixed)
          }
          formatOnBlur
          initialValue={initialValue}
          validate={validate('', validations)}>
          {({ input, meta }) => {
            // Have to set the values here, only place where we have access to the input value onChange
            if (!disabled) {
              if (
                name === 'FeeList.MerchantServiceChargeFees.VisaDebit(Personal)'
              ) {
                ObjectPath.set(
                  values,
                  'FeeList.MerchantServiceChargeFees.VisaPremiumDebit',
                  (Number(input.value) + 0.4).toFixed(2),
                );
              } else if (
                name ===
                'FeeList.MerchantServiceChargeFees.VisaCredit(Personal)'
              ) {
                ObjectPath.set(
                  values,
                  'FeeList.MerchantServiceChargeFees.VisaPremiumCredit',
                  (Number(input.value) + 0.4).toFixed(2),
                );
              } else if (
                name ===
                'FeeList.MerchantServiceChargeFees.MastercardCredit(Personal)'
              ) {
                ObjectPath.set(
                  values,
                  'FeeList.MerchantServiceChargeFees.MastercardPremiumCredit',
                  (Number(input.value) + 0.4).toFixed(2),
                );
              } else if (
                name ===
                'FeeList.MerchantServiceChargeFees.MastercardDebit(Personal)'
              ) {
                ObjectPath.set(
                  values,
                  'FeeList.MerchantServiceChargeFees.MastercardPremiumDebit',
                  (Number(input.value) + 0.4).toFixed(2),
                );
              }
            }
            return (
              <>
                <FeesInput
                  disabled={disabled}
                  {...name}
                  {...input}
                  value={fixedValue(input.value)}
                  {...meta}
                  icon={
                    type?.toLowerCase() === 'percentage'
                      ? 'percentage'
                      : 'pound'
                  }
                />
                {meta.error && meta.touched && !meta.active && (
                  <SErrorWrapper>{meta.error}</SErrorWrapper>
                )}
              </>
            );
          }}
        </Field>
      </SFeesInputWrapper>
    </SFeesLineWrapper>
  );
};

export { FeesLine };
export type { FeesLineProps }