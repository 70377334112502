import React from 'react';

const InfoIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 0C12.8355 0 9.74205 0.938384 7.11087 2.69649C4.47968 4.45459 2.42892 6.95345 1.21792 9.87706C0.00691724 12.8007 -0.309936 16.0177 0.307427 19.1214C0.924791 22.2251 2.44864 25.0761 4.68628 27.3137C6.92392 29.5513 9.77485 31.0752 12.8785 31.6926C15.9822 32.3099 19.1993 31.9931 22.1229 30.7821C25.0465 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.9951 11.758 30.3078 7.69122 27.3083 4.6917C24.3088 1.69218 20.242 0.00489628 16 0V0ZM14.5115 7.5185C14.8472 7.20279 15.2907 7.02702 15.7515 7.02702C16.2123 7.02702 16.6558 7.20279 16.9915 7.5185C17.3176 7.84666 17.5009 8.29035 17.5015 8.753C17.5015 9.21713 17.3171 9.66225 16.9889 9.99044C16.6607 10.3186 16.2156 10.503 15.7515 10.503C15.2874 10.503 14.8422 10.3186 14.5141 9.99044C14.1859 9.66225 14.0015 9.21713 14.0015 8.753C14.0022 8.29039 14.1855 7.84676 14.5115 7.5185ZM18.0015 25.0035H14.0015V23.0035H15V14.012H14.002V12.012H17V23.0045H18.002L18.0015 25.0035Z"
      fill="#4F9EA6"
    />
  </svg>
);

export default InfoIcon;
