export default {
  id: 'hafaSamband',
  nextButtonText: 'Áfram',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Hvernig höfum við samband?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Email',
      label: 'Netfang',
      type: 'email',
      icon: 'MAIL',
      placeholder: 'Sláðu inn netfang',
      validations: ['REQUIRED(Þú þarft að slá inn netfang)', 'EMAIL(Ekki gilt netfang)', 'MAXLENGTH(60 IS)'],
      reviewPageTitle: 'Netfang',
    },
    {
      componentType: 'PHONENUMBER',
      name: 'PhoneNumber',
      label: 'Símanúmer',
      placeholder: 'Sláðu inn símanúmer',
      defaultCountry: 'IS',
      validations: ['REQUIRED(Þú þarft að slá inn símanúmer)', 'PHONENUMBER(Ekki gilt símanúmer)', 'MAXLENGTH(11 IS)'],
      reviewPageTitle: 'Símanúmer',
    },
  ],
};
