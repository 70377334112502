import { AnyObject } from 'react-final-form';
import { FieldTypes, ConditionalInterface } from 'src/types/field';

export interface ConditionalAugmentProps {
  field: FieldTypes;
  values: AnyObject;
  agent?: string;
  contextMap: {
    [index: string]: AnyObject;
  };
}

export const getReferenceValue = (
  { contextMap }: ConditionalAugmentProps,
  copyFrom: string,
) => {
  const isArray = copyFrom.includes('[');
  const isObjectArray = copyFrom.includes('{');

  // Add string to array to allow usage of array methods
  let copyFromString = [copyFrom];

  // ['CONTEXT.someField{objectArrayItemName}'] => ['CONTEXT.someField']['objectArrayItemName']
  copyFromString = copyFromString[0].replace(/}/g, '').split('{');
  const objectReference = copyFromString.slice(1);

  // ['CONTEXT.someField[arrayItemName]'] => ['CONTEXT.someField']['arrayItemName']
  copyFromString = copyFromString[0].replace(/]/g, '').split('[');
  const arrayReference = copyFromString.slice(1);

  // ['CONTEXT.someField.itemName'] => ['CONTEXT.someField']['itemName']
  const dotReference = copyFromString[0].split('.');

  // The context defaults to the default context VALUES if another valid context is not specified
  const desiredContext = contextMap[dotReference[0]]
    ? contextMap[dotReference[0]]
    : contextMap.VALUES;

  // Remove first item from array if it has a specific context
  if (desiredContext !== contextMap.VALUES) {
    dotReference.splice(0, 1);
  }

  // Gives referenceValue the value of referenced object, e.g. CONTEXT.typeOfCards
  let referenceValue = dotReference.reduce(
    (accumulatedObject: AnyObject, childName: string) =>
      accumulatedObject !== undefined
        ? accumulatedObject[childName]
        : undefined,
    desiredContext,
  );

  if (referenceValue !== undefined && isArray) {
    // Gives referenceValue the value of referenced array item if it is an array
    referenceValue =
      referenceValue[referenceValue.findIndex(arrayReference[0])];
  } else if (referenceValue !== undefined && isObjectArray) {
    // Gives referenceValue the value of referenced object array item if it is an object array
    const reference = referenceValue.find(
      (val: AnyObject) => val.name === objectReference[0],
    );
    referenceValue = reference ? reference.value : undefined;
  }

  return (referenceValue as unknown) as string;
};

export const compareReferenceValue = (
  referenceValue?: string | number,
  conditionValue?: string,
) =>
  conditionValue && referenceValue
    ? String(referenceValue) === String(conditionValue)
    : !!referenceValue;

export const referenceHasCorrectValue = (
  { field, values, contextMap }: ConditionalAugmentProps,
  { conditionName, conditionValue }: ConditionalInterface,
) => {
  const conditionNameAsArray = Array.isArray(conditionName)
    ? conditionName
    : [conditionName];
  let correctValueFlag = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, value] of conditionNameAsArray.entries()) {
    const referenceValue = getReferenceValue(
      { field, values, contextMap },
      value,
    );

    if (Array.isArray(conditionValue)) {
      correctValueFlag = compareReferenceValue(
        referenceValue,
        conditionValue[index],
      );
      if (!correctValueFlag) {
        return false;
      }
    } else {
      correctValueFlag =
        correctValueFlag ||
        compareReferenceValue(referenceValue, conditionValue);
    }
  }
  return correctValueFlag;
};
