export default {
  id: 'storeType',
  conditionalPreviousSteps: [
    {
      id: 'PreviouslyAcceptedCards',
      value: 'no',
      skipToStep: 'paymentCards',
      skipToPage: 'store',
    },
    {
      id: 'FasterPayments',
      value: undefined,
      skipToStep: 'fasterPayments',
      skipToPage: 'store',
    },
    {
      id: 'FasterPayments',
      value: [],
      skipToStep: 'fasterPayments',
      skipToPage: 'store',
    },
  ],
  fields: [
    {
      componentType: 'MULTIPLESTORES',
      addButtonText: 'Add store',
      addButtonIcon: 'INCREMENT',
      removeButtonText: 'Remove store',
      removeButtonIcon: 'DECREMENT',
    },
  ],
};
