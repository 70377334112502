import React, { useState } from 'react';
import Link from 'next/link';

import { Title, Select, PrivacyPolicy } from '@local';

import { SButtonWrapper, SButton } from '../index.styled';

// TODO have the text and list of forms come from a JSON so it can be easily changable
// Valitor IS beginning screen

const ValitorISBeginning = () => {
  const forms = [
    {
      value: 'einstaklingur',
      label: 'Einstaklingur',
    },
    {
      value: 'fyrirtaeki',
      label: 'Fyrirtæki og önnur félög',
    },
  ];

  const [applicationName, setApplicationName] = useState(forms[0].value);

  return (
    <>
      <Title tag="h1" className="size-md-medium extra-margin-top">
        Hvaða umsóknarform á við um þig?
      </Title>
      <div className="row">
        <div className="col-sm-5 col-md-5 col-lg-7">
          <Select
            id="chooseLegalForm"
            label="Umsóknarform"
            placeholder="Select"
            onChange={(ev: { value: string }) => {
              setApplicationName(ev.value);
            }}
            defaultValue={forms[0]}
            options={forms}
          />
        </div>
      </div>
      <SButtonWrapper>
        <Link href={`/umsokn/${applicationName}`}>
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <SButton id="approveAndContinue" onClick={() => {}} type="button">
            Áfram
          </SButton>
        </Link>
      </SButtonWrapper>
      <PrivacyPolicy />
    </>
  );
};

export { ValitorISBeginning };
