export default {
  title: {
    componentType: 'TITLE',
    text: 'What are the opening hours for your store/s?',
    className: 'size-md-medium',
  },
  textArea: {
    componentType: 'TEXTAREA',
    name: 'OpeningHours',
    label: 'Opening hours',
    placeholder: 'E.g. Mon-Fri 9-17',
    rows: 3,
    validations: ['REQUIRED'],
  },
};
