import styled, { css } from "styled-components";

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;

  p {
    margin: 0;
    text-align: center;
    font-size: 12px;
  }
`

export const SHeadline = styled.h5`
  ${({ theme: { color } }) => css`
      font-size: 18px;
      color: ${color.blue};
      margin-bottom: 22px;
      font-weight: normal;
  `}
`