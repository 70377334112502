import React from 'react';

const InfoIconError = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16zm-1.1523-13.21h2.6211l.5478-10.49508h-3.7168L14.8477 18.79zm-.1075 2.3096c-.3222.308-.4834.7627-.4834 1.3643 0 .58.1647 1.0312.4942 1.3535.3294.3151.7949.4726 1.3965.4726.5872 0 1.0455-.1611 1.375-.4834.3294-.3294.4941-.777.4941-1.3427 0-.5873-.1647-1.0384-.4941-1.3535-.3223-.3152-.7806-.4727-1.375-.4727-.6159 0-1.085.154-1.4073.4619z"
      fill="#D50000"
    />
  </svg>
);

export default InfoIconError;
