import styled, { css } from 'styled-components';

interface ButtonProps {
  increment?: boolean;
}

export const SButton = styled.button<ButtonProps>`
  border: none;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  background-color: transparent;
  padding: 0;
`;

export const SWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    display: flex;
    justify-content: space-between;
    margin-top: ${grid.horizontal * 2}px;
    width: 10px;
    &.no-margins {
      margin-top: 0;
      margin-bottom: 0;
    }
  `}
`;

export const SCount = styled.input`
  ${({ theme: { breakpoints } }) => css`
    border: none;
    font-size: 22px;
    height: 40px;
    background-color: transparent;
    max-width: 104px;
    &.medium-width {
      max-width: 50px;
      @media (min-width: ${breakpoints.desktopMin}) {
        max-width: 50px;
      }
      @media (min-width: ${breakpoints.desktopMed}) {
        max-width: 104px;
      }
      @media (min-width: ${breakpoints.desktopMax}) {
        max-width: 104px;
      }
    }
    text-align: center;
  `}
`;

export const SParagraph = styled.p`
  ${({ theme: { color, grid } }) => css`
    margin-top: ${grid.horizontal}px;
    margin-bottom: ${grid.horizontal}px;
    &.no-margins {
      margin-top: 0;
      margin-bottom: 0;
    }
    color: ${color.blue};
  `}
`;
