import React from 'react';
import { Label } from '@local';
import ObjectPath from 'object-path';

import { AnyObject } from 'final-form';
import { STextArea, TextAreaWrapper } from './index.styled';

interface TextAreaProps {
  name: string;
  label?: string;
  active?: boolean;
  disabled?: boolean;
  rows?: number;
  error?: boolean;
  icon?: string;
  message?: string;
  required?: boolean;
  placeholder?: string;
  touched?: boolean;
  value?: string;
  values?: AnyObject;
}

const TextArea = (props: TextAreaProps) => {
  const {
    disabled,
    label,
    name,
    active,
    error,
    touched,
    message,
    rows = 3,
    value,
    values,
  } = props;

  return (
    <>
      <TextAreaWrapper active={active} error={error} touched={touched}>
        <Label {...{ name, label, disabled }} />
        <STextArea
          id={name}
          {...props}
          rows={rows}
          value={values ? values[name] || ObjectPath.get(values, name) : value}
          data-testid={name}
        />
        {(error && message) || (error && touched && !active && error)}
      </TextAreaWrapper>
    </>
  );
};

export { TextArea };
