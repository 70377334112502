import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Title, Select } from '@local';

import { SButtonWrapper, SButton } from '../index.styled';
// TODO have the text and list of forms come from a JSON so it can be easily changable

const Countries = {
  DK: [
    { value: 'soletrader', label: 'Sole Trader' },
    {
      value: 'privatelimitedcompany',
      label: 'Private Limited Company',
    },
  ],
  UK: [
    { value: 'soletrader', label: 'Sole Trader' },
    {
      value: 'privatelimitedcompany',
      label: 'Private Limited Company',
    },
  ],
};

const AbsBeginning = () => {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const forms = (Countries as any)[`${router.query.country}`];
  // eslint-disable-next-line dot-notation
  const [applicationName, setApplicationName] = useState(forms[0].value);
  return (
    <>
      <Title tag="h1" className="size-md-medium extra-margin-top">
        What type of legal form are you applying for?
      </Title>
      <div className="row">
        <div className="col-sm-5 col-md-5 col-lg-7">
          <Select
            id="chooseLegalForm"
            label="Legal forms"
            placeholder="Select"
            onChange={(ev: { value: string }) => {
              setApplicationName(ev.value);
            }}
            defaultValue={forms[0]}
            options={forms}
          />
        </div>
      </div>
      <SButtonWrapper>
        <Link href={`/abs/${applicationName}?country=${router.query.country}`}>
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <SButton id="approveAndContinue" onClick={() => {}} type="button">
            Continue
          </SButton>
        </Link>
      </SButtonWrapper>
    </>
  );
};

export { AbsBeginning };
