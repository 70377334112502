

const errors: { [key: number]: string } = {
  6001: '- Notandi er ekki með rafræn skilríki.',
  6005: '- Auðkenning rann út á tíma',
  7022: '- Ekki tókst að senda auðkenningarbeiðni í síma.',
  7023: '- Notandi hætti við.'
}

const getErrorByDokobitErrorCode = (errorCode: number): string => {

  return errors[errorCode] || '';
}

export { getErrorByDokobitErrorCode }