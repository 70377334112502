import React from 'react';
import { RadioButtons } from '@local';
import { AnyObject } from 'final-form';
import ObjectPath from 'object-path';
import { FormValuesTypes } from 'src/types/formValues';
import { RadioButtonProps } from '../RadioButton';

interface DynamicRadioButtonsProps {
  radioValues?: RadioButtonProps[];
  values?: FormValuesTypes;
  validations?: string[];
}

const conditionalRadioButtons = (
  values?: AnyObject,
  hiddenStatusDependsOn?: string,
) => {
  if (values) {
    if (
      values[hiddenStatusDependsOn as string] ||
      ObjectPath.get(values, hiddenStatusDependsOn as string)
    ) {
      return false;
    }
  }

  if (!hiddenStatusDependsOn) return false;
  return true;
};

const DynamicRadioButtons = ({
  radioValues,
  values,
  validations,
}: DynamicRadioButtonsProps) => {
  const newRadioValues: RadioButtonProps[] = [];
  if (radioValues) {
    radioValues.forEach(radioValue => {
      const isHidden = conditionalRadioButtons(
        values,
        radioValue.hiddenStatusDependsOn,
      );
      if (!isHidden) {
        const newRadioValue = radioValue;
        if (values && radioValue.hiddenStatusDependsOn) {
          newRadioValue.labelText = ObjectPath.get(
            values,
            radioValue.hiddenStatusDependsOn as string,
          );
        }

        newRadioValues.push(newRadioValue);
      }
    });
  }
  return <RadioButtons values={values} validations={validations} radioValues={newRadioValues} />;
};

export { DynamicRadioButtons };
export type { DynamicRadioButtonsProps }
