import styled, { css } from 'styled-components';

interface TextAreaProps {
  active?: boolean;
  error?: boolean;
  touched?: boolean;
}

export const TextAreaWrapper = styled.div<TextAreaProps>`
  font-size: 18px;
  .error-label {
    position: absolute;
  }
`;

export const STextArea = styled.textarea<TextAreaProps>`
  ${({ theme: { color, grid }, touched, active, error }) => css`
    padding-left: ${grid.vertical * 3}px;
    padding-right: ${grid.vertical * 3}px;
    padding-top: 20px;
    width: 100%;
    color: ${color.darkGray};
    caret-color: ${color.teal};
    position: relative;
    border: 1px solid ${color.grayThree};
    border-radius: 4px;
    outline: none;
    font-size: 20px;
    background: inherit;
    margin-bottom: ${grid.horizontal * 3}px;
    min-height: ${grid.horizontal * 6}px;
    max-height: ${grid.horizontal * 40}px;
    ::placeholder {
      color: ${color.grayThree};
      font-weight: 100;
      line-height: 34px;
      font-size: 20px;
    }
    ${!error &&
      touched &&
      css`
        border-color: ${color.teal};
      `};
    ${error &&
      touched &&
      css`
        margin-bottom: ${active
            ? grid.horizontal * 3
            : grid.horizontal * 0.5}px;
        border-color: ${color.red};
      `};
    ${active &&
      css`
        border-color: ${color.teal};
      `};
    &:disabled {
      background: ${color.lightGray};
      border-color: ${color.grayTwo};
    }
  `}
`;
