export default {
  title: 'What % of your annual turnover relates to deposits?',
  id: 'deposits',
  conditionalNextSteps: [
    {
      // 9
      id: 'UpfrontPayments',
      value: ['deposit'],
      skipToPage: 'terminals',
      skipToStep: 'terminalList',
    },
  ],
  fields: [
    {
      componentType: 'INPUT',
      name: 'AnnualTurnoverDeposits',
      label: 'Annual turnover in %',
      type: 'NUMBER',
      fractional: 0,
      placeholder: 'E.g. 22%',
      validations: ['REQUIRED', 'INTEGER', 'MINVALUE(1)', 'MAXVALUE(100)'],
    },
    {
      componentType: 'TITLE',
      text: 'How long before delivery are deposits taken?',
      className: 'size-md-medium',
    },
    {
      componentType: 'INPUT',
      name: 'DepositDays',
      label: 'Days',
      type: 'NUMBER',
      fractional: 0,
      placeholder: '0',
      validations: ['REQUIRED', 'INTEGER', 'MINVALUE(1)', 'MAXLENGTH(3)'],
    },
  ],
};
