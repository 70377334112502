import styled, { css } from 'styled-components';

const SEditButton = styled.button`
  ${({ theme: { color, grid } }) => css`
    color: ${color.darkGray};
    font-size: 14px;
    display: flex;
    font-weight: normal;
    flex-direction: column;
    border: none;
    background-color: transparent;
    padding: ${grid.vertical}px;
  `}
`;

const SWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: flex-end;
  `}
`;

export { SEditButton, SWrapper };
