export default {
  id: 'einstaklingarFyrirtaekis',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Einstaklingar fyrirtækis',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'DYNAMICACCORDION',
      name: "DirectorsPersons",
      reviewPageTitle: "Einstaklingar fyrirtækis",
      titleFieldName: 'DirectorsPersons.*.FirstName',
      addButtonText: 'Bæta við',
      addButtonIcon: 'INCREMENT',
      removeButtonText: 'Fjarlægja',
      removeButtonIcon: 'DECREMENT',
      listName: 'DirectorsPersons',
      initializeList: false,
      maxListLength: 2,
      components: [
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.FirstName',
          label: 'Fornafn',
          type: 'text',
          icon: 'USER',
          placeholder: 'Sláðu inn fornafn',
          validations: ['REQUIRED(Þú þarft að slá inn fornafn)', 'MAXLENGTH(50 IS)'],
          reviewPageTitle: 'Fornafn',
          className: 'medium-margin-top',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.MiddleName',
          label: 'Millinafn (valkvætt)',
          type: 'text',
          icon: 'USER',
          placeholder: 'Sláðu inn millinafn',
          validations: ['MAXLENGTH(50 IS)'],
          reviewPageTitle: 'Millinafn',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.LastName',
          label: 'Eftirnafn',
          type: 'text',
          icon: 'USER',
          placeholder: 'Sláðu inn eftirnafn',
          validations: ['REQUIRED(Þú þarft að slá inn eftirnafn)', 'MAXLENGTH(50 IS)'],
          reviewPageTitle: 'Eftirnafn',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.Kennitala',
          label: 'Kennitala',
          type: 'text',
          mask: 'KENNITALA',
          placeholder: 'Sláðu inn kennitölu',
          validations: ['REQUIRED(Þú þarft að slá inn kennitölu)', 'MAXLENGTH(11 IS)', 'MINLENGTH(11 IS)'],
          reviewPageTitle: 'Kennitala',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.Email',
          label: 'Netfang',
          type: 'email',
          icon: 'MAIL',
          placeholder: 'Sláðu inn netfang',
          validations: ['REQUIRED(Þú þarft að slá inn netfang)', 'EMAIL(Ekki gilt netfang)', 'MAXLENGTH(60 IS)'],
          reviewPageTitle: 'Netfang',
        },
        {
          componentType: 'PHONENUMBER',
          name: 'DirectorsPersons.*.PhoneNumber',
          label: 'Símanúmer',
          placeholder: 'Sláðu inn símanúmer',
          defaultCountry: 'IS',
          validations: ['REQUIRED(Þú þarft að slá inn símanúmer)', 'PHONENUMBER(Ekki gilt símanúmer)', 'MAXLENGTH(11 IS)'],
          reviewPageTitle: 'Símanúmer',
        },
        {
          componentType: 'TITLE',
          text: 'Heimilisfang',
          className: 'size-md-medium',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.Street',
          label: 'Heimilisfang',
          type: 'text',
          icon: 'HOUSE',
          placeholder: 'Sláðu inn heimilisfang',
          validations: ['REQUIRED(Þú þarft að slá inn heimilisfang)', 'MAXLENGTH(50 IS)'],
          reviewPageTitle: 'Heimilisfang',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.City',
          label: 'Staður',
          type: 'text',
          icon: 'TOWNBUILDING',
          placeholder: 'Sláðu inn stað',
          validations: ['REQUIRED(Þú þarft að slá inn stað)', 'MAXLENGTH(100 IS)'],
          reviewPageTitle: 'Staður',
        },
        {
          componentType: 'INPUT',
          name: 'DirectorsPersons.*.Postcode',
          label: 'Póstnúmer',
          type: 'text',
          icon: 'POSTBOXICON',
          placeholder: 'Sláðu inn póstnúmer',
          validations: ['REQUIRED(Þú þarft að slá inn póstnúmer)', 'MAXLENGTH(10 IS)'],
          reviewPageTitle: 'Póstnúmer',
        },
        {
          componentType: 'TITLE',
          text: 'Hlutverk',
          className: 'size-md-medium',
          reviewPageTitle: 'Hlutverk'
        },
        // TODO: Using a checkbox in the DynamicAccordion component is not working properly, so we need to find a way to make this work
        /* {
          componentType: 'CHECKBOX',
          validations: ['CHECKBOX'],
          reviewPageTitle: 'Hlutverk einstaklings',
          checkboxValues: [
            {
              labelText: 'Forstjóri / Framkvæmdarstjóri',
              name: 'DirectorsPersons.*.Hlutverk',
              value: 'ceo',
            },
            {
              labelText: 'Eigandi / Hluthafi fyrirækis',
              name: 'DirectorsPersons.*.Hlutverk',
              value: 'partOwner',
            },
            {
              labelText: 'Raunverulegur eigandi (Yfir 25% eignarhald)',
              name: 'DirectorsPersons.*.Hlutverk',
              value: 'owner',
            },
            {
              labelText: 'Prókúruhafi',
              name: 'DirectorsPersons.*.Hlutverk',
              value: 'proxyHolder',
            },
            {
              labelText: 'Stjórn',
              name: 'DirectorsPersons.*.Hlutverk',
              value: 'board',
            },
            {
              labelText: 'Tengiliður',
              name: 'DirectorsPersons.*.Hlutverk',
              value: 'contact',
            },
          ],
        }, */
        {
          componentType: 'INFO',
          className: 'extra-margin-bottom',
          text:
            'Hakið við í öll þau hlutverk sem á við um einstakling.',
        },
      ],
    },
    {
      componentType: 'FADEANIMATION',
      reviewPageTitle: "Er fyrirtækið skráð á hlutabréfamarkað?",
      component: {
        componentType: 'TITLE',
        text: 'Er fyrirtækið skráð á hlutabréfamarkað?',
        className: 'size-md-medium',
      },
      conditional: {
        type: ['SHOW'],
        conditionName: '', // Ef fyrirtækið er hlutafélag
      },
    },
    {
      componentType: 'FADEANIMATION',
      reviewPageTitle: "Er fyrirtækið skráð á hlutabréfamarkað?",
      component: {
        componentType: 'CHECKBOX',
        reviewPageTitle: 'Er fyrirtækið skráð á hlutabréfamarkað?',
        name: 'OnStockMarket',
        checkboxValues: [
          {
            labelText: 'Já, það er skráð',
            name: 'OnStockMarket',
            value: 'yes',
          },
        ]
      },
      conditional: {
        type: ['SHOW'],
        conditionName: '', // Ef fyrirtækið er hlutafélag
      },
    },
  ],
};
