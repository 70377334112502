import React, { FunctionComponent } from 'react';

import { SDescriptionTerm, SDescriptionDetail } from './index.styled';

interface ReviewListItemProps {
  title: string;
  className?: string;
}

const ReviewListItem: FunctionComponent<ReviewListItemProps> = ({
  title,
  className,
  children,
}) => (
  <>
    <SDescriptionTerm data-testid={title}>{title}</SDescriptionTerm>
    <SDescriptionDetail className={className as string}>
      {children}
    </SDescriptionDetail>
  </>
);

export { ReviewListItem };
export type { ReviewListItemProps }