import React from 'react';
import { FieldTypes } from 'src/types/field';
import { AnyObject } from 'final-form';
import { Field as FinalFormField, FormRenderProps } from 'react-final-form';
import { validate } from '@utils';

interface FieldProps {
  name?: string;
  field: FieldTypes;
  values: AnyObject;
  form: FormRenderProps['form'];
  children: Function;
}

const getFormat = (fractional?: number) => {
  return fractional !== undefined
    ? (value: string) =>
        !Number.isNaN(Number(value))
          ? Number(value).toFixed(fractional)
          : undefined
    : undefined;
};

const Field = ({ field, values, form, children }: FieldProps) => {
  const key = field.validations
    ? ((field.name as string) || (field.id as string) || 'Missing name') + 0
    : ((field.name as string) || (field.id as string) || 'Missing name') + 1;

  const validation = !field.disabled
    ? validate(field.label, field.validations)
    : undefined;

  return (
    <FinalFormField
      name={(field.name as string) || (field.id as string) || 'Missing name'}
      // When validations change on runtime, we change the key to force rerender:
      // See https://final-form.org/docs/react-final-form/types/FieldProps for an example.
      key={key}
      validate={validation}
      initialValue={field.value}
      format={getFormat(field.fractional)}
      formatOnBlur={!field.disabled}>
      {({ input, meta }) => (
        <>
          {children({ ...input, ...meta, ...field, values, form })}
          {meta.error && meta.touched && !meta.active && meta.error}
        </>
      )}
    </FinalFormField>
  );
};

export { Field };
export type { FieldProps }
