export default {
  bankNameTitle: {
    componentType: 'TITLE',
    text: 'What’s the name of your bank?',
    className: 'size-md-medium no-margin-top',
  },
  bankNameInput: {
    componentType: 'INPUT',
    name: 'BankName',
    label: 'Bank name',
    type: 'text',
    icon: 'BANK',
    placeholder: 'The name of your bank',
    validations: ['REQUIRED', 'MAXLENGTH(50)'],
    reviewPageTitle: 'Bank name',
  },
  swiftCodeInput: {
    componentType: 'INPUT',
    name: 'SwiftCode',
    label: 'SWIFT Code',
    type: 'text',
    placeholder: 'BUKBGB22',
    reviewPageTitle: 'SWIFT Code',
    validations: ['REQUIRED', 'MINLENGTH(8)', 'MAXLENGTH(11)'],
  },
  accountDetailsTitle: {
    componentType: 'TITLE',
    text: 'What are your account details?',
    className: 'size-md-medium',
  },
  accountNumberInput: {
    componentType: 'INPUTSIDEBYSIDE',
    reviewPageTitle: 'Account details',
    components: [
      {
        componentType: 'INPUT',
        name: 'SortCode',
        label: 'Sort code',
        type: 'text',
        placeholder: '00-00-00',
        mask: 'SORTCODE',
        validations: ['REQUIRED', 'SORTCODE', 'MAXLENGTH(8)'],
        reviewPageTitle: 'Sort code',
      },
      {
        componentType: 'INPUT',
        name: 'AccountNumber',
        label: 'Account number',
        type: 'text',
        placeholder: '12345678',
        validations: ['REQUIRED', 'NUMBER', 'MINVALUE(1)', 'MAXLENGTH(8)'],
        reviewPageTitle: 'Account number',
      },
    ],
  },
  ibanInput: {
    componentType: 'INPUT',
    name: 'Iban',
    label: 'IBAN number',
    type: 'text',
    placeholder: 'GB29 NWBK 6016 1331 9268 19',
    reviewPageTitle: 'IBAN number',
  },
  accountOwnerInput: {
    componentType: 'INPUT',
    name: 'AccountOwner',
    label: 'Account owner',
    type: 'text',
    placeholder: 'The name of the bank account owner',
    reviewPageTitle: 'Account owner name',
  },
};
