export default {
  id: 'managementInfo.tsx',
  showOnTemplates: 'showOnCountries',
  showOnCountries: ['DK'],
  fields: [
    {
      componentType: 'TITLE',
      text: 'Please fill out management information',
      className: 'size-md-small no-margin-top',
    },
    {
      componentType: 'DYNAMICACCORDION',
      titleFieldName: 'Directors.*.FirstName',
      addButtonText: 'Add person',
      addButtonIcon: 'INCREMENT',
      removeButtonText: 'Remove person',
      removeButtonIcon: 'DECREMENT',
      startOpen: true,
      listName: 'Directors',
      initializeList: true,
      components: [
        {
          componentType: 'INPUT',
          name: 'Directors.*.FirstName',
          label: 'First name',
          type: 'text',
          icon: 'USER',
          placeholder: 'Enter persons’s first name',
          validations: ['REQUIRED', 'MAXLENGTH(50)'],
          reviewPageTitle: 'First Name',
          className: 'medium-margin-top',
        },
        {
          componentType: 'INPUT',
          name: 'Directors.*.MiddleName',
          label: 'Middle name (optional)',
          type: 'text',
          icon: 'USER',
          placeholder: 'Enter person’s middle name',
          validations: ['MAXLENGTH(50)'],
          reviewPageTitle: 'Middle Name',
        },
        {
          componentType: 'INPUT',
          name: 'Directors.*.LastName',
          label: 'Last name',
          type: 'text',
          icon: 'USER',
          placeholder: 'Enter person’s last name',
          validations: ['REQUIRED', 'MAXLENGTH(50)'],
          reviewPageTitle: 'Last Name',
        },
        {
          componentType: 'TITLE',
          text: 'When is the person’s birthday?',
          className: 'size-md-medium',
        },
        {
          componentType: 'DATEINPUT',
          name: 'Directors.*.Birth',
        },
        {
          componentType: 'TITLE',
          text: 'What’s the person’s home address?',
          className: 'size-md-medium',
        },
        {
          componentType: 'INPUT',
          name: 'Directors.*.Street',
          label: 'Address Line 1',
          type: 'text',
          icon: 'HOUSE',
          placeholder: 'Enter the person’s address',
          validations: ['REQUIRED', 'MAXLENGTH(50)'],
          reviewPageTitle: 'Address Line 1',
        },
        {
          componentType: 'INPUT',
          name: 'Directors.*.StreetContinued',
          label: 'Address Line 2 (optional)',
          type: 'text',
          icon: 'HOUSE',
          placeholder: 'Enter the person’s address',
          reviewPageTitle: 'Address Line 2',
        },
        {
          componentType: 'INPUT',
          name: 'Directors.*.City',
          label: 'Town/City',
          type: 'text',
          icon: 'TOWNBUILDING',
          placeholder: 'E.g. London',
          validations: ['REQUIRED', 'MAXLENGTH(100)'],
          reviewPageTitle: 'Town/City',
        },
        {
          componentType: 'INPUT',
          name: 'Directors.*.Postcode',
          label: 'Postcode',
          type: 'text',
          icon: 'POSTBOXICON',
          placeholder: 'E.g. SW1Y 4QE',
          validations: ['REQUIRED', 'MAXLENGTH(10)'],
          reviewPageTitle: 'Postcode',
        },
        {
          componentType: 'TITLE',
          text: 'How can we contact the person?',
          className: 'size-md-medium',
        },
        {
          componentType: 'INPUT',
          name: 'Directors.*.Email',
          label: 'Email',
          type: 'email',
          icon: 'MAIL',
          placeholder: 'Enter person’s email',
          validations: ['REQUIRED', 'EMAIL', 'MAXLENGTH(60)'],
          reviewPageTitle: 'Email',
        },
        {
          componentType: 'PHONENUMBER',
          name: 'Directors.*.Phone',
          label: 'Phone number',
          placeholder: 'E.g 7911 123456',
          validations: ['REQUIRED', 'PHONENUMBER', 'MAXLENGTH(15)'],
          reviewPageTitle: 'Store Phone number',
        },
        {
          componentType: 'TITLE',
          text: 'Select person’s role',
          className: 'size-md-medium',
        },
        {
          componentType: 'SELECT',
          label: 'Roles',
          multiSelect: true,
          options: 'ROLES',
          placeholder: 'E.g. restaurant',
          name: 'Directors.*.Roles',
        },
        {
          componentType: 'INFO',
          text: 'Note: You can select multiple roles for each person',
          className: 'medium-margin-top medium-margin-bottom',
        },
        {
          componentType: 'FADEANIMATION',
          name: 'Directors.*.Identification',
          component: {
            componentType: 'MEDIAUPLOAD',
            label: 'Media_Upload',
            fileTypesAccepted: 'image/*,application/pdf',
            validations: ['REQUIRED(You must upload a photo of person’s ID)'],
          },
          conditional: {
            type: ['SHOW'],
            conditionName: 'Directors.*.Roles',
            conditionValue: ['beneficialOwner', 'authorizedSignatory'],
          },
        },
      ],
    },
  ],
};
