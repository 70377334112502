import React from 'react';

export const UserIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0c-1.7998 0-3.734.544415-5.2268 1.828-1.50655 1.29533-2.51513 3.30248-2.51513 6.12039v4.95481c0 3.2905 1.74713 6.0861 3.61293 7.8628v1.4275c0 .3194-.1513.8468-.9491 1.4281-.8134.5925-2.27422 1.2184-4.81684 1.6764-1.52413.2561-2.93051.9811-4.02339 2.074C.988039 28.4656.262829 29.8732.0071077 31.3985c-.0250993.1497.0169826.3029.1150493.4188.098067.1159.242172.1827.393976.1827H31.4839c.1617 0 .3141-.0758.4117-.2048.0975-.1291.1289-.2963.0849-.452-.4304-1.5197-1.275-2.8897-2.4393-3.957s-2.6025-1.7898-4.1539-2.0867l-.0006-.0001c-2.5487-.4842-3.8645-1.1079-4.5382-1.6749-.6346-.5341-.7195-1.034-.7195-1.431v-1.4276c1.867-1.7806 3.6129-4.6236 3.6129-7.8627V7.94839c0-2.81791-1.0086-4.82506-2.5151-6.12039C19.734.544415 17.7998 0 16 0z"
      fill="#555"
    />
  </svg>
);

export default UserIcon;
