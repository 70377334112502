export default {
  id: 'email',
  fields: [
    {
      componentType: 'TITLE',
      text: 'How can we contact you?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'INPUT',
      name: 'Email',
      label: 'Email',
      type: 'email',
      icon: 'MAIL',
      placeholder: 'Enter your email',
      validations: ['REQUIRED', 'EMAIL', 'MAXLENGTH(60)'],
      reviewPageTitle: 'Email',
    },
    {
      componentType: 'PHONENUMBER',
      name: 'Phone',
      label: 'Phone number',
      placeholder: 'E.g 7911 123456',
      validations: ['REQUIRED', 'PHONENUMBER', 'MAXLENGTH(15)'],
      reviewPageTitle: 'Store Phone number',
    },
  ],
};
