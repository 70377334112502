export default {
  id: 'confirmBusinessExistence',
  nextButtonText: 'Upload',
  previousButtonText: 'Use another image/s',
  showOnTemplates: 'showOnAgents',
  showOnAgents: ['virtual'],
  fields: [
    {
      componentType: 'UPLOADEDIMAGES',
      valuesArray: 'Stores',
      propertyName: 'BusinessExistence',
      placeholder:
        'Please note that you will have to provide a valid photos of business to finish the application at a later time.',
    },
  ],
};
