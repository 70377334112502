import React, { useState, useContext, useEffect } from 'react';
import { Icon, ApplicationConsumer, AccordionList, Accordion } from '@local';
import { FieldTypes, ConditionalInterface } from 'src/types/field';
import { FormValuesTypes } from 'src/types/formValues';
import { FormRenderProps, FormSpy } from 'react-final-form';
import { replaceWildCard, changeNumberToWildCardChar } from '@utils';
import ObjectPath from 'object-path';
import { AugmentComponent } from '../../../helpers/augmentComponent';
import { ComponentMap } from '../../../helpers/componentMapper/index';
import {
  SButtonWrapper,
  SAddButton,
  SRemoveButton,
  SAccordionListWrapper,
  SAccordionWrapper,
} from './index.styled';

interface DynamicAccordionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
  startOpen?: boolean;
  components: string;
  addButtonText: string;
  removeButtonText: string;
  titleFieldName: string;
  addButtonIcon: string;
  removeButtonIcon: string;
  form: FormRenderProps['form'];
  listName: string;
  isCompanyInfo: boolean;
  initializeList: boolean;
  maxListLength: number;
}

const newFields = (isCompanyInfo: boolean) => {
  return isCompanyInfo
    ? {
        Name: '',
        Number: '',
        Shares: '',
      }
    : { FirstName: '', MiddleName: '', LastName: '' };
};

const renderComponents = (
  components: FieldTypes[],
  index: number,
  accordionTitleFieldName: string,
  form: FormRenderProps['form'],
  startOpen?: boolean,
) => {
  const componentElements = [] as JSX.Element[];
  for (let i = 0; i < components.length; i++) {
    // Replacing * wildcard since using array in values
    const copyComponent = Object.assign({}, components[i]);
    copyComponent.name = replaceWildCard(
      copyComponent.name || '',
      '*',
      index.toString(),
    );

    // Replacing * wildcard for checkbox values
    if (copyComponent.componentType === "CHECKBOX") {
      if (copyComponent.checkboxValues) {
        copyComponent.checkboxValues.forEach(value => {
          value.name = changeNumberToWildCardChar(value.name, '*');
          value.name = replaceWildCard(value.name || '', '*', index.toString());
        })
      }
    }

    // Replacing * wildcard for components in conditional property
    if (copyComponent.conditional) {
      const copyConditional = Object.assign(
        {},
        copyComponent.conditional,
      ) as ConditionalInterface;
      const name = replaceWildCard(
        (copyComponent.conditional as ConditionalInterface).conditionName.toString(),
        '*',
        index.toString(),
      );

      copyConditional.conditionName = name;
      copyComponent.conditional = copyConditional;
    }

    // Rendering each component and adding to JSX Elements array
    const renderedComponent = (
      <div>
        <FormSpy subscription={{ values: true, valid: true }}>
          {props => (
            <AugmentComponent
              values={props.values}
              field={copyComponent}
              form={form}
              render={(field: FieldTypes) => {
                return <ComponentMap field={field} values={props.values} />;
              }}
            />
          )}
        </FormSpy>
      </div>
    );
    componentElements.push(renderedComponent);
  }

  // Wrapping JSX element array with Accordion
  return (
    <FormSpy subscription={{ values: true, valid: true }}>
      {props => (
        <SAccordionWrapper>
          <Accordion
            title="Missing name"
            id={`Accordion-${index}`}
            startOpen={startOpen}
            titleFieldName={replaceWildCard(
              accordionTitleFieldName,
              '*',
              index.toString(),
            )}
            values={props.values}>
            {componentElements}
          </Accordion>
        </SAccordionWrapper>
      )}
    </FormSpy>
  );
};

const initializeInputs = (
  components: FieldTypes[],
  accordionTitleFieldName: string,
  form: FormRenderProps['form'],
  listName: string,
  values?: FormValuesTypes,
  startOpen?: boolean,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialList = [] as JSX.Element[];

  if (values) {
    const list = (ObjectPath.get(values, listName) as unknown) as string[];
    if (list && list.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      list.forEach((index: any, key: any) => {
        initialList.push(
          renderComponents(
            components,
            key,
            accordionTitleFieldName,
            form,
            startOpen,
          ),
        );
      });
      return initialList;
    }
    return [];
  }
  return [];
};

const DynamicAccordion = ({
  values,
  components,
  titleFieldName,
  form,
  startOpen,
  addButtonText,
  removeButtonText,
  addButtonIcon,
  removeButtonIcon,
  listName,
  isCompanyInfo,
  initializeList,
  maxListLength,
}: DynamicAccordionProps) => {
  let list = (ObjectPath.get(values, listName) as unknown) as object[];

  if (values && !list) {
    // eslint-disable-next-line no-param-reassign
    ObjectPath.set(values, listName, []);
    ObjectPath.push(values, listName, newFields(isCompanyInfo));
    list = ObjectPath.get(values, listName);
    if (!initializeList) {
      list.pop();
    }
  }

  const { managementInfo, setManagementInfo, companyInfo, setCompanyInfo } = useContext(ApplicationConsumer);
  // Using state variable to put inputs and info in correct index in array
  const [buttonClick, setButtonClick] = useState(list.length || 0);

  const initializeCompanyInfo = () => {
    if (list.length !== companyInfo.length) {
      // When refreshing we need to initilize our Elements to match directors in values
      const initialElements = initializeInputs(
        (components as unknown) as FieldTypes[],
        titleFieldName,
        form,
        listName,
        values,
      );
      setCompanyInfo(initialElements);
    }
  };

  const initializeManagementInfo = () => {
    const initialElements = initializeInputs(
      (components as unknown) as FieldTypes[],
      titleFieldName,
      form,
      listName,
      values,
    );
    setManagementInfo(initialElements);
  };

  useEffect(() => {
    if (isCompanyInfo) {
      initializeCompanyInfo();
    } else if (list.length !== managementInfo.length) {
      initializeManagementInfo();
    }
  }, []);

  const onAddBtnClick = () => {
    setButtonClick(buttonClick + 1);
    list.push(newFields(isCompanyInfo));
    const addButton = renderComponents(
      (components as unknown) as FieldTypes[],
      buttonClick,
      titleFieldName,
      form,
      startOpen,
    );
    let newJsxElements = [] as JSX.Element[];

    if (managementInfo && managementInfo.length > 0) {
      newJsxElements = managementInfo;
    }

    if (companyInfo && companyInfo.length > 0) {
      newJsxElements = companyInfo;
    }

    newJsxElements.push(addButton);
    isCompanyInfo
      ? setCompanyInfo(newJsxElements)
      : setManagementInfo(newJsxElements);
  };

  const onRmvBtnClick = () => {
    setButtonClick(buttonClick - 1);
    const newInfoList = isCompanyInfo ? companyInfo : managementInfo;
    newInfoList.pop();
    isCompanyInfo
      ? setCompanyInfo(newInfoList)
      : setManagementInfo(newInfoList);
    list.pop();
  };

  return (
    <>
      <SAccordionListWrapper>
        <AccordionList>
          {isCompanyInfo ? companyInfo : managementInfo}
        </AccordionList>
      </SAccordionListWrapper>
      <SButtonWrapper>
        <SAddButton
          type="button"
          id="AddPerson"
          data-testid="AddPerson"
          onClick={onAddBtnClick}
          disabled={buttonClick === maxListLength}>
          <Icon icon={addButtonIcon} />
          {addButtonText}
        </SAddButton>
        {!isCompanyInfo && initializeList && managementInfo.length > 1 && (
          <>
            <SRemoveButton
              type="button"
              id="RemovePerson"
              data-testid="RemovePerson"
              onClick={onRmvBtnClick}>
              <Icon icon={removeButtonIcon} />
              {removeButtonText}
            </SRemoveButton>
          </>
        )}
        {!isCompanyInfo && !initializeList && managementInfo.length > 0 && (
          <>
            <SRemoveButton
              type="button"
              id="RemovePerson"
              data-testid="RemovePerson"
              onClick={onRmvBtnClick}>
              <Icon icon={removeButtonIcon} />
              {removeButtonText}
            </SRemoveButton>
          </>
        )}
        {isCompanyInfo && companyInfo.length > 0 && (
          <>
            <SRemoveButton
              type="button"
              id="RemovePerson"
              data-testid="RemovePerson"
              onClick={onRmvBtnClick}>
              <Icon icon={removeButtonIcon} />
              {removeButtonText}
            </SRemoveButton>
          </>
        )}
      </SButtonWrapper>
    </>
  );
};

export { DynamicAccordion };