import React from 'react';

export const TribunalIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        opacity=".1"
        d="M2.38566 12.5L16.4666 1.50001 30.5475 12.5H2.38566zm29.16764 18H1.37988V28h1.00578v-1.45H30.5475V28h1.0058v2.5zm-29.67053-18H30.5475V16H1.88277v-3.5zM14.455 26.55V16h4.0231v10.55H14.455zm10.0578 0V16h4.0231v10.55h-4.0231zm-20.11558 0V16h4.02312v10.55"
        fill="#4F9EA6"
      />
      <path
        opacity=".15"
        d="M1.88281 14.5V16h2.51445v3h4.02312v-3h6.03472v3h4.0231v-3h6.0347v3h4.0231v-3h2.0115v-1.5H1.88281zm23.13299-2l-8.5492-7.49997v2L23.0042 12.5h2.0116zm-22.6301 14h28.1618v2H2.3857v-2z"
        fill="#555"
      />
      <path
        d="M2.38566 12.5L16.4666 1.50001 30.5475 12.5H2.38566zM14.455 16v10.55V16zm4.0231 0v10.55V16zm6.0347 0v10.55V16zm4.0231 0v10.55V16zM4.39722 16v10.55V16zm4.02312 0v10.55V16zM31.5533 30.5H1.37988V28h1.00578v-1.45H30.5475V28h1.0058v2.5zm-29.67053-18H30.5475V16H1.88277v-3.5z"
        stroke="#1B5074"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          transform="translate(.374023)"
          d="M0 0h32.185v32H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default TribunalIcon;
