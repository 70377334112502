import React, { useContext } from 'react';

import { ApplicationConsumer } from '@local';
import { PageStructure } from '../../types/application';
import { ReviewBlock } from './ReviewBlock';
import { ReviewCompany } from './ReviewCompany';

interface ReviewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
  extraComponents?: {
    componentType: string;
  }[];
}

const Review = ({ values, extraComponents }: ReviewProps) => {
  const { application } = useContext(ApplicationConsumer);

  return (
    <>
      {extraComponents &&
        extraComponents.map(
          extraComponent =>
            extraComponent.componentType === 'COMPANYREVIEW' && (
              <ReviewCompany {...values} />
            ),
        )}
      {application.pages.map(
        (page: PageStructure, index: number) =>
          page.showOnReviewPage && (
            <ReviewBlock
              key={page.title}
              title={page.title}
              values={values}
              page={page}
              pageNumber={index}
              formSetup={application}
            />
          ),
      )}
    </>
  );
};

export { Review };
