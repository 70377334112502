import React from 'react';

const number = (e: React.BaseSyntheticEvent) => {
  const { value } = e.target;
  // No value, so return early
  if (!value) {
    return value;
  }

  // Filter string to only have numbers
  const onlyNums = value.replace(/[^\d]/g, '');

  return onlyNums;
};

export { number };