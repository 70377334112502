export default {
  icon: 'POSTERMINALICON',
  title: 'Terminal',
  id: 'terminals',
  showOnReviewPage: true,
  steps: [
    // Step terminalList
    {
      title: 'List of terminals that you can order for your business',
      id: 'terminalList',
      conditionalPreviousSteps: [
        {
          id: 'PaymentsAdvance',
          value: 'no',
          skipToStep: 'paymentInAdvance',
          skipToPage: 'store',
        },
        {
          id: 'UpfrontPayments',
          value: ['deposit'],
          skipToStep: 'deposits',
          skipToPage: 'store',
        },
        {
          id: 'UpfrontPayments',
          value: ['fullPayment'],
          skipToStep: 'fullPayment',
          skipToPage: 'store',
        },
      ],
      information:
        'Here is a list of terminals we have in stock right now that you can pick and choose of your liking. You can order one of each, few of the same one or just the one you want and need.',
      fields: [
        {
          componentType: 'TERMINALLIST',
          name: 'terminalList',
          validations: ['POSLIST'],
          terminals: [
            {
              kicker: 'Pax A80',
              maxValue: 400,
              minValue: 280,
              title: 'Ethernet Countertop',
              image: {
                name: 'pax-terminal',
                alt: 'Image of POS device',
              },
              index: 0,
              list: [
                {
                  text: 'Android operating system',
                },
                {
                  text: 'Market leading technology',
                },
                {
                  text: 'Contactless',
                },
                {
                  text: 'Ethernet only',
                },
                {
                  text: 'Mains powered',
                },
              ],
            },
            {
              kicker: 'Pax A920',
              maxValue: 500,
              minValue: 330,
              title: 'Wi-Fi',
              image: {
                name: 'pax-countertop',
                alt: 'Image of POS device',
              },
              index: 1,
              list: [
                {
                  text: 'Android operating system',
                },
                {
                  text: 'Charging base (if needed)',
                },
                {
                  text: 'Touch screen',
                },
                {
                  text: 'Contactless',
                },
              ],
            },
            {
              kicker: 'Pax A920 4G',
              maxValue: 600,
              minValue: 410,
              title: 'GPRS & Wi-Fi',
              image: {
                name: 'pax-countertop',
                alt: 'Image of POS device',
              },
              index: 2,
              list: [
                {
                  text: 'Android operating system',
                },
                {
                  text: 'Charging base (if needed)',
                },
                {
                  text: 'Touch screen',
                },
                {
                  text: 'Contactless',
                },
                {
                  text: 'Global romaing SIM card',
                },
                {
                  text: 'Wi-Fi Capability',
                },
              ],
            },
            {
              kicker: 'Ingenico Move3500',
              maxValue: 600,
              minValue: 410,
              title: 'Bluetooth, Wi-Fi',
              image: {
                name: 'bluetoothWifi',
                alt: 'Image of POS device',
              },
              index: 3,
              list: [
                {
                  text: 'Telium Tetra Operating System',
                },
                {
                  text: "Supports multiple MID's, separate Amex MID etc",
                },
                {
                  text: 'Portable handheld device with a battery',
                },
                {
                  text: 'Ethernet connection via base',
                },
                {
                  text: 'Contactless',
                },
              ],
            },
            {
              kicker: 'Ingenico Move3500N',
              title: 'GPRS',
              maxValue: 600,
              minValue: 400,
              image: {
                name: 'bluetoothWifi',
                alt: 'Image of POS device',
              },
              index: 4,
              list: [
                {
                  text: 'Telium Tetra Operating System',
                },
                {
                  text: "Supports multiple MID's, separate Amex MID etc",
                },
                {
                  text: 'Portable handheld device with a battery',
                },
                {
                  text: 'Contactless',
                },
              ],
            },
            {
              kicker: 'Ingenico Move5000',
              title: 'GPRS/Bluetooth (Ethernet Only) and Wi-Fi',
              maxValue: 800,
              minValue: 540,
              image: {
                name: 'move5000-terminal',
                alt: 'Image of POS device',
              },
              index: 5,
              list: [
                {
                  text: 'Telium Tetra Operating System',
                },
                {
                  text: "Supports multiple MID's, separate Amex MID etc",
                },
                {
                  text: 'Portable handheld device with a battery',
                },
                {
                  text: 'Ethernet connection via base',
                },
                {
                  text: 'Contactless',
                },
                {
                  text: 'Touch screen',
                },
              ],
            },
          ],
        },
        {
          componentType: 'TITLE',
          text:
            'Would you like to add any of the following services to your terminal?',
          className: 'size-md-medium',
        },
        {
          componentType: 'CHECKBOX',
          label: 'Terminal services',
          stateManagementRequired: true,
          checkboxValues: [
            {
              labelText: 'Tips',
              name: 'TerminalServices',
              value: 'TIPS',
            },
            {
              labelText: 'Cash-back',
              name: 'TerminalServices',
              value: 'CASHBACK',
              disabled: true,
              disabledStatusDependsOn: {
                context: 'terminal',
                listenToValues: [
                  'PAX A80',
                  'PAXA920',
                  'PAXA9204G',
                  'MOVE3500WIFI',
                  'MOVE3500NGPRS',
                ],
              },
            },
            {
              labelText: 'Staff ID',
              name: 'TerminalServices',
              value: 'STAFFID',
              disabled: true,
              disabledStatusDependsOn: {
                context: 'terminal',
                listenToValues: ['MOVE3500WIFI', 'MOVE3500NGPRS'],
              },
            },
          ],
        },
        {
          componentType: 'TITLE',
          text: 'Would you like to rent or purchase your terminal/s?',
          className: 'size-md-medium',
        },
        {
          componentType: 'RADIOBUTTONS',
          validations: ['REQUIRED'],
          radioValues: [
            {
              labelText: 'Rent',
              name: 'PurchaseOrRentTerminal',
              value: 'Rent',
              checked: true,
            },
            {
              labelText: 'Purchase',
              name: 'PurchaseOrRentTerminal',
              value: 'Purchase',
            },
          ],
        },
        {
          componentType: 'FADEANIMATION',
          component: {
            componentType: 'TITLE',
            text: 'Select the minimum terminal contract length:',
            className: 'size-md-medium',
          },
          conditional: {
            type: ['SHOW'],
            conditionName: 'PurchaseOrRentTerminal',
            conditionValue: 'Rent',
          },
        },
        {
          componentType: 'FADEANIMATION',
          component: {
            componentType: 'RADIOBUTTONS',
            validations: ['REQUIRED'],
            radioValues: [
              {
                labelText: '18 months',
                name: 'TerminalContractLength',
                value: '18',
              },
              {
                labelText: '24 months',
                name: 'TerminalContractLength',
                value: '24',
              },
              {
                labelText: '36 months',
                name: 'TerminalContractLength',
                value: '36',
              },
              {
                labelText: '48 months',
                name: 'TerminalContractLength',
                value: '48',
                checked: true,
              },
            ],
          },
          conditional: {
            type: ['SHOW'],
            conditionName: 'PurchaseOrRentTerminal',
            conditionValue: 'Rent',
          },
        },
        {
          componentType: 'FADEANIMATION',
          component: {
            componentType: 'TITLE',
            text: 'Please enter prices for terminal purchase excluding VAT',
            className: 'size-md-medium',
          },
          conditional: {
            type: ['SHOW'],
            conditionName: 'PurchaseOrRentTerminal',
            conditionValue: 'Purchase',
          },
        },
        {
          componentType: 'FADEANIMATION',
          component: {
            componentType: 'BUYTERMINALLIST',
            terminalsName: 'terminalList',
          },
          conditional: {
            type: ['SHOW'],
            conditionName: 'PurchaseOrRentTerminal',
            conditionValue: 'Purchase',
          },
        },
        {
          componentType: 'TITLE',
          text: 'Terminal fees',
          className: 'size-md-medium',
          conditional: {
            type: ['SHOW'],
            conditionName: 'PurchaseOrRentTerminal',
            conditionValue: 'Rent',
          },
        },
        {
          componentType: 'INPUT',
          name: 'PaymentTerminalFee',
          label: 'Payment terminal fee for all terminals - per month',
          type: 'NUMBER',
          icon: 'POUND',
          fractional: 2,
          placeholder: 'E.g. 3.50',
          validations: ['NUMBER', 'MINVALUE(0)'],
          conditional: {
            type: ['SHOW'],
            conditionName: 'PurchaseOrRentTerminal',
            conditionValue: 'Rent',
          },
        },
      ],
    },
  ],
};
