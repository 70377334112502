import { isValidPhoneNumber } from 'react-phone-number-input';

const phoneNumber = (value: string | undefined, label: string | undefined, arg: string | undefined) => {
  if (value) {
    return isValidPhoneNumber(value) ? undefined : arg ? `${arg}` : 'Not a valid phone number';
  } else {
    return undefined;
  }
}

export { phoneNumber };
