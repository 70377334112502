export default {
  id: 'faerslusvid',
  nextButtonText: 'Áfram',
  previousButtonText: 'Til baka',
  fields: [
    {
      componentType: 'TITLE',
      text: 'Áætluð meðaltalsfærsla?',
      className: 'size-md-medium no-margin-top',
    },
    {
      componentType: 'RADIOBUTTONS',
      reviewPageTitle: 'Áætluð meðaltalsfærsla',
      name: 'AvgTrans',
      validations: ['REQUIRED(Þú þarft að velja upphæð)'],
      radioValues: [
        // TODO: Handle this in sendApplication rewrite
        // Split values by "-" and get [1]
        {
          labelText: '0kr - 4.999kr',
          name: 'AvgTrans',
          value: '0-4999',
        },
        {
          labelText: '5.000kr - 9.999kr',
          name: 'AvgTrans',
          value: '5000-9999',
        },
        {
          labelText: '10.000kr - 14.999kr',
          name: 'AvgTrans',
          value: '10000-14999',
        },
        {
          labelText: 'Meira en 14.999kr',
          name: 'AvgTrans',
          value: '15000',
        },
      ],
    },
    {
      componentType: 'FADEANIMATION',
      name: 'AverageTransactionAmountMoreThanFifteenThousand',
      component: {
        componentType: 'INPUT',
        reviewPageTitle: 'Áætluð meðaltalsfærsla',
        label: 'Áætluð meðaltalsfærsla',
        type: "NUMBER",
        validations: ['REQUIRED(Þú þarft að velja upphæð)', 'MINVALUE(15000 IS)'],
        placeholder: "Sláðu inn upphæð"
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'AvgTrans',
        conditionValue: '15000',
      },
    },
    {
      componentType: 'TITLE',
      text: 'Áætluð hámarksupphæð?',
      className: 'size-md-medium',
    },
    {
      componentType: 'RADIOBUTTONS',
      validations: ['REQUIRED(Þú þarft að velja upphæð)'],
      name: 'MaxTrans',
      reviewPageTitle: 'Áætluð hámarksupphæð',
      // TODO: Handle this in sendApplication rewrite
      // Split values by "-" and get [1]
      radioValues: [
        {
          labelText: '0kr - 9.999kr',
          name: 'MaxTrans',
          value: '0-9999',
        },
        {
          labelText: '10.000kr - 24.999kr',
          name: 'MaxTrans',
          value: '10000-24999',
        },
        {
          labelText: '25.000kr - 49.999kr',
          name: 'MaxTrans',
          value: '25000-49999',
        },
        {
          labelText: 'Meira en 49.999kr',
          name: 'MaxTrans',
          value: '50000',
        },
      ],
    },
    {
      componentType: 'FADEANIMATION',
      name: 'MaxTransactionAmountMoreThanFiftyThousand',
      reviewPageTitle: 'Áætluð hámarksupphæð',
      component: {
        componentType: 'INPUT',
        label: 'Áætluð hámarksupphæð',
        type: "NUMBER",
        validations: ['REQUIRED(Þú þarft að velja upphæð)', 'MINVALUE(50000 IS)'],
        placeholder: "Sláðu inn upphæð"
      },
      conditional: {
        type: ['SHOW'],
        conditionName: 'MaxTrans',
        conditionValue: '50000',
      },
    },
    {
      componentType: 'TITLE',
      text: 'Áætluð mánaðarleg velta?',
      className: 'size-md-medium',
    },
    {
      componentType: 'INPUT',
      validations: ['REQUIRED(Þú þarft að slá inn upphæð)'],
      type: "NUMBER",
      label: "Áætluð mánaðarleg velta",
      reviewPageTitle: 'Áætluð mánaðarleg velta',
      placeholder: 'Sláðu inn upphæð',
      name: "AvgRevenue",
      labelPosition: 'top'
    },
    {
      componentType: 'TITLE',
      text: 'Áætlaður afhendingartími?',
      className: 'size-md-medium',
    },
    {
      componentType: 'INPUT',
      validations: ['REQUIRED(Þú þarft að slá inn fjölda daga)'],
      type: "NUMBER",
      label: "Dagar",
      reviewPageTitle: 'Áætlaður afhendingartími',
      placeholder: "Sláðu inn fjölda daga",
      name: "AverageTimeDelivery",
      labelPosition: 'right'
    },
  ],
};
