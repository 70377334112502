import * as Validators from '@validators';

type ValidatorFunction = (
  value: string,
  label?: string | undefined,
  args?: string | undefined,
) => string | undefined;

interface ValidatorMapProps {
  [index: string]: ValidatorFunction;
}

const validatorMap: ValidatorMapProps = {
  CHECKBOX: Validators.checkbox,
  EMAIL: Validators.email,
  MAXLENGTH: Validators.maxLength,
  MINLENGTH: Validators.minLength,
  MAXVALUE: Validators.maxValue,
  MINVALUE: Validators.minValue,
  INTEGER: Validators.integer,
  NUMBER: Validators.number,
  PHONENUMBER: Validators.phoneNumber,
  POSLIST: Validators.posList,
  REQUIRED: Validators.required,
  SELECTREQUIRED: Validators.selectRequired,
  SORTCODE: Validators.sortCode,
  WEBSITE: Validators.website,
  DATE: Validators.date,
  ISAT: Validators.isat,
};

// The function takes in a list of strings and checks if there exists a corresponding validator and returns it
const validatorFactory = (validators: string[]) =>
  validators.reduce(
    (validatorArray: ValidatorFunction[], validator: string) => {
      // Remove the closing parenthesis and split by the opening one.
      const splitValidator = validator.replace(/[)]/g, '').split('(');
      let mappedValidator;

      if (splitValidator.length > 1) {
        // Spread the validator arguments to an arg constant
        const args = splitValidator
          ? [...splitValidator[1].split(',')]
          : undefined;
        // Create a new anonymous function and inject args at the end
        mappedValidator = validatorMap[splitValidator[0]]
          ? (value: string, label: string | undefined) =>
            args === undefined
            ? validatorMap[splitValidator[0]](value, label)
            : validatorMap[splitValidator[0]](value, label, ...args)
          : undefined;
      } else {
        mappedValidator = validatorMap[splitValidator[0]];
      }

      mappedValidator
        ? validatorArray.push(mappedValidator)
        : // eslint-disable-next-line no-console
          console.warn(
            `ValidatorFactory: "${validator}" is not a registered type, cannot create validator.`,
          );
      return validatorArray;
    },
    [],
  );

export { validatorFactory };
