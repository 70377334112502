import styled, { css } from 'styled-components';

const STable = styled.table`
  ${({ theme: { color, grid } }) => css`
    color: ${color.blue};
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    margin-bottom: ${grid.horizontal * 2}px;
    th {
      font-size: 16px;
      line-height: 1.375em;
      padding-bottom: ${grid.horizontal * 2}px;
      font-weight: normal;
      &:last-child {
        padding-right: ${grid.vertical * 2}px;
        text-align: right;
      }
    }

    td {
      padding-bottom: ${grid.horizontal * 2}px;
      font-size: 22px;
    }
    td:first-child {
      color: ${color.darkGray};
    }
    td:last-child {
      padding-left: ${grid.vertical * 4}px;
      font-weight: bold;
      text-align: right;
      padding-right: ${grid.vertical * 2}px;
    }
  `}
`;

export { STable };
