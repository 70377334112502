import React, { FunctionComponent, useState, createContext } from 'react';

import listOfTerminals from '../../public/static/content/terminals';

interface Terminals {
  value: number;
  terminals: Terminal[];
}

interface Terminal {
  id: string;
  name: string;
  value: number;
  type: string;
  maxValue: number;
  minValue: number;
}

interface TerminalInterface {
  terminals: Terminals;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTerminals: (value: any) => void;
}

const TerminalConsumer = createContext({} as TerminalInterface);

const TerminalProvider: FunctionComponent = ({ children }) => {
  const [terminals, setTerminals] = useState({
    value: 0,
    terminals: listOfTerminals,
  });

  return (
    <TerminalConsumer.Provider
      value={{
        terminals,
        setTerminals,
      }}>
      {children}
    </TerminalConsumer.Provider>
  );
};

export {TerminalConsumer, TerminalProvider};
export type { TerminalInterface };
