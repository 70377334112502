/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useContext, useState, useEffect } from 'react';

import { Icon, TerminalConsumer } from '@local';
import { SWrapper, SParagraph, SCount, SButton } from './index.styled';

interface CounterProps {
  name: string;
  onChange: (setNewValue: SetValueProps) => void;
  title: string;
  index?: number;
  text?: string;
  className?: string;
  defaultValue?: number;
}

interface SetValueProps {
  value: number;
}

const Counter = ({ name, onChange, title, index, text, className, defaultValue }: CounterProps) => {
  const { terminals, setTerminals } = useContext(TerminalConsumer);
  const [loadFromSessionStroage, setloadFromSessionStorage] = useState(true);
  const theIndex = index ? index : Number(name.split('.').pop());

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const counter = document.getElementById(name) as HTMLInputElement;
      // Need to change value of counter manually after session storage has been cleared
      if (terminals.value === 0) {
        terminals.terminals[theIndex].value = 0;
        counter.value = "0";
      }
    }

    if (typeof window !== 'undefined' && loadFromSessionStroage) {
      const sessionTerminal = sessionStorage.getItem('data');
      if (sessionTerminal !== null) {
        const parsedData = JSON.parse(sessionTerminal);
        const { terminalList } = parsedData;
        if (terminalList && terminals.terminals[theIndex].value === 0) {
          if (
            terminals.terminals[theIndex].value !==
            terminalList.terminals[theIndex].value
          ) {
            terminals.terminals[theIndex].value =
              terminalList.terminals[theIndex].value;

            setTerminals(terminalList);
          }
        }
      }
    }
  })

  const handleChange = (delta: number) => {
    if (terminals.terminals[theIndex].value + delta >= 0) {
      setloadFromSessionStorage(false);
      // Final Form is unable to recognise that value has changed via
      // OnChange() since it's unable to deep-compare objects so we
      // initialize a new object that is not passed by reference
      const newValue = { ...terminals };
      newValue.value += delta;
      const terminalValue = newValue.terminals[theIndex].value + delta;
      newValue.terminals[theIndex].value = terminalValue;
      setTerminals(newValue);
      onChange(newValue);
    }
  };

  useEffect(() => {
    // Only add the default value once
    if (typeof defaultValue !== 'undefined' && terminals.terminals[theIndex].value < 1) {
      handleChange(defaultValue);
    }
  }, [])

  return (
    <>
      <SParagraph className={className}>{text}</SParagraph>
      <SWrapper className={className}>
        <SButton
          type="button"
          id={`decrement-${name}`}
          data-testid={`decrement-${name}`}
          className="decrement"
          onClick={() => handleChange(-1)}>
          <Icon icon="DECREMENT" />
          <span className="sr-only">Decrease number of terminals</span>
        </SButton>
        <label className="sr-only" htmlFor={name} id="asd">
          Number of {title}
        </label>
        <SCount
          id={name}
          data-testid={title}
          value={terminals.terminals[theIndex].value}
          className={className}
        />
        <SButton
          type="button"
          id={`increment-${name}`}
          data-testid={`increment-${name}`}
          className="increment"
          onClick={() => handleChange(1)}>
          <Icon icon="INCREMENT" />
          <span className="sr-only">Increase number of terminals</span>
        </SButton>
      </SWrapper>
    </>
  );
};

export { Counter };
export type { CounterProps, SetValueProps }
