import { theme } from '../variables';

export const cookieConsentStyles = {
  background: theme.color.teal,
  justifyContent: "center",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
} as React.CSSProperties;

export const cookieConsentButtonStyles = {
  background: "white",
  fontWeight: "400",
  fontSize: "20px",
  borderRadius: "4px",
  borderColor: "transparent",
  marginRight: "30px",
  marginBottom: "30px"
} as React.CSSProperties;

export const cookieConsentContentStyles = {
  padding: "10px",
} as React.CSSProperties;
