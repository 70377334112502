import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import {
  Icon,
  Label,
  ErrorLabel,
  Input,
  RadioButton,
  Title,
  DateInput,
  CompaniesHouseConsumer,
} from '@local';

import { validate } from '@utils';
import PhoneInput from 'react-phone-number-input';
import { FormValuesTypes } from 'src/types/formValues';
import {
  SWrapper,
  SKicker,
  SPhoneWrapper,
  SRightIconWrapper,
} from './index.styled';
import { parsePhoneNumber } from '../../../helpers/PhoneNumber/Parse';

interface CompaniesHouseDirectorProps {
  index: number;
  name: string;
  occupation: string;
  showRadioButtons: boolean;
  values?: FormValuesTypes;
  label?: string;
  disabled?: boolean;
  email?: string;
}

const CompaniesHouseDirector = ({
  index,
  name,
  occupation,
  showRadioButtons,
  values,
  label,
  disabled,
}: CompaniesHouseDirectorProps) => {
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);

  const handleSharesChange = (value: number) => {
    companiesHouseValues.persons_of_interest[index].share_percentage = value;

    let totalShares = 0;

    companiesHouseValues.persons_of_interest.forEach(person => {
      totalShares += Number(person.share_percentage);
    });

    if (totalShares > 100) {
      return (
        <ErrorLabel>
          Combined shares percentage cannot be more than 100%
        </ErrorLabel>
      );
    }
    return undefined;
  };

  return (
    <SWrapper>
      <SKicker>{occupation}</SKicker>
      <Title className="size-md-medium no-margin-top bold">{name}</Title>
      <Field
        name={`personofinterest${index}email`}
        validate={validate('Email', ['REQUIRED', 'EMAIL', 'MAXLENGTH(60)'])}>
        {({ input, meta }) => (
          <>
            <Input
              {...input}
              {...meta}
              label="Email"
              name={`personofinterest${index}email`}
              icon="MAIL"
              placeholder="Enter email"
            />
            {meta.error && meta.touched && !meta.active && meta.error}
          </>
        )}
      </Field>
      <Field
        name={`personofinterest${index}phonenumber`}
        validate={validate('Phone number', ['REQUIRED', 'PHONENUMBER'])}>
        {({ input, meta }) => (
          <>
            <Label {...{ name, label, disabled }} />
            <SPhoneWrapper
              error={meta.error}
              touched={meta.touched}
              active={meta.active}>
              <PhoneInput
                placeholder="E.g 7911 123456"
                defaultCountry="GB"
                onKeyUp={() =>
                  parsePhoneNumber(
                    `personofinterest${index}phonenumber`,
                    values,
                  )
                }
                {...input}
                value={input.value}
                data-testid={`personofinterest${index}phonenumber`}
              />
              {meta.error && meta.touched && !meta.active && (
                <SRightIconWrapper>
                  <Icon icon="WARNING" />
                </SRightIconWrapper>
              )}
            </SPhoneWrapper>
            <ErrorLabel>
              {meta.error && meta.touched && !meta.active && meta.error}
            </ErrorLabel>
          </>
        )}
      </Field>
      {showRadioButtons && (
        <Field
          name="AuthorizedSignatory"
          key="AuthorizedSignatory"
          type="radio"
          value={index.toString()}
          validate={validate('Choosing authorized signatory', ['REQUIRED'])}>
          {({ input, meta }) => {
            return (
              <>
                <RadioButton
                  value={index.toString()}
                  name="AuthorizedSignatory"
                  labelText="The authorized signatory"
                  onChange={input.onChange}
                  checked={input.checked}
                />
                {meta.error && meta.touched && !meta.active && meta.error}
              </>
            );
          }}
        </Field>
      )}
      <Field
        name={`personofinterest${index}sharepercentage`}
        validate={v => handleSharesChange((v as unknown) as number)}
        defaultValue={
          (values?.CompaniesHouseData.persons_of_interest[index]
            ?.share_percentage as unknown) as string
        }>
        {({ input, meta }) => (
          <>
            <Input
              {...input}
              {...meta}
              label="Shares %"
              name={`personofinterest${index}SharePercentage`}
              icon="PIECHART"
              placeholder="% Shares"
              value={input.value}
            />
            {meta.error && meta.touched && !meta.active && meta.error}
          </>
        )}
      </Field>
      <Field
        name={`personofinterest${index}address.street`}
        defaultValue={
          values?.CompaniesHouseData.persons_of_interest[index]?.address
            .address_line_1 as string
        }
        validate={validate('Address', ['REQUIRED', 'MAXLENGTH(50)'])}
      >
        {({ input, meta }) => (
          <>
            <Input
              {...input}
              {...meta}
              label="Address"
              name={`personofinterest${index}address.street`}
              icon="HOUSE"
              placeholder="Enter home address"
              value={input.value}
            />
            {meta.error && meta.touched && !meta.active && meta.error}
          </>
        )}
      </Field>
      <Field
        name={`personofinterest${index}address.street2`}
        defaultValue={
          values?.CompaniesHouseData.persons_of_interest[index]?.address
            .address_line_2 as string
        }>
        {({ input, meta }) => (
          <>
            <Input
              {...input}
              {...meta}
              label="Address 2"
              name={`personofinterest${index}address.street2`}
              icon="HOUSE"
              placeholder="Enter home address2"
              value={input.value}
            />
            {meta.error && meta.touched && !meta.active && meta.error}
          </>
        )}
      </Field>
      <Field
        name={`personofinterest${index}address.postal_code`}
        defaultValue={
          values?.CompaniesHouseData.persons_of_interest[index]?.address
            .postal_code as string
        }
        validate={validate('Postal Code', ['REQUIRED', 'MAXLENGTH(10)', 'MINLENGTH(3)'])}
      >
        {({ input, meta }) => (
          <>
            <Input
              {...input}
              {...meta}
              label="Postal Code"
              name={`personofinterest${index}address.postal_code`}
              icon="HOUSE"
              placeholder="Enter postal_code"
              value={input.value}
            />
            {meta.error && meta.touched && !meta.active && meta.error}
          </>
        )}
      </Field>
      <Field
        name={`personofinterest${index}address.locality`}
        defaultValue={
          values?.CompaniesHouseData.persons_of_interest[index]?.address
            .locality as string
        }
        validate={validate('Town/City', ['REQUIRED', 'MAXLENGTH(100)'])}
      >
        {({ input, meta }) => (
          <>
            <Input
              {...input}
              {...meta}
              label="Town/City"
              name={`personofinterest${index}address.locality`}
              icon="HOUSE"
              placeholder="Enter locality"
              value={input.value}
            />
            {meta.error && meta.touched && !meta.active && meta.error}
          </>
        )}
      </Field>
      <DateInput
        name={`personofinterest${index}.dateofbirth` as string}
        preselectDay={
          values?.CompaniesHouseData.persons_of_interest[index]?.date_of_birth
            .day as string
        }
        preselectMonth={
          values?.CompaniesHouseData.persons_of_interest[index]?.date_of_birth
            .month as string
        }
        preselectYear={
          values?.CompaniesHouseData.persons_of_interest[index]?.date_of_birth
            .year as string
        }
        // eslint-disable-next-line react/jsx-boolean-value
        personsBirthday={true}
      />
    </SWrapper>
  );
};

export { CompaniesHouseDirector };
