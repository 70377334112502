import { ConditionalInterface } from 'src/types/field';
import {
  ConditionalAugmentProps,
  referenceHasCorrectValue,
} from '../augmentorUtils';

const conditionalDisable = (
  conditionalAugment: ConditionalAugmentProps,
  conditional: ConditionalInterface,
) => {
  const { field } = conditionalAugment;
  // Check if the reference value is the same as desired value
  const correctValue = referenceHasCorrectValue(
    conditionalAugment,
    conditional,
  );
  // If the field has not been declared disabled, it's expected to be enabled
  const disabled = field.disabled || false;

  if (!correctValue) {
    // Keeps the initial disabled/enabled if the value is incorrect
    return { ...field, disabled };
  }
  // Swaps disabled/enabled if the value is correct
  return { ...field, disabled: !disabled };
};

export { conditionalDisable };
