import React, { FunctionComponent } from 'react';
import { BulletList } from '@local';

import { BulletProps } from '../../BulletList';
import { SImage, SContent } from './index.styled';

interface TerminalProps {
  title: string;
  image: ImageProps;
  list: BulletProps[];
  kicker: string;
  index?: number;
  price?: string;
  maxValue?: number;
  minValue?: number;
  buyName?: string;
  defaultValue?: number;
  type?: string;
  value?: string
}

interface ImageProps {
  name: string;
  alt: string;
}

const Terminal: FunctionComponent<TerminalProps> = ({
  image,
  title,
  kicker,
  list,
  children,
}) => (
  <>
    <div className="col-sm-4 col-md-2 col-lg-2 offset-sm-1">
      <SImage
        src={`/static/images/terminals/${image.name}.png`}
        alt={image.alt}
      />
    </div>
    <SContent className="col-sm-4 col-md-3 col-lg-4 offset-sm-1 offset-lg-1">
      <BulletList title={title} kicker={kicker} list={list} />
      {children}
    </SContent>
  </>
);

export { Terminal };
export type { TerminalProps };