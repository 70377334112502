import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useSpring, animated } from 'react-spring';
import { FieldTypes } from 'src/types/field';
import { ComponentMap } from '../../../helpers/componentMapper/index';

interface FadeAnimationProps {
  component: string;
  form?: FormRenderProps['form'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
  name?: string;
  className?: string;
}

const FadeAnimation = ({
  component,
  values,
  name,
}: FadeAnimationProps) => {
  const animationStyle = useSpring({
    config: { duration: 1000 },
    opacity: 1,
    from: { opacity: 0 },
    reverse: false,
  });
  if (name && name !== 'Missing name') {
    // eslint-disable-next-line no-param-reassign
    ((component as unknown) as FieldTypes).id = name;
    // eslint-disable-next-line no-param-reassign
    ((component as unknown) as FieldTypes).name = name;
  }

  return (
    <animated.div style={animationStyle}>
      <ComponentMap field={component} values={values} />
    </animated.div>
  );
};

export { FadeAnimation };
