import styled, { css } from 'styled-components';

export const SButtonWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: ${breakpoints.tabletMin}) {
      flex-direction: row;
      justify-content: space-between;
    }
  `};
`;

export const SAccordionListWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    margin-bottom: ${grid.horizontal * 4}px;
  `}
`;

export const SAccordionWrapper = styled.div`
  ${({ theme: { grid } }) => css`
    margin-top: ${grid.horizontal}px;
    overflow: visible;
  `}
`;

export const SAddButton = styled.button`
  ${({ theme: { color, grid, breakpoints } }) => css`
    margin-bottom: ${grid.horizontal * 1}px;
    font-weight: 400;
    font-size: 18px;
    background-color: ${color.teal};
    color: white;
    border-color: transparent;
    height: ${grid.horizontal * 3}px;

    &:disabled:hover {
      box-shadow: none;
      transform: none;
      background-color: ${color.teal50};
    }
    &:disabled {
      box-shadow: none;
      transform: none;
      background-color: ${color.teal50};
      svg {
        rect {
          fill: ${color.teal50};
        }
      }
    }
    align-items: center;

    svg {
      rect {
        fill: ${color.teal};
      }
      path {
        fill: ${color.white};
      }
    }

    @media (min-width: ${breakpoints.tabletMin}) {
      width: ${grid.vertical * 25}px;
      margin-bottom: 0px;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
      transform: translate3d(0, 0, 0);
      box-shadow: 0 0 0 rgba(79, 158, 166, 0.3);
      transition: transform 200ms, box-shadow 200ms ease;
      &:hover {
        transform: translate3d(0, -1px, 0);
        box-shadow: 7px 9px 0px rgba(79, 158, 166, 0.3);
        transition: transform 200ms, box-shadow 200ms ease;
      }
    }
  `}
`;

export const SRemoveButton = styled.button`
  ${({ theme: { color, grid, breakpoints } }) => css`
    font-weight: 400;
    font-size: 18px;
    background: ${color.white};
    color: ${color.teal};
    border: 2px solid ${color.teal};
    border-radius: 2px;
    border-color: ${color.teal};

    svg {
      path:nth-child(1) {
        fill: ${color.white};
      }
      path:nth-child(2) {
        fill: ${color.teal};
      }
    }
    align-items: center;

    height: ${grid.horizontal * 3}px;

    @media (min-width: ${breakpoints.tabletMin}) {
      width: ${grid.vertical * 25}px;
      margin-bottom: 0px;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
      transform: translate3d(0, 0, 0);
      box-shadow: 0 0 0 rgba(79, 158, 166, 0.3);
      transition: transform 200ms, box-shadow 200ms ease;
      &:hover {
        transform: translate3d(0, -1px, 0);
        box-shadow: 7px 9px 0px rgba(79, 158, 166, 0.3);
        transition: transform 200ms, box-shadow 200ms ease;
      }
    }
  `}
`;
