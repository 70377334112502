import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { CompaniesHouseConsumer } from '@local';
import { FormValuesTypes } from 'src/types/formValues';
import { SWrapper } from './index.styled';

export interface CompaniesHouseResultProps {
  values?: FormValuesTypes;
}

const CompaniesHouseResults = ({ values }: CompaniesHouseResultProps) => {
  const { companiesHouseValues } = useContext(CompaniesHouseConsumer);
  const {
    company_name,
    company_number,
    company_status,
    company_status_text,
    registered_office_address,
  } = companiesHouseValues;
  const {
    address_line_1,
    address_line_2,
    country,
    locality,
    postal_code,
  } = registered_office_address;

  if (values) {
    // eslint-disable-next-line no-param-reassign
    values.CompaniesHouseData = companiesHouseValues;

    // eslint-disable-next-line no-param-reassign
    values.CompaniesHouseData.search_date = dayjs().format();
  }
  // We have not gotten the criteria of what happens if the company status is anything but Active.
  // For now you can continue even if you have an inactive company

  return (
    <>
      {company_name !== '' ? (
        <SWrapper error={company_status !== 'active'}>
          <dl>
            {company_number && (
              <>
                <dt>Company number</dt>
                <dd data-testid="companyNumber">{company_number}</dd>
              </>
            )}
            {company_status && (
              <>
                <dt>Company status</dt>
                <dd data-testid="companyStatusText">{company_status_text}</dd>
              </>
            )}
            {company_name && (
              <>
                <dt>Company name</dt>
                <dd data-testid="companyName">{company_name}</dd>
              </>
            )}
            {registered_office_address && (
              <>
                <dt>Company address</dt>
                <dd data-testid="companyAddress">
                  {address_line_1 && `${address_line_1}, `}
                  {address_line_2 && `${address_line_2}, `}
                  {locality},
                  {!country || country.length === 0 ? ' ' : `${country}, `}
                  {postal_code}
                </dd>
              </>
            )}
          </dl>
        </SWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export { CompaniesHouseResults };
