import React, { useState } from 'react';
import { Field, AnyObject } from 'react-final-form';
import ObjectPath from 'object-path';
import { RadioButton, ErrorLabel, Label } from '@local';
import { validate } from '@utils';
import { RadioButtonProps } from './RadioButton';
import { SWrapper } from './index.styled';

interface RadioButtonsProps {
  radioValues?: RadioButtonProps[];
  values?: AnyObject;
  validations?: string[];
  label?: string;
  name?: string;
}

const RadioButtons = ({
  radioValues,
  validations,
  values,
  label,
  name
}: RadioButtonsProps) => {
  const [error, setError] = useState(undefined);
  return (
    <SWrapper>
      {label && name && <Label {...{ name, label }} />}
      {radioValues
        ? radioValues.map(({ value, name: radioName, labelText, checked }) => {
            return (
              <Field
                initialValue={checked ? value : undefined}
                name={radioName}
                key={value}
                type="radio"
                value={value}
                validate={validate('Radio', validations)}>
                {({ input, meta }) => {
                  setError(
                    meta.error && meta.touched && !meta.active && meta.error,
                  );

                  return (
                    <RadioButton
                      {...meta}
                      value={value}
                      name={radioName}
                      labelText={labelText}
                      onChange={input.onChange}
                      checked={
                        values
                          ? values[radioName] === value ||
                          ObjectPath.get(values, radioName) === value
                          : false
                      }
                    />
                  );
                }}
              </Field>
            );
          })
        : null}
      <ErrorLabel>{error}</ErrorLabel>
    </SWrapper>
  );
};

export { RadioButtons };
export type { RadioButtonsProps };