import React, { FunctionComponent, SyntheticEvent } from 'react';

import { SWrapper } from './index.styled';

interface RadioButtonProps {
  labelText: string;
  name: string;
  value: string;
  disabled?: boolean;
  checked?: boolean;
  hiddenStatusDependsOn?: string;
  onChange?: (ev: SyntheticEvent) => void;
}

const RadioButton: FunctionComponent<RadioButtonProps> = ({
  checked,
  labelText,
  onChange,
  name,
  value,
  disabled,
}) => (
    <SWrapper htmlFor={value}>
      <input
        type="radio"
        id={value}
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        data-testid={value}
      />
      <span className="labelText">{labelText}</span>
      <span className="checkmark" />
    </SWrapper>

  );

export { RadioButton };
export type { RadioButtonProps };
