const MCCODES = [
  {"value":"742","label":"Veterinary Services","category":"Professional Services"},
  {"value":"763","label":"Agricultural Co-operatives","category":"Professional Services"},
  {"value":"780","label":"Landscaping and Horticultural Services","category":"Construction"},
  {"value":"1520","label":"General Contractors, Residential and Commercial","category":"Construction"},
  {"value":"1711","label":"Heating, Plumbing and Air Conditioning Contractors","category":"Construction"},
  {"value":"1731","label":"Electrical Contractors","category":"Construction"},
  {"value":"1740","label":"Masonry, Stonework, Tile Setting, Plastering, and Insulation Contractors","category":"Construction"},
  {"value":"1750","label":"Carpentry Contractors","category":"Construction"},
  {"value":"1761","label":"Roofing, Siding, and Sheet Metal Work Contractors","category":"Construction"},
  {"value":"1771","label":"Concrete Work Contractors","category":"Construction"},
  {"value":"1799","label":"Special Trade Contractors - No Elsewhere Classified","category":"Construction"},
  {"value":"2741","label":"Miscellaneous Publishing and Printing","category":"Printing and Publishing"},
  {"value":"2791","label":"Typesetting, Platemaking and Related Services","category":"Printing and Publishing"},
  {"value":"2842","label":"Specialty Cleaning, Polishing, and Sanitation Preparations","category":"Chemical Products and Supplies"},
  {"value":"4011","label":"Railroads","category":"Transportation Services"},
  {"value":"4111","label":"Local And Suburban Commuter Passanger Transport, include Ferries","category":"Transportation Services"},
  {"value":"4112","label":"Passenger Railways","category":"Transportation Services"},
  {"value":"4121","label":"Taxicabs and Limousines","category":"Transportation Services"},
  {"value":"4131","label":"Bus Lines","category":"Transportation Services"},
  {"value":"4214","label":"Motor Freight Carriers","category":"Transportation Services"},
  {"value":"4215","label":"Courier Services - Air or Ground, and Freight Forwarders","category":"Transportation Services"},
  {"value":"4225","label":"Public Warehousing-Farm Products, Refrigerated Goods","category":"Specialty Retail Merchants"},
  {"value":"4411","label":"Steamship Lines and Cruise Lines","category":"Transportation Services"},
  {"value":"4457","label":"Boat Rentals and Leasing/Rentals - Boats","category":"Rental Services"},
  {"value":"4468","label":"Marinas, Marine Service, and Supply","category":"Rental Services"},
  {"value":"4511","label":"Airlines and Air Carriers","category":"Transportation Services"},
  {"value":"4582","label":"Airports, Flying Fields, and Airport Terminals","category":"Transportation Services"},
  {"value":"4722","label":"Travel Agencies and Tour Operators","category":"Transportation Services"},
  {"value":"4784","label":"Toll and Bridge Fees","category":"Transportation Services"},
  {"value":"4789","label":"Travel Service - Not Elsewhere Classified","category":"Transportation Services"},
  {"value":"4812","label":"Telecommunication Equipment and Telephone Sales","category":"Telecommunications"},
  {"value":"4814","label":"Telecommunication Service","category":"Telecommunications"},
  {"value":"4816","label":"Computer Network/Information Service","category":"Telecommunications"},
  {"value":"4821","label":"Telegraph Services","category":"Telecommunications"},
  {"value":"4829","label":"Wire Transfer Money Order","category":"Financial Services"},
  {"value":"4899","label":"Cable, Satellite, and other pay TV & radio","category":"Telecommunications"},
  {"value":"4900","label":"Utilities -Electric, Gas, Water and Sanitary","category":"Specialty Retail Merchants"},
  {"value":"5013","label":"Motor Vehicle Supplies and New Parts","category":"Automotive"},
  {"value":"5021","label":"Office and Commercial Furniture","category":"Office Furniture and Supplies"},
  {"value":"5039","label":"Construction Materials, Not Elsewhere Classified","category":"Construction Materials and Supplies"},
  {"value":"5044","label":"Office, Photografic, Photocopy and Microfilm Equipment","category":"Commercial Equipment"},
  {"value":"5045","label":"Computers, Computer Peripherals Equipment, Software","category":"Electronic and Computer"},
  {"value":"5046","label":"Commercial Equipment, Not Elsewhere Classified","category":"Commercial Equipment"},
  {"value":"5047","label":"Dental/Med/Hospital/Lab Equip & Supplies","category":"Miscellaneous Wholesalers"},
  {"value":"5051","label":"Metal Service Centers and Offices","category":"Construction Materials and Supplies"},
  {"value":"5065","label":"Electrical Parts and Equipment","category":"Electronic and Computer"},
  {"value":"5072","label":"Hardware Equipment and Supplies","category":"Commercial Equipment"},
  {"value":"5074","label":"Plumbing and Heating Equipment and Supplies","category":"Construction Materials and Supplies"},
  {"value":"5085","label":"Industrial Supplies, Not Elsewhere Classified","category":"Commercial Equipment"},
  {"value":"5094","label":"Precious Stones and Metals, Watches and Jewelry","category":"Miscellaneous Wholesalers"},
  {"value":"5099","label":"Durable Goods, Not Elsewhere Classified","category":"Durable and Non-Durable Goods"},
  {"value":"5111","label":"Stationery, Office Supplies and Printing and Writing Paper","category":"Office Furniture and Supplies"},
  {"value":"5122","label":"Drugs, Drug Proprietaries, and Druggist's Sundries","category":"Medical"},
  {"value":"5131","label":"Piece Goods, Notions, and Other Dry Goods","category":"Durable and Non-Durable Goods"},
  {"value":"5137","label":"Men's, Women's, and Children's Uniforms and Commercial Clothing","category":"Clothing and Footwear"},
  {"value":"5139","label":"Commercial Footwear","category":"Clothing and Footwear"},
  {"value":"5169","label":"Chemicals and Allied Products, Not Elsewhere Classified","category":"Chemical Products and Supplies"},
  {"value":"5172","label":"Petroleum and Petroleum Products","category":"Durable and Non-Durable Goods"},
  {"value":"5192","label":"Books, Periodicals, and Newspapers","category":"Printing and Publishing"},
  {"value":"5193","label":"Florists' Supplies, Nursery Stock, and Flowers","category":"Miscellaneous Wholesalers"},
  {"value":"5198","label":"Paints, Varnishes, and Supplies","category":"Chemical Products and Supplies"},
  {"value":"5199","label":"Nondurable Goods, Not Elsewhere Classified","category":"Durable and Non-Durable Goods"},
  {"value":"5200","label":"Home Supply Warehouse Stores","category":"Home and Office Furnishing"},
  {"value":"5211","label":"Lumber and Building Materials Stores","category":"Home Improvement"},
  {"value":"5231","label":"Glass, Paint, and Wallpaper Stores","category":"Home Improvement"},
  {"value":"5251","label":"Hardware Stores","category":"Home Improvement"},
  {"value":"5261","label":"Nurseries and Lawn and Garden Supply Stores","category":"Home Improvement"},
  {"value":"5271","label":"Mobile Home Dealers","category":"Recreational"},
  {"value":"5300","label":"Wholesale Clubs","category":"Associations/Memberships"},
  {"value":"5309","label":"Duty Free Stores","category":"Department and Discount Stores"},
  {"value":"5310","label":"Discount Stores","category":"Department and Discount Stores"},
  {"value":"5311","label":"Department Stores","category":"Department and Discount Stores"},
  {"value":"5331","label":"Variety Stores","category":"Department and Discount Stores"},
  {"value":"5399","label":"Miscellaneous General Merchandise","category":"Department and Discount Stores"},
  {"value":"5411","label":"Grocery Stores and Supermarkets","category":"Food and Drink"},
  {"value":"5422","label":"Meat Provisioners - Freezer and Locker","category":"Food and Drink"},
  {"value":"5441","label":"Candy, Nut, and Confectionery Stores","category":"Food and Drink"},
  {"value":"5451","label":"Dairy Product Stores","category":"Food and Drink"},
  {"value":"5462","label":"Bakeries","category":"Food and Drink"},
  {"value":"5499","label":"Miscellaneous Food Stores - Convenience Stores and Specialty Markets","category":"Food and Drink"},
  {"value":"5511","label":"New & Used Car/Truck - Sales/Svc/Leasing","category":"Automotive Related Merchants"},
  {"value":"5521","label":"Used Car/Truck - Sales/Svc/Leasing","category":"Automotive Related Merchants"},
  {"value":"5532","label":"Automotive Tire Stores","category":"Automotive Related Merchants"},
  {"value":"5533","label":"Automotive Parts and Accessories Stores","category":"Automotive Related Merchants"},
  {"value":"5541","label":"Service Stations","category":"Automotive Related Merchants"},
  {"value":"5542","label":"Automated Fuel Dispensers","category":"Automotive Related Merchants"},
  {"value":"5551","label":"Boat Dealers","category":"Specialty Retail Merchants"},
  {"value":"5561","label":"Camper, Recreational, and Utility Trailer Dealers","category":"Recreational"},
  {"value":"5571","label":"Motorcycle Shops and Dealers","category":"Specialty Retail Merchants"},
  {"value":"5592","label":"Motor Home Dealers","category":"Recreational"},
  {"value":"5598","label":"Snowmobile Dealers","category":"Recreational"},
  {"value":"5599","label":"Miscellaneous Automotive, Aircraft, and Farm Equipment Dealers","category":"Automotive Related Merchants"},
  {"value":"5611","label":"Men's and Boy's Clothing and Accessories Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5621","label":"Women's Ready-To-Wear Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5631","label":"Women's Accessory and Specialty Shops","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5641","label":"Children's and Infant's Wear Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5651","label":"Family Clothing Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5655","label":"Sports and Riding Apparel Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5661","label":"Shoe Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5681","label":"Furriers and Fur Shops","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5691","label":"Men's and Women's Clothing Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5697","label":"Tailors and Seamstresses, Mending and Alterations","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5698","label":"Wig And Toupee Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5699","label":"Miscellaneous Apparel and Accessory Stores","category":"Apparel, Clothing, and Related Merchants"},
  {"value":"5712","label":"Furniture, Home Furnishings, and Equipment Stores, Except Appliances","category":"Home and Office Furnishing"},
  {"value":"5713","label":"Floor Covering Stores","category":"Home and Office Furnishing"},
  {"value":"5714","label":"Drapery, Window Covering, and Upholstery Stores","category":"Home and Office Furnishing"},
  {"value":"5718","label":"Fireplaces, Fireplace Screens, and Fireplace Accessories Stores","category":"Home and Office Furnishing"},
  {"value":"5719","label":"Miscellaneous Home Furnishing Specialty Stores","category":"Home and Office Furnishing"},
  {"value":"5722","label":"Household Appliance Stores","category":"Home and Office Furnishing"},
  {"value":"5732","label":"Electronics Stores","category":"Electronics"},
  {"value":"5733","label":"Music Stores/Insturments/Pianos","category":"Specialty Retail Merchants"},
  {"value":"5734","label":"Compute Software Store","category":"Electronics"},
  {"value":"5735","label":"Record Stores","category":"Entertainment"},
  {"value":"5811","label":"Caterers","category":"Eating/Drinking Places"},
  {"value":"5812","label":"Eating Places and Restaurants","category":"Eating/Drinking Places"},
  {"value":"5813","label":"Bars/Taverns/Lounges/Discos","category":"Eating/Drinking Places"},
  {"value":"5814","label":"Fast Food Restaurants","category":"Eating/Drinking Places"},
  {"value":"5815","label":"Digital Goods - Audiovisual Media Including Books, Movies and Music","category":"Entertainment"},
  {"value":"5816","label":"Digital Goods - Games","category":"Electronic and Computer"},
  {"value":"5817","label":"Digital Goods - Software Applications (Excluding Games)","category":"Electronic and Computer"},
  {"value":"5818","label":"Digital Goods - Multicategory","category":"Electronic and Computer"},
  {"value":"5912","label":"Drug Stores and Pharmacies","category":"Health Care Services"},
  {"value":"5921","label":"Package Stores - Beer, Wine and Liquor","category":"Food and Drink"},
  {"value":"5931","label":"Used Merchandise and Secondhand Stores","category":"Antique Shops, Pawn Shops, and Related Merchants"},
  {"value":"5932","label":"Antique Shops","category":"Antique Shops, Pawn Shops, and Related Merchants"},
  {"value":"5933","label":"Pawn Shops","category":"Antique Shops, Pawn Shops, and Related Merchants"},
  {"value":"5935","label":"Wrecking and Salvage Yards","category":"Automotive Related Merchants"},
  {"value":"5937","label":"Antique Reproductions","category":"Antique Shops, Pawn Shops, and Related Merchants"},
  {"value":"5940","label":"Bicycle Shops - Sales and Service","category":"Recreational"},
  {"value":"5941","label":"Sporting Goods Stores","category":"Recreational"},
  {"value":"5942","label":"Book Stores","category":"Specialty Retail Merchants"},
  {"value":"5943","label":"School, Stationery, and Office Supply Stores","category":"Specialty Retail Merchants"},
  {"value":"5944","label":"Jewelry, Watch, Clock, and Silverware Stores","category":"Specialty Retail Merchants"},
  {"value":"5945","label":"Hobby, Toy, and Game Shops","category":"Art, Crafts, Hobbies, and Related Merchants"},
  {"value":"5946","label":"Camera and Photographic Supply Stores","category":"Photography and Photocopying Services"},
  {"value":"5947","label":"Gift, Card, Novelty, and Souvenir Stores","category":"Specialty Retail Merchants"},
  {"value":"5948","label":"Luggage and Leather Goods Stores","category":"Specialty Retail Merchants"},
  {"value":"5949","label":"Sewing, Needlework, Fabric, and Piece Goods Stores","category":"Art, Crafts, Hobbies, and Related Merchants"},
  {"value":"5950","label":"Glassware and Crystal Stores","category":"Specialty Retail Merchants"},
  {"value":"5960","label":"Direct Marketing - Insurance Services","category":"Direct Marketing"},
  {"value":"5962","label":"Direct Marketing - Travel Related Arrangement Services","category":"Direct Marketing"},
  {"value":"5963","label":"Door-to-Door Sales","category":"Direct Marketing"},
  {"value":"5964","label":"Direct Marketing - Catalog Merchants","category":"Direct Marketing"},
  {"value":"5965","label":"Direct Marketing - Combination Catalog and Retail Merchants","category":"Direct Marketing"},
  {"value":"5966","label":"Direct Marketing - Outbound Telemarketing Merchants","category":"Direct Marketing"},
  {"value":"5967","label":"Direct Marketing - Inbound Telemarketing Merchants","category":"Direct Marketing"},
  {"value":"5968","label":"Direct Marketing - Continuity/ Subscription Merchants","category":"Direct Marketing"},
  {"value":"5969","label":"Direct Marketing/Direct Marketers - Not Elsewhere Classified","category":"Direct Marketing"},
  {"value":"5970","label":"Artist's Supply and Craft Shops","category":"Art, Crafts, Hobbies, and Related Merchants"},
  {"value":"5971","label":"Art Dealers and Galleries","category":"Art, Crafts, Hobbies, and Related Merchants"},
  {"value":"5972","label":"Stamp and Coin Stores","category":"Art, Crafts, Hobbies, and Related Merchants"},
  {"value":"5973","label":"Religious Goods Stores","category":"Specialty Retail Merchants"},
  {"value":"5975","label":"Hearing Aids - Sales, Service, and Supplies","category":"Health Care Services"},
  {"value":"5976","label":"Orthopedic Goods and Prosthetic Devices","category":"Health Care Services"},
  {"value":"5977","label":"Cosmetic Stores","category":"Personal Services"},
  {"value":"5978","label":"Typewriter Stores - Sales, Service, and Rentals","category":"Specialty Retail Merchants"},
  {"value":"5983","label":"Fuel Dealers","category":"Specialty Retail Merchants"},
  {"value":"5992","label":"Florists","category":"Specialty Retail Merchants"},
  {"value":"5993","label":"Cigar Stores and Stands","category":"Specialty Retail Merchants"},
  {"value":"5994","label":"News Dealers and Newsstands","category":"Specialty Retail Merchants"},
  {"value":"5995","label":"Pet Shops, Pet Foods, and Supplies","category":"Specialty Retail Merchants"},
  {"value":"5996","label":"Swimming Pools - Sales, Supplies, and Service","category":"Specialty Retail Merchants"},
  {"value":"5997","label":"Electric Razor Stores - Sales and Service","category":"Specialty Retail Merchants"},
  {"value":"5998","label":"Tent And Awning Shops","category":"Recreational"},
  {"value":"5999","label":"Miscellaneous and Specialty Retail Stores","category":"Specialty Retail Merchants"},
  {"value":"6010","label":"Financial Inst/Manual Cash","category":"Financial Services"},
  {"value":"6011","label":"Financial Inst/Auto Cash Disbursements","category":"Financial Services"},
  {"value":"6012","label":"Financial Inst/Merchandise and Services","category":"Financial Services"},
  {"value":"6051","label":"Non-Fin Inst - Foreign Currency, Money Orders","category":"Financial Services"},
  {"value":"6211","label":"Security Brokers and Dealers","category":"Financial Services"},
  {"value":"6300","label":"Insurance Sales, Underwriting, and Premiums","category":"Insurance Services"},
  {"value":"6513","label":"Real Estate Agents & Managers - Rentals","category":"Personal Services"},
  {"value":"7011","label":"Lodging - Hotels, Motels, and Resorts","category":"Lodging/Accommodations"},
  {"value":"7012","label":"Timeshares","category":"Lodging/Accommodations"},
  {"value":"7032","label":"Sporting and Recreational Camps","category":"Recreational"},
  {"value":"7033","label":"Trailer Parks and Campgrounds","category":"Recreational"},
  {"value":"7210","label":"Laundry, Cleaning, and Garment Services","category":"Cleaning/Laundry Services"},
  {"value":"7211","label":"Laundry Services - Family and Commercial","category":"Cleaning/Laundry Services"},
  {"value":"7216","label":"Dry Cleaners","category":"Cleaning/Laundry Services"},
  {"value":"7217","label":"Carpet and Upholstery Cleaning","category":"Cleaning/Laundry Services"},
  {"value":"7221","label":"Photographic Studios","category":"Photography and Photocopying Services"},
  {"value":"7230","label":"Beauty and Barber Shops","category":"Personal Services"},
  {"value":"7251","label":"Hat Cleaning Shops","category":"Repair Services"},
  {"value":"7261","label":"Funeral Services and Crematories","category":"Professional Services"},
  {"value":"7273","label":"Dating and Escort Services","category":"Personal Services"},
  {"value":"7276","label":"Tax Preparation Service","category":"Personal Services"},
  {"value":"7277","label":"Counseling Services - Debt, Marriage, Personal","category":"Personal Services"},
  {"value":"7278","label":"Buying and Shopping Services and Clubs","category":"Personal Services"},
  {"value":"7296","label":"Clothing Rental - Costumes, Uniforms, and Formal Wear","category":"Rental Services"},
  {"value":"7297","label":"Massage Parlors","category":"Personal Services"},
  {"value":"7298","label":"Health & Beauty Spas","category":"Personal Services"},
  {"value":"7299","label":"Miscellaneous Personal Services (Not Elsewhere Classified)","category":"Personal Services"},
  {"value":"7311","label":"Advertising Services","category":"Professional Services"},
  {"value":"7321","label":"Consumer Credit Reporting Agencies","category":"Personal Services"},
  {"value":"7333","label":"Commercial Photography, Art, and Graphics","category":"Photography and Photocopying Services"},
  {"value":"7338","label":"Quick Copy, Reproduction and Blueprinting Services","category":"Photography and Photocopying Services"},
  {"value":"7339","label":"Stenographic and Secretarial Support Services","category":"Professional Services"},
  {"value":"7342","label":"Exterminating and Disinfecting Services","category":"Cleaning/Laundry Services"},
  {"value":"7349","label":"Cleaning and Maintenance, Janitorial Services","category":"Cleaning/Laundry Services"},
  {"value":"7361","label":"Employment Agencies and Temporary Help Services","category":"Personal Services"},
  {"value":"7372","label":"Computer Programming, Data Processing, Integrated Systems","category":"Professional Services"},
  {"value":"7375","label":"Information Retrieval Services","category":"Miscellaneous Wholesalers"},
  {"value":"7379","label":"Computer Maintenance and Repair Services","category":"Electronic and Computer"},
  {"value":"7392","label":"Management Consulting and Public Relations Services","category":"Personal Services"},
  {"value":"7393","label":"Detective Agencies, Protective Agencies, and Security Services","category":"Security Services"},
  {"value":"7394","label":"Equipment, Tool, Furniture, and Appliance Rental and Leasing","category":"Rental Services"},
  {"value":"7395","label":"Photofinishing Laboratories and Photo Developing","category":"Photography and Photocopying Services"},
  {"value":"7399","label":"Business Services, Not Elsewhere Classified","category":"Business Services"},
  {"value":"7512","label":"Automobile Rental Agency","category":"Rental Services"},
  {"value":"7513","label":"Truck and Utility Trailer Rentals","category":"Rental Services"},
  {"value":"7519","label":"Motor Home and Recreational Vehicle Rentals","category":"Rental Services"},
  {"value":"7523","label":"Parking Lots and Garages","category":"Automotive Related Merchants"},
  {"value":"7531","label":"Automotive Body Repair Shops","category":"Repair Services"},
  {"value":"7534","label":"Tire Retreading and Repair Shops","category":"Automotive Related Merchants"},
  {"value":"7535","label":"Automotive Paint Shops","category":"Automotive Related Merchants"},
  {"value":"7538","label":"Automotive Service Shops (Non-Dealer)","category":"Automotive Related Merchants"},
  {"value":"7542","label":"Car Washes","category":"Cleaning/Laundry Services"},
  {"value":"7549","label":"Towing Services","category":"Automotive Related Merchants"},
  {"value":"7622","label":"Electronics Repair Shops","category":"Repair Services"},
  {"value":"7623","label":"Air Conditioning Repair Shops/Refrigeration Repair Shops","category":"Repair Services"},
  {"value":"7629","label":"Electrical/Appliance Repair Shops","category":"Repair Services"},
  {"value":"7631","label":"Watch, Clock, and Jewelry Repair Shops","category":"Repair Services"},
  {"value":"7641","label":"Furniture - Reupholstery, Repair and Refinishing","category":"Repair Services"},
  {"value":"7692","label":"Welding Services","category":"Repair Services"},
  {"value":"7699","label":"Miscellaneous Repair Shops and Related Services","category":"Repair Services"},
  {"value":"7829","label":"Motion Pictures and Video Tape Production and Distribution","category":"Printing and Publishing"},
  {"value":"7832","label":"Motion Picture Theaters","category":"Entertainment"},
  {"value":"7841","label":"Video Tape Rental Stores","category":"Rental Services"},
  {"value":"7911","label":"Dance Halls, Studios, and Schools","category":"Recreational"},
  {"value":"7922","label":"Theatrical Producers (Except Motion Pictures), and Ticket Agencies","category":"Entertainment"},
  {"value":"7929","label":"Bands, Orchestras, and Miscellaneous Entertainers","category":"Entertainment"},
  {"value":"7932","label":"Billiard and Pool Establishments","category":"Recreational"},
  {"value":"7933","label":"Bowling Alleys","category":"Recreational"},
  {"value":"7941","label":"Commercial Sports, Professional Sports Clubs, Athletic Fields, and Sports Promoters","category":"Entertainment"},
  {"value":"7991","label":"Tourist Attractions and Exhibits","category":"Recreational"},
  {"value":"7992","label":"Public Golf Courses","category":"Recreational"},
  {"value":"7993","label":"Video Amusement Game Supplies","category":"Recreational"},
  {"value":"7994","label":"Video Game Arcades and Establishments","category":"Recreational"},
  {"value":"7995","label":"Betting/Track/Casino/Lotto","category":"Recreational"},
  {"value":"7996","label":"Amusement Parks, Circuses, Carnivals, and Fortune Tellers","category":"Entertainment"},
  {"value":"7997","label":"Membership Clubs, Country Clubs, and Private Golf Courses","category":"Associations/Memberships"},
  {"value":"7998","label":"Aquariums, Seaquariums, Dolphinariums","category":"Entertainment"},
  {"value":"7999","label":"Recreational Facilities","category":"Recreational"},
  {"value":"8011","label":"Doctors and Physicians (Not Elsewhere Classified)","category":"Health Care Services"},
  {"value":"8021","label":"Dentists and Orthodontists","category":"Health Care Services"},
  {"value":"8031","label":"Osteopaths","category":"Health Care Services"},
  {"value":"8041","label":"Chiropractors","category":"Health Care Services"},
  {"value":"8042","label":"Optometrists and Ophthalmologists","category":"Health Care Services"},
  {"value":"8043","label":"Opticians, Optical Goods, and Eyeglasses","category":"Health Care Services"},
  {"value":"8049","label":"Podiatrists and Chiropodists","category":"Health Care Services"},
  {"value":"8050","label":"Convalescent Homes/Nursing and Personal Care Facilities","category":"Health Care Services"},
  {"value":"8071","label":"Medical and Dental Laboratories","category":"Health Care Services"},
  {"value":"8099","label":"Medical Services and Health Practitioners","category":"Health Care Services"},
  {"value":"8111","label":"Legal Services and Attorneys","category":"Legal Services"},
  {"value":"8220","label":"Colleges, Universities, Professional Schools, and Junior Colleges","category":"Educational Services"},
  {"value":"8241","label":"Correspondence Schools","category":"Educational Services"},
  {"value":"8244","label":"Business and Secretarial Schools","category":"Educational Services"},
  {"value":"8249","label":"Trade and Vocational Schools","category":"Educational Services"},
  {"value":"8299","label":"Schools and Educational Services","category":"Educational Services"},
  {"value":"8351","label":"Child Care Services","category":"Personal Services"},
  {"value":"8398","label":"Charitable and Social Service Organizations","category":"Associations/Memberships"},
  {"value":"8641","label":"Civic, Social, and Fraternal Associations","category":"Associations/Memberships"},
  {"value":"8651","label":"Political Organizations","category":"Associations/Memberships"},
  {"value":"8661","label":"Religious Organizations","category":"Associations/Memberships"},
  {"value":"8675","label":"Automobile Associations","category":"Associations/Memberships"},
  {"value":"8699","label":"Membership Organizations (Not Elsewhere Classified/Professional Organizations)","category":"Associations/Memberships"},
  {"value":"8734","label":"Testing Laboratories (Non-Medical)","category":"Miscellaneous Wholesalers"},
  {"value":"8911","label":"Architectural, Engineering, and Surveying Services","category":"Professional Services"},
  {"value":"8931","label":"Accounting, Auditing, and Bookkeeping Services","category":"Professional Services"},
  {"value":"8999","label":"Professional Services, Not Elsewhere Classified","category":"Professional Services"},
  {"value":"9211","label":"Court Costs, including Alimony and Child Supportt","category":"Legal Services"},
  {"value":"9223","label":"Bail and Bond Payments","category":"Legal Services"},
  {"value":"9950","label":"Intra-Company Purchases","category":"Miscellaneous Wholesalers"}
  ]

export { MCCODES };
