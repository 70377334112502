import React from 'react';
import { ComponentMap } from '../../helpers/componentMapper/index';

import { Wrapper, SWrapper } from './index.styled';

interface InputSideBySideProps {
  components: InputProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
}

interface InputProps extends JSX.Element {
  className?: string;
}

const renderComponents = (components: InputProps[]) => {
  return components.map((component: InputProps, index: number) => {
    const { className } = component;
    return (
      <SWrapper className={`space ${className || ''}`} key={index}>
        <ComponentMap field={component} />
      </SWrapper>
    );
  });
};

const InputSideBySide = ({ components }: InputSideBySideProps) => {

  return (
    <>
      <Wrapper>{renderComponents(components)}</Wrapper>
    </>
  );
};

export { InputSideBySide };
