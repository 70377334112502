import React from 'react';

import {
  CompaniesHouseInput,
  CompaniesHouseInputProps,
} from './CompaniesHouseInput';

const CompaniesHouseSearch = ({
  placeholder,
  validations,
  label,
  values,
}: CompaniesHouseInputProps) => {
  return (
    <CompaniesHouseInput
      placeholder={placeholder as string}
      validations={validations}
      label={label}
      values={values}
    />
  );
};

export { CompaniesHouseSearch };
