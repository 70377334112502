import styled, { css } from "styled-components";

export const STitle = styled.h2`
  color: white;
  margin-bottom: 10px;
`

export const SParagraph = styled.p`
  ${({ theme: { breakpoints } }) => css`
    color: white;
    font-size: 20px;

    @media (max-width: ${breakpoints.tabletMin}) {
      font-size: 18px;
    }
  `}
`;

export const SLink = styled.a`
  ${({ theme: { breakpoints } }) => css`
    color: white;
    font-size: 20px;
    text-decoration: underline;
    font-weight: bold;

    @media (max-width: ${breakpoints.tabletMin}) {
      font-size: 18px;
    }
  `}
`;