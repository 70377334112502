import React from 'react';

import { SWrapper, SInput, SIcon, SFeesInputProps } from './index.styled';

interface FeesInputProps extends SFeesInputProps {
  name: string;
}

const FeesInput = (props: FeesInputProps) => {
  // console.log('props: ', props);
  const { active, error, icon, touched, name, disabled } = props;
  return (
    <>
      <SWrapper>
        <SInput
          id={name}
          type="number"
          disabled={disabled}
          active={active}
          error={error}
          touched={touched}
          {...props}
          data-testid={name}
          pattern="([0-9]{1,3}).([0-9]{1,3})"
        />
        <SIcon
          active={active}
          disabled={disabled}
          error={error}
          touched={touched}
          icon={icon}
        />
      </SWrapper>
    </>
  );
};

export { FeesInput };
export type { FeesInputProps };
